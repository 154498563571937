/* eslint-disable no-loop-func */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getResult } from "services/formService";
import { ReactComponent as MazeStages } from "static/images/maze-stages.svg";
import { ReactComponent as MazeStagesMobile } from "static/images/maze-stages-mobile.svg";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import Button from "components/Button";
import { StyledCircle, LiCircle, Span } from "./style";

const FeelingsExplanation = () => {
  const { handleSubmit } = useForm();
  const { id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedWords, setSelectedWords] = useState([]);

  const onSubmit = () => {
    history.push("/videos/5");
  };

  const getQuestions = async (exercise, id) => {
    dispatch({ type: "LOADING", payload: true });
    try {
      const { data } = await getResult(exercise, id);

      setSelectedWords(data.answer);

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "LOADING", payload: false });
      console.log(error);
    }
  };

  useEffect(() => {
    getQuestions(4, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Circle = () => {
    let rows = [];
    let rotate = 0;

    const totalLength =
      selectedWords?.map((word) => word.text).join(" - ").length + 3;

    for (let index = 0; index < selectedWords?.length; index++) {
      const split = (selectedWords[index].text + " - ").split("");

      rows.push(
        <LiCircle key={index}>
          {split.map((letter, i) => {
            return (
              <Span rotate={(rotate + i) * (360 / totalLength )} key={i}>
                {letter}
              </Span>
            );
          })}
        </LiCircle>
      );
      rotate = rotate + split.length;
    }

    return rows;
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header variant="primary-style-2" />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary px-5 lg:px-24  pb-14 pt-24 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="flex mb-10">
          <ArrowRight className="text-primary" />
          <p className="pl-2 pr-4">
            Esse é seu círculo pessoal dos sentimentos.
          </p>
        </div>
        <div className="flex justify-between flex-col md:flex-row">
          <div className="flex justify-center items-baseline md:w-3/6">
            <MazeStages className="hidden md:block"/>
            <MazeStagesMobile className="md:hidden"/>
          </div>
          <div className="relative flex flex-col items-baseline md:w-4/6 lg:w-4/6 md:h-96 md:mb-40">
            <p className="text-2xl text-center md:text-left text-primary-style-2 font-semibold mb-8 mt-14">
              CÍRCULO DOS SENTIMENTOS
            </p>
            {selectedWords?.length > 1 && (
              <StyledCircle>
                <ul>
                  <Circle />
                </ul>
              </StyledCircle>
            )}
          </div>
        </div>

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-end mt-14">
              <Button
                className="bg-primary-style-2 hover:bg-primary-style-2-light active:bg-primary-style-2-light text-xs font-semibold text-white"
                type="submit"
              >
                AVANÇAR
              </Button>
            </div>
            <hr className="my-8 border-gray-800 border rounded" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeelingsExplanation;
