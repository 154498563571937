import React from "react";
import styled from "@emotion/styled/macro";

const Styled = styled.svg`
  .certificate-mobile-0 {
    fill: #ffffff;
  }
  .certificate-mobile-1 {
    fill: url(#CERTIFICATE_MOBILE_1_);
  }
  .certificate-mobile-2 {
    fill: url(#CERTIFICATE_MOBILE_2_);
  }
  .certificate-mobile-3 {
    fill: url(#CERTIFICATE_MOBILE_3_);
  }
  .certificate-mobile-4 {
    fill: url(#CERTIFICATE_MOBILE_4_);
  }
  .certificate-mobile-5 {
    fill: url(#CERTIFICATE_MOBILE_5_);
  }
  .certificate-mobile-6 {
    fill: url(#CERTIFICATE_MOBILE_6_);
  }
  .certificate-mobile-7 {
    fill: url(#CERTIFICATE_MOBILE_7_);
  }
  .certificate-mobile-8 {
    fill: url(#CERTIFICATE_MOBILE_8_);
  }
  .certificate-mobile-9 {
    fill: url(#CERTIFICATE_MOBILE_9_);
  }
  .certificate-mobile-10 {
    fill: url(#CERTIFICATE_MOBILE_10_);
  }
  .certificate-mobile-11 {
    fill: url(#CERTIFICATE_MOBILE_11_);
  }
  .certificate-mobile-12 {
    fill: url(#CERTIFICATE_MOBILE_12_);
  }
  .certificate-mobile-13 {
    fill: url(#CERTIFICATE_MOBILE_13_);
  }
  .certificate-mobile-14 {
    fill: url(#CERTIFICATE_MOBILE_14_);
  }
  .certificate-mobile-15 {
    fill: url(#CERTIFICATE_MOBILE_15_);
  }
  .certificate-mobile-16 {
    fill: url(#CERTIFICATE_MOBILE_16_);
  }
  .certificate-mobile-17 {
    fill: url(#CERTIFICATE_MOBILE_17_);
  }
  .certificate-mobile-18 {
    fill: url(#CERTIFICATE_MOBILE_18_);
  }
  .certificate-mobile-19 {
    fill: url(#CERTIFICATE_MOBILE_19_);
  }
  .certificate-mobile-20 {
    fill: url(#CERTIFICATE_MOBILE_20_);
  }
  .certificate-mobile-21 {
    fill: url(#CERTIFICATE_MOBILE_21_);
  }
  .certificate-mobile-22 {
    fill: url(#CERTIFICATE_MOBILE_22_);
  }
  .certificate-mobile-23 {
    fill: url(#CERTIFICATE_MOBILE_23_);
  }
  .certificate-mobile-24 {
    fill: url(#CERTIFICATE_MOBILE_24_);
  }
  .certificate-mobile-25 {
    fill: url(#CERTIFICATE_MOBILE_25_);
  }
  .certificate-mobile-26 {
    fill: url(#CERTIFICATE_MOBILE_26_);
  }
  .certificate-mobile-27 {
    fill: url(#CERTIFICATE_MOBILE_27_);
  }
  .certificate-mobile-28 {
    fill: url(#CERTIFICATE_MOBILE_28_);
  }
  .certificate-mobile-29 {
    fill: url(#CERTIFICATE_MOBILE_29_);
  }
  .certificate-mobile-30 {
    fill: url(#CERTIFICATE_MOBILE_30_);
  }
  .certificate-mobile-31 {
    fill: url(#CERTIFICATE_MOBILE_31_);
  }
  .certificate-mobile-32 {
    fill: url(#CERTIFICATE_MOBILE_32_);
  }
  .certificate-mobile-33 {
    fill: url(#CERTIFICATE_MOBILE_33_);
  }
  .certificate-mobile-34 {
    fill: url(#CERTIFICATE_MOBILE_34_);
  }
  .certificate-mobile-35 {
    fill: url(#CERTIFICATE_MOBILE_35_);
  }
  .certificate-mobile-36 {
    fill: url(#CERTIFICATE_MOBILE_36_);
  }
  .certificate-mobile-37 {
    fill: url(#CERTIFICATE_MOBILE_37_);
  }
  .certificate-mobile-38 {
    fill: url(#CERTIFICATE_MOBILE_38_);
  }
  .certificate-mobile-39 {
    fill: url(#CERTIFICATE_MOBILE_39_);
  }
  .certificate-mobile-40 {
    fill: url(#CERTIFICATE_MOBILE_40_);
  }
  .certificate-mobile-41 {
    fill: url(#CERTIFICATE_MOBILE_41_);
  }
  .certificate-mobile-42 {
    fill: url(#CERTIFICATE_MOBILE_42_);
  }
  .certificate-mobile-43 {
    fill: url(#CERTIFICATE_MOBILE_43_);
  }
  .certificate-mobile-44 {
    fill: url(#CERTIFICATE_MOBILE_44_);
  }
  .certificate-mobile-45 {
    fill: url(#CERTIFICATE_MOBILE_45_);
  }
  .certificate-mobile-46 {
    fill: url(#CERTIFICATE_MOBILE_46_);
  }
  .certificate-mobile-47 {
    fill: url(#CERTIFICATE_MOBILE_47_);
  }
  .certificate-mobile-48 {
    fill: url(#CERTIFICATE_MOBILE_48_);
  }
  .certificate-mobile-49 {
    fill: url(#CERTIFICATE_MOBILE_49_);
  }
  .certificate-mobile-50 {
    fill: url(#CERTIFICATE_MOBILE_50_);
  }
  .certificate-mobile-51 {
    fill: url(#CERTIFICATE_MOBILE_51_);
  }
  .certificate-mobile-52 {
    fill: url(#CERTIFICATE_MOBILE_52_);
  }
  .certificate-mobile-53 {
    fill: url(#CERTIFICATE_MOBILE_53_);
  }
  .certificate-mobile-54 {
    fill: url(#CERTIFICATE_MOBILE_54_);
  }
  .certificate-mobile-55 {
    fill: url(#CERTIFICATE_MOBILE_55_);
  }
  .certificate-mobile-56 {
    fill: url(#CERTIFICATE_MOBILE_56_);
  }
  .certificate-mobile-57 {
    fill: url(#CERTIFICATE_MOBILE_57_);
  }
  .certificate-mobile-58 {
    fill: url(#CERTIFICATE_MOBILE_58_);
  }
  .certificate-mobile-59 {
    fill: url(#CERTIFICATE_MOBILE_59_);
  }
  .certificate-mobile-60 {
    fill: url(#CERTIFICATE_MOBILE_60_);
  }
  .certificate-mobile-61 {
    fill: url(#CERTIFICATE_MOBILE_61_);
  }
  .certificate-mobile-62 {
    fill: url(#CERTIFICATE_MOBILE_62_);
  }
  .certificate-mobile-63 {
    fill: url(#CERTIFICATE_MOBILE_63_);
  }
  .certificate-mobile-64 {
    fill: url(#CERTIFICATE_MOBILE_64_);
  }
  .certificate-mobile-65 {
    fill: url(#CERTIFICATE_MOBILE_65_);
  }
  .certificate-mobile-66 {
    fill: url(#CERTIFICATE_MOBILE_66_);
  }
  .certificate-mobile-67 {
    fill: url(#CERTIFICATE_MOBILE_67_);
  }
  .certificate-mobile-68 {
    fill: url(#CERTIFICATE_MOBILE_68_);
  }
  .certificate-mobile-69 {
    fill: url(#CERTIFICATE_MOBILE_69_);
  }
  .certificate-mobile-70 {
    fill: url(#CERTIFICATE_MOBILE_70_);
  }
  .certificate-mobile-71 {
    fill: url(#CERTIFICATE_MOBILE_71_);
  }
  .certificate-mobile-72 {
    fill: url(#CERTIFICATE_MOBILE_72_);
  }
  .certificate-mobile-73 {
    fill: url(#CERTIFICATE_MOBILE_73_);
  }
  .certificate-mobile-74 {
    fill: url(#CERTIFICATE_MOBILE_74_);
  }
  .certificate-mobile-75 {
    fill: url(#CERTIFICATE_MOBILE_75_);
  }
  .certificate-mobile-76 {
    fill: url(#CERTIFICATE_MOBILE_76_);
  }
  .certificate-mobile-77 {
    fill: url(#CERTIFICATE_MOBILE_77_);
  }
  .certificate-mobile-78 {
    fill: url(#CERTIFICATE_MOBILE_78_);
  }
  .certificate-mobile-79 {
    fill: url(#CERTIFICATE_MOBILE_79_);
  }
  .certificate-mobile-80 {
    fill: url(#CERTIFICATE_MOBILE_80_);
  }
  .certificate-mobile-81 {
    fill: url(#CERTIFICATE_MOBILE_81_);
  }
  .certificate-mobile-82 {
    fill: url(#CERTIFICATE_MOBILE_82_);
  }
  .certificate-mobile-83 {
    fill: url(#CERTIFICATE_MOBILE_83_);
  }
  .certificate-mobile-84 {
    fill: url(#CERTIFICATE_MOBILE_84_);
  }
  .certificate-mobile-85 {
    fill: url(#CERTIFICATE_MOBILE_85_);
  }
  .certificate-mobile-86 {
    fill: url(#CERTIFICATE_MOBILE_86_);
  }
  .certificate-mobile-87 {
    fill: url(#CERTIFICATE_MOBILE_87_);
  }
  .certificate-mobile-88 {
    fill: url(#CERTIFICATE_MOBILE_88_);
  }
  .certificate-mobile-89 {
    fill: url(#CERTIFICATE_MOBILE_89_);
  }
  .certificate-mobile-90 {
    fill: url(#CERTIFICATE_MOBILE_90_);
  }
  .certificate-mobile-91 {
    fill: url(#CERTIFICATE_MOBILE_91_);
  }
  .certificate-mobile-92 {
    fill: url(#CERTIFICATE_MOBILE_92_);
  }
  .certificate-mobile-93 {
    fill: url(#CERTIFICATE_MOBILE_93_);
  }
  .certificate-mobile-94 {
    fill: url(#CERTIFICATE_MOBILE_94_);
  }
  .certificate-mobile-95 {
    fill: url(#CERTIFICATE_MOBILE_95_);
  }
  .certificate-mobile-96 {
    fill: url(#CERTIFICATE_MOBILE_96_);
  }
  .certificate-mobile-97 {
    fill: url(#CERTIFICATE_MOBILE_97_);
  }
  .certificate-mobile-98 {
    fill: url(#CERTIFICATE_MOBILE_98_);
  }
  .certificate-mobile-99 {
    fill: url(#CERTIFICATE_MOBILE_99_);
  }
  .certificate-mobile-100 {
    fill: url(#CERTIFICATE_MOBILE_100_);
  }
  .certificate-mobile-101 {
    fill: url(#CERTIFICATE_MOBILE_101_);
  }
  .certificate-mobile-102 {
    fill: url(#CERTIFICATE_MOBILE_102_);
  }
  .certificate-mobile-103 {
    fill: url(#CERTIFICATE_MOBILE_103_);
  }
  .certificate-mobile-104 {
    fill: url(#CERTIFICATE_MOBILE_104_);
  }
  .certificate-mobile-105 {
    fill: url(#CERTIFICATE_MOBILE_105_);
  }
  .certificate-mobile-106 {
    fill: url(#CERTIFICATE_MOBILE_106_);
  }
  .certificate-mobile-107 {
    fill: url(#CERTIFICATE_MOBILE_107_);
  }
  .certificate-mobile-108 {
    fill: url(#CERTIFICATE_MOBILE_108_);
  }
  .certificate-mobile-109 {
    fill: url(#CERTIFICATE_MOBILE_109_);
  }
  .certificate-mobile-110 {
    fill: url(#CERTIFICATE_MOBILE_110_);
  }
  .certificate-mobile-111 {
    fill: url(#CERTIFICATE_MOBILE_111_);
  }
  .certificate-mobile-112 {
    fill: url(#CERTIFICATE_MOBILE_112_);
  }
  .certificate-mobile-113 {
    fill: url(#CERTIFICATE_MOBILE_113_);
  }
  .certificate-mobile-114 {
    fill: url(#CERTIFICATE_MOBILE_114_);
  }
  .certificate-mobile-115 {
    fill: url(#CERTIFICATE_MOBILE_115_);
  }
  .certificate-mobile-116 {
    fill: url(#CERTIFICATE_MOBILE_116_);
  }
  .certificate-mobile-117 {
    fill: url(#CERTIFICATE_MOBILE_117_);
  }
  .certificate-mobile-118 {
    fill: url(#CERTIFICATE_MOBILE_118_);
  }
  .certificate-mobile-119 {
    fill: url(#CERTIFICATE_MOBILE_119_);
  }
  .certificate-mobile-120 {
    fill: url(#CERTIFICATE_MOBILE_120_);
  }
  .certificate-mobile-121 {
    fill: url(#CERTIFICATE_MOBILE_121_);
  }
  .certificate-mobile-122 {
    fill: url(#CERTIFICATE_MOBILE_122_);
  }
  .certificate-mobile-123 {
    fill: url(#CERTIFICATE_MOBILE_123_);
  }
  .certificate-mobile-124 {
    fill: none;
  }
  .certificate-mobile-125 {
    fill: #7c7b7b;
  }
  .certificate-mobile-126 {
    fill: #616060;
  }
  .certificate-mobile-127 {
    fill: none;
    stroke: #616060;
    stroke-width: 0.4539;
    stroke-miterlimit: 10;
  }
  .certificate-mobile-128 {
    fill: #ffdfb4;
  }
  .certificate-mobile-129 {
    fill: #96999b;
  }
  .certificate-mobile-130 {
    fill: #283b88;
  }
  .certificate-mobile-131 {
    fill: #231f20;
  }
  .certificate-mobile-132 {
    fill: url(#CERTIFICATE_MOBILE_124_);
  }
  .certificate-mobile-133 {
    fill: url(#CERTIFICATE_MOBILE_125_);
  }
  .certificate-mobile-134 {
    fill: url(#CERTIFICATE_MOBILE_126_);
  }
  .certificate-mobile-135 {
    fill: url(#CERTIFICATE_MOBILE_127_);
  }
  .certificate-mobile-136 {
    fill: none;
    stroke: #616060;
    stroke-width: 0.5376;
    stroke-miterlimit: 10;
  }
  .certificate-mobile-137 {
    fill: #a0a2a4;
  }
  .certificate-mobile-138 {
    fill: url(#CERTIFICATE_MOBILE_128_);
  }
  .certificate-mobile-139 {
    fill: url(#CERTIFICATE_MOBILE_129_);
  }
  .certificate-mobile-140 {
    fill: none;
    stroke: #808083;
    stroke-width: 0.5001;
    stroke-miterlimit: 10;
  }
  .certificate-mobile-141 {
    clip-path: url(#CERTIFICATE_MOBILE_130_);
    fill: #f06260;
  }
  .certificate-mobile-142 {
    clip-path: url(#CERTIFICATE_MOBILE_130_);
    fill: #f68f50;
  }
  .certificate-mobile-143 {
    clip-path: url(#CERTIFICATE_MOBILE_130_);
    fill: #6685c3;
  }
  .certificate-mobile-144 {
    clip-path: url(#CERTIFICATE_MOBILE_130_);
    fill: #283b88;
  }
  .certificate-mobile-145 {
    fill: none;
    stroke: url(#CERTIFICATE_MOBILE_131_);
    stroke-width: 0.5001;
    stroke-miterlimit: 10;
  }
  .certificate-mobile-146 {
    opacity: 0.9;
  }
  .certificate-mobile-147 {
    fill: #e6e6e6;
  }
  .certificate-mobile-148 {
    clip-path: url(#CERTIFICATE_MOBILE_132_);
    fill: #d3d4d6;
  }
  .certificate-mobile-149 {
    opacity: 0.42;
    clip-path: url(#CERTIFICATE_MOBILE_132_);
    fill: #d3d4d6;
    stroke: #737477;
    stroke-width: 0.7048;
    stroke-miterlimit: 10;
  }
  .certificate-mobile-150 {
    clip-path: url(#CERTIFICATE_MOBILE_132_);
    fill: url(#CERTIFICATE_MOBILE_133_);
  }
  .certificate-mobile-151 {
    opacity: 0.26;
    clip-path: url(#CERTIFICATE_MOBILE_132_);
    fill: url(#CERTIFICATE_MOBILE_134_);
  }
  .certificate-mobile-152 {
    opacity: 0.55;
    clip-path: url(#CERTIFICATE_MOBILE_132_);
  }
  .certificate-mobile-153 {
    fill: url(#CERTIFICATE_MOBILE_135_);
  }
  .certificate-mobile-154 {
    fill: url(#CERTIFICATE_MOBILE_136_);
  }
  .certificate-mobile-155 {
    fill: url(#CERTIFICATE_MOBILE_145_);
  }
  .certificate-mobile-156 {
    fill: url(#CERTIFICATE_MOBILE_138_);
  }
  .certificate-mobile-157 {
    fill: url(#CERTIFICATE_MOBILE_139_);
  }
  .certificate-mobile-158 {
    fill: url(#CERTIFICATE_MOBILE_140_);
  }
  .certificate-mobile-159 {
    fill: url(#CERTIFICATE_MOBILE_141_);
  }
  .certificate-mobile-160 {
    fill: url(#CERTIFICATE_MOBILE_142_);
  }
  .certificate-mobile-161 {
    fill: url(#CERTIFICATE_MOBILE_143_);
  }
  .certificate-mobile-162 {
    fill: url(#CERTIFICATE_MOBILE_144_);
  }
  .certificate-mobile-163 {
    fill: url(#CERTIFICATE_MOBILE_145_);
  }
  .certificate-mobile-164 {
    fill: url(#CERTIFICATE_MOBILE_145_);
  }
  .certificate-mobile-165 {
    fill: url(#CERTIFICATE_MOBILE_145_);
  }
  .certificate-mobile-166 {
    fill: url(#CERTIFICATE_MOBILE_145_);
  }
  .certificate-mobile-167 {
    fill: url(#CERTIFICATE_MOBILE_145_);
  }
  .certificate-mobile-168 {
    fill: url(#CERTIFICATE_MOBILE_145_);
  }
  .certificate-mobile-169 {
    fill: url(#CERTIFICATE_MOBILE_145_);
  }
  .certificate-mobile-170 {
    fill: url(#CERTIFICATE_MOBILE_145_);
  }

  .certificate-mobile-st-138 {
    fill: #283b88;
  }
  .certificate-mobile-st-139 {
    font-family: "GreatVibes-Regular";
  }
  .certificate-mobile-st-140 {
    font-size: 23px;
  }

  .certificate-mobile-st-136 {
	font-size: 11px;
  }
`;

const CertificateSvgMobile = ({ data, className }) => (
  <Styled
    className={className}
    version="1.0"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 339.61 360.58"
    style={{ enableBackground: "new 0 0 339.61 360.58" }}
    XmlSpace="preserve"
  >
    <g>
      <image
        style={{ overflow: "visible", opacity: "0.5" }}
        width="1419"
        height="1516"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABZEAAAXxCAYAAADoQ9acAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAOmtJREFUeNrs3dtu21gSQNEjx909
//+500k0MBAiDHM2Scl2YmXWAgRRF+vmt41CcQwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD4
jS4+AwAAAADAh3b9nW9++UPfCwAAAADgT/dL4vLlA7y+uAwAAAAA0K5v9Jy7XH7xa15+8WcBAAAA
AHhU13d47GaXX/Balzuf916fEwAAAADgo7gnBl/vfN5d3irOXm64fbnxbwEAAAAA/p9cb7h9vfFv
b/YWwfZMML7sPLZ3/NafFQAAAADgozgzQXx0fD143t77nPL8yi95FIJn15eD54y4fXQ/AAAAAMAj
uHVNxV4wnr3GZfO8y3hFSH5NkL0nGl/G7UFZNAYAAAAA/mRnJoyvk+N6bHa9PT7t3kB7FJArGNdl
jHlUrs8oLAMAAAAAj+h6cN9RNJ5dxs599fqn3bPO4paAvFyeDu7b/u3stUfcBgAAAAB4JGfWVswC
8dfxcyz+OjpMXzbXY9yx2uLWiFwBeXv8NDmu66Pp5HpfAAAAAIBHdLS+oi5fJ9eX8WNc/hrvd3dI
viXInplA3sbjp83x7L6joDz7nEIyAAAAAPCI9qaQj8Lx9vhrPL6dWh7jFastnl/xZSsgVzj+NLlv
FpeFZAAAAADgT3RPQN67fBnfJ5HXr/ly+2l1/3YS+SZnI/Jl53i757ji8afN8Swu700lz95/dhsA
AAAA4CO57tw+M338ZXK8DsjL8TooL6sutu9581qLe3ci750kr+Lxp2/vt779NLmukDxGTyUDAAAA
ADyCox3I64D8ZXK9XJZu+uXb62yD8dJZv07e/6ap5OdXfuGaQv405vH4efwYkpfjWndxZq1F3QcA
AAAA8Ltdd+47Wl+xjsefx/eA/HK8Hcb9Mnn99TTy9d4vcCYiz9ZHzC6zNRZLJP5rfA/I60tNJ8/W
W4xhPzIAAAAA8Fj29iAv17MT5q2njl+i8fO365fL0k8/f3uNdSjenkxv1lTXn+lwKvmWSeQKt9uT
6W2nkP8a3yPy+vh5dVxTydt1GWNYaQEAAAAAPKa9gDxbYfF5df3v+HEQd3suuW08vn573nK8jcWn
V1rcsxN5u1piHZC3U8h/xeXv8WNY3q65WF5jbz/yGEIyAAAAAPAY9k6mt4Tk7e7jZfL4JSA/f7v+
75i30vXrPo0OybPPs+soIl8OHpudTG+7C3kJxX+vLuuQvI7Jy99sdySf3Y0MAAAAAPBRHe1CXiLy
MoH87/i+yuLleLsKeExe4zq5LF21wvHuVPLZSeTt9O/eTuR1PF5PIy8B+Z/xc1Bex+R1SK6T7G0/
0+w2AAAAAMDvtLcPuU6mt11hsUwhr4duR7zO+vhpfD+x3ratLp/j1EqLW3ciXzbHRyfV20bkf8b3
iDyLyduT7lVEFpABAAAAgEcwC8kVkdcrLJaI/LK+ovYg1wn51l11G5LXay3eZSfy1iwmz9ZZrFdY
vFz+M34MytuJ5O1ai8vkR7IbGQAAAAD46K6T49qFvF5jsZ5AXkfkMebh+MvquV/G/lDuTe6JyEdT
yOuQPNuJvMTjvZC8/nG2ld1eZAAAAADg0eztQ/4yfjyZ3hKRt1PIY/w8wbxef7Hd7rA3jXzaayaR
j2LyEoLXJ847E5JnJ9k7uxcZAAAAAOAjOtqHvJ5CfumjFZC3qy/+Gt9Pvvd5nF8RfNpeRN5bG7G3
G3m2zmK9qmIvJC+Pr/cifzrxpcVkAAAAAOAj2q6zmEXk9RTyUUBeP3e5LAH57GaH9TTyek/ydEL5
3nUW6+vl+Na1FtuQvNyerbSY1fPtZwAAAAAA+Khm+5BnEfllEvlMQJ7tTN5eakD4XdZZXHbecLbS
4mgaeTuRvJxsb70beT2NvDeJLCQDAAAAAB/Z3j7k9WqK9V7jWUBenrM01m1IvqWlXjafLb3lTuSj
mDwLykswXkLyP2MekfdGsLdfHgAAAADgozhaZ7FMF2876Bg/B+QKx3vx+F13Is/M3mwWkLfrLT6t
vtg6Ir8cL9F4veZieXzv5HpjmEYGAAAAAD626+R6dlK9MwF5vQZ4u8Vh3WX34vFy3+mVFm8xiTxG
TyI/bb7I8/g5Js9WXeztRTaJDAAAAAA8ippEXq+yeOmenzfPm00gr7c9zPrr3hqLXzaJPFZvuj0+
Csmfxs9TyesvPdudfCYiC8gAAAAAwEc224m8ROTZSfCWgLxd+buePp6dUO/sHuSbPL/Rj3A5cdme
GbBi8iwsb/92jB7FBgAAAAD43a6T28t961UWsxUWL010vSd5trriaP/xq6aP197qxHqz+2o/8mwy
+dPkB9kem0QGAAAAAB7RdhL5pXOu11ksj80C8t7k8Zk9yL/0xHqXg8f21lpsp5FnYfkpfhARGQAA
AAB4ZLN1FuvG+XL7pYEuUXkWi7ddddZfb9mDfPrkevdMIl9OPlZReW9C+czl6MyCAAAAAAC/W62z
uI6fT7Z3tovWCosxzu8+vrmhPr/TD3SZXB/tS67dyXsR+a4vDQAAAADwztbdcpk8PhuR96aM9wLy
u7TS5zf4AWY7NrbX916edu4b4w3OLAgAAAAA8E6WWLwE5OX66zjuoLdcxuhm+uqG+tpJ5MvB8WXn
b2758q8d0QYAAAAA+Chu6aBnNjMc9dJXtdPnd/jye19g78uc/cHG5PnjrX4QAAAAAIBXWk8g1/1n
Bmu3r3H0+Ni5727Pv+hHqy9ySyGfBeY3/0EAAAAAAF5pFo8vO7fPNtGj93kXz7/gB7r3785UdZPI
AAAAAMBHc93cvmzuW+9IvqV9nvHmffQ9J5H36vheWb/c+UXFYwAAAADgI1ha5fWOvzvTT+vv3sXT
b/rxXvsal3d4XQAAAACAtzTrmJd3eN139fRAP7JQDAAAAAD8KR6mfT79AT+yuAwAAAAAfFQP3zGf
/A8BAAAAACgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwA
AAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAA
kogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQA
AAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAA
kERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCID
AAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAA
gCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZ
AAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAA
ACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInI
AAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAA
ACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElE
BgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAA
AABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgi
MgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAA
AABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEAS
kQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAA
AABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACS
iAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAA
AAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQ
RGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMA
AAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACA
JCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkA
AAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAA
JBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgA
AAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAA
IInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQG
AAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAA
AElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIy
AAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAA
AEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKR
AQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAA
AEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKI
DAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAA
AACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBE
ZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAA
AACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAk
IgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAA
AACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAk
ERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAA
AAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAg
icgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYA
AAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAA
SUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIA
AAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAA
SCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEB
AAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAA
QBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogM
AAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAA
AJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERk
AAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAA
AJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQi
AwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAA
AIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQR
GQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAA
AAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJ
yAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAA
AAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJ
RAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAA
AAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABI
IjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEA
AAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABA
EpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwA
AAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAA
kogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQA
AAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAA
kERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCID
AAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAA
gCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZ
AAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAA
ACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInI
AAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAA
ACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElE
BgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAA
AABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgi
MgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAA
AABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEAS
kQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAA
AABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACS
iAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAA
AAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQ
RGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMA
AAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACA
JCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkA
AAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAA
JBEZAAAAAIAkIgMAAPyPHTsQAAAAABDkbz3BBoURAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAA
AABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQy
AAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAA
sCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMA
AAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABLIgMAAAAAsCQyAAAAAABL
IgMAAAAAsCQyAAAAABA7dogCQAzAQBDu/3/u+cL6BmZMfORCEpEBAAAAAEgiMgAAAAAASUQGAAAA
ACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAAAABIIjIAAAAAAElE
BgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEASkQEAAAAASCIyAAAA
AABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAAAABAEpEBAAAAAEgi
MgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACSiAwAAAAAQBKRAQAA
AABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAAAAAAkogMAAAAAEAS
kQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQRGQAAAAAAJKIDAAA
AABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACCJyAAAAAAAJBEZAAAAAIAkIgMAAAAAkERkAAAAAACS
iAwAAAAAQBKRAQAAAABIIjIAAAAAAElEBgAAAAAgicgAAAAAACQRGQAAAACAJCIDAAAAAJBEZAAA
AAAAkogMAAAAAEASkQEAAAAASCIyAAAAAABJRAYAAAAAIInIAAAAAAAkERkAAAAAgCQiAwAAAACQ
RGQAAAAAAJKIDAAAAABAEpEBAAAAAEgiMgAAAAAASUQGAAAAACAtR+RzLQAAAADAa+Y75jdy8PTJ
AAAAAACXmfb5C9Deve62jQNhAKVc9f2fd9s42jVgIiwzJHWxE7l7DkBIkXUjkV8fBqPLNy7MkXss
T7gvAAAAAMAjRTnm8oT7PtX85AVaVhxfgt+WAws3+d8EAAAAAL7RcuC6Nflp67qnmE+2QNHfS7U/
VedMD3o+AAAAAMAzrM0+e9fsfdZh8zctUm+BevtTdWwK7q0SGQAAAAD4Tr08dGsmmlK/Avnp5i9a
nLRigZZgPxpT41nTVy4cAAAAAMDAmra+9Uhpe5bae+5h8xMXYTSBZcVIneMAAAAAAGfWC4DX5KCt
81rPWVPdvNn8oMn3AuTlwHj/b1yCY1P6aGWhAhkAAAAAOLNRVvqejuWoKbWD5MNh8vwFi5LSurC4
3K9H2ed4qkbdIxkAAAAA4LtFH8qrM9HW2BIup9SvUj5sfsDkW7/1AuT3FQu1JkROSXAMAAAAAJxb
lJW+bxy9ULl8Rr3fepfV5o0TnTq/tULjqMq4tUjXYnu5j2t1XyEyAAAAAPBKeiFymYleq2OtIHlU
rZzS+Ptyq8Pk+eDEo54aayqOr8Hi5PGWPgLkst/xpRgp/Rkkp+IYAAAAAMB3a7WzuKnz0lsmWuaj
dWZaZ6prKpTr5+5udTE/cEHW9D2ug+R6cX7cx22/DJBvx8pgWSUyAAAAAPAqRpXIOR99C/bLHLXV
O3nUM/mQ+eCkWwtQh8Z1Yv4WLE4OkS/VvfM9WiFySoJkAAAAAOCcWlXBUYj8+z7eqhGFyVFl8pFe
yU2PaGeR0jhALsuy36pF+Xnf5gC5DITf7+9Y9kiOqpCFyAAAAADAGS3Bts5Qc2b6OxhvxbYsyo3y
1739kbvmHRNu9fLo9UG+ps/hcQ6Qf6XPAXK+PgfI5e8qkQEAAACAVzH6plzdseGWm/66j7xfVym3
WlyM+iOntCNQftSH9VqVyNFH88oJ9wLkfE2rH7IqZAAAAADgFYw6OrRC5H+Kv+tWF9HH90Z9kneZ
N05y2jDpVnD8+/7cUYA8p48P7V1Sv50FAAAAAMCZ9dpZRH2RbwHyr2JbBsm9QHlLf+RVwfJ8cLLl
sfdgtEqxy3A4CpDfUhwia2cBAAAAALySUTuLKEfNgfE/xfiV/gyT6wA5ymejj+ptrkieB5Obim19
rDXpVhXynD4Hw60A+ef9nDnFVcgqkQEAAACAVxJlqq2P6+UgOVci10HymmrkXiXypnD5kT2R34NJ
z+kjQL5t66rilOIAOZ9bB85CZAAAAADgFW0pzC2/KdcLkvO5+bqoEvnLeiLXk+2Fx3X59Y/7RKKe
xlGAXFch/7if3+uHLEwGAAAAAM6oVfWbs9HbNlcPl9XIW4LkNW0tdofJ8wMWYJSa/7hPqFeBXCbs
ZduLViuLOjQWIgMAAAAAZ7QEf7eKdMsWweU35soguQyQf6Vt7Sx2mXdMdkrjauQ82TIAvgQLVVch
5/C47oW8ph+yIBkAAAAAOJOl8feoTXDd1qIVJkd9kddUIW8KlucNk506E62/JJjD3ij8Telz2Hyb
aNTGoleFLDQGAAAAAF7Jmmw1+sheGSSXgXIdItdB8tIY9ft0zSsmNXV+Kyc5pc8B8luKeyCXqXr5
Ab7yQ3pRL+Q6jBYkAwAAAACvoA5v63y17I1ctgDOIXLZ3qKsUI7aWbSC5DXv9sl8YKJT+jNALrc5
TE6NhckB8s/00Te57IOct3VFc0oqkQEAAACA17QE2zLoLauRc4hcdnMoQ+XfwX7dzqK8f0o7eyRv
7Yk8BX/XQfL1/vsUnB99TC+Hx2UVct1LeRQgC5QBAAAAgDPq9UXO2zLwLVtblDlq/dG9MlCOqpCj
ALn3Xk3zyklOjZvXE03pIzy+VudEH96b08cH+OoAeUsvZCEyAAAAAHBGS+dYqzdy3SO5DJLLMLk+
PvqoXlSFPAyT5wctQB0ip2ARyvQ7Vx3nVhZ1C4tRgCw0BgAAAABeUVSJ3MpRr8G2rk6+Budt6Yc8
tKcn8hQ89PZCl/RnmNxaiNtEyqrjcr8VIPc+pidQBgAAAADOrFf9G1UK1+0trimuTh6Fx1E1cvQ+
XfOGSU6N/fKc98bEy4D5cp/UJY3bV/TaWAiPAQAAAIBX0uuP3AqTe6PVwqIVHqfOftN8cMJlVXIO
iPNH9lKwAHXF8aj6WIAMAAAAAPxN9gTJZaAchcv173WQHD13tenA+WXAW+9fgv3Wdk14PB14ZwAA
AACAM4mqgUdhch0oR9tWeLx0nju0J5BtBcl5G43L4Fh9bXTvI+8MAAAAAHAWvWrkvI2C4KhNRXRs
adyz9fyuvYHsliA5OtY7p3X/R7w3AAAAAMB3WgbHWhXEvZHSkwLkmyNh7ChIzttepfE0uP7oOwIA
AAAAnN2ovUXejiqVe9fX+6sdDWinwf4oVI62o3cTKgMAAAAAr2xZeXxtoBxte/ubPCKQ7YW/ayuM
11QeC48BAAAAgL/JmjB5tL+m4ng58pKPCmanDX9PG68FAAAAAPg/WTb8vWy8drNHBrZrK4j3VBoL
lgEAAACAv9Gy47dl53m7PCOcnZ7wGwAAAADA/83yhN82e3ZwO53gHQAAAAAAXtnyoHN2+coAV1gM
AAAAAPA4y1c85AzBrnAZAAAAAKBtsQQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAC1
fwH+DDqje1JxXgAAAABJRU5ErkJggg=="
        transform="matrix(0.24 0 0 0.24 0.5724 -0.4101)"
      ></image>
      <g>
        <rect
          x="4.95"
          y="4.14"
          className="certificate-mobile-0"
          width="328.82"
          height="351.89"
        />
      </g>
    </g>
    <g>
      <g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_1_"
            gradientUnits="userSpaceOnUse"
            x1="-0.5863"
            y1="52.4765"
            x2="43.5322"
            y2="8.3581"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-1"
            d="M3.39,56.2c0.73-1.14,1.57-2.23,2.62-3.11c1.03-0.9,2.26-1.52,3.34-2.33c1.1-0.79,2.02-1.82,2.8-2.93
				c0.77-1.11,1.45-2.3,1.89-3.59c0.47-1.28,0.59-2.64,0.77-3.99c0.18-1.35,0.58-2.68,1.21-3.89c0.63-1.21,1.37-2.37,2.27-3.4
				c0.89-1.04,2.01-1.86,3.17-2.58c1.15-0.71,2.25-1.52,3.13-2.55c0.89-1.01,1.63-2.16,2.24-3.36c0.62-1.2,1.01-2.5,1.19-3.84
				c0.18-1.34,0.31-2.72,0.78-4.02c0.45-1.3,1.14-2.5,1.92-3.63c0.78-1.13,1.72-2.17,2.84-2.97c1.11-0.82,2.32-1.44,3.33-2.32
				c1.03-0.86,1.86-1.93,2.58-3.07c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-0.74,1.15-1.6,2.25-2.67,3.13
				c-1.05,0.91-2.29,1.52-3.37,2.32c-1.1,0.78-2.01,1.78-2.78,2.88c-0.77,1.1-1.46,2.28-1.9,3.55c-0.47,1.26-0.6,2.61-0.79,3.96
				c-0.18,1.36-0.6,2.69-1.23,3.91c-0.63,1.21-1.38,2.37-2.29,3.39c-0.9,1.04-2.03,1.86-3.19,2.56c-1.16,0.71-2.26,1.5-3.15,2.53
				c-0.9,1.01-1.64,2.15-2.27,3.35c-0.63,1.2-1.04,2.5-1.22,3.85c-0.19,1.34-0.32,2.71-0.8,4c-0.45,1.29-1.14,2.48-1.92,3.6
				C11.43,48.98,10.5,50,9.39,50.8c-1.1,0.81-2.32,1.42-3.36,2.31C4.98,53.97,4.13,55.06,3.39,56.2z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_2_"
            gradientUnits="userSpaceOnUse"
            x1="-1.1685"
            y1="58.2885"
            x2="48.1809"
            y2="8.9391"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-2"
            d="M3.39,62.59c0.81-1.27,1.73-2.49,2.87-3.5c1.11-1.03,2.44-1.79,3.61-2.74c1.19-0.93,2.19-2.09,3.04-3.33
				c0.85-1.24,1.62-2.56,2.14-3.97c0.55-1.41,0.76-2.91,1.03-4.4c0.26-1.49,0.76-2.95,1.47-4.29c0.71-1.34,1.53-2.62,2.52-3.78
				c0.97-1.17,2.18-2.13,3.43-2.98c1.25-0.85,2.43-1.79,3.39-2.95c0.97-1.15,1.79-2.42,2.49-3.75c0.7-1.33,1.19-2.76,1.45-4.24
				c0.26-1.48,0.48-3.01,1.04-4.43c0.54-1.43,1.31-2.76,2.17-4.01c0.86-1.25,1.87-2.43,3.08-3.37c1.19-0.96,2.51-1.71,3.61-2.73
				c1.12-1,2.03-2.2,2.83-3.46c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-0.83,1.28-1.76,2.51-2.91,3.52
				c-1.13,1.04-2.47,1.8-3.64,2.73c-1.19,0.92-2.18,2.05-3.04,3.29c-0.86,1.23-1.62,2.54-2.15,3.94c-0.56,1.4-0.78,2.89-1.05,4.38
				c-0.27,1.5-0.77,2.96-1.49,4.3c-0.72,1.34-1.55,2.62-2.54,3.78c-0.98,1.17-2.19,2.13-3.44,2.97c-1.25,0.84-2.44,1.78-3.41,2.93
				c-0.98,1.14-1.81,2.42-2.52,3.74c-0.71,1.33-1.21,2.76-1.48,4.25c-0.27,1.48-0.49,3-1.05,4.41c-0.54,1.42-1.31,2.74-2.17,3.98
				c-0.86,1.24-1.87,2.4-3.07,3.33c-1.18,0.94-2.51,1.69-3.63,2.72C5.14,60.11,4.21,61.32,3.39,62.59z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_3_"
            gradientUnits="userSpaceOnUse"
            x1="-1.7507"
            y1="64.1005"
            x2="52.8296"
            y2="9.5202"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-3"
            d="M3.39,68.98c0.9-1.4,1.9-2.75,3.11-3.9c1.19-1.17,2.62-2.06,3.88-3.14c1.28-1.07,2.36-2.36,3.29-3.73
				c0.93-1.37,1.78-2.82,2.4-4.37c0.64-1.54,0.94-3.18,1.28-4.81c0.34-1.64,0.93-3.22,1.72-4.69c0.79-1.47,1.7-2.88,2.76-4.17
				c1.05-1.3,2.34-2.4,3.69-3.38c1.34-0.98,2.61-2.06,3.65-3.36c1.05-1.28,1.95-2.69,2.74-4.14c0.79-1.46,1.36-3.02,1.7-4.65
				c0.34-1.62,0.64-3.29,1.29-4.84c0.62-1.56,1.48-3.02,2.42-4.4c0.94-1.38,2.03-2.69,3.33-3.76c1.28-1.09,2.7-1.98,3.87-3.14
				c1.2-1.13,2.19-2.47,3.08-3.86c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-0.91,1.41-1.92,2.76-3.16,3.92
				c-1.21,1.18-2.65,2.07-3.9,3.13c-1.28,1.05-2.35,2.33-3.29,3.69c-0.94,1.36-1.79,2.8-2.41,4.33c-0.65,1.53-0.95,3.16-1.3,4.79
				c-0.35,1.64-0.95,3.23-1.75,4.7c-0.8,1.47-1.71,2.88-2.78,4.17c-1.06,1.3-2.35,2.4-3.7,3.37c-1.35,0.98-2.62,2.05-3.67,3.34
				c-1.06,1.28-1.97,2.68-2.76,4.14c-0.79,1.46-1.38,3.03-1.73,4.65c-0.35,1.62-0.66,3.28-1.31,4.82c-0.62,1.55-1.48,3-2.42,4.37
				c-0.95,1.37-2.03,2.66-3.32,3.72c-1.27,1.08-2.7,1.96-3.9,3.13C5.3,66.24,4.3,67.59,3.39,68.98z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_4_"
            gradientUnits="userSpaceOnUse"
            x1="-2.3516"
            y1="69.8937"
            x2="57.4596"
            y2="10.0825"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-4"
            d="M3.39,75.38c0.98-1.53,2.06-3.01,3.36-4.29c1.28-1.3,2.8-2.33,4.15-3.55c1.37-1.2,2.52-2.63,3.54-4.13
				c1.02-1.51,1.95-3.08,2.65-4.76c0.73-1.67,1.11-3.46,1.54-5.23c0.43-1.77,1.1-3.49,1.98-5.09c0.88-1.6,1.87-3.14,3.01-4.57
				c1.13-1.44,2.51-2.67,3.94-3.79c1.44-1.12,2.79-2.34,3.91-3.76c1.13-1.41,2.12-2.95,2.99-4.54c0.87-1.59,1.53-3.29,1.96-5.05
				c0.43-1.76,0.81-3.57,1.55-5.25c0.71-1.69,1.64-3.27,2.67-4.79c1.03-1.51,2.19-2.95,3.57-4.16c1.36-1.23,2.88-2.25,4.14-3.54
				c1.29-1.27,2.36-2.74,3.33-4.26c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-0.99,1.53-2.08,3.02-3.4,4.31
				c-1.3,1.31-2.82,2.34-4.17,3.54c-1.36,1.19-2.52,2.6-3.54,4.09c-1.02,1.5-1.96,3.06-2.66,4.73c-0.73,1.66-1.12,3.43-1.56,5.21
				c-0.44,1.78-1.12,3.5-2,5.1c-0.88,1.6-1.88,3.14-3.03,4.56c-1.14,1.43-2.52,2.66-3.96,3.77c-1.44,1.11-2.8,2.32-3.93,3.74
				c-1.14,1.41-2.13,2.94-3.01,4.53c-0.88,1.59-1.56,3.29-1.99,5.05c-0.44,1.76-0.83,3.56-1.56,5.23c-0.71,1.68-1.65,3.26-2.68,4.76
				c-1.03,1.5-2.19,2.93-3.57,4.12c-1.36,1.22-2.88,2.23-4.16,3.53C5.47,72.37,4.38,73.85,3.39,75.38z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_5_"
            gradientUnits="userSpaceOnUse"
            x1="-2.9332"
            y1="75.7062"
            x2="62.1089"
            y2="10.6641"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-5"
            d="M3.39,81.77C4.46,80.11,5.62,78.5,7,77.08c1.36-1.44,2.98-2.6,4.41-3.95c1.46-1.34,2.69-2.89,3.79-4.53
				c1.1-1.64,2.11-3.34,2.9-5.15c0.81-1.8,1.28-3.73,1.79-5.63c0.51-1.91,1.28-3.76,2.23-5.49c0.96-1.73,2.03-3.4,3.26-4.96
				c1.22-1.57,2.67-2.94,4.2-4.19c1.53-1.25,2.96-2.61,4.17-4.16c1.22-1.55,2.28-3.21,3.24-4.93c0.95-1.72,1.71-3.55,2.22-5.45
				c0.51-1.9,0.98-3.84,1.8-5.66c0.79-1.82,1.81-3.54,2.92-5.18c1.11-1.64,2.35-3.21,3.82-4.56c1.45-1.36,3.06-2.52,4.4-3.95
				c1.37-1.4,2.53-3.01,3.59-4.66c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.08,1.66-2.25,3.28-3.65,4.7
				c-1.38,1.44-3,2.61-4.43,3.94c-1.45,1.32-2.69,2.87-3.79,4.49c-1.1,1.63-2.12,3.32-2.91,5.12c-0.82,1.79-1.29,3.71-1.81,5.62
				c-0.52,1.91-1.29,3.76-2.26,5.49c-0.97,1.73-2.05,3.4-3.28,4.95c-1.22,1.56-2.68,2.93-4.22,4.18c-1.53,1.25-2.97,2.59-4.19,4.15
				c-1.23,1.54-2.3,3.2-3.26,4.92c-0.96,1.72-1.73,3.55-2.25,5.45c-0.52,1.9-1,3.83-1.82,5.64c-0.8,1.81-1.82,3.52-2.93,5.15
				c-1.11,1.63-2.36,3.19-3.82,4.52c-1.45,1.35-3.06,2.5-4.43,3.94C5.63,78.51,4.47,80.11,3.39,81.77z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_6_"
            gradientUnits="userSpaceOnUse"
            x1="-3.5149"
            y1="81.5188"
            x2="66.7581"
            y2="11.2458"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-6"
            d="M3.39,88.17c1.15-1.79,2.39-3.54,3.86-5.09c1.45-1.57,3.15-2.87,4.67-4.36c1.54-1.47,2.86-3.16,4.05-4.93
				c1.18-1.77,2.28-3.6,3.15-5.54c0.9-1.93,1.45-4,2.05-6.04c0.6-2.05,1.45-4.02,2.49-5.89c1.04-1.86,2.2-3.66,3.51-5.35
				c1.3-1.7,2.84-3.2,4.45-4.59c1.62-1.39,3.14-2.88,4.43-4.57c1.3-1.68,2.45-3.48,3.49-5.33c1.04-1.86,1.88-3.81,2.47-5.85
				c0.6-2.04,1.15-4.12,2.06-6.06c0.88-1.96,1.98-3.8,3.17-5.57c1.19-1.77,2.52-3.47,4.07-4.95c1.54-1.5,3.23-2.79,4.67-4.35
				c1.46-1.54,2.69-3.27,3.84-5.06c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.16,1.79-2.41,3.55-3.9,5.1
				c-1.46,1.58-3.18,2.87-4.69,4.35c-1.54,1.46-2.85,3.13-4.05,4.89c-1.19,1.76-2.29,3.59-3.17,5.52c-0.9,1.92-1.46,3.98-2.07,6.02
				c-0.61,2.05-1.46,4.03-2.51,5.89c-1.05,1.86-2.21,3.66-3.53,5.34c-1.31,1.7-2.85,3.2-4.47,4.58c-1.62,1.38-3.15,2.87-4.45,4.55
				c-1.31,1.67-2.47,3.47-3.51,5.32c-1.04,1.85-1.9,3.81-2.5,5.85c-0.61,2.04-1.17,4.11-2.08,6.05c-0.88,1.95-1.99,3.78-3.18,5.55
				c-1.2,1.76-2.52,3.45-4.07,4.92c-1.53,1.48-3.24,2.78-4.69,4.34C5.8,84.64,4.55,86.38,3.39,88.17z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_7_"
            gradientUnits="userSpaceOnUse"
            x1="-4.0965"
            y1="87.3313"
            x2="71.4074"
            y2="11.8274"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-7"
            d="M3.39,94.56c1.23-1.92,2.55-3.8,4.11-5.48c1.53-1.71,3.33-3.14,4.93-4.76c1.63-1.61,3.02-3.43,4.3-5.32
				c1.27-1.9,2.45-3.86,3.4-5.94c0.98-2.07,1.62-4.27,2.3-6.45c0.68-2.19,1.62-4.29,2.74-6.29c1.13-1.99,2.37-3.92,3.76-5.75
				c1.38-1.83,3.01-3.47,4.71-4.99c1.7-1.52,3.31-3.15,4.69-4.97c1.38-1.81,2.62-3.74,3.74-5.72c1.12-1.99,2.05-4.08,2.73-6.25
				c0.68-2.17,1.32-4.39,2.31-6.47c0.96-2.09,2.15-4.06,3.42-5.96c1.28-1.9,2.68-3.74,4.32-5.35c1.62-1.63,3.41-3.06,4.93-4.76
				c1.54-1.67,2.86-3.54,4.09-5.46c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0
				c-1.24,1.93-2.58,3.81-4.15,5.49c-1.54,1.71-3.35,3.14-4.96,4.75c-1.63,1.59-3.02,3.4-4.3,5.29c-1.27,1.89-2.46,3.85-3.42,5.91
				c-0.99,2.06-1.63,4.25-2.32,6.43C44.37,34.9,43.42,37,42.29,39c-1.14,1.99-2.38,3.92-3.78,5.74c-1.39,1.83-3.02,3.46-4.73,4.98
				c-1.71,1.52-3.32,3.14-4.71,4.95c-1.39,1.81-2.63,3.73-3.77,5.71c-1.13,1.98-2.07,4.08-2.76,6.25c-0.69,2.18-1.34,4.38-2.33,6.45
				c-0.97,2.08-2.16,4.04-3.43,5.94c-1.28,1.89-2.69,3.72-4.32,5.32c-1.62,1.62-3.42,3.05-4.95,4.75
				C5.96,90.77,4.63,92.64,3.39,94.56z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_8_"
            gradientUnits="userSpaceOnUse"
            x1="-4.6781"
            y1="93.1439"
            x2="76.0567"
            y2="12.409"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-8"
            d="M3.39,100.95c1.32-2.06,2.72-4.07,4.36-5.88c1.61-1.84,3.5-3.41,5.19-5.17c1.71-1.74,3.19-3.7,4.55-5.72
				c1.35-2.03,2.62-4.13,3.66-6.33c1.07-2.2,1.79-4.53,2.56-6.85c0.77-2.32,1.79-4.56,3-6.69c1.21-2.12,2.53-4.19,4.01-6.14
				c1.47-1.96,3.17-3.74,4.97-5.39c1.79-1.66,3.48-3.42,4.94-5.37c1.47-1.94,2.79-4,3.99-6.12c1.2-2.12,2.22-4.34,2.98-6.66
				c0.77-2.31,1.49-4.66,2.57-6.87c1.05-2.22,2.32-4.32,3.67-6.36c1.36-2.03,2.85-4,4.57-5.75c1.71-1.77,3.59-3.33,5.19-5.16
				c1.63-1.8,3.03-3.81,4.34-5.86c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.33,2.06-2.74,4.07-4.4,5.89
				c-1.63,1.84-3.52,3.41-5.22,5.16c-1.71,1.73-3.19,3.67-4.55,5.69c-1.36,2.02-2.63,4.11-3.67,6.31c-1.07,2.19-1.8,4.52-2.58,6.84
				c-0.78,2.32-1.81,4.56-3.02,6.69c-1.22,2.12-2.55,4.18-4.03,6.13c-1.47,1.96-3.18,3.73-4.98,5.38c-1.8,1.65-3.5,3.41-4.96,5.36
				c-1.48,1.94-2.8,4-4.02,6.11c-1.21,2.12-2.24,4.34-3.01,6.66c-0.77,2.31-1.51,4.66-2.59,6.86c-1.05,2.21-2.33,4.3-3.68,6.33
				c-1.36,2.03-2.85,3.98-4.58,5.72c-1.7,1.75-3.59,3.32-5.21,5.15C6.13,96.9,4.72,98.9,3.39,100.95z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_9_"
            gradientUnits="userSpaceOnUse"
            x1="-5.2597"
            y1="98.9564"
            x2="80.706"
            y2="12.9907"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-9"
            d="M3.39,107.35c1.4-2.19,2.89-4.33,4.61-6.28c1.7-1.97,3.67-3.68,5.45-5.57c1.8-1.87,3.36-3.96,4.8-6.12
				c1.43-2.16,2.79-4.39,3.91-6.73c1.15-2.33,1.96-4.8,2.82-7.26c0.85-2.46,1.96-4.83,3.25-7.09c1.3-2.26,2.7-4.45,4.26-6.54
				c1.55-2.09,3.34-4,5.22-5.8c1.88-1.79,3.66-3.69,5.2-5.77c1.55-2.08,2.95-4.27,4.25-6.52c1.29-2.25,2.39-4.61,3.24-7.06
				c0.85-2.45,1.66-4.94,2.82-7.28c1.13-2.35,2.49-4.58,3.92-6.75c1.44-2.16,3.01-4.26,4.82-6.15c1.79-1.9,3.76-3.6,5.45-5.56
				c1.72-1.94,3.2-4.07,4.6-6.26c0.05-0.09,0.17-0.11,0.25-0.06s0.11,0.17,0.06,0.25l0,0c-1.41,2.19-2.91,4.33-4.65,6.29
				c-1.71,1.98-3.7,3.68-5.47,5.56c-1.8,1.86-3.36,3.94-4.81,6.09c-1.44,2.16-2.79,4.38-3.92,6.71c-1.16,2.32-1.97,4.79-2.83,7.24
				c-0.86,2.46-1.98,4.83-3.28,7.08c-1.3,2.25-2.71,4.45-4.28,6.53c-1.56,2.09-3.35,3.99-5.24,5.78c-1.89,1.79-3.67,3.68-5.22,5.76
				c-1.56,2.07-2.97,4.26-4.27,6.51c-1.3,2.25-2.41,4.6-3.27,7.06c-0.86,2.45-1.68,4.93-2.84,7.26c-1.14,2.34-2.49,4.57-3.94,6.73
				c-1.45,2.16-3.02,4.24-4.83,6.12c-1.79,1.89-3.77,3.59-5.47,5.55C6.3,103.03,4.8,105.17,3.39,107.35z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_10_"
            gradientUnits="userSpaceOnUse"
            x1="-5.8414"
            y1="104.769"
            x2="85.3553"
            y2="13.5723"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-10"
            d="M3.39,113.74c1.49-2.32,3.06-4.59,4.86-6.68c1.78-2.11,3.85-3.95,5.71-5.97c1.89-2.01,3.53-4.23,5.06-6.52
				c1.52-2.3,2.95-4.65,4.16-7.13c1.24-2.46,2.13-5.07,3.07-7.66c0.94-2.59,2.13-5.09,3.51-7.48c1.38-2.39,2.87-4.71,4.51-6.93
				c1.63-2.23,3.51-4.27,5.48-6.2c1.97-1.93,3.83-3.96,5.46-6.18c1.64-2.21,3.12-4.53,4.5-6.91c1.37-2.38,2.56-4.87,3.49-7.46
				c0.94-2.58,1.84-5.21,3.08-7.68c1.22-2.48,2.65-4.85,4.18-7.15c1.53-2.3,3.18-4.53,5.07-6.54c1.88-2.03,3.93-3.87,5.71-5.97
				c1.8-2.07,3.37-4.34,4.85-6.65c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.49,2.32-3.08,4.6-4.9,6.68
				c-1.79,2.11-3.87,3.95-5.73,5.96c-1.89,2-3.53,4.21-5.06,6.49c-1.52,2.29-2.96,4.64-4.18,7.1c-1.24,2.45-2.14,5.05-3.09,7.65
				c-0.95,2.59-2.15,5.1-3.53,7.48c-1.39,2.39-2.88,4.71-4.53,6.92c-1.64,2.22-3.52,4.26-5.49,6.18c-1.97,1.92-3.84,3.95-5.48,6.16
				c-1.64,2.21-3.14,4.53-4.52,6.9c-1.38,2.38-2.58,4.87-3.52,7.46c-0.94,2.58-1.85,5.2-3.1,7.66c-1.22,2.47-2.66,4.83-4.19,7.13
				c-1.53,2.29-3.19,4.51-5.08,6.51c-1.87,2.02-3.94,3.86-5.73,5.96C6.46,109.16,4.89,111.43,3.39,113.74z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_11_"
            gradientUnits="userSpaceOnUse"
            x1="-6.423"
            y1="110.5815"
            x2="90.0046"
            y2="14.1539"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-11"
            d="M3.39,120.14c1.57-2.45,3.22-4.86,5.12-7.07c1.87-2.24,4.02-4.22,5.97-6.38c1.97-2.14,3.7-4.5,5.31-6.92
				c1.6-2.43,3.12-4.92,4.42-7.52c1.32-2.59,2.3-5.34,3.33-8.07c1.02-2.73,2.3-5.36,3.76-7.88c1.47-2.52,3.04-4.98,4.76-7.33
				c1.72-2.36,3.68-4.53,5.73-6.6c2.06-2.06,4-4.23,5.71-6.58c1.72-2.34,3.29-4.8,4.75-7.31c1.46-2.52,2.73-5.14,3.75-7.86
				c1.02-2.72,2.01-5.48,3.33-8.08c1.3-2.62,2.82-5.11,4.43-7.54c1.61-2.43,3.35-4.79,5.33-6.94c1.96-2.17,4.11-4.14,5.97-6.37
				c1.89-2.21,3.53-4.61,5.1-7.05c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.58,2.45-3.24,4.86-5.15,7.08
				c-1.88,2.24-4.04,4.22-5.99,6.36c-1.97,2.13-3.7,4.48-5.32,6.89c-1.61,2.42-3.13,4.9-4.43,7.5c-1.33,2.59-2.31,5.32-3.35,8.05
				c-1.03,2.73-2.32,5.36-3.79,7.88c-1.47,2.52-3.05,4.97-4.78,7.32c-1.72,2.36-3.69,4.53-5.75,6.58c-2.06,2.06-4.01,4.21-5.73,6.56
				c-1.73,2.34-3.3,4.79-4.77,7.3c-1.46,2.51-2.75,5.13-3.78,7.86c-1.03,2.72-2.02,5.47-3.35,8.07c-1.31,2.61-2.83,5.1-4.44,7.52
				c-1.62,2.42-3.35,4.77-5.33,6.91c-1.96,2.16-4.12,4.12-5.99,6.36C6.63,115.29,4.97,117.69,3.39,120.14z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_12_"
            gradientUnits="userSpaceOnUse"
            x1="-7.0046"
            y1="116.3941"
            x2="94.6539"
            y2="14.7356"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-12"
            d="M3.39,126.53c1.65-2.58,3.39-5.12,5.37-7.47c1.95-2.37,4.19-4.49,6.23-6.78c2.06-2.28,3.87-4.76,5.56-7.32
				c1.69-2.56,3.29-5.18,4.67-7.92c1.41-2.73,2.47-5.61,3.58-8.47c1.11-2.86,2.47-5.63,4.02-8.28c1.55-2.65,3.2-5.24,5.02-7.72
				c1.8-2.49,3.84-4.8,5.99-7c2.14-2.2,4.17-4.49,5.97-6.98c1.8-2.48,3.46-5.06,5-7.71c1.54-2.65,2.9-5.4,4-8.26
				c1.11-2.86,2.18-5.75,3.59-8.49c1.39-2.75,2.99-5.37,4.68-7.94c1.7-2.56,3.51-5.06,5.58-7.34c2.05-2.3,4.28-4.41,6.22-6.77
				c1.97-2.34,3.7-4.87,5.35-7.45c0.05-0.09,0.17-0.11,0.25-0.06s0.11,0.17,0.06,0.25l0,0c-1.66,2.58-3.41,5.12-5.4,7.48
				c-1.96,2.38-4.21,4.49-6.25,6.77c-2.06,2.27-3.87,4.74-5.57,7.29c-1.69,2.55-3.3,5.17-4.69,7.9c-1.41,2.72-2.49,5.59-3.6,8.45
				c-1.12,2.86-2.49,5.63-4.04,8.28c-1.56,2.65-3.22,5.24-5.04,7.72c-1.81,2.49-3.86,4.79-6,6.98c-2.15,2.19-4.19,4.48-5.99,6.97
				c-1.81,2.47-3.47,5.06-5.03,7.7c-1.55,2.65-2.92,5.4-4.03,8.26c-1.11,2.86-2.19,5.74-3.61,8.47c-1.39,2.74-3,5.36-4.7,7.92
				c-1.7,2.55-3.52,5.04-5.59,7.31c-2.05,2.29-4.29,4.39-6.24,6.76C6.8,121.42,5.05,123.95,3.39,126.53z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_13_"
            gradientUnits="userSpaceOnUse"
            x1="-7.5862"
            y1="122.2066"
            x2="99.3032"
            y2="15.3172"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-13"
            d="M3.39,132.93c1.74-2.72,3.56-5.39,5.62-7.87c2.03-2.51,4.36-4.75,6.48-7.18c2.14-2.41,4.04-5.03,5.82-7.72
				c1.77-2.69,3.46-5.45,4.92-8.32c1.49-2.86,2.64-5.88,3.84-8.87c1.19-3,2.64-5.9,4.27-8.68c1.63-2.78,3.37-5.51,5.27-8.12
				c1.88-2.62,4.01-5.07,6.24-7.4c4.53-4.6,8.23-9.93,11.48-15.49c1.63-2.78,3.07-5.67,4.26-8.66c1.19-2.99,2.35-6.02,3.85-8.89
				c1.47-2.88,3.16-5.64,4.93-8.34c1.78-2.69,3.68-5.32,5.83-7.74c2.13-2.43,4.45-4.68,6.48-7.18c2.06-2.47,3.87-5.14,5.61-7.85
				c0.05-0.09,0.17-0.11,0.25-0.06s0.11,0.17,0.06,0.25l0,0c-1.75,2.72-3.58,5.39-5.65,7.87c-2.05,2.51-4.38,4.75-6.51,7.17
				c-2.14,2.4-4.04,5.01-5.82,7.69c-1.78,2.69-3.47,5.43-4.94,8.3c-1.5,2.85-2.66,5.86-3.86,8.86c-1.2,3-2.66,5.9-4.29,8.68
				c-1.64,2.78-3.39,5.5-5.29,8.11c-1.89,2.62-4.02,5.06-6.26,7.39c-4.55,4.59-8.26,9.91-11.52,15.46
				c-1.63,2.78-3.09,5.66-4.28,8.66c-1.2,2.99-2.36,6.01-3.86,8.87c-1.47,2.87-3.17,5.62-4.95,8.31c-1.79,2.69-3.69,5.3-5.84,7.71
				c-2.13,2.42-4.46,4.66-6.5,7.16C6.97,127.55,5.14,130.21,3.39,132.93z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_14_"
            gradientUnits="userSpaceOnUse"
            x1="-8.1679"
            y1="128.0191"
            x2="103.9525"
            y2="15.8988"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-14"
            d="M3.39,139.32c1.82-2.85,3.73-5.65,5.87-8.27c2.12-2.64,4.53-5.02,6.74-7.58c2.23-2.54,4.21-5.3,6.07-8.12
				c1.86-2.83,3.63-5.71,5.18-8.72c1.58-2.99,2.81-6.14,4.09-9.28c1.28-3.13,2.81-6.16,4.53-9.08c1.72-2.92,3.54-5.77,5.52-8.52
				c1.97-2.76,4.18-5.33,6.5-7.8c4.71-4.87,8.57-10.46,11.99-16.29c1.71-2.91,3.24-5.93,4.51-9.06c1.28-3.13,2.52-6.29,4.1-9.29
				c1.56-3.01,3.33-5.9,5.19-8.73c1.87-2.82,3.85-5.58,6.08-8.14c2.22-2.57,4.63-4.95,6.74-7.58c2.14-2.61,4.04-5.41,5.86-8.25
				c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.83,2.85-3.75,5.65-5.9,8.27
				c-2.13,2.64-4.55,5.02-6.76,7.57c-2.23,2.53-4.21,5.28-6.08,8.09c-1.86,2.82-3.64,5.7-5.19,8.7c-1.58,2.98-2.83,6.13-4.11,9.26
				c-1.29,3.13-2.83,6.16-4.55,9.08c-1.73,2.91-3.56,5.76-5.54,8.51c-1.98,2.75-4.19,5.33-6.51,7.79
				c-4.72,4.86-8.59,10.44-12.03,16.26c-1.72,2.91-3.25,5.93-4.54,9.06c-1.28,3.13-2.53,6.28-4.12,9.27c-1.56,3-3.34,5.89-5.2,8.71
				c-1.87,2.82-3.86,5.57-6.09,8.11c-2.22,2.56-4.63,4.93-6.76,7.57C7.13,133.68,5.22,136.48,3.39,139.32z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_15_"
            gradientUnits="userSpaceOnUse"
            x1="-8.7495"
            y1="133.8316"
            x2="108.6017"
            y2="16.4804"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-15"
            d="M3.39,145.71c1.91-2.98,3.89-5.92,6.13-8.67c2.2-2.77,4.7-5.29,7-7.98c2.31-2.68,4.38-5.56,6.32-8.52
				c1.94-2.96,3.8-5.98,5.43-9.11c1.66-3.13,2.99-6.41,4.35-9.68c1.36-3.27,2.98-6.43,4.78-9.48c1.8-3.05,3.71-6.04,5.77-8.92
				c2.05-2.89,4.35-5.6,6.75-8.2c4.88-5.14,8.91-11,12.5-17.08c1.79-3.05,3.41-6.2,4.77-9.46c1.36-3.26,2.69-6.56,4.35-9.69
				c1.64-3.15,3.5-6.17,5.44-9.13c1.95-2.96,4.02-5.85,6.34-8.53c2.3-2.7,4.8-5.21,6.99-7.98c2.23-2.74,4.21-5.67,6.11-8.65
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.92,2.98-3.91,5.92-6.16,8.67
				c-2.22,2.78-4.72,5.29-7.02,7.97c-2.32,2.67-4.38,5.54-6.33,8.49c-1.94,2.95-3.81,5.96-5.45,9.09c-1.67,3.12-3,6.4-4.37,9.66
				c-1.37,3.27-3,6.43-4.8,9.48c-1.81,3.05-3.72,6.03-5.79,8.91c-2.06,2.89-4.36,5.59-6.77,8.19c-4.89,5.13-8.93,10.98-12.54,17.06
				c-1.8,3.04-3.42,6.19-4.79,9.46c-1.37,3.26-2.7,6.55-4.37,9.68c-1.64,3.14-3.51,6.15-5.45,9.11c-1.95,2.95-4.02,5.83-6.34,8.51
				c-2.3,2.69-4.81,5.2-7.02,7.97C7.3,139.81,5.31,142.74,3.39,145.71z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_16_"
            gradientUnits="userSpaceOnUse"
            x1="-9.3311"
            y1="139.6442"
            x2="113.251"
            y2="17.0621"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-16"
            d="M3.39,152.11c1.99-3.11,4.06-6.18,6.38-9.06c2.29-2.91,4.88-5.56,7.25-8.39c2.4-2.81,4.54-5.83,6.58-8.92
				c2.02-3.09,3.96-6.24,5.68-9.51c1.75-3.26,3.16-6.68,4.6-10.08c1.45-3.4,3.15-6.7,5.03-9.88c1.89-3.18,3.88-6.3,6.03-9.31
				c2.14-3.02,4.52-5.87,7.01-8.6c5.05-5.41,9.25-11.53,13.01-17.88c1.88-3.18,3.58-6.46,5.02-9.86c1.45-3.4,2.86-6.83,4.61-10.09
				c1.72-3.28,3.67-6.43,5.69-9.53c2.03-3.09,4.18-6.11,6.59-8.93c2.39-2.84,4.97-5.48,7.25-8.38c2.31-2.88,4.38-5.94,6.37-9.05
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-2,3.11-4.08,6.18-6.41,9.07c-2.3,2.91-4.9,5.56-7.27,8.37
				c-2.4,2.8-4.55,5.81-6.59,8.89c-2.03,3.08-3.97,6.23-5.7,9.49c-1.75,3.25-3.17,6.66-4.62,10.06c-1.46,3.4-3.17,6.7-5.06,9.88
				c-1.89,3.18-3.89,6.29-6.05,9.3c-2.14,3.02-4.53,5.86-7.02,8.59c-5.06,5.39-9.27,11.51-13.05,17.86
				c-1.89,3.18-3.59,6.46-5.05,9.86c-1.45,3.4-2.87,6.82-4.63,10.08c-1.73,3.27-3.68,6.42-5.71,9.51c-2.04,3.08-4.19,6.1-6.6,8.91
				c-2.39,2.82-4.98,5.47-7.27,8.37C7.47,145.93,5.39,149,3.39,152.11z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_17_"
            gradientUnits="userSpaceOnUse"
            x1="-9.9127"
            y1="145.4567"
            x2="117.9003"
            y2="17.6437"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-17"
            d="M3.39,158.5c2.08-3.25,4.23-6.45,6.63-9.46c2.37-3.04,5.05-5.83,7.51-8.79c2.48-2.95,4.71-6.1,6.83-9.32
				c2.11-3.22,4.13-6.51,5.94-9.91c1.83-3.39,3.33-6.95,4.86-10.48c1.53-3.54,3.32-6.96,5.29-10.28
				c3.93-6.62,8.31-13.03,13.54-18.71c5.22-5.67,9.59-12.06,13.52-18.68c1.96-3.31,3.75-6.73,5.28-10.26
				c1.53-3.53,3.03-7.1,4.86-10.49c1.81-3.41,3.84-6.7,5.95-9.92c2.12-3.22,4.35-6.38,6.84-9.33c2.47-2.97,5.14-5.75,7.51-8.78
				c2.4-3.01,4.55-6.21,6.62-9.45c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-2.08,3.24-4.25,6.45-6.66,9.46
				c-2.38,3.04-5.07,5.82-7.53,8.78c-2.49,2.94-4.72,6.08-6.84,9.29c-2.11,3.22-4.14,6.49-5.95,9.89
				c-1.84,3.38-3.34,6.93-4.88,10.47c-1.54,3.54-3.34,6.96-5.31,10.28c-1.98,3.31-4.06,6.56-6.3,9.7c-2.23,3.15-4.7,6.12-7.28,8.99
				c-5.24,5.66-9.61,12.05-13.56,18.66c-1.97,3.31-3.76,6.72-5.3,10.26c-1.54,3.53-3.04,7.09-4.88,10.48
				c-1.81,3.4-3.85,6.68-5.96,9.9c-2.12,3.22-4.36,6.36-6.85,9.31c-2.47,2.96-5.15,5.74-7.53,8.77
				C7.64,152.06,5.48,155.26,3.39,158.5z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_18_"
            gradientUnits="userSpaceOnUse"
            x1="-10.3636"
            y1="151.1385"
            x2="122.5502"
            y2="18.2248"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-18"
            d="M3.39,164.9c2.16-3.38,4.4-6.71,6.89-9.86c2.46-3.17,5.22-6.09,7.77-9.19c2.57-3.08,4.88-6.36,7.08-9.71
				c2.19-3.36,4.3-6.77,6.19-10.31c1.92-3.52,3.5-7.21,5.11-10.88c1.62-3.67,3.49-7.23,5.54-10.68c4.1-6.89,8.64-13.56,14.05-19.51
				c5.4-5.94,9.92-12.6,14.02-19.48c2.05-3.44,3.92-6.99,5.53-10.66c1.62-3.67,3.2-7.37,5.12-10.9c1.89-3.54,4-6.96,6.2-10.32
				c2.2-3.35,4.52-6.64,7.1-9.73c2.56-3.1,5.31-6.02,7.76-9.18c2.48-3.14,4.72-6.47,6.88-9.85c0.05-0.09,0.17-0.11,0.26-0.06
				c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-2.17,3.38-4.42,6.71-6.91,9.86c-2.47,3.18-5.24,6.09-7.79,9.18
				c-2.57,3.07-4.89,6.35-7.09,9.69c-2.2,3.35-4.31,6.76-6.21,10.29c-1.92,3.52-3.51,7.2-5.13,10.87
				c-1.63,3.67-3.51,7.23-5.57,10.67c-4.12,6.88-8.67,13.55-14.08,19.49c-5.41,5.93-9.95,12.58-14.07,19.46
				c-2.06,3.44-3.93,6.99-5.56,10.66c-1.62,3.66-3.21,7.36-5.14,10.88c-1.9,3.54-4.01,6.95-6.21,10.3
				c-2.21,3.35-4.53,6.63-7.11,9.71c-2.56,3.09-5.32,6-7.78,9.17C7.81,158.19,5.56,161.52,3.39,164.9z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_19_"
            gradientUnits="userSpaceOnUse"
            x1="-10.9452"
            y1="156.951"
            x2="127.1994"
            y2="18.8064"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-19"
            d="M3.39,171.29c2.24-3.51,4.57-6.98,7.14-10.26c2.54-3.31,5.39-6.36,8.02-9.59
				c2.65-3.21,5.05-6.63,7.34-10.11c2.28-3.49,4.47-7.04,6.44-10.71c2-3.66,3.67-7.48,5.37-11.29c1.7-3.81,3.66-7.5,5.8-11.08
				c4.27-7.15,8.98-14.09,14.55-20.31c5.57-6.21,10.26-13.13,14.53-20.28c2.13-3.58,4.09-7.26,5.79-11.06
				c1.7-3.8,3.37-7.63,5.37-11.3c1.98-3.68,4.17-7.23,6.45-10.72c2.29-3.49,4.69-6.91,7.35-10.13c2.64-3.24,5.48-6.29,8.02-9.59
				c2.57-3.28,4.89-6.74,7.13-10.25c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-2.25,3.51-4.59,6.98-7.17,10.26c-2.55,3.31-5.41,6.36-8.04,9.58c-2.66,3.2-5.06,6.61-7.35,10.09
				c-2.28,3.48-4.48,7.02-6.46,10.69c-2.01,3.65-3.68,7.47-5.39,11.27c-1.71,3.81-3.68,7.5-5.82,11.07
				c-4.29,7.15-9.01,14.08-14.59,20.28c-5.58,6.2-10.29,13.11-14.57,20.26c-2.14,3.57-4.1,7.26-5.81,11.06
				c-1.71,3.8-3.38,7.63-5.39,11.28c-1.98,3.67-4.18,7.21-6.47,10.7c-2.29,3.48-4.7,6.89-7.36,10.1c-2.64,3.23-5.49,6.27-8.04,9.57
				C7.98,164.32,5.65,167.78,3.39,171.29z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_20_"
            gradientUnits="userSpaceOnUse"
            x1="-11.5268"
            y1="162.7635"
            x2="131.8487"
            y2="19.388"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-20"
            d="M3.39,177.68c2.33-3.64,4.74-7.24,7.39-10.66c2.63-3.44,5.56-6.63,8.28-9.99c2.74-3.35,5.22-6.9,7.59-10.51
				c2.36-3.62,4.64-7.3,6.7-11.11c2.09-3.79,3.84-7.75,5.62-11.69c1.79-3.94,3.83-7.76,6.05-11.48c4.44-7.42,9.32-14.62,15.06-21.11
				c5.74-6.48,10.6-13.67,15.04-21.08c2.22-3.71,4.26-7.53,6.04-11.46c1.79-3.93,3.54-7.9,5.63-11.7c2.06-3.81,4.34-7.49,6.71-11.12
				c2.37-3.62,4.86-7.17,7.6-10.53c2.72-3.37,5.65-6.55,8.28-9.99c2.65-3.41,5.06-7.01,7.38-10.65c0.05-0.09,0.17-0.11,0.26-0.06
				c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-2.34,3.64-4.75,7.24-7.42,10.66c-2.64,3.44-5.58,6.63-8.3,9.98
				c-2.74,3.34-5.23,6.88-7.6,10.49c-2.37,3.62-4.65,7.29-6.71,11.09c-2.09,3.78-3.85,7.73-5.64,11.67
				c-1.8,3.94-3.85,7.76-6.07,11.47c-4.45,7.41-9.34,14.61-15.1,21.08c-5.75,6.47-10.63,13.65-15.08,21.06
				c-2.22,3.71-4.27,7.52-6.07,11.46c-1.79,3.93-3.55,7.89-5.65,11.68c-2.07,3.8-4.35,7.48-6.72,11.1
				c-2.38,3.61-4.87,7.16-7.61,10.5c-2.73,3.36-5.66,6.54-8.3,9.98C8.14,170.45,5.73,174.05,3.39,177.68z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_21_"
            gradientUnits="userSpaceOnUse"
            x1="-12.1085"
            y1="168.5761"
            x2="136.498"
            y2="19.9697"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-21"
            d="M3.39,184.08c2.41-3.78,4.91-7.51,7.65-11.06c2.71-3.57,5.73-6.9,8.53-10.39
				c2.82-3.48,5.39-7.16,7.84-10.91c2.45-3.75,4.81-7.57,6.95-11.5c2.17-3.92,4.01-8.01,5.88-12.09c1.87-4.07,4-8.03,6.31-11.88
				c4.61-7.69,9.65-15.15,15.57-21.9c5.91-6.75,10.94-14.2,15.55-21.88c2.3-3.84,4.42-7.79,6.3-11.86c1.87-4.07,3.71-8.17,5.88-12.1
				c2.15-3.94,4.51-7.76,6.96-11.52c2.46-3.75,5.03-7.44,7.86-10.93c2.81-3.5,5.83-6.82,8.53-10.39c2.74-3.54,5.22-7.27,7.64-11.04
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-2.42,3.77-4.92,7.51-7.67,11.06
				c-2.72,3.57-5.75,6.89-8.55,10.38c-2.83,3.47-5.4,7.15-7.86,10.89c-2.45,3.75-4.82,7.56-6.97,11.48c-2.18,3.92-4.02,8-5.9,12.07
				c-1.88,4.07-4.02,8.03-6.33,11.87c-4.62,7.68-9.68,15.14-15.61,21.88c-5.92,6.74-10.97,14.18-15.59,21.85
				c-2.31,3.84-4.44,7.79-6.32,11.86c-1.88,4.07-3.72,8.16-5.9,12.08c-2.15,3.93-4.52,7.75-6.98,11.5
				c-2.46,3.75-5.03,7.43-7.87,10.9c-2.81,3.49-5.84,6.81-8.55,10.38C8.31,176.58,5.81,180.31,3.39,184.08z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_22_"
            gradientUnits="userSpaceOnUse"
            x1="-12.6901"
            y1="174.3887"
            x2="141.1473"
            y2="20.5513"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-22"
            d="M3.39,190.47c2.5-3.91,5.07-7.77,7.9-11.46c2.8-3.71,5.9-7.16,8.79-10.79c2.91-3.61,5.56-7.43,8.1-11.31
				c2.53-3.89,4.98-7.83,7.21-11.9c2.26-4.06,4.17-8.28,6.13-12.49c1.96-4.21,4.17-8.3,6.56-12.28c4.78-7.95,9.99-15.68,16.08-22.7
				c6.08-7.02,11.28-14.73,16.06-22.68c2.39-3.98,4.59-8.06,6.55-12.26c1.96-4.2,3.88-8.44,6.14-12.5
				c2.23-4.07,4.68-8.02,7.21-11.91c2.54-3.88,5.19-7.71,8.11-11.33c2.89-3.64,6-7.09,8.79-10.79c2.82-3.68,5.39-7.54,7.89-11.44
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-2.51,3.91-5.09,7.77-7.93,11.46
				c-2.81,3.71-5.92,7.16-8.81,10.78c-2.91,3.61-5.57,7.41-8.11,11.29c-2.54,3.88-4.99,7.82-7.22,11.88
				c-2.26,4.05-4.19,8.27-6.15,12.47c-1.97,4.21-4.19,8.29-6.58,12.27C75.62,82.93,70.4,90.65,64.3,97.66
				c-6.09,7.01-11.31,14.71-16.1,22.65c-2.39,3.97-4.61,8.05-6.58,12.25c-1.96,4.2-3.89,8.43-6.16,12.48
				c-2.24,4.07-4.69,8.01-7.23,11.9c-2.55,3.88-5.2,7.69-8.12,11.3c-2.9,3.63-6.01,7.08-8.81,10.78
				C8.48,182.71,5.9,186.57,3.39,190.47z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_23_"
            gradientUnits="userSpaceOnUse"
            x1="-13.2717"
            y1="180.2012"
            x2="145.7965"
            y2="21.133"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-23"
            d="M3.39,196.87c2.58-4.04,5.24-8.04,8.15-11.86c2.88-3.84,6.07-7.43,9.04-11.19c3-3.75,5.73-7.7,8.35-11.71
				c2.61-4.02,5.15-8.1,7.46-12.3c2.34-4.19,4.34-8.55,6.39-12.89c2.04-4.34,4.34-8.56,6.81-12.68c4.95-8.22,10.33-16.21,16.58-23.5
				c6.25-7.28,11.62-15.27,16.57-23.48c2.47-4.11,4.76-8.32,6.81-12.66c2.04-4.34,4.05-8.71,6.39-12.9
				c2.32-4.21,4.85-8.29,7.47-12.31c2.63-4.02,5.36-7.97,8.36-11.72c2.98-3.77,6.17-7.36,9.04-11.19c2.91-3.81,5.56-7.8,8.14-11.84
				c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-2.59,4.04-5.26,8.04-8.18,11.85c-2.89,3.84-6.09,7.43-9.06,11.18
				c-3,3.74-5.74,7.68-8.36,11.69c-2.62,4.01-5.16,8.09-7.48,12.28c-2.35,4.18-4.36,8.54-6.41,12.88
				c-2.05,4.34-4.36,8.56-6.84,12.67c-4.96,8.21-10.35,16.2-16.62,23.48c-6.26,7.27-11.65,15.25-16.61,23.45
				c-2.48,4.11-4.78,8.32-6.83,12.65c-2.05,4.34-4.06,8.7-6.41,12.89c-2.32,4.2-4.86,8.28-7.48,12.29
				c-2.63,4.01-5.37,7.96-8.37,11.7c-2.98,3.76-6.18,7.34-9.06,11.18C8.65,188.84,5.98,192.83,3.39,196.87z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_24_"
            gradientUnits="userSpaceOnUse"
            x1="-13.8533"
            y1="186.0137"
            x2="150.4458"
            y2="21.7146"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-24"
            d="M3.39,203.26c2.67-4.17,5.41-8.31,8.41-12.25c2.96-3.97,6.24-7.7,9.3-11.59c3.08-3.88,5.9-7.96,8.61-12.11
				c2.7-4.15,5.32-8.37,7.71-12.7c2.43-4.32,4.51-8.82,6.64-13.29c2.13-4.48,4.51-8.83,7.07-13.08c5.12-8.48,10.66-16.74,17.09-24.3
				c6.42-7.55,11.96-15.8,17.08-24.28c2.56-4.24,4.93-8.59,7.06-13.06c2.13-4.47,4.22-8.97,6.65-13.3c2.4-4.34,5.02-8.55,7.72-12.71
				c2.71-4.15,5.53-8.24,8.62-12.12c3.06-3.9,6.34-7.62,9.3-11.59c2.99-3.94,5.73-8.07,8.4-12.24c0.05-0.09,0.17-0.11,0.26-0.06
				s0.11,0.17,0.06,0.26l0,0c-2.68,4.17-5.43,8.3-8.43,12.25c-2.97,3.97-6.26,7.7-9.32,11.58c-3.08,3.87-5.91,7.95-8.62,12.09
				c-2.7,4.15-5.33,8.35-7.73,12.68c-2.43,4.31-4.53,8.8-6.66,13.28c-2.14,4.47-4.53,8.83-7.09,13.07
				c-5.13,8.48-10.69,16.73-17.13,24.28c-6.44,7.54-11.99,15.78-17.12,24.25c-2.56,4.24-4.95,8.58-7.08,13.05
				c-2.13,4.47-4.23,8.97-6.67,13.29c-2.41,4.33-5.03,8.54-7.74,12.69c-2.71,4.14-5.54,8.22-8.63,12.1
				c-3.07,3.89-6.35,7.61-9.32,11.58C8.82,194.96,6.07,199.09,3.39,203.26z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_25_"
            gradientUnits="userSpaceOnUse"
            x1="-14.435"
            y1="191.8263"
            x2="155.0951"
            y2="22.2963"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-25"
            d="M3.39,209.66c2.75-4.31,5.58-8.57,8.66-12.65c3.05-4.11,6.41-7.97,9.56-12c3.17-4.02,6.07-8.23,8.86-12.51
				c2.78-4.29,5.48-8.63,7.97-13.1c2.51-4.45,4.68-9.08,6.9-13.69c2.21-4.61,4.68-9.1,7.32-13.48c5.29-8.75,11-17.27,17.6-25.1
				c6.59-7.82,12.3-16.33,17.58-25.08c2.64-4.38,5.1-8.85,7.31-13.46c2.21-4.61,4.39-9.24,6.9-13.7c2.49-4.47,5.19-8.82,7.97-13.11
				c2.79-4.28,5.7-8.5,8.87-12.52c3.15-4.04,6.51-7.89,9.55-11.99c3.08-4.08,5.9-8.34,8.65-12.64c0.05-0.09,0.17-0.11,0.26-0.06
				c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-2.76,4.31-5.6,8.57-8.69,12.65c-3.06,4.11-6.43,7.96-9.58,11.98
				c-3.17,4.01-6.07,8.21-8.87,12.49c-2.79,4.28-5.5,8.62-7.98,13.08c-2.52,4.45-4.7,9.07-6.92,13.68
				c-2.22,4.61-4.7,9.09-7.35,13.47c-5.3,8.74-11.03,17.26-17.64,25.07c-6.61,7.81-12.33,16.31-17.63,25.05
				c-2.65,4.37-5.12,8.85-7.34,13.45c-2.22,4.6-4.4,9.23-6.92,13.69c-2.49,4.47-5.2,8.81-7.99,13.09c-2.8,4.28-5.71,8.49-8.88,12.5
				c-3.15,4.03-6.52,7.88-9.57,11.98C8.99,201.09,6.15,205.35,3.39,209.66z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_26_"
            gradientUnits="userSpaceOnUse"
            x1="-15.0166"
            y1="197.6388"
            x2="159.7443"
            y2="22.8779"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-26"
            d="M3.39,216.05c2.84-4.44,5.75-8.84,8.91-13.05c3.13-4.24,6.58-8.23,9.81-12.4c3.25-4.15,6.24-8.5,9.11-12.91
				c2.87-4.42,5.65-8.9,8.22-13.5c2.6-4.59,4.85-9.35,7.15-14.09c2.3-4.74,4.85-9.36,7.58-13.87c5.46-9.01,11.34-17.8,18.11-25.9
				c6.77-8.09,12.64-16.87,18.09-25.88c2.72-4.51,5.27-9.12,7.57-13.86c2.3-4.74,4.56-9.51,7.16-14.1
				c2.57-4.61,5.36-9.09,8.23-13.51c2.88-4.42,5.87-8.77,9.12-12.92c3.23-4.17,6.68-8.16,9.81-12.39c3.16-4.21,6.07-8.6,8.91-13.04
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-2.84,4.44-5.77,8.83-8.94,13.05
				c-3.14,4.24-6.6,8.23-9.83,12.38c-3.25,4.14-6.24,8.48-9.13,12.89c-2.87,4.41-5.66,8.88-8.24,13.48
				c-2.6,4.58-4.87,9.34-7.17,14.08c-2.31,4.74-4.87,9.36-7.6,13.87c-5.47,9.01-11.37,17.79-18.15,25.87
				c-6.78,8.08-12.66,16.85-18.13,25.85c-2.73,4.5-5.29,9.12-7.59,13.85c-2.3,4.74-4.57,9.5-7.18,14.09
				c-2.58,4.6-5.37,9.07-8.24,13.49c-2.88,4.41-5.88,8.75-9.14,12.9c-3.24,4.16-6.69,8.15-9.83,12.38
				C9.16,207.22,6.24,211.61,3.39,216.05z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_27_"
            gradientUnits="userSpaceOnUse"
            x1="-15.5982"
            y1="203.4513"
            x2="164.3936"
            y2="23.4595"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-27"
            d="M3.39,222.44c2.92-4.57,5.92-9.1,9.17-13.45c3.22-4.37,6.75-8.5,10.07-12.8c3.33-4.28,6.41-8.76,9.37-13.31
				c2.95-4.55,5.82-9.16,8.47-13.9c2.68-4.72,5.02-9.62,7.41-14.49c2.38-4.88,5.02-9.63,7.83-14.27
				c5.63-9.28,11.68-18.33,18.62-26.69c6.94-8.36,12.98-17.4,18.6-26.67c2.81-4.64,5.44-9.39,7.82-14.26
				c2.38-4.87,4.73-9.78,7.41-14.5c2.66-4.74,5.53-9.35,8.48-13.91c2.96-4.55,6.04-9.03,9.38-13.32c3.32-4.3,6.85-8.43,10.06-12.79
				c3.24-4.34,6.24-8.87,9.16-13.44c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-2.93,4.57-5.94,9.1-9.19,13.45c-3.23,4.37-6.77,8.5-10.09,12.78c-3.34,4.27-6.41,8.75-9.38,13.29
				c-2.96,4.55-5.83,9.15-8.49,13.88c-2.69,4.71-5.04,9.6-7.43,14.48c-2.39,4.88-5.04,9.63-7.85,14.27
				c-5.64,9.27-11.7,18.32-18.65,26.67c-6.95,8.34-13,17.38-18.64,26.65c-2.82,4.64-5.46,9.38-7.85,14.25
				c-2.39,4.87-4.74,9.77-7.43,14.49c-2.66,4.73-5.54,9.34-8.5,13.89c-2.97,4.54-6.05,9.02-9.39,13.3
				c-3.32,4.29-6.86,8.41-10.08,12.78C9.33,213.35,6.32,217.87,3.39,222.44z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_28_"
            gradientUnits="userSpaceOnUse"
            x1="-16.1798"
            y1="209.2639"
            x2="169.0429"
            y2="24.0412"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-28"
            d="M3.39,228.84c3.01-4.71,6.09-9.37,9.42-13.85c3.3-4.51,6.92-8.77,10.32-13.2
				c3.42-4.42,6.58-9.03,9.62-13.71c3.04-4.69,5.99-9.43,8.73-14.3c2.77-4.85,5.19-9.88,7.66-14.89c2.47-5.01,5.19-9.9,8.09-14.67
				c5.8-9.55,12.01-18.87,19.12-27.49c7.11-8.62,13.32-17.93,19.11-27.47c2.89-4.77,5.61-9.65,8.08-14.66
				c2.47-5.01,4.9-10.04,7.67-14.9c2.74-4.87,5.7-9.62,8.74-14.3c3.05-4.68,6.21-9.3,9.63-13.72c3.4-4.44,7.02-8.69,10.32-13.19
				c3.33-4.48,6.41-9.14,9.41-13.84c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-3.01,4.7-6.11,9.37-9.45,13.85c-3.31,4.51-6.94,8.76-10.34,13.19c-3.42,4.41-6.58,9.01-9.63,13.69
				c-3.04,4.68-6,9.42-8.75,14.28c-2.77,4.85-5.21,9.87-7.68,14.88c-2.48,5.01-5.21,9.89-8.11,14.67
				c-5.81,9.54-12.04,18.85-19.16,27.47c-7.12,8.61-13.34,17.91-19.15,27.45c-2.9,4.77-5.63,9.65-8.1,14.65
				c-2.47,5-4.91,10.04-7.68,14.89c-2.75,4.87-5.71,9.61-8.75,14.29c-3.05,4.68-6.22,9.29-9.64,13.7
				c-3.41,4.43-7.03,8.68-10.34,13.18C9.5,219.48,6.41,224.14,3.39,228.84z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_29_"
            gradientUnits="userSpaceOnUse"
            x1="-16.7615"
            y1="215.0764"
            x2="173.6921"
            y2="24.6228"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-29"
            d="M3.39,235.23c3.09-4.84,6.26-9.63,9.68-14.25c3.39-4.64,7.09-9.03,10.58-13.6c3.5-4.55,6.74-9.3,9.88-14.11
				c3.12-4.82,6.16-9.69,8.98-14.69c2.85-4.99,5.36-10.15,7.92-15.29c2.55-5.15,5.36-10.16,8.34-15.07
				c5.96-9.81,12.35-19.4,19.63-28.29c7.28-8.89,13.66-18.47,19.62-28.27c2.98-4.91,5.78-9.92,8.33-15.06
				c2.55-5.14,5.07-10.31,7.92-15.3c2.82-5,5.86-9.88,8.99-14.7c3.13-4.81,6.38-9.57,9.88-14.12c3.49-4.57,7.19-8.96,10.57-13.6
				c3.41-4.61,6.58-9.4,9.67-14.24c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-3.1,4.84-6.27,9.63-9.7,14.25
				c-3.4,4.64-7.11,9.03-10.6,13.59c-3.51,4.54-6.75,9.28-9.89,14.09c-3.13,4.81-6.17,9.68-9,14.68
				c-2.86,4.98-5.38,10.14-7.94,15.28c-2.56,5.14-5.38,10.16-8.36,15.07c-5.98,9.8-12.38,19.38-19.67,28.27
				c-7.29,8.88-13.68,18.45-19.66,28.25c-2.99,4.9-5.8,9.91-8.36,15.05c-2.56,5.14-5.08,10.3-7.94,15.29c-2.83,5-5.88,9.87-9,14.69
				c-3.14,4.81-6.39,9.55-9.9,14.1c-3.49,4.56-7.2,8.95-10.59,13.58C9.66,225.61,6.49,230.4,3.39,235.23z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_30_"
            gradientUnits="userSpaceOnUse"
            x1="-17.3431"
            y1="220.8889"
            x2="178.3414"
            y2="25.2045"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-30"
            d="M3.39,241.63c3.17-4.97,6.43-9.9,9.93-14.65c3.47-4.77,7.26-9.3,10.83-14c3.59-4.68,6.91-9.56,10.13-14.51
				c3.21-4.95,6.33-9.96,9.24-15.09c2.93-5.12,5.53-10.42,8.17-15.7c2.64-5.28,5.53-10.43,8.59-15.47
				c6.13-10.08,12.69-19.93,20.14-29.09c7.45-9.16,13.99-19,20.13-29.07c3.06-5.04,5.95-10.19,8.59-15.46
				c2.64-5.27,5.24-10.58,8.18-15.7c2.91-5.14,6.03-10.15,9.24-15.1c3.22-4.95,6.54-9.83,10.14-14.52c3.57-4.7,7.36-9.23,10.83-14
				c3.5-4.74,6.75-9.67,9.92-14.64c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-3.18,4.97-6.44,9.9-9.95,14.65c-3.48,4.77-7.28,9.3-10.85,13.99c-3.59,4.67-6.92,9.55-10.14,14.49
				c-3.21,4.95-6.34,9.95-9.25,15.08c-2.94,5.11-5.55,10.4-8.19,15.68c-2.65,5.28-5.54,10.43-8.62,15.47
				c-6.15,10.07-12.72,19.91-20.18,29.07c-7.46,9.15-14.02,18.98-20.17,29.05c-3.07,5.04-5.97,10.18-8.61,15.45
				c-2.64,5.27-5.25,10.57-8.19,15.69c-2.91,5.13-6.04,10.14-9.26,15.09c-3.22,4.94-6.55,9.82-10.15,14.5
				c-3.58,4.69-7.37,9.22-10.85,13.98C9.83,231.74,6.58,236.66,3.39,241.63z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_31_"
            gradientUnits="userSpaceOnUse"
            x1="-17.9247"
            y1="226.7015"
            x2="182.9907"
            y2="25.7861"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-31"
            d="M3.39,248.02c3.26-5.11,6.6-10.17,10.18-15.05c3.56-4.91,7.43-9.57,11.09-14.4
				c3.67-4.82,7.08-9.83,10.38-14.91c3.29-5.08,6.5-10.23,9.49-15.49c3.02-5.25,5.7-10.68,8.43-16.1c2.72-5.41,5.7-10.7,8.85-15.87
				c6.3-10.34,13.03-20.46,20.65-29.89c7.62-9.43,14.33-19.53,20.63-29.87c3.15-5.17,6.12-10.45,8.84-15.86
				c2.72-5.41,5.41-10.85,8.43-16.1c2.99-5.27,6.2-10.41,9.5-15.5c3.3-5.08,6.71-10.1,10.39-14.92c3.66-4.84,7.53-9.5,11.08-14.4
				c3.58-4.88,6.92-9.94,10.18-15.04c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-3.27,5.1-6.61,10.16-10.21,15.04
				c-3.57,4.91-7.45,9.56-11.11,14.39c-3.68,4.81-7.09,9.81-10.4,14.89c-3.3,5.08-6.51,10.21-9.51,15.47
				c-3.02,5.25-5.71,10.67-8.44,16.08c-2.73,5.41-5.71,10.69-8.87,15.87c-6.32,10.34-13.05,20.45-20.69,29.87
				c-7.63,9.42-14.36,19.51-20.68,29.85c-3.15,5.17-6.13,10.45-8.86,15.85c-2.73,5.41-5.42,10.84-8.45,16.09
				c-3,5.26-6.21,10.4-9.51,15.48c-3.31,5.07-6.72,10.08-10.4,14.9c-3.66,4.83-7.54,9.48-11.1,14.38
				C10,237.86,6.66,242.92,3.39,248.02z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_32_"
            gradientUnits="userSpaceOnUse"
            x1="-18.5064"
            y1="232.5141"
            x2="187.64"
            y2="26.3677"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-32"
            d="M3.39,254.41c3.34-5.24,6.76-10.43,10.44-15.45c3.64-5.04,7.6-9.83,11.34-14.8
				c3.76-4.95,7.25-10.09,10.64-15.31c3.38-5.22,6.67-10.49,9.74-15.89c3.1-5.39,5.87-10.95,8.68-16.5
				c2.81-5.55,5.87-10.96,9.1-16.27c6.47-10.61,13.37-20.99,21.16-30.69c7.79-9.69,14.67-20.06,21.14-30.67
				c3.23-5.31,6.29-10.72,9.1-16.26c2.81-5.54,5.58-11.11,8.68-16.5c3.08-5.4,6.37-10.68,9.75-15.9
				c3.39-5.21,6.88-10.36,10.65-15.32c3.74-4.97,7.7-9.76,11.34-14.8c3.67-5.01,7.09-10.2,10.43-15.44
				c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-3.35,5.24-6.78,10.43-10.46,15.44c-3.65,5.04-7.62,9.83-11.36,14.79
				c-3.76,4.94-7.26,10.08-10.65,15.28c-3.38,5.21-6.68,10.48-9.76,15.87c-3.11,5.38-5.88,10.94-8.7,16.48
				c-2.82,5.54-5.88,10.96-9.12,16.26c-6.49,10.6-13.39,20.98-21.2,30.67c-7.8,9.68-14.7,20.05-21.18,30.65
				c-3.24,5.3-6.3,10.71-9.12,16.25c-2.81,5.54-5.59,11.11-8.7,16.49c-3.08,5.4-6.38,10.67-9.77,15.88
				c-3.39,5.21-6.89,10.35-10.66,15.3c-3.75,4.96-7.71,9.75-11.36,14.78C10.17,243.99,6.74,249.18,3.39,254.41z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_33_"
            gradientUnits="userSpaceOnUse"
            x1="-19.088"
            y1="238.3266"
            x2="192.2892"
            y2="26.9494"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-33"
            d="M3.39,260.81c3.43-5.37,6.93-10.7,10.69-15.85c3.73-5.17,7.77-10.1,11.6-15.2
				c3.84-5.08,7.42-10.36,10.89-15.71c3.46-5.35,6.84-10.76,10-16.29c3.19-5.52,6.04-11.22,8.93-16.9
				c2.89-5.68,6.04-11.23,9.36-16.67c6.64-10.88,13.7-21.52,21.67-31.49c7.96-9.96,15.01-20.6,21.65-31.47
				c3.32-5.44,6.46-10.98,9.35-16.66c2.89-5.68,5.75-11.38,8.94-16.9c3.16-5.54,6.54-10.95,10-16.3c3.47-5.35,7.05-10.63,10.9-15.72
				c3.83-5.1,7.87-10.03,11.59-15.2c3.75-5.14,7.26-10.47,10.68-15.84c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26
				l0,0c-3.44,5.37-6.95,10.69-10.72,15.84c-3.74,5.17-7.79,10.1-11.62,15.19c-3.85,5.08-7.43,10.35-10.91,15.68
				c-3.47,5.34-6.85,10.75-10.02,16.27c-3.19,5.51-6.05,11.21-8.95,16.88c-2.9,5.68-6.05,11.23-9.38,16.66
				c-6.66,10.87-13.73,21.51-21.7,31.46c-7.97,9.95-15.04,20.58-21.69,31.45c-3.32,5.44-6.47,10.98-9.37,16.65
				c-2.9,5.67-5.76,11.37-8.96,16.89c-3.17,5.53-6.55,10.94-10.02,16.28c-3.48,5.34-7.06,10.62-10.91,15.7
				c-3.83,5.09-7.88,10.02-11.61,15.19C10.34,250.12,6.83,255.44,3.39,260.81z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_34_"
            gradientUnits="userSpaceOnUse"
            x1="-19.6696"
            y1="244.1391"
            x2="196.9385"
            y2="27.531"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-34"
            d="M3.39,267.2c3.51-5.5,7.1-10.96,10.94-16.25c3.81-5.31,7.94-10.37,11.85-15.6
				c3.93-5.22,7.59-10.63,11.15-16.1c3.55-5.48,7.01-11.02,10.25-16.69c3.27-5.65,6.21-11.48,9.19-17.3
				c2.98-5.81,6.21-11.5,9.61-17.07c6.81-11.14,14.04-22.05,22.17-32.29c8.13-10.23,15.35-21.13,22.16-32.27
				c3.4-5.57,6.63-11.25,9.6-17.06c2.98-5.81,5.92-11.65,9.19-17.3c3.25-5.67,6.71-11.21,10.26-16.7
				c3.56-5.48,7.22-10.89,11.15-16.11c3.91-5.24,8.04-10.3,11.85-15.6c3.84-5.28,7.43-10.73,10.94-16.24
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-3.52,5.5-7.12,10.96-10.97,16.24
				c-3.82,5.3-7.96,10.37-11.87,15.59c-3.93,5.21-7.6,10.61-11.16,16.08c-3.55,5.48-7.02,11.01-10.27,16.67
				c-3.28,5.65-6.22,11.47-9.21,17.28c-2.99,5.81-6.22,11.49-9.63,17.06c-6.82,11.13-14.07,22.04-22.21,32.26
				c-8.14,10.22-15.38,21.11-22.2,32.24c-3.41,5.57-6.64,11.24-9.63,17.05c-2.98,5.81-5.93,11.64-9.21,17.29
				c-3.25,5.66-6.72,11.2-10.27,16.68c-3.56,5.47-7.23,10.88-11.17,16.09c-3.92,5.23-8.05,10.29-11.87,15.59
				C10.51,256.25,6.91,261.7,3.39,267.2z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_35_"
            gradientUnits="userSpaceOnUse"
            x1="-20.2512"
            y1="249.9517"
            x2="201.5878"
            y2="28.1127"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-35"
            d="M3.39,273.6c3.6-5.64,7.27-11.23,11.2-16.64c3.9-5.44,8.11-10.64,12.11-16c4.01-5.35,7.76-10.89,11.4-16.5
				c3.63-5.62,7.18-11.29,10.51-17.09c3.36-5.78,6.38-11.75,9.44-17.7c3.06-5.95,6.38-11.76,9.87-17.47
				c6.98-11.41,14.38-22.59,22.68-33.08c8.3-10.49,15.69-21.66,22.67-33.07c3.49-5.71,6.8-11.52,9.86-17.46
				c3.06-5.94,6.09-11.92,9.45-17.7c3.33-5.8,6.88-11.48,10.51-17.1c3.64-5.61,7.39-11.16,11.41-16.51c4-5.37,8.21-10.56,12.1-16
				c3.92-5.41,7.6-11,11.19-16.64c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-3.61,5.64-7.29,11.23-11.22,16.64c-3.9,5.44-8.13,10.63-12.13,15.99c-4.02,5.34-7.77,10.88-11.41,16.48
				c-3.64,5.61-7.19,11.28-10.52,17.07c-3.36,5.78-6.39,11.74-9.46,17.68c-3.07,5.94-6.39,11.76-9.89,17.46
				c-6.99,11.4-14.41,22.57-22.72,33.06c-8.31,10.48-15.72,21.65-22.71,33.04c-3.49,5.7-6.81,11.51-9.88,17.45
				c-3.07,5.94-6.1,11.91-9.47,17.69c-3.34,5.8-6.89,11.47-10.53,17.08c-3.65,5.61-7.4,11.15-11.42,16.49
				c-4,5.36-8.22,10.55-12.12,15.99C10.68,262.38,7,267.96,3.39,273.6z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_36_"
            gradientUnits="userSpaceOnUse"
            x1="-20.8328"
            y1="255.7642"
            x2="206.2371"
            y2="28.6943"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-36"
            d="M3.39,279.99c3.68-5.77,7.44-11.5,11.45-17.04c3.98-5.57,8.28-10.9,12.36-16.4
				c4.1-5.48,7.93-11.16,11.65-16.9c3.71-5.75,7.34-11.56,10.76-17.49c3.44-5.92,6.55-12.02,9.7-18.1
				c3.15-6.08,6.55-12.03,10.12-17.87c7.15-11.67,14.72-23.12,23.19-33.88c8.47-10.76,16.03-22.2,23.18-33.87
				c3.57-5.84,6.97-11.78,10.11-17.86c3.15-6.08,6.26-12.18,9.7-18.1c3.42-5.94,7.05-11.74,10.77-17.5
				c3.72-5.75,7.56-11.43,11.66-16.91c4.08-5.5,8.38-10.83,12.36-16.4c4.01-5.54,7.77-11.27,11.45-17.04
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-3.69,5.77-7.46,11.49-11.48,17.04
				c-3.99,5.57-8.3,10.9-12.38,16.39c-4.1,5.48-7.94,11.15-11.67,16.88c-3.72,5.74-7.36,11.54-10.78,17.47
				c-3.45,5.91-6.56,12.01-9.72,18.08c-3.16,6.08-6.56,12.03-10.14,17.86c-7.16,11.67-14.74,23.1-23.23,33.86
				c-8.48,10.75-16.06,22.18-23.22,33.84c-3.58,5.83-6.98,11.78-10.14,17.85c-3.15,6.07-6.27,12.17-9.72,18.09
				c-3.42,5.93-7.06,11.73-10.78,17.48c-3.73,5.74-7.57,11.41-11.67,16.89c-4.09,5.49-8.39,10.82-12.38,16.39
				C10.85,268.5,7.08,274.23,3.39,279.99z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_37_"
            gradientUnits="userSpaceOnUse"
            x1="-21.4145"
            y1="261.5767"
            x2="210.8864"
            y2="29.2759"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-37"
            d="M3.39,286.39c3.77-5.9,7.61-11.76,11.71-17.44c4.07-5.7,8.45-11.17,12.61-16.8
				c4.18-5.62,8.1-11.43,11.91-17.3c3.8-5.88,7.51-11.82,11.01-17.89c3.53-6.05,6.72-12.28,9.95-18.5
				c3.23-6.21,6.72-12.3,10.37-18.27c7.32-11.94,15.06-23.65,23.7-34.68c8.64-11.03,16.37-22.73,23.69-34.67
				c3.66-5.97,7.14-12.05,10.37-18.26c3.23-6.21,6.43-12.45,9.96-18.5c3.5-6.07,7.22-12.01,11.02-17.89
				c3.81-5.88,7.73-11.69,11.91-17.31c4.17-5.64,8.55-11.1,12.61-16.8c4.09-5.68,7.93-11.53,11.7-17.43
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-3.77,5.9-7.63,11.76-11.73,17.44
				c-4.07,5.7-8.47,11.17-12.63,16.79c-4.19,5.61-8.11,11.41-11.92,17.28c-3.8,5.88-7.53,11.81-11.03,17.87
				c-3.53,6.04-6.73,12.27-9.97,18.48c-3.24,6.21-6.73,12.29-10.4,18.26c-7.33,11.93-15.08,23.64-23.74,34.66
				c-8.65,11.02-16.39,22.71-23.73,34.64c-3.66,5.97-7.15,12.04-10.39,18.25c-3.24,6.21-6.44,12.44-9.98,18.49
				c-3.51,6.06-7.23,12-11.04,17.88c-3.81,5.87-7.74,11.68-11.93,17.29c-4.17,5.63-8.56,11.09-12.63,16.79
				C11.02,274.63,7.17,280.49,3.39,286.39z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_38_"
            gradientUnits="userSpaceOnUse"
            x1="-21.9961"
            y1="267.3893"
            x2="215.5356"
            y2="29.8575"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-38"
            d="M3.39,292.78c3.85-6.04,7.78-12.03,11.96-17.84c4.15-5.84,8.62-11.44,12.87-17.2
				c4.27-5.75,8.27-11.69,12.16-17.7c3.88-6.02,7.68-12.09,11.27-18.29c3.61-6.18,6.89-12.55,10.21-18.9
				c3.32-6.35,6.89-12.56,10.63-18.67C79.98,171.97,87.88,160,96.7,148.7c8.81-11.3,16.71-23.26,24.19-35.47
				c3.74-6.1,7.31-12.31,10.62-18.66c3.32-6.34,6.6-12.72,10.21-18.9c3.59-6.2,7.39-12.28,11.27-18.29
				c3.89-6.01,7.9-11.96,12.17-17.71c4.25-5.77,8.72-11.36,12.87-17.2c4.18-5.81,8.1-11.8,11.95-17.83
				c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-3.86,6.03-7.8,12.02-11.98,17.84c-4.16,5.84-8.64,11.43-12.89,17.19
				c-4.27,5.74-8.28,11.68-12.18,17.68c-3.89,6.01-7.7,12.08-11.29,18.27c-3.62,6.18-6.9,12.54-10.23,18.88
				c-3.33,6.35-6.9,12.56-10.65,18.66c-7.5,12.2-15.42,24.17-24.24,35.46c-8.82,11.29-16.73,23.25-24.23,35.44
				c-3.75,6.1-7.32,12.31-10.64,18.65c-3.32,6.34-6.61,12.71-10.23,18.89c-3.59,6.2-7.4,12.27-11.29,18.28
				c-3.9,6.01-7.91,11.95-12.18,17.69c-4.25,5.76-8.73,11.35-12.89,17.19C11.19,280.76,7.25,286.75,3.39,292.78z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_39_"
            gradientUnits="userSpaceOnUse"
            x1="-22.5777"
            y1="273.2018"
            x2="220.1849"
            y2="30.4392"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-39"
            d="M3.39,299.17c3.94-6.17,7.95-12.29,12.21-18.24c4.23-5.97,8.79-11.7,13.12-17.6
				c4.35-5.88,8.44-11.96,12.42-18.1c3.97-6.15,7.85-12.35,11.52-18.69c3.7-6.32,7.06-12.82,10.46-19.3
				c3.4-6.48,7.06-12.83,10.88-19.07c7.66-12.47,15.73-24.71,24.71-36.28c8.98-11.56,17.05-23.8,24.7-36.27
				c3.82-6.24,7.48-12.58,10.88-19.06c3.4-6.48,6.77-12.98,10.47-19.3c3.67-6.34,7.56-12.54,11.53-18.69
				c3.98-6.14,8.07-12.22,12.42-18.11c4.34-5.9,8.89-11.63,13.12-17.6c4.26-5.94,8.27-12.07,12.21-18.23
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-3.94,6.17-7.97,12.29-12.24,18.24
				c-4.24,5.97-8.81,11.7-13.14,17.59c-4.36,5.88-8.45,11.95-12.43,18.08c-3.97,6.14-7.86,12.34-11.54,18.67
				c-3.7,6.31-7.07,12.81-10.48,19.28c-3.41,6.48-7.07,12.82-10.9,19.06c-7.67,12.46-15.76,24.7-24.75,36.26
				c-8.99,11.55-17.07,23.78-24.74,36.24c-3.83,6.23-7.49,12.57-10.9,19.05c-3.41,6.48-6.78,12.98-10.48,19.29
				c-3.68,6.33-7.57,12.53-11.54,18.68c-3.98,6.14-8.08,12.21-12.44,18.09c-4.34,5.89-8.9,11.62-13.14,17.59
				C11.36,286.89,7.34,293.01,3.39,299.17z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_40_"
            gradientUnits="userSpaceOnUse"
            x1="-23.1593"
            y1="279.0143"
            x2="224.8342"
            y2="31.0208"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-40"
            d="M3.39,305.57c4.02-6.3,8.12-12.56,12.47-18.64c4.32-6.1,8.96-11.97,13.38-18
				c4.44-6.02,8.61-12.23,12.67-18.5c4.05-6.28,8.02-12.62,11.78-19.08c3.78-6.45,7.23-13.08,10.72-19.7
				c3.49-6.61,7.23-13.1,11.14-19.47c7.83-12.74,16.07-25.25,25.22-37.08c9.15-11.83,17.39-24.33,25.21-37.06
				c3.91-6.37,7.64-12.85,11.13-19.46c3.48-6.61,6.94-13.25,10.72-19.7c3.76-6.47,7.73-12.81,11.78-19.09
				c4.06-6.28,8.24-12.49,12.68-18.51c4.42-6.04,9.06-11.9,13.38-18c4.35-6.08,8.44-12.33,12.46-18.63
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.03,6.3-8.13,12.56-12.49,18.64
				c-4.33,6.1-8.98,11.97-13.4,17.99c-4.44,6.01-8.62,12.21-12.69,18.48c-4.06,6.28-8.03,12.61-11.79,19.07
				c-3.79,6.44-7.24,13.07-10.74,19.68c-3.5,6.61-7.24,13.09-11.16,19.46c-7.84,12.73-16.1,25.23-25.26,37.06
				c-9.16,11.82-17.41,24.31-25.25,37.04c-3.92,6.37-7.66,12.84-11.15,19.45c-3.49,6.61-6.95,13.24-10.74,19.69
				c-3.76,6.46-7.74,12.8-11.8,19.08c-4.07,6.27-8.25,12.48-12.69,18.49c-4.42,6.03-9.07,11.89-13.4,17.99
				C11.53,293.02,7.42,299.27,3.39,305.57z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_41_"
            gradientUnits="userSpaceOnUse"
            x1="-23.741"
            y1="284.8268"
            x2="229.4834"
            y2="31.6024"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-41"
            d="M3.39,311.96c4.11-6.44,8.29-12.83,12.72-19.04c4.4-6.24,9.13-12.24,13.63-18.4
				c4.52-6.15,8.78-12.49,12.92-18.9c4.14-6.41,8.19-12.89,12.03-19.48c3.87-6.58,7.4-13.35,10.97-20.1
				c3.57-6.75,7.4-13.36,11.39-19.87c8-13,16.41-25.78,25.73-37.88c9.32-12.1,17.72-24.86,25.72-37.86
				c3.99-6.5,7.81-13.11,11.39-19.86c3.57-6.75,7.11-13.52,10.97-20.1c3.84-6.6,7.9-13.07,12.03-19.49
				c4.15-6.41,8.4-12.76,12.93-18.91c4.51-6.17,9.23-12.17,13.63-18.4c4.43-6.21,8.61-12.6,12.72-19.03
				c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-4.11,6.43-8.3,12.82-12.75,19.04c-4.41,6.24-9.15,12.23-13.65,18.39
				c-4.53,6.14-8.79,12.48-12.94,18.88c-4.14,6.41-8.2,12.88-12.05,19.47c-3.87,6.58-7.41,13.34-10.99,20.08
				c-3.58,6.75-7.41,13.36-11.41,19.86c-8.01,13-16.43,25.76-25.77,37.85c-9.33,12.09-17.75,24.84-25.76,37.84
				c-4,6.5-7.83,13.11-11.41,19.85c-3.58,6.74-7.12,13.51-10.99,20.09c-3.85,6.6-7.91,13.06-12.05,19.47
				c-4.15,6.41-8.41,12.74-12.95,18.89c-4.51,6.16-9.24,12.15-13.65,18.39C11.7,299.14,7.51,305.53,3.39,311.96z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_42_"
            gradientUnits="userSpaceOnUse"
            x1="-24.3226"
            y1="290.6394"
            x2="234.1327"
            y2="32.1841"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-42"
            d="M3.39,318.36c4.19-6.57,8.46-13.09,12.98-19.44c4.49-6.37,9.3-12.5,13.89-18.8
				c4.61-6.28,8.95-12.76,13.18-19.3c4.22-6.55,8.36-13.15,12.28-19.88c3.95-6.72,7.57-13.62,11.23-20.5
				c3.66-6.88,7.57-13.63,11.65-20.27c8.17-13.27,16.75-26.31,26.24-38.68c9.49-12.36,18.06-25.4,26.23-38.66
				c4.08-6.64,7.98-13.38,11.64-20.26c3.65-6.88,7.28-13.79,11.23-20.5c3.93-6.73,8.07-13.34,12.29-19.89
				c4.23-6.54,8.57-13.02,13.18-19.31c4.59-6.3,9.4-12.43,13.89-18.8c4.52-6.34,8.78-12.87,12.97-19.43
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.2,6.57-8.47,13.09-13,19.43
				c-4.5,6.37-9.32,12.5-13.91,18.79c-4.61,6.28-8.96,12.75-13.19,19.28c-4.23,6.54-8.37,13.14-12.3,19.87
				c-3.96,6.71-7.58,13.61-11.24,20.49c-3.66,6.88-7.58,13.62-11.67,20.26c-8.18,13.26-16.77,26.29-26.28,38.65
				c-9.5,12.36-18.09,25.38-26.27,38.64c-4.09,6.63-8,13.37-11.66,20.25c-3.66,6.88-7.29,13.78-11.25,20.49
				c-3.93,6.73-8.08,13.33-12.31,19.87c-4.24,6.54-8.58,13.01-13.2,19.29c-4.59,6.29-9.41,12.42-13.91,18.79
				C11.86,305.27,7.59,311.79,3.39,318.36z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_43_"
            gradientUnits="userSpaceOnUse"
            x1="-24.9042"
            y1="296.4519"
            x2="238.782"
            y2="32.7657"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-43"
            d="M3.39,324.75c4.27-6.7,8.63-13.36,13.23-19.84c4.57-6.5,9.47-12.77,14.14-19.2
				c4.69-6.42,9.12-13.03,13.43-19.7c4.31-6.68,8.53-13.42,12.54-20.28c4.04-6.85,7.74-13.88,11.48-20.9
				c3.74-7.02,7.74-13.9,11.9-20.67c8.34-13.54,17.08-26.84,26.75-39.48c9.66-12.63,18.4-25.93,26.74-39.46
				c4.16-6.77,8.15-13.65,11.89-20.66c3.74-7.01,7.45-14.05,11.48-20.9c4.01-6.87,8.23-13.61,12.54-20.29
				c4.32-6.68,8.74-13.29,13.44-19.71c4.67-6.43,9.57-12.7,14.14-19.2c4.6-6.48,8.95-13.13,13.23-19.83
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.28,6.7-8.64,13.35-13.25,19.83
				c-4.58,6.5-9.49,12.77-14.16,19.19c-4.7,6.41-9.13,13.01-13.45,19.68c-4.31,6.68-8.54,13.41-12.56,20.27
				c-4.04,6.84-7.75,13.87-11.5,20.89c-3.75,7.01-7.75,13.89-11.92,20.66c-8.35,13.53-17.11,26.83-26.79,39.45
				c-9.67,12.62-18.43,25.91-26.78,39.44c-4.17,6.77-8.17,13.64-11.92,20.65c-3.75,7.01-7.46,14.04-11.5,20.89
				c-4.02,6.86-8.25,13.6-12.56,20.27c-4.32,6.67-8.75,13.28-13.45,19.69c-4.68,6.43-9.58,12.69-14.16,19.19
				C12.03,311.4,7.68,318.05,3.39,324.75z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_44_"
            gradientUnits="userSpaceOnUse"
            x1="-25.4858"
            y1="302.2645"
            x2="243.4313"
            y2="33.3473"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-44"
            d="M3.39,331.14c4.36-6.83,8.8-13.62,13.48-20.24c4.66-6.64,9.64-13.04,14.4-19.6
				c4.78-6.55,9.29-13.29,13.69-20.1c4.39-6.81,8.7-13.68,12.79-20.68c4.12-6.98,7.91-14.15,11.73-21.3
				c3.83-7.15,7.9-14.16,12.15-21.07c8.51-13.8,17.42-27.37,27.26-40.27c9.83-12.9,18.74-26.46,27.25-40.26
				c4.25-6.9,8.32-13.91,12.15-21.06c3.82-7.15,7.62-14.32,11.74-21.3c4.1-7,8.4-13.87,12.8-20.69c4.4-6.81,8.91-13.55,13.69-20.11
				c4.76-6.57,9.74-12.97,14.4-19.6c4.69-6.61,9.12-13.4,13.48-20.23c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26
				l0,0c-4.37,6.83-8.81,13.62-13.51,20.23c-4.67,6.64-9.66,13.03-14.42,19.59c-4.78,6.54-9.3,13.28-13.7,20.08
				c-4.4,6.81-8.71,13.67-12.81,20.67c-4.13,6.98-7.92,14.14-11.75,21.29c-3.83,7.15-7.92,14.16-12.17,21.06
				c-8.52,13.8-17.45,27.36-27.29,40.25c-9.84,12.89-18.77,26.44-27.29,40.24c-4.25,6.9-8.34,13.91-12.17,21.05
				c-3.83,7.14-7.63,14.31-11.76,21.29c-4.1,7-8.42,13.86-12.81,20.67c-4.41,6.8-8.92,13.54-13.71,20.09
				c-4.76,6.56-9.75,12.96-14.42,19.59C12.2,317.53,7.76,324.31,3.39,331.14z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_45_"
            gradientUnits="userSpaceOnUse"
            x1="-26.0675"
            y1="308.077"
            x2="248.0806"
            y2="33.929"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-45"
            d="M3.39,337.54c4.44-6.97,8.96-13.89,13.74-20.64c4.74-6.77,9.81-13.3,14.65-20
				c4.86-6.68,9.46-13.56,13.94-20.5c4.48-6.95,8.87-13.95,13.05-21.08c4.21-7.12,8.08-14.42,11.99-21.7
				c3.91-7.28,8.07-14.43,12.41-21.47c8.67-14.07,17.76-27.91,27.76-41.07c10-13.17,19.08-26.99,27.75-41.06
				c4.33-7.04,8.49-14.18,12.4-21.46c3.91-7.28,7.79-14.59,11.99-21.7c4.18-7.13,8.57-14.14,13.05-21.09
				c4.49-6.94,9.08-13.82,13.95-20.51c4.84-6.7,9.91-13.23,14.65-20c4.77-6.74,9.29-13.66,13.73-20.63
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.45,6.97-8.98,13.89-13.76,20.63
				c-4.75,6.77-9.83,13.3-14.67,19.99c-4.87,6.68-9.47,13.54-13.96,20.48c-4.48,6.94-8.88,13.94-13.06,21.06
				c-4.21,7.11-8.09,14.41-12.01,21.69c-3.92,7.28-8.09,14.42-12.43,21.46c-8.69,14.06-17.79,27.89-27.8,41.05
				c-10.01,13.16-19.11,26.98-27.79,41.04c-4.34,7.03-8.51,14.17-12.42,21.45c-3.92,7.28-7.8,14.58-12.01,21.69
				c-4.19,7.13-8.58,14.13-13.07,21.07c-4.49,6.94-9.09,13.81-13.96,20.49c-4.85,6.69-9.92,13.22-14.67,19.99
				C12.37,323.66,7.85,330.58,3.39,337.54z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_46_"
            gradientUnits="userSpaceOnUse"
            x1="-26.6491"
            y1="313.8896"
            x2="252.7299"
            y2="34.5106"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-46"
            d="M3.39,343.93c4.53-7.1,9.13-14.16,13.99-21.04c4.83-6.9,9.98-13.57,14.91-20.4
				c4.95-6.82,9.62-13.82,14.19-20.9c4.56-7.08,9.04-14.22,13.3-21.48c4.29-7.25,8.25-14.68,12.24-22.1
				c4-7.42,8.24-14.69,12.66-21.87c8.84-14.33,18.1-28.44,28.27-41.87c10.17-13.43,19.42-27.53,28.26-41.86
				c4.42-7.17,8.66-14.44,12.66-21.86c3.99-7.41,7.95-14.85,12.25-22.1c4.26-7.27,8.74-14.4,13.3-21.49
				c4.57-7.08,9.25-14.09,14.2-20.91c4.93-6.83,10.08-13.5,14.91-20.4c4.86-6.88,9.46-13.93,13.99-21.03
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.54,7.1-9.15,14.15-14.02,21.03
				c-4.84,6.9-10,13.57-14.93,20.39c-4.95,6.81-9.63,13.81-14.21,20.88c-4.57,7.07-9.05,14.21-13.32,21.46
				c-4.3,7.24-8.26,14.67-12.26,22.09c-4,7.41-8.26,14.69-12.68,21.86c-8.86,14.33-18.13,28.42-28.31,41.85
				c-10.18,13.42-19.45,27.51-28.3,41.84c-4.42,7.17-8.68,14.44-12.68,21.85c-4,7.41-7.97,14.85-12.27,22.09
				c-4.27,7.26-8.75,14.39-13.32,21.47c-4.58,7.07-9.26,14.08-14.22,20.89c-4.93,6.83-10.09,13.49-14.93,20.39
				C12.54,329.79,7.93,336.84,3.39,343.93z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_47_"
            gradientUnits="userSpaceOnUse"
            x1="-27.2307"
            y1="319.7021"
            x2="257.3792"
            y2="35.0922"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-47"
            d="M3.39,350.33c4.61-7.23,9.3-14.42,14.25-21.44c4.91-7.04,10.15-13.84,15.16-20.8
				c5.03-6.95,9.79-14.09,14.45-21.3c4.65-7.21,9.21-14.48,13.55-21.88c4.37-7.38,8.42-14.95,12.5-22.5
				c4.08-7.55,8.41-14.96,12.92-22.27c9.01-14.6,18.44-28.97,28.78-42.67c10.34-13.7,19.76-28.06,28.77-42.66
				c4.5-7.3,8.83-14.71,12.91-22.26c4.08-7.55,8.12-15.12,12.5-22.5c4.35-7.4,8.91-14.67,13.56-21.89
				c4.66-7.21,9.42-14.35,14.45-21.31c5.01-6.97,10.25-13.77,15.16-20.8c4.94-7.01,9.63-14.2,14.24-21.43
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.62,7.23-9.32,14.42-14.27,21.43
				c-4.92,7.04-10.17,13.83-15.18,20.79c-5.04,6.94-9.8,14.08-14.46,21.28c-4.65,7.21-9.22,14.47-13.57,21.86
				c-4.38,7.38-8.43,14.94-12.52,22.49c-4.09,7.55-8.43,14.96-12.94,22.26c-9.03,14.59-18.46,28.95-28.82,42.65
				c-10.35,13.69-19.79,28.04-28.81,42.63c-4.51,7.3-8.85,14.7-12.93,22.25c-4.09,7.54-8.14,15.11-12.52,22.49
				c-4.35,7.39-8.92,14.66-13.58,21.87c-4.66,7.2-9.43,14.34-14.47,21.29c-5.02,6.96-10.26,13.76-15.18,20.79
				C12.71,335.91,8.01,343.1,3.39,350.33z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_48_"
            gradientUnits="userSpaceOnUse"
            x1="-27.8123"
            y1="325.5146"
            x2="262.0284"
            y2="35.6739"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-48"
            d="M3.39,356.72c4.7-7.37,9.47-14.69,14.5-21.84c5-7.17,10.32-14.1,15.42-21.2c5.12-7.08,9.96-14.36,14.7-21.7
				c4.73-7.35,9.38-14.75,13.81-22.28c4.46-7.52,8.59-15.22,12.75-22.9c4.17-7.68,8.58-15.23,13.17-22.67
				c9.18-14.87,18.78-29.5,29.29-43.47c10.51-13.97,20.1-28.59,29.28-43.46c4.59-7.44,9-14.98,13.17-22.66
				c4.16-7.68,8.29-15.39,12.76-22.9c4.43-7.53,9.08-14.94,13.81-22.28c4.74-7.34,9.59-14.62,14.71-21.71
				c5.1-7.1,10.42-14.03,15.41-21.2c5.03-7.14,9.8-14.46,14.5-21.83c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0
				c-4.71,7.36-9.49,14.68-14.52,21.83c-5.01,7.17-10.34,14.1-15.44,21.19c-5.12,7.08-9.97,14.34-14.72,21.68
				c-4.74,7.34-9.39,14.74-13.83,22.26c-4.47,7.51-8.6,15.21-12.77,22.89c-4.17,7.68-8.6,15.22-13.19,22.66
				c-9.2,14.86-18.8,29.49-29.33,43.45c-10.52,13.96-20.12,28.58-29.32,43.43c-4.59,7.43-9.01,14.97-13.19,22.65
				c-4.17,7.68-8.31,15.38-12.77,22.89c-4.44,7.53-9.09,14.93-13.83,22.27c-4.75,7.34-9.6,14.61-14.72,21.69
				c-5.1,7.09-10.43,14.02-15.43,21.19C12.88,342.04,8.1,349.36,3.39,356.72z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_49_"
            gradientUnits="userSpaceOnUse"
            x1="-25.9759"
            y1="328.3041"
            x2="266.3752"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-49"
            d="M5.51,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38C199.68,55.37,194.79,62.7,190,70.1c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C15.08,344.98,10.26,352.36,5.51,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_50_"
            gradientUnits="userSpaceOnUse"
            x1="-21.9082"
            y1="328.3041"
            x2="270.4429"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-50"
            d="M9.58,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C19.14,344.98,14.32,352.36,9.58,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_51_"
            gradientUnits="userSpaceOnUse"
            x1="-17.8406"
            y1="328.3041"
            x2="274.5105"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-51"
            d="M13.65,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C23.21,344.98,18.39,352.36,13.65,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_52_"
            gradientUnits="userSpaceOnUse"
            x1="-13.7729"
            y1="328.3041"
            x2="278.5782"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-52"
            d="M17.71,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C27.28,344.98,22.46,352.36,17.71,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_53_"
            gradientUnits="userSpaceOnUse"
            x1="-9.7053"
            y1="328.3041"
            x2="282.6458"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-53"
            d="M21.78,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C31.35,344.98,26.53,352.36,21.78,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_54_"
            gradientUnits="userSpaceOnUse"
            x1="-5.6376"
            y1="328.3041"
            x2="286.7135"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-54"
            d="M25.85,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C35.42,344.98,30.59,352.36,25.85,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_55_"
            gradientUnits="userSpaceOnUse"
            x1="-1.57"
            y1="328.3041"
            x2="290.7811"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-55"
            d="M29.92,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C39.48,344.98,34.66,352.36,29.92,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_56_"
            gradientUnits="userSpaceOnUse"
            x1="2.4977"
            y1="328.3041"
            x2="294.8488"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-56"
            d="M33.98,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C43.55,344.98,38.73,352.36,33.98,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_57_"
            gradientUnits="userSpaceOnUse"
            x1="6.5653"
            y1="328.3041"
            x2="298.9164"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-57"
            d="M38.05,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C47.62,344.98,42.8,352.36,38.05,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_58_"
            gradientUnits="userSpaceOnUse"
            x1="10.633"
            y1="328.3041"
            x2="302.9841"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-58"
            d="M42.12,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C51.69,344.98,46.86,352.36,42.12,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_59_"
            gradientUnits="userSpaceOnUse"
            x1="14.7007"
            y1="328.3041"
            x2="307.0518"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-59"
            d="M46.19,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C55.75,344.98,50.93,352.36,46.19,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_60_"
            gradientUnits="userSpaceOnUse"
            x1="18.7684"
            y1="328.3041"
            x2="311.1194"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-60"
            d="M50.25,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C59.82,344.98,55,352.36,50.25,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_61_"
            gradientUnits="userSpaceOnUse"
            x1="22.836"
            y1="328.3041"
            x2="315.1871"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-61"
            d="M54.32,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C63.89,344.98,59.07,352.36,54.32,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_62_"
            gradientUnits="userSpaceOnUse"
            x1="26.9037"
            y1="328.3041"
            x2="319.2548"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-62"
            d="M58.39,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C67.96,344.98,63.13,352.36,58.39,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_63_"
            gradientUnits="userSpaceOnUse"
            x1="30.9713"
            y1="328.3041"
            x2="323.3224"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-63"
            d="M62.46,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C72.02,344.98,67.2,352.36,62.46,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_64_"
            gradientUnits="userSpaceOnUse"
            x1="35.039"
            y1="328.3041"
            x2="327.39"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-64"
            d="M66.52,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C76.09,344.98,71.27,352.36,66.52,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_65_"
            gradientUnits="userSpaceOnUse"
            x1="39.1066"
            y1="328.3041"
            x2="331.4577"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-65"
            d="M70.59,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C80.16,344.98,75.34,352.36,70.59,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_66_"
            gradientUnits="userSpaceOnUse"
            x1="43.1742"
            y1="328.3041"
            x2="335.5254"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-66"
            d="M74.66,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C84.23,344.98,79.41,352.36,74.66,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_67_"
            gradientUnits="userSpaceOnUse"
            x1="47.2419"
            y1="328.3041"
            x2="339.593"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-67"
            d="M78.73,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38C272.9,55.37,268,62.7,263.22,70.1c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C88.29,344.98,83.47,352.36,78.73,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_68_"
            gradientUnits="userSpaceOnUse"
            x1="51.3096"
            y1="328.3041"
            x2="343.6606"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-68"
            d="M82.8,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C92.36,344.98,87.54,352.36,82.8,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_69_"
            gradientUnits="userSpaceOnUse"
            x1="55.3772"
            y1="328.3041"
            x2="347.7283"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-69"
            d="M86.86,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C96.43,344.98,91.61,352.36,86.86,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_70_"
            gradientUnits="userSpaceOnUse"
            x1="59.4449"
            y1="328.3041"
            x2="351.796"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-70"
            d="M90.93,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C100.5,344.98,95.68,352.36,90.93,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_71_"
            gradientUnits="userSpaceOnUse"
            x1="63.5126"
            y1="328.3041"
            x2="355.8637"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-71"
            d="M95,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C104.57,344.98,99.74,352.36,95,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_72_"
            gradientUnits="userSpaceOnUse"
            x1="67.5802"
            y1="328.3041"
            x2="359.9313"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-72"
            d="M99.07,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C108.63,344.98,103.81,352.36,99.07,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_73_"
            gradientUnits="userSpaceOnUse"
            x1="71.6479"
            y1="328.3041"
            x2="363.999"
            y2="35.953"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-73"
            d="M103.13,359.79c4.74-7.43,9.55-14.82,14.62-22.03c5.04-7.23,10.4-14.23,15.54-21.39
				c5.16-7.15,10.05-14.49,14.82-21.89c4.77-7.41,9.46-14.88,13.93-22.47c4.5-7.58,8.67-15.35,12.87-23.09
				c4.21-7.75,8.66-15.36,13.29-22.86c9.26-14.99,18.94-29.76,29.53-43.85c10.59-14.09,20.26-28.85,29.52-43.84
				c4.63-7.5,9.08-15.11,13.29-22.85c4.2-7.74,8.38-15.51,12.88-23.1c4.47-7.6,9.16-15.06,13.93-22.48
				c4.78-7.41,9.67-14.75,14.83-21.9c5.14-7.17,10.5-14.16,15.54-21.39c5.07-7.21,9.88-14.59,14.62-22.02
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.75,7.43-9.57,14.81-14.65,22.02
				c-5.05,7.23-10.42,14.23-15.56,21.38c-5.16,7.14-10.06,14.47-14.84,21.87c-4.78,7.4-9.47,14.87-13.95,22.45
				c-4.51,7.57-8.68,15.33-12.89,23.08c-4.21,7.74-8.68,15.35-13.31,22.85c-9.28,14.99-18.97,29.74-29.57,43.83
				c-10.6,14.09-20.29,28.83-29.56,43.82c-4.63,7.5-9.1,15.1-13.31,22.84c-4.21,7.74-8.39,15.51-12.9,23.08
				c-4.48,7.59-9.17,15.05-13.95,22.46c-4.79,7.4-9.68,14.74-14.85,21.88c-5.14,7.16-10.51,14.15-15.56,21.38
				C112.7,344.98,107.88,352.36,103.13,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_74_"
            gradientUnits="userSpaceOnUse"
            x1="76.0977"
            y1="328.6863"
            x2="365.0114"
            y2="39.7726"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-74"
            d="M107.2,359.79c4.68-7.34,9.44-14.64,14.46-21.77c4.98-7.15,10.29-14.06,15.37-21.13
				c5.1-7.06,9.93-14.31,14.66-21.63c4.72-7.32,9.35-14.7,13.76-22.21c4.44-7.49,8.56-15.17,12.71-22.83
				c4.15-7.66,8.55-15.18,13.13-22.59c9.15-14.82,18.72-29.41,29.2-43.33c10.48-13.92,20.04-28.5,29.19-43.32
				c4.57-7.41,8.97-14.93,13.12-22.59c4.15-7.66,8.26-15.34,12.71-22.83c4.42-7.51,9.05-14.89,13.77-22.21
				c4.73-7.32,9.56-14.57,14.66-21.63c5.08-7.08,10.39-13.99,15.37-21.13c5.01-7.12,9.77-14.42,14.45-21.76
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.69,7.34-9.46,14.64-14.48,21.76
				c-4.99,7.15-10.31,14.05-15.39,21.12c-5.11,7.05-9.94,14.3-14.67,21.61c-4.72,7.32-9.36,14.69-13.78,22.19
				c-4.45,7.49-8.57,15.16-12.73,22.81c-4.16,7.66-8.57,15.18-13.15,22.59c-9.17,14.81-18.74,29.39-29.24,43.31
				c-10.49,13.91-20.06,28.48-29.23,43.29c-4.58,7.41-8.98,14.92-13.14,22.58c-4.16,7.65-8.28,15.33-12.73,22.82
				c-4.42,7.5-9.06,14.88-13.78,22.2c-4.73,7.31-9.57,14.56-14.68,21.62c-5.09,7.07-10.4,13.98-15.39,21.12
				C116.66,345.16,111.89,352.45,107.2,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_75_"
            gradientUnits="userSpaceOnUse"
            x1="80.747"
            y1="329.2679"
            x2="364.4298"
            y2="45.5852"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-75"
            d="M111.27,359.79c4.6-7.21,9.27-14.38,14.2-21.37c4.9-7.01,10.12-13.79,15.12-20.73
				c5.02-6.93,9.76-14.04,14.4-21.23c4.63-7.19,9.18-14.44,13.51-21.81c4.36-7.36,8.39-14.9,12.45-22.43
				c4.07-7.53,8.38-14.91,12.87-22.19c8.98-14.55,18.38-28.87,28.69-42.53c10.31-13.65,19.7-27.97,28.68-42.52
				c4.49-7.28,8.8-14.66,12.87-22.19c4.06-7.52,8.09-15.07,12.46-22.43c4.33-7.38,8.88-14.62,13.51-21.81
				c4.64-7.19,9.39-14.31,14.41-21.23c5-6.94,10.22-13.72,15.11-20.73c4.93-6.99,9.6-14.15,14.2-21.36
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.61,7.21-9.29,14.37-14.23,21.36
				c-4.91,7.01-10.14,13.79-15.14,20.72c-5.02,6.92-9.77,14.03-14.42,21.21c-4.64,7.18-9.19,14.42-13.53,21.79
				c-4.37,7.35-8.4,14.89-12.47,22.41c-4.07,7.52-8.4,14.91-12.89,22.19c-9,14.55-18.4,28.86-28.73,42.51
				c-10.32,13.64-19.73,27.95-28.72,42.49c-4.49,7.28-8.82,14.66-12.89,22.18c-4.07,7.52-8.11,15.06-12.47,22.42
				c-4.34,7.37-8.89,14.61-13.53,21.8c-4.65,7.18-9.4,14.29-14.42,21.22c-5,6.94-10.23,13.71-15.13,20.72
				C120.56,345.42,115.87,352.58,111.27,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_76_"
            gradientUnits="userSpaceOnUse"
            x1="85.3963"
            y1="329.8495"
            x2="363.8482"
            y2="51.3977"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-76"
            d="M115.34,359.79c4.51-7.08,9.1-14.11,13.95-20.97c4.81-6.88,9.95-13.52,14.86-20.33
				c4.93-6.79,9.59-13.78,14.15-20.83c4.55-7.06,9.01-14.17,13.26-21.41c4.28-7.23,8.22-14.64,12.2-22.03
				c3.98-7.39,8.21-14.65,12.62-21.8c8.81-14.29,18.04-28.34,28.18-41.73c10.14-13.39,19.36-27.43,28.17-41.72
				c4.4-7.15,8.63-14.4,12.61-21.79c3.98-7.39,7.92-14.81,12.2-22.03c4.25-7.24,8.71-14.36,13.26-21.42
				c4.56-7.05,9.22-14.04,14.16-20.84c4.91-6.81,10.05-13.45,14.86-20.33c4.84-6.85,9.43-13.88,13.94-20.96
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.52,7.07-9.12,14.1-13.97,20.96
				c-4.82,6.88-9.97,13.52-14.88,20.32c-4.94,6.79-9.6,13.76-14.17,20.81c-4.55,7.05-9.02,14.16-13.27,21.39
				c-4.28,7.22-8.23,14.63-12.22,22.01c-3.99,7.39-8.23,14.64-12.64,21.79c-8.83,14.28-18.07,28.33-28.22,41.71
				c-10.15,13.38-19.39,27.42-28.21,41.69c-4.41,7.14-8.65,14.39-12.63,21.78c-3.99,7.39-7.94,14.8-12.22,22.02
				c-4.26,7.24-8.72,14.35-13.28,21.4c-4.56,7.05-9.23,14.03-14.17,20.82c-4.92,6.8-10.06,13.44-14.88,20.32
				C124.45,345.69,119.86,352.72,115.34,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_77_"
            gradientUnits="userSpaceOnUse"
            x1="90.0456"
            y1="330.4312"
            x2="363.2665"
            y2="57.2102"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-77"
            d="M119.4,359.79c4.43-6.94,8.93-13.84,13.69-20.57c4.73-6.75,9.78-13.26,14.61-19.93
				c4.85-6.66,9.43-13.51,13.9-20.43c4.46-6.92,8.84-13.9,13-21.01c4.19-7.09,8.05-14.37,11.94-21.63
				c3.9-7.26,8.04-14.38,12.36-21.4c8.64-14.02,17.7-27.81,27.67-40.93c9.97-13.12,19.02-26.9,27.66-40.92
				c4.32-7.01,8.46-14.13,12.36-21.39c3.89-7.26,7.76-14.54,11.95-21.63c4.16-7.11,8.54-14.09,13.01-21.02
				c4.47-6.92,9.05-13.77,13.9-20.44c4.83-6.68,9.88-13.19,14.61-19.93c4.76-6.72,9.26-13.62,13.69-20.56
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.44,6.94-8.95,13.84-13.72,20.56
				c-4.74,6.75-9.8,13.25-14.63,19.92c-4.85,6.65-9.44,13.5-13.91,20.41c-4.47,6.92-8.85,13.89-13.02,20.99
				c-4.2,7.09-8.06,14.36-11.96,21.61c-3.9,7.26-8.06,14.38-12.38,21.39c-8.66,14.01-17.73,27.8-27.71,40.91
				c-9.98,13.11-19.05,26.88-27.7,40.89c-4.32,7.01-8.48,14.12-12.38,21.38c-3.9,7.25-7.77,14.53-11.97,21.62
				c-4.17,7.1-8.55,14.08-13.02,21c-4.48,6.91-9.06,13.76-13.92,20.42c-4.83,6.67-9.89,13.18-14.63,19.92
				C128.35,345.96,123.84,352.85,119.4,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_78_"
            gradientUnits="userSpaceOnUse"
            x1="94.6949"
            y1="331.0128"
            x2="362.6849"
            y2="63.0227"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-78"
            d="M123.47,359.79c4.34-6.81,8.77-13.58,13.44-20.17c4.64-6.61,9.61-12.99,14.35-19.53
				c4.76-6.53,9.26-13.24,13.64-20.03c4.38-6.79,8.67-13.64,12.75-20.61c4.11-6.96,7.88-14.1,11.69-21.23
				c3.81-7.12,7.87-14.11,12.11-21c8.48-13.76,17.36-27.28,27.17-40.13c9.8-12.85,18.68-26.37,27.15-40.12
				c4.23-6.88,8.29-13.87,12.1-20.99c3.81-7.12,7.59-14.27,11.69-21.23c4.08-6.98,8.37-13.83,12.75-20.62
				c4.39-6.79,8.88-13.51,13.65-20.04c4.74-6.54,9.71-12.92,14.35-19.53c4.67-6.59,9.09-13.35,13.43-20.16
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.35,6.81-8.78,13.57-13.46,20.16
				c-4.65,6.61-9.63,12.99-14.37,19.52c-4.77,6.52-9.27,13.23-13.66,20.01c-4.38,6.78-8.68,13.63-12.76,20.59
				c-4.11,6.95-7.89,14.09-11.71,21.21c-3.82,7.12-7.89,14.11-12.13,20.99c-8.49,13.75-17.39,27.26-27.2,40.11
				c-9.81,12.84-18.71,26.35-27.2,40.1c-4.24,6.88-8.31,13.86-12.13,20.98c-3.82,7.12-7.6,14.26-11.71,21.22
				c-4.09,6.97-8.39,13.81-12.77,20.6c-4.39,6.78-8.89,13.5-13.66,20.02c-4.75,6.54-9.72,12.91-14.37,19.52
				C132.25,346.22,127.82,352.98,123.47,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_79_"
            gradientUnits="userSpaceOnUse"
            x1="99.3441"
            y1="331.5945"
            x2="362.1033"
            y2="68.8353"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-79"
            d="M127.54,359.79c4.26-6.68,8.6-13.31,13.19-19.77c4.56-6.48,9.44-12.72,14.1-19.13
				c4.68-6.39,9.09-12.98,13.39-19.63c4.29-6.66,8.5-13.37,12.49-20.21c4.02-6.83,7.71-13.84,11.43-20.83
				c3.73-6.99,7.7-13.85,11.85-20.6c8.31-13.49,17.02-26.75,26.66-39.33c9.63-12.58,18.34-25.83,26.65-39.32
				c4.15-6.75,8.12-13.6,11.85-20.59c3.72-6.99,7.42-14.01,11.44-20.83c4-6.84,8.2-13.56,12.5-20.22
				c4.3-6.65,8.71-13.24,13.39-19.64c4.66-6.41,9.54-12.65,14.1-19.13c4.59-6.45,8.92-13.08,13.18-19.76
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.27,6.68-8.61,13.31-13.21,19.76
				c-4.57,6.48-9.46,12.72-14.12,19.12c-4.68,6.39-9.1,12.96-13.4,19.61c-4.3,6.65-8.51,13.36-12.51,20.2
				c-4.03,6.82-7.72,13.83-11.45,20.81c-3.73,6.99-7.72,13.84-11.88,20.59c-8.32,13.48-17.05,26.73-26.7,39.31
				c-9.64,12.57-18.37,25.82-26.69,39.3c-4.16,6.74-8.14,13.59-11.87,20.58c-3.73,6.99-7.43,14-11.46,20.82
				c-4,6.84-8.22,13.55-12.51,20.2c-4.31,6.65-8.72,13.23-13.41,19.62c-4.66,6.4-9.55,12.64-14.12,19.12
				C136.15,346.49,131.81,353.12,127.54,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_80_"
            gradientUnits="userSpaceOnUse"
            x1="103.9934"
            y1="332.1761"
            x2="361.5217"
            y2="74.6478"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-80"
            d="M131.61,359.79c4.18-6.54,8.43-13.04,12.93-19.37c4.47-6.35,9.27-12.46,13.84-18.73
				c4.59-6.26,8.92-12.71,13.13-19.23c4.21-6.52,8.33-13.1,12.24-19.81c3.94-6.69,7.54-13.57,11.18-20.43
				c3.64-6.86,7.54-13.58,11.6-20.2c8.14-13.22,16.69-26.21,26.15-38.54c9.46-12.32,18-25.3,26.14-38.52
				c4.06-6.61,7.95-13.33,11.59-20.19c3.64-6.85,7.25-13.74,11.18-20.43c3.91-6.71,8.04-13.29,12.24-19.82
				c4.22-6.52,8.54-12.98,13.14-19.24c4.58-6.28,9.37-12.39,13.84-18.73c4.5-6.32,8.75-12.82,12.93-19.36
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.18,6.54-8.44,13.04-12.96,19.36
				c-4.48,6.35-9.29,12.45-13.86,18.72c-4.6,6.25-8.93,12.7-13.15,19.21c-4.21,6.52-8.34,13.09-12.26,19.8
				c-3.94,6.69-7.55,13.56-11.2,20.41c-3.65,6.86-7.55,13.58-11.62,20.19c-8.15,13.22-16.71,26.2-26.19,38.51
				c-9.47,12.31-18.03,25.28-26.18,38.5c-4.07,6.61-7.97,13.33-11.62,20.18c-3.65,6.85-7.26,13.73-11.2,20.42
				c-3.92,6.71-8.05,13.28-12.26,19.8c-4.22,6.51-8.55,12.96-13.15,19.22c-4.58,6.27-9.38,12.37-13.86,18.72
				C140.05,346.75,135.79,353.25,131.61,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_81_"
            gradientUnits="userSpaceOnUse"
            x1="108.6427"
            y1="332.7577"
            x2="360.9401"
            y2="80.4603"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-81"
            d="M135.67,359.79c4.09-6.41,8.26-12.78,12.68-18.97c4.39-6.21,9.1-12.19,13.59-18.33
				c4.51-6.13,8.75-12.45,12.88-18.83c4.12-6.39,8.16-12.84,11.98-19.41c3.85-6.56,7.37-13.3,10.93-20.03
				c3.56-6.72,7.37-13.31,11.35-19.8c7.97-12.96,16.35-25.68,25.64-37.74c9.29-12.05,17.66-24.77,25.63-37.72
				c3.98-6.48,7.78-13.07,11.34-19.79c3.55-6.72,7.08-13.47,10.93-20.03c3.83-6.58,7.87-13.03,11.99-19.42
				c4.13-6.39,8.37-12.71,12.89-18.84c4.49-6.14,9.2-12.12,13.59-18.33c4.42-6.19,8.58-12.55,12.67-18.96
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.1,6.41-8.27,12.77-12.7,18.96
				c-4.4,6.21-9.12,12.19-13.61,18.32c-4.51,6.12-8.76,12.43-12.89,18.81c-4.13,6.39-8.17,12.83-12,19.4
				c-3.86,6.55-7.38,13.29-10.94,20.01c-3.56,6.72-7.38,13.31-11.37,19.79c-7.98,12.95-16.37,25.67-25.68,37.71
				c-9.3,12.04-17.69,24.75-25.67,37.7c-3.99,6.48-7.8,13.06-11.36,19.78c-3.56,6.72-7.09,13.46-10.95,20.02
				c-3.83,6.57-7.88,13.02-12.01,19.4c-4.14,6.38-8.38,12.7-12.9,18.82c-4.49,6.14-9.21,12.11-13.61,18.32
				C143.95,347.02,139.77,353.38,135.67,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_82_"
            gradientUnits="userSpaceOnUse"
            x1="113.292"
            y1="333.3393"
            x2="360.3584"
            y2="86.2729"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-82"
            d="M139.74,359.79c4.01-6.28,8.09-12.51,12.42-18.57c4.3-6.08,8.93-11.92,13.33-17.93
				c4.42-5.99,8.58-12.18,12.62-18.43c4.04-6.26,7.99-12.57,11.73-19.01c3.77-6.43,7.2-13.04,10.67-19.63
				c3.47-6.59,7.2-13.05,11.09-19.4c7.8-12.69,16.01-25.15,25.13-36.94c9.12-11.78,17.33-24.24,25.12-36.92
				c3.89-6.35,7.61-12.8,11.09-19.39c3.47-6.59,6.91-13.2,10.68-19.63c3.74-6.44,7.7-12.76,11.74-19.02
				c4.05-6.25,8.21-12.44,12.63-18.44c4.41-6.01,9.03-11.85,13.33-17.93c4.33-6.05,8.41-12.29,12.42-18.56
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-4.01,6.28-8.1,12.51-12.45,18.57
				c-4.31,6.08-8.95,11.92-13.35,17.92c-4.43,5.99-8.59,12.16-12.64,18.41c-4.04,6.25-8,12.56-11.75,19
				c-3.77,6.42-7.21,13.03-10.69,19.61c-3.48,6.59-7.21,13.04-11.11,19.39c-7.81,12.68-16.04,25.14-25.17,36.91
				c-9.13,11.77-17.35,24.22-25.16,36.9c-3.9,6.34-7.63,12.79-11.11,19.38c-3.48,6.59-6.92,13.2-10.69,19.62
				c-3.75,6.44-7.71,12.75-11.75,19c-4.05,6.25-8.22,12.43-12.65,18.42c-4.41,6-9.04,11.84-13.35,17.92
				C147.84,347.29,143.76,353.52,139.74,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_83_"
            gradientUnits="userSpaceOnUse"
            x1="117.9413"
            y1="333.9209"
            x2="359.7768"
            y2="92.0854"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-83"
            d="M143.81,359.79c3.92-6.15,7.92-12.25,12.17-18.17c4.22-5.95,8.76-11.66,13.08-17.53
				c4.34-5.86,8.41-11.91,12.37-18.03c3.95-6.12,7.82-12.31,11.48-18.61c3.68-6.29,7.03-12.77,10.42-19.23
				c3.39-6.46,7.03-12.78,10.84-19c7.63-12.42,15.67-24.62,24.62-36.14c8.95-11.52,16.99-23.7,24.61-36.12
				c3.81-6.21,7.45-12.53,10.83-18.99c3.38-6.45,6.74-12.94,10.42-19.23c3.66-6.31,7.53-12.49,11.48-18.62
				c3.96-6.12,8.04-12.18,12.38-18.04c4.32-5.88,8.86-11.58,13.08-17.53c4.25-5.92,8.24-12.02,12.16-18.16
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-3.93,6.14-7.94,12.24-12.19,18.17
				c-4.23,5.95-8.78,11.65-13.1,17.52c-4.34,5.85-8.42,11.9-12.39,18.01c-3.96,6.12-7.83,12.3-11.49,18.6
				c-3.69,6.29-7.04,12.76-10.44,19.21c-3.4,6.45-7.04,12.78-10.86,18.99c-7.64,12.42-15.7,24.6-24.66,36.12
				c-8.96,11.51-17.01,23.68-24.65,36.1c-3.82,6.21-7.46,12.53-10.85,18.98c-3.39,6.45-6.75,12.93-10.44,19.22
				c-3.66,6.31-7.54,12.48-11.5,18.61c-3.97,6.12-8.05,12.17-12.39,18.02c-4.32,5.87-8.87,11.57-13.1,17.52
				C151.74,347.55,147.74,353.65,143.81,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_84_"
            gradientUnits="userSpaceOnUse"
            x1="122.5906"
            y1="334.5026"
            x2="359.1952"
            y2="97.898"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-84"
            d="M147.88,359.79c3.84-6.01,7.75-11.98,11.92-17.77c4.13-5.81,8.59-11.39,12.82-17.13
				c4.25-5.73,8.24-11.65,12.12-17.63c3.87-5.99,7.65-12.04,11.22-18.22c3.6-6.16,6.86-12.5,10.16-18.83
				c3.3-6.32,6.86-12.52,10.58-18.6c7.46-12.16,15.33-24.09,24.12-35.34c8.78-11.25,16.65-23.17,24.1-35.32
				c3.72-6.08,7.28-12.27,10.58-18.59c3.3-6.32,6.57-12.67,10.17-18.83c3.57-6.18,7.36-12.23,11.23-18.22
				c3.88-5.99,7.87-11.91,12.12-17.64c4.24-5.74,8.69-11.32,12.82-17.13c4.16-5.79,8.07-11.75,11.91-17.76
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-3.84,6.01-7.77,11.98-11.94,17.77
				c-4.14,5.81-8.61,11.39-12.84,17.12c-4.26,5.72-8.25,11.63-12.13,17.61c-3.87,5.99-7.67,12.03-11.24,18.2
				c-3.6,6.15-6.87,12.49-10.18,18.81c-3.31,6.32-6.87,12.51-10.6,18.59c-7.47,12.15-15.36,24.07-24.15,35.32
				c-8.79,11.24-16.67,23.15-24.14,35.3c-3.73,6.08-7.29,12.26-10.6,18.58c-3.31,6.32-6.58,12.66-10.18,18.82
				c-3.58,6.17-7.37,12.22-11.24,18.21c-3.88,5.98-7.88,11.9-12.14,17.62c-4.24,5.74-8.7,11.31-12.84,17.12
				C155.64,347.82,151.72,353.78,147.88,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_85_"
            gradientUnits="userSpaceOnUse"
            x1="127.2399"
            y1="335.0842"
            x2="358.6136"
            y2="103.7105"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-85"
            d="M151.95,359.79c3.75-5.88,7.58-11.71,11.66-17.37c4.05-5.68,8.42-11.12,12.57-16.73
				c4.17-5.59,8.07-11.38,11.86-17.23c3.78-5.86,7.48-11.77,10.97-17.82c3.51-6.03,6.69-12.24,9.91-18.43
				c3.22-6.19,6.69-12.25,10.33-18.2c7.29-11.89,15-23.56,23.61-34.54c8.61-10.98,16.31-22.64,23.6-34.53
				c3.64-5.95,7.11-12,10.32-18.19c3.22-6.19,6.4-12.4,9.91-18.43c3.49-6.05,7.19-11.96,10.97-17.82
				c3.79-5.85,7.7-11.65,11.87-17.24c4.15-5.61,8.52-11.05,12.57-16.73c4.08-5.65,7.9-11.49,11.66-17.36
				c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-3.76,5.88-7.6,11.71-11.69,17.37c-4.06,5.68-8.44,11.12-12.59,16.72
				c-4.17,5.59-8.08,11.37-11.88,17.21c-3.79,5.85-7.5,11.76-10.99,17.8c-3.52,6.02-6.7,12.23-9.93,18.41
				c-3.23,6.19-6.7,12.24-10.35,18.19c-7.3,11.89-15.02,23.54-23.65,34.52c-8.62,10.97-16.33,22.62-23.64,34.5
				c-3.65,5.94-7.12,12-10.35,18.18c-3.22,6.18-6.41,12.39-9.93,18.42c-3.49,6.04-7.2,11.95-10.99,17.81
				c-3.8,5.85-7.71,11.63-11.88,17.22c-4.15,5.6-8.53,11.04-12.59,16.72C159.54,348.08,155.7,353.91,151.95,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_86_"
            gradientUnits="userSpaceOnUse"
            x1="131.8892"
            y1="335.6658"
            x2="358.032"
            y2="109.523"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-86"
            d="M156.01,359.79c3.67-5.75,7.41-11.45,11.41-16.97c3.97-5.55,8.25-10.86,12.31-16.33
				c4.08-5.46,7.9-11.11,11.61-16.83c3.7-5.73,7.31-11.51,10.71-17.42c3.43-5.89,6.52-11.97,9.65-18.03
				c3.13-6.06,6.52-11.98,10.08-17.8c7.12-11.63,14.66-23.02,23.1-33.74c8.44-10.71,15.97-22.1,23.09-33.73
				c3.56-5.81,6.94-11.74,10.07-17.79c3.13-6.05,6.23-12.14,9.66-18.03c3.4-5.91,7.02-11.7,10.72-17.42
				c3.71-5.72,7.53-11.38,11.62-16.84c4.07-5.48,8.35-10.78,12.31-16.33c3.99-5.52,7.74-11.22,11.4-16.96
				c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-3.68,5.74-7.43,11.44-11.43,16.97c-3.97,5.55-8.27,10.85-12.33,16.32
				c-4.09,5.45-7.91,11.1-11.62,16.81c-3.71,5.72-7.33,11.5-10.73,17.4c-3.43,5.89-6.53,11.96-9.67,18.01
				c-3.14,6.05-6.53,11.98-10.1,17.79c-7.13,11.62-14.68,23.01-23.14,33.72c-8.45,10.7-16,22.08-23.13,33.7
				c-3.56,5.81-6.95,11.73-10.09,17.78c-3.14,6.05-6.24,12.13-9.68,18.02c-3.41,5.91-7.03,11.69-10.74,17.41
				c-3.72,5.72-7.54,11.37-11.63,16.82c-4.07,5.47-8.36,10.77-12.33,16.32C163.44,348.35,159.69,354.05,156.01,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_87_"
            gradientUnits="userSpaceOnUse"
            x1="136.5384"
            y1="336.2474"
            x2="357.4503"
            y2="115.3355"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-87"
            d="M160.08,359.79c3.58-5.61,7.24-11.18,11.15-16.57c3.88-5.41,8.08-10.59,12.06-15.93
				c4-5.33,7.73-10.85,11.35-16.43c3.61-5.59,7.15-11.24,10.46-17.02c3.34-5.76,6.35-11.7,9.4-17.63c3.05-5.92,6.35-11.72,9.82-17.4
				c6.95-11.36,14.32-22.49,22.59-32.94c8.27-10.45,15.63-21.57,22.58-32.93c3.47-5.68,6.77-11.47,9.81-17.39
				c3.05-5.92,6.06-11.87,9.4-17.63c3.32-5.78,6.85-11.43,10.47-17.03c3.62-5.59,7.36-11.11,11.36-16.44
				c3.98-5.35,8.18-10.52,12.06-15.93c3.91-5.39,7.57-10.95,11.15-16.57c0.05-0.09,0.17-0.11,0.26-0.06
				c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-3.59,5.61-7.26,11.18-11.18,16.57c-3.89,5.41-8.1,10.59-12.08,15.92
				c-4,5.32-7.74,10.83-11.37,16.41c-3.62,5.59-7.16,11.23-10.48,17c-3.35,5.76-6.36,11.69-9.42,17.61
				c-3.06,5.92-6.36,11.71-9.84,17.39c-6.96,11.35-14.35,22.48-22.63,32.92c-8.28,10.44-15.66,21.55-22.62,32.9
				c-3.48,5.68-6.78,11.46-9.84,17.38c-3.05,5.92-6.07,11.86-9.42,17.62c-3.32,5.77-6.86,11.42-10.48,17.01
				c-3.63,5.58-7.37,11.1-11.38,16.42c-3.99,5.34-8.19,10.51-12.08,15.91C167.34,348.62,163.67,354.18,160.08,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_88_"
            gradientUnits="userSpaceOnUse"
            x1="141.1877"
            y1="336.8291"
            x2="356.8687"
            y2="121.148"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-88"
            d="M164.15,359.79c3.5-5.48,7.07-10.92,10.9-16.17c3.8-5.28,7.91-10.32,11.81-15.53
				c3.91-5.19,7.56-10.58,11.1-16.03c3.53-5.46,6.98-10.98,10.21-16.62c3.26-5.63,6.18-11.44,9.14-17.23
				c2.96-5.79,6.18-11.45,9.57-17c6.78-11.09,13.98-21.96,22.08-32.14c8.1-10.18,15.29-21.04,22.07-32.13
				c3.39-5.55,6.6-11.2,9.56-16.99c2.96-5.79,5.89-11.6,9.15-17.23c3.23-5.65,6.68-11.16,10.21-16.63
				c3.54-5.46,7.19-10.85,11.11-16.04c3.9-5.21,8.01-10.25,11.8-15.53c3.82-5.25,7.4-10.69,10.89-16.17
				c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-3.51,5.48-7.09,10.91-10.92,16.17c-3.81,5.28-7.93,10.32-11.83,15.52
				c-3.92,5.18-7.57,10.57-11.11,16.01c-3.54,5.45-6.99,10.97-10.22,16.6c-3.26,5.62-6.19,11.42-9.16,17.21
				c-2.97,5.79-6.19,11.45-9.59,16.99c-6.79,11.09-14.01,21.95-22.12,32.12c-8.11,10.17-15.32,21.02-22.11,32.1
				c-3.39,5.55-6.61,11.2-9.58,16.98c-2.97,5.78-5.9,11.59-9.17,17.22c-3.24,5.64-6.69,11.15-10.23,16.61
				c-3.55,5.45-7.2,10.83-11.12,16.02c-3.9,5.2-8.02,10.24-11.82,15.51C171.23,348.88,167.65,354.31,164.15,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_89_"
            gradientUnits="userSpaceOnUse"
            x1="145.837"
            y1="337.4107"
            x2="356.2871"
            y2="126.9606"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-89"
            d="M168.22,359.79c3.41-5.35,6.9-10.65,10.65-15.78c3.71-5.15,7.74-10.05,11.55-15.13
				c3.83-5.06,7.39-10.31,10.85-15.63c3.45-5.33,6.81-10.71,9.95-16.22c3.17-5.49,6.01-11.17,8.89-16.83
				c2.88-5.66,6.01-11.18,9.31-16.6c6.61-10.83,13.64-21.43,21.58-31.35c7.93-9.91,14.95-20.5,21.56-31.33
				c3.3-5.42,6.43-10.94,9.31-16.59c2.88-5.65,5.72-11.33,8.89-16.83c3.15-5.51,6.51-10.9,9.96-16.23
				c3.46-5.32,7.02-10.58,10.85-15.64c3.81-5.08,7.84-9.98,11.55-15.13c3.74-5.12,7.23-10.42,10.64-15.77
				c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-3.42,5.35-6.92,10.65-10.67,15.77c-3.72,5.15-7.76,10.05-11.57,15.12
				c-3.83,5.05-7.4,10.3-10.86,15.61c-3.45,5.32-6.82,10.7-9.97,16.2c-3.18,5.49-6.02,11.16-8.91,16.81
				c-2.89,5.65-6.02,11.18-9.33,16.59c-6.63,10.82-13.67,21.41-21.61,31.32c-7.94,9.9-14.98,20.49-21.6,31.3
				c-3.31,5.41-6.44,10.93-9.33,16.58c-2.88,5.65-5.73,11.33-8.91,16.82c-3.15,5.51-6.52,10.89-9.98,16.21
				c-3.46,5.32-7.03,10.57-10.87,15.62c-3.82,5.07-7.85,9.97-11.57,15.11C175.13,349.15,171.64,354.45,168.22,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_90_"
            gradientUnits="userSpaceOnUse"
            x1="150.4863"
            y1="337.9923"
            x2="355.7055"
            y2="132.7731"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-90"
            d="M172.28,359.79c3.33-5.21,6.73-10.38,10.39-15.38c3.63-5.02,7.57-9.79,11.3-14.73
				c3.74-4.93,7.22-10.05,10.59-15.23c3.36-5.19,6.64-10.44,9.7-15.82c3.09-5.36,5.84-10.9,8.63-16.43
				c2.79-5.52,5.84-10.92,9.06-16.2c6.44-10.56,13.31-20.9,21.07-30.55c7.76-9.65,14.61-19.97,21.05-30.53
				c3.22-5.28,6.26-10.67,9.05-16.19c2.79-5.52,5.55-11.07,8.64-16.43c3.06-5.38,6.34-10.63,9.71-15.83
				c3.37-5.19,6.85-10.32,10.6-15.25c3.73-4.95,7.67-9.72,11.29-14.73c3.65-4.99,7.06-10.15,10.39-15.37
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-3.34,5.21-6.75,10.38-10.42,15.37
				c-3.64,5.01-7.59,9.78-11.32,14.72c-3.75,4.92-7.23,10.03-10.61,15.21c-3.37,5.19-6.65,10.43-9.72,15.8
				c-3.09,5.36-5.85,10.89-8.65,16.41c-2.8,5.52-5.85,10.91-9.08,16.19c-6.46,10.56-13.33,20.88-21.11,30.52
				c-7.77,9.64-14.64,19.95-21.09,30.5c-3.22,5.28-6.27,10.66-9.07,16.18c-2.8,5.52-5.56,11.06-8.66,16.42
				c-3.07,5.37-6.35,10.62-9.72,15.81c-3.38,5.18-6.86,10.3-10.61,15.22c-3.73,4.94-7.68,9.7-11.31,14.71
				C179.03,349.41,175.62,354.58,172.28,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_91_"
            gradientUnits="userSpaceOnUse"
            x1="155.1355"
            y1="338.5739"
            x2="355.1238"
            y2="138.5856"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-91"
            d="M176.35,359.79c3.24-5.08,6.57-10.12,10.14-14.98c3.54-4.88,7.4-9.52,11.04-14.33
				c3.66-4.79,7.05-9.78,10.34-14.84c3.28-5.06,6.47-10.18,9.45-15.42c3-5.23,5.67-10.64,8.38-16.02c2.71-5.39,5.67-10.65,8.8-15.8
				c6.27-10.3,12.97-20.37,20.56-29.75c7.59-9.38,14.27-19.44,20.54-29.73c3.13-5.15,6.09-10.4,8.8-15.79
				c2.71-5.38,5.38-10.8,8.39-16.03c2.98-5.25,6.17-10.37,9.45-15.43c3.29-5.06,6.68-10.05,10.35-14.85
				c3.64-4.81,7.5-9.45,11.04-14.33c3.57-4.85,6.89-9.89,10.13-14.97c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0
				c-3.25,5.08-6.58,10.12-10.16,14.97c-3.55,4.88-7.42,9.52-11.06,14.32c-3.66,4.78-7.06,9.77-10.35,14.81
				c-3.28,5.05-6.48,10.17-9.46,15.4c-3.01,5.22-5.68,10.62-8.4,16.01c-2.72,5.39-5.68,10.65-8.83,15.79
				c-6.29,10.29-12.99,20.35-20.6,29.72c-7.6,9.37-14.3,19.42-20.59,29.71c-3.14,5.15-6.1,10.4-8.82,15.78
				c-2.71,5.38-5.39,10.79-8.4,16.02c-2.98,5.24-6.18,10.36-9.47,15.41c-3.29,5.05-6.69,10.04-10.36,14.83
				c-3.65,4.8-7.51,9.44-11.06,14.31C182.93,349.68,179.6,354.71,176.35,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_92_"
            gradientUnits="userSpaceOnUse"
            x1="159.7848"
            y1="339.1555"
            x2="354.5423"
            y2="144.3981"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-92"
            d="M180.42,359.79c3.16-4.95,6.4-9.85,9.88-14.58c3.46-4.75,7.23-9.25,10.79-13.93
				c3.57-4.66,6.88-9.51,10.08-14.44c3.19-4.93,6.3-9.91,9.19-15.02c2.92-5.1,5.5-10.37,8.13-15.62c2.62-5.26,5.5-10.38,8.55-15.4
				c6.1-10.03,12.63-19.83,20.05-28.95c7.42-9.11,13.93-18.9,20.04-28.93c3.05-5.02,5.92-10.14,8.54-15.39
				c2.62-5.25,5.21-10.53,8.13-15.63c2.89-5.11,6-10.1,9.2-15.03c3.2-4.92,6.51-9.78,10.09-14.45c3.56-4.68,7.33-9.18,10.78-13.92
				c3.48-4.72,6.72-9.62,9.88-14.57c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-3.17,4.95-6.41,9.85-9.91,14.57c-3.47,4.75-7.25,9.25-10.81,13.92c-3.58,4.65-6.89,9.5-10.1,14.41c-3.2,4.92-6.31,9.9-9.21,15
				c-2.93,5.09-5.51,10.36-8.14,15.61c-2.63,5.25-5.51,10.38-8.57,15.4c-6.12,10.02-12.66,19.82-20.09,28.93
				c-7.43,9.1-13.96,18.89-20.08,28.91c-3.05,5.01-5.94,10.13-8.57,15.38c-2.63,5.25-5.22,10.52-8.15,15.62
				c-2.9,5.11-6.01,10.09-9.21,15.01c-3.21,4.92-6.52,9.77-10.11,14.43c-3.56,4.67-7.34,9.17-10.8,13.91
				C186.83,349.95,183.59,354.85,180.42,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_93_"
            gradientUnits="userSpaceOnUse"
            x1="164.4341"
            y1="339.7372"
            x2="353.9606"
            y2="150.2107"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-93"
            d="M184.49,359.79c3.08-4.82,6.23-9.59,9.63-14.18c3.37-4.62,7.06-8.99,10.53-13.53
				c3.49-4.53,6.71-9.25,9.83-14.04c3.11-4.79,6.13-9.65,8.94-14.62c2.83-4.96,5.33-10.1,7.87-15.22c2.54-5.12,5.33-10.12,8.3-15
				c5.93-9.76,12.29-19.3,19.54-28.15c7.25-8.84,13.6-18.37,19.53-28.13c2.96-4.88,5.75-9.87,8.29-14.99
				c2.54-5.12,5.04-10.26,7.88-15.23c2.81-4.98,5.84-9.84,8.94-14.63c3.12-4.79,6.35-9.52,9.84-14.05
				c3.47-4.55,7.16-8.91,10.53-13.52c3.4-4.59,6.55-9.36,9.62-14.17c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26
				l0,0c-3.08,4.81-6.24,9.58-9.66,14.18c-3.38,4.62-7.08,8.98-10.55,13.52c-3.49,4.52-6.72,9.23-9.84,14.01
				c-3.11,4.79-6.14,9.64-8.95,14.61c-2.84,4.96-5.35,10.09-7.89,15.21c-2.55,5.12-5.35,10.11-8.32,15
				c-5.95,9.76-12.32,19.29-19.58,28.13c-7.26,8.83-13.62,18.35-19.57,28.11c-2.97,4.88-5.77,9.87-8.31,14.98
				c-2.54,5.12-5.05,10.26-7.89,15.22c-2.82,4.98-5.85,9.82-8.96,14.62c-3.12,4.79-6.36,9.5-9.85,14.03
				c-3.48,4.54-7.17,8.9-10.55,13.51C190.73,350.21,187.57,354.98,184.49,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_94_"
            gradientUnits="userSpaceOnUse"
            x1="169.0834"
            y1="340.3188"
            x2="353.379"
            y2="156.0232"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-94"
            d="M188.55,359.79c2.99-4.68,6.06-9.32,9.38-13.78c3.29-4.48,6.89-8.72,10.28-13.13
				c3.4-4.39,6.55-8.98,9.58-13.64c3.02-4.66,5.96-9.38,8.68-14.22c2.75-4.83,5.16-9.84,7.62-14.82c2.45-4.99,5.16-9.85,8.04-14.6
				c5.77-9.5,11.95-18.77,19.03-27.35c7.08-8.58,13.26-17.84,19.02-27.33c2.88-4.75,5.58-9.61,8.03-14.59
				c2.45-4.98,4.87-10,7.62-14.83c2.73-4.85,5.67-9.57,8.69-14.23c3.03-4.66,6.18-9.25,9.59-13.65c3.39-4.41,6.99-8.65,10.27-13.12
				c3.31-4.45,6.38-9.09,9.37-13.77c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-3,4.68-6.08,9.32-9.4,13.78
				c-3.3,4.48-6.91,8.72-10.3,13.11c-3.41,4.38-6.55,8.97-9.59,13.62c-3.03,4.66-5.97,9.37-8.7,14.21
				c-2.76,4.82-5.18,9.82-7.64,14.81c-2.46,4.99-5.18,9.85-8.06,14.6c-5.78,9.49-11.98,18.76-19.07,27.33
				c-7.09,8.57-13.28,17.82-19.06,27.31c-2.89,4.75-5.6,9.6-8.06,14.58c-2.46,4.98-4.88,9.99-7.64,14.82
				c-2.73,4.84-5.68,9.56-8.71,14.22c-3.04,4.65-6.19,9.24-9.6,13.63c-3.39,4.4-7,8.63-10.29,13.11
				C194.63,350.48,191.55,355.11,188.55,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_95_"
            gradientUnits="userSpaceOnUse"
            x1="173.7327"
            y1="340.9005"
            x2="352.7974"
            y2="161.8357"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-95"
            d="M192.62,359.79c2.91-4.55,5.89-9.06,9.12-13.38c3.2-4.35,6.72-8.45,10.02-12.73
				c3.32-4.26,6.38-8.72,9.32-13.24c2.94-4.53,5.79-9.12,8.43-13.83c2.67-4.7,4.99-9.57,7.36-14.42c2.37-4.85,4.99-9.58,7.79-14.2
				c5.6-9.23,11.62-18.24,18.53-26.55c6.91-8.31,12.92-17.3,18.51-26.53c2.79-4.62,5.41-9.34,7.78-14.19
				c2.37-4.85,4.7-9.73,7.37-14.43c2.64-4.72,5.5-9.3,8.44-13.84c2.95-4.53,6.01-8.99,9.33-13.25c3.3-4.28,6.82-8.38,10.02-12.72
				c3.23-4.32,6.21-8.82,9.12-13.37c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-2.91,4.55-5.91,9.05-9.15,13.38c-3.21,4.35-6.74,8.45-10.04,12.71c-3.32,4.25-6.38,8.7-9.34,13.22
				c-2.94,4.52-5.8,9.1-8.45,13.81c-2.67,4.69-5.01,9.56-7.38,14.41c-2.38,4.85-5.01,9.58-7.81,14.2
				c-5.61,9.23-11.64,18.23-18.57,26.53c-6.92,8.3-12.94,17.29-18.55,26.51c-2.8,4.61-5.43,9.33-7.8,14.18
				c-2.37,4.85-4.71,9.72-7.38,14.42c-2.65,4.71-5.51,9.29-8.45,13.82c-2.95,4.52-6.02,8.97-9.34,13.23
				c-3.31,4.27-6.83,8.37-10.04,12.71C198.52,350.74,195.53,355.24,192.62,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_96_"
            gradientUnits="userSpaceOnUse"
            x1="178.382"
            y1="341.4821"
            x2="352.2158"
            y2="167.6483"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-96"
            d="M196.69,359.79c2.82-4.42,5.72-8.79,8.87-12.98c3.12-4.22,6.55-8.19,9.77-12.32
				c3.23-4.12,6.21-8.45,9.07-12.84c2.85-4.4,5.62-8.85,8.18-13.43c2.58-4.56,4.82-9.3,7.11-14.02c2.28-4.72,4.82-9.32,7.53-13.8
				c5.43-8.97,11.28-17.71,18.02-25.75c6.73-8.04,12.58-16.77,18-25.73c2.71-4.48,5.24-9.07,7.52-13.79
				c2.28-4.72,4.53-9.46,7.11-14.03c2.56-4.58,5.33-9.04,8.18-13.44c2.86-4.39,5.84-8.72,9.08-12.85c3.22-4.15,6.65-8.11,9.76-12.32
				c3.14-4.19,6.04-8.56,8.86-12.97c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-2.83,4.42-5.74,8.79-8.9,12.98c-3.13,4.22-6.57,8.18-9.79,12.31c-3.24,4.12-6.21,8.43-9.08,12.82
				c-2.86,4.39-5.63,8.84-8.19,13.41c-2.59,4.56-4.84,9.29-7.13,14.01c-2.29,4.72-4.84,9.31-7.55,13.8
				c-5.44,8.96-11.31,17.7-18.06,25.73c-6.75,8.03-12.6,16.75-18.04,25.71c-2.72,4.48-5.26,9.07-7.55,13.78
				c-2.29,4.71-4.54,9.45-7.13,14.02c-2.56,4.58-5.34,9.03-8.2,13.42c-2.87,4.39-5.85,8.71-9.09,12.83
				c-3.22,4.14-6.66,8.1-9.78,12.31C202.42,351.01,199.52,355.38,196.69,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_97_"
            gradientUnits="userSpaceOnUse"
            x1="183.0313"
            y1="342.0637"
            x2="351.6342"
            y2="173.4608"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-97"
            d="M200.76,359.79c2.74-4.28,5.55-8.52,8.62-12.58c3.03-4.08,6.38-7.92,9.51-11.92
				c3.15-3.99,6.04-8.18,8.81-12.44c2.77-4.26,5.45-8.58,7.92-13.03c2.5-4.43,4.65-9.04,6.85-13.62c2.2-4.59,4.65-9.05,7.28-13.4
				c5.26-8.7,10.94-17.18,17.51-24.96c6.56-7.77,12.24-16.24,17.49-24.93c2.62-4.35,5.07-8.81,7.27-13.39
				c2.2-4.58,4.36-9.19,6.86-13.63c2.47-4.45,5.16-8.77,7.93-13.04c2.78-4.26,5.67-8.46,8.82-12.45c3.13-4.01,6.48-7.84,9.51-11.92
				c3.06-4.05,5.87-8.29,8.61-12.57c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-2.75,4.28-5.57,8.52-8.64,12.58c-3.04,4.08-6.4,7.92-9.53,11.91c-3.15,3.98-6.04,8.17-8.83,12.42
				c-2.77,4.26-5.47,8.57-7.94,13.01c-2.5,4.42-4.67,9.02-6.87,13.61c-2.21,4.58-4.67,9.05-7.3,13.4
				c-5.27,8.69-10.97,17.17-17.55,24.93c-6.58,7.76-12.27,16.22-17.53,24.91c-2.63,4.35-5.09,8.8-7.29,13.38
				c-2.2,4.58-4.37,9.19-6.88,13.62c-2.48,4.44-5.17,8.76-7.94,13.02c-2.78,4.25-5.68,8.44-8.84,12.43c-3.14,4-6.49,7.83-9.53,11.91
				C206.32,351.27,203.5,355.51,200.76,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_98_"
            gradientUnits="userSpaceOnUse"
            x1="187.6805"
            y1="342.6453"
            x2="351.0525"
            y2="179.2733"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-98"
            d="M204.83,359.79c2.65-4.15,5.38-8.26,8.36-12.18c2.95-3.95,6.21-7.65,9.26-11.52
				c3.06-3.86,5.87-7.92,8.56-12.04c2.68-4.13,5.29-8.32,7.67-12.63c2.41-4.3,4.48-8.77,6.6-13.22c2.11-4.45,4.48-8.78,7.02-13
				c5.09-8.43,10.6-16.65,17-24.16c6.39-7.5,11.9-15.7,16.99-24.14c2.54-4.22,4.9-8.54,7.02-12.99c2.11-4.45,4.19-8.93,6.6-13.23
				c2.39-4.32,4.99-8.51,7.68-12.64c2.69-4.13,5.5-8.19,8.57-12.05c3.05-3.88,6.31-7.58,9.25-11.52c2.98-3.92,5.7-8.02,8.35-12.17
				c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-2.66,4.15-5.4,8.26-8.39,12.18c-2.96,3.95-6.23,7.65-9.28,11.51
				c-3.07,3.85-5.88,7.9-8.57,12.02c-2.69,4.12-5.3,8.31-7.68,12.61c-2.42,4.29-4.5,8.76-6.62,13.21c-2.12,4.45-4.5,8.78-7.05,13
				c-5.1,8.43-10.63,16.63-17.04,24.13c-6.41,7.49-11.93,15.69-17.03,24.11c-2.55,4.22-4.92,8.54-7.04,12.98
				c-2.12,4.45-4.2,8.92-6.62,13.21c-2.39,4.31-5,8.5-7.69,12.62c-2.7,4.12-5.51,8.18-8.58,12.03c-3.05,3.87-6.32,7.56-9.27,11.51
				C210.22,351.54,207.48,355.64,204.83,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_99_"
            gradientUnits="userSpaceOnUse"
            x1="192.3298"
            y1="343.2269"
            x2="350.4709"
            y2="185.0858"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-99"
            d="M208.89,359.79c2.57-4.02,5.21-7.99,8.11-11.78c2.86-3.82,6.04-7.38,9-11.12c2.98-3.72,5.7-7.65,8.31-11.64
				c2.6-4,5.12-8.05,7.41-12.23c2.33-4.16,4.31-8.5,6.34-12.82c2.03-4.32,4.31-8.52,6.77-12.61c4.92-8.17,10.27-16.12,16.49-23.36
				c6.22-7.24,11.56-15.17,16.48-23.34c2.46-4.09,4.73-8.28,6.76-12.59c2.03-4.31,4.02-8.66,6.35-12.83
				c2.3-4.18,4.82-8.24,7.42-12.24c2.61-3.99,5.33-7.92,8.32-11.65c2.96-3.75,6.14-7.31,9-11.12c2.89-3.79,5.53-7.76,8.1-11.77
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-2.58,4.02-5.23,7.99-8.14,11.78
				c-2.88,3.82-6.06,7.38-9.02,11.11c-2.98,3.72-5.71,7.63-8.32,11.62c-2.61,3.99-5.13,8.04-7.43,12.21
				c-2.33,4.16-4.33,8.49-6.36,12.8c-2.04,4.32-4.33,8.51-6.79,12.6c-4.93,8.16-10.29,16.1-16.53,23.34
				c-6.23,7.23-11.59,15.15-16.52,23.31c-2.46,4.08-4.75,8.27-6.78,12.58c-2.03,4.31-4.03,8.65-6.37,12.81
				c-2.31,4.18-4.83,8.23-7.44,12.22c-2.62,3.99-5.34,7.91-8.33,11.63c-2.97,3.74-6.15,7.3-9.02,11.11
				C214.12,351.81,211.47,355.78,208.89,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_100_"
            gradientUnits="userSpaceOnUse"
            x1="196.9791"
            y1="343.8086"
            x2="349.8893"
            y2="190.8983"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-100"
            d="M212.96,359.79c2.48-3.89,5.04-7.73,7.85-11.39c2.78-3.68,5.87-7.12,8.74-10.72
				c2.89-3.59,5.53-7.38,8.05-11.24c2.52-3.86,4.95-7.79,7.16-11.83c2.24-4.03,4.14-8.23,6.09-12.42c1.94-4.18,4.14-8.25,6.52-12.21
				c4.75-7.9,9.93-15.59,15.99-22.56c6.05-6.97,11.22-14.64,15.97-22.54c2.37-3.95,4.56-8.01,6.51-12.19
				c1.94-4.18,3.85-8.39,6.09-12.43c2.22-4.05,4.65-7.98,7.17-11.84c2.53-3.86,5.16-7.66,8.06-11.25c2.88-3.61,5.97-7.04,8.74-10.72
				c2.81-3.65,5.36-7.49,7.85-11.37c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-2.49,3.88-5.06,7.73-7.88,11.39c-2.79,3.68-5.89,7.11-8.76,10.71c-2.9,3.58-5.54,7.37-8.07,11.22
				c-2.52,3.86-4.96,7.77-7.18,11.81c-2.25,4.02-4.16,8.22-6.11,12.4c-1.95,4.18-4.16,8.25-6.54,12.2
				c-4.76,7.9-9.96,15.57-16.03,22.54c-6.06,6.96-11.25,14.62-16.01,22.51c-2.38,3.95-4.58,8.01-6.53,12.18
				c-1.95,4.18-3.86,8.38-6.11,12.41c-2.22,4.04-4.66,7.96-7.18,11.83c-2.53,3.86-5.17,7.64-8.07,11.23
				c-2.88,3.6-5.98,7.03-8.76,10.71C218.02,352.07,215.45,355.91,212.96,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_101_"
            gradientUnits="userSpaceOnUse"
            x1="201.6284"
            y1="344.3902"
            x2="349.3077"
            y2="196.7109"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-101"
            d="M217.03,359.79c2.4-3.75,4.88-7.46,7.6-10.99c2.7-3.55,5.7-6.85,8.49-10.32c2.81-3.46,5.36-7.12,7.8-10.84
				c2.43-3.73,4.78-7.52,6.91-11.43c2.16-3.9,3.97-7.97,5.83-12.02c1.86-4.05,3.97-7.98,6.26-11.81
				c4.58-7.64,9.59-15.06,15.48-21.76c5.88-6.7,10.88-14.1,15.46-21.74c2.29-3.82,4.39-7.74,6.25-11.79
				c1.86-4.04,3.68-8.12,5.84-12.03c2.13-3.92,4.48-7.71,6.92-11.45c2.44-3.73,5-7.39,7.81-10.86c2.79-3.48,5.8-6.77,8.49-10.32
				c2.72-3.52,5.19-7.22,7.59-10.97c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-2.41,3.75-4.89,7.46-7.63,10.99c-2.71,3.55-5.72,6.85-8.51,10.31c-2.81,3.45-5.37,7.1-7.81,10.82
				c-2.44,3.72-4.79,7.51-6.92,11.41c-2.16,3.89-3.99,7.95-5.85,12c-1.87,4.05-3.99,7.98-6.28,11.8
				c-4.59,7.63-9.62,15.04-15.52,21.74c-5.89,6.69-10.91,14.09-15.5,21.71c-2.29,3.82-4.41,7.74-6.28,11.78
				c-1.86,4.04-3.69,8.11-5.86,12.01c-2.14,3.91-4.49,7.7-6.93,11.43c-2.45,3.72-5,7.38-7.82,10.83c-2.8,3.47-5.81,6.76-8.51,10.31
				C221.92,352.34,219.43,356.04,217.03,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_102_"
            gradientUnits="userSpaceOnUse"
            x1="206.2776"
            y1="344.9718"
            x2="348.7261"
            y2="202.5233"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-102"
            d="M221.1,359.79c2.31-3.62,4.71-7.2,7.35-10.59c2.61-3.42,5.53-6.58,8.23-9.92
				c2.72-3.32,5.19-6.85,7.55-10.44c2.35-3.6,4.61-7.26,6.65-11.03c2.07-3.77,3.81-7.7,5.58-11.62c1.77-3.92,3.8-7.72,6.01-11.41
				c4.41-7.37,9.26-14.53,14.97-20.96c5.71-6.43,10.54-13.57,14.95-20.94c2.2-3.69,4.23-7.48,6-11.39
				c1.77-3.91,3.51-7.85,5.58-11.63c2.05-3.79,4.31-7.45,6.66-11.05c2.36-3.6,4.83-7.13,7.56-10.46c2.71-3.35,5.62-6.51,8.23-9.92
				c2.64-3.39,5.03-6.96,7.34-10.57c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-2.32,3.62-4.72,7.2-7.38,10.59c-2.62,3.42-5.55,6.58-8.25,9.91c-2.73,3.31-5.2,6.83-7.56,10.42
				c-2.35,3.59-4.62,7.24-6.67,11.02c-2.08,3.76-3.82,7.69-5.6,11.6c-1.78,3.92-3.82,7.71-6.03,11.4
				c-4.42,7.37-9.28,14.51-15.01,20.94c-5.72,6.42-10.57,13.55-14.99,20.91c-2.21,3.68-4.24,7.47-6.02,11.38
				c-1.78,3.91-3.52,7.85-5.6,11.61c-2.05,3.78-4.32,7.43-6.68,11.03c-2.36,3.59-4.84,7.11-7.57,10.43
				c-2.71,3.34-5.63,6.49-8.25,9.9C225.82,352.6,223.42,356.17,221.1,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_103_"
            gradientUnits="userSpaceOnUse"
            x1="210.9269"
            y1="345.5534"
            x2="348.1445"
            y2="208.3359"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-103"
            d="M225.16,359.79c2.23-3.49,4.54-6.93,7.09-10.19c2.53-3.28,5.36-6.31,7.98-9.52
				c2.64-3.19,5.02-6.58,7.29-10.04c2.26-3.47,4.44-6.99,6.4-10.64c1.99-3.63,3.64-7.43,5.32-11.21c1.69-3.78,3.63-7.45,5.75-11.01
				c4.24-7.11,8.92-14,14.46-20.17c5.54-6.16,10.2-13.04,14.44-20.14c2.12-3.55,4.06-7.21,5.74-10.99
				c1.69-3.78,3.34-7.59,5.33-11.23c1.96-3.65,4.14-7.18,6.41-10.65c2.27-3.46,4.66-6.86,7.3-10.06c2.63-3.21,5.45-6.24,7.97-9.52
				c2.55-3.25,4.86-6.69,7.08-10.18c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-2.24,3.49-4.56,6.93-7.12,10.19c-2.54,3.28-5.38,6.31-8,9.51c-2.64,3.18-5.03,6.56-7.3,10.02c-2.27,3.46-4.45,6.98-6.42,10.62
				c-1.99,3.63-3.65,7.42-5.34,11.2c-1.7,3.78-3.65,7.45-5.78,11c-4.26,7.1-8.95,13.98-14.5,20.14
				c-5.55,6.15-10.23,13.02-14.48,20.12c-2.12,3.55-4.07,7.21-5.77,10.98c-1.69,3.77-3.35,7.58-5.35,11.21
				c-1.97,3.65-4.15,7.17-6.42,10.63c-2.28,3.46-4.67,6.85-7.31,10.03c-2.63,3.2-5.46,6.23-7.99,9.5
				C229.71,352.87,227.4,356.31,225.16,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_104_"
            gradientUnits="userSpaceOnUse"
            x1="215.5762"
            y1="346.1351"
            x2="347.5629"
            y2="214.1484"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-104"
            d="M229.23,359.79c2.15-3.35,4.37-6.67,6.84-9.79c2.44-3.15,5.19-6.05,7.72-9.12
				c2.55-3.06,4.85-6.32,7.04-9.64c2.18-3.33,4.27-6.73,6.15-10.24c1.9-3.5,3.47-7.17,5.07-10.81c1.6-3.65,3.46-7.18,5.5-10.61
				c4.07-6.84,8.58-13.47,13.96-19.37c5.37-5.9,9.86-12.5,13.93-19.34c2.03-3.42,3.89-6.95,5.49-10.59
				c1.6-3.64,3.17-7.32,5.07-10.82c1.88-3.52,3.97-6.92,6.16-10.25c2.19-3.33,4.49-6.6,7.05-9.66c2.54-3.08,5.28-5.97,7.72-9.11
				c2.47-3.12,4.69-6.43,6.83-9.78c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0
				c-2.15,3.35-4.39,6.67-6.87,9.79c-2.45,3.15-5.21,6.04-7.74,9.11c-2.56,3.05-4.86,6.3-7.05,9.62c-2.18,3.33-4.28,6.71-6.16,10.22
				c-1.91,3.49-3.48,7.15-5.09,10.8c-1.61,3.65-3.48,7.18-5.52,10.6c-4.09,6.84-8.61,13.45-13.99,19.34
				c-5.38,5.88-9.89,12.49-13.98,19.32c-2.04,3.42-3.9,6.94-5.51,10.58c-1.61,3.64-3.18,7.31-5.09,10.81
				c-1.88,3.51-3.98,6.9-6.17,10.23c-2.19,3.32-4.5,6.58-7.06,9.64c-2.54,3.07-5.29,5.96-7.74,9.1
				C233.61,353.13,231.38,356.44,229.23,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_105_"
            gradientUnits="userSpaceOnUse"
            x1="220.0947"
            y1="346.8474"
            x2="346.9807"
            y2="219.9615"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-105"
            d="M233.3,359.79c2.06-3.22,4.2-6.4,6.59-9.39c2.36-3.02,5.02-5.78,7.47-8.72c2.47-2.92,4.68-6.05,6.78-9.24
				c2.09-3.2,4.1-6.46,5.89-9.84c1.82-3.37,3.3-6.9,4.81-10.41c1.52-3.51,3.29-6.92,5.24-10.21c1.96-3.29,4.02-6.52,6.23-9.64
				c2.21-3.13,4.66-6.08,7.22-8.93c5.19-5.63,9.53-11.97,13.43-18.54c1.95-3.29,3.72-6.68,5.23-10.19c1.52-3.51,3-7.05,4.82-10.42
				c1.79-3.39,3.81-6.65,5.9-9.85c2.1-3.2,4.32-6.33,6.8-9.26c2.46-2.95,5.11-5.7,7.46-8.71c2.38-2.99,4.52-6.16,6.58-9.38
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-2.07,3.22-4.22,6.4-6.62,9.39c-2.37,3.02-5.04,5.78-7.49,8.7
				c-2.47,2.91-4.69,6.03-6.79,9.22c-2.1,3.19-4.11,6.45-5.91,9.82c-1.82,3.36-3.31,6.88-4.83,10.4c-1.53,3.51-3.31,6.91-5.27,10.2
				c-1.96,3.29-4.03,6.51-6.25,9.63c-2.21,3.13-4.67,6.08-7.23,8.92c-5.21,5.62-9.55,11.95-13.47,18.52
				c-1.96,3.29-3.73,6.68-5.26,10.18c-1.52,3.51-3.01,7.04-4.84,10.41c-1.8,3.38-3.82,6.64-5.92,9.83
				c-2.11,3.19-4.33,6.32-6.81,9.24c-2.46,2.93-5.12,5.69-7.48,8.7C237.51,353.4,235.37,356.57,233.3,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_106_"
            gradientUnits="userSpaceOnUse"
            x1="224.744"
            y1="347.4291"
            x2="346.399"
            y2="225.774"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-106"
            d="M237.37,359.79c1.98-3.09,4.03-6.14,6.34-8.99c2.27-2.88,4.85-5.51,7.21-8.31
				c2.38-2.79,4.51-5.78,6.53-8.85c2.01-3.07,3.93-6.19,5.64-9.44c1.73-3.23,3.13-6.63,4.56-10.01c1.43-3.38,3.12-6.65,4.99-9.81
				c1.87-3.16,3.85-6.25,5.98-9.24c2.12-3,4.49-5.82,6.96-8.53c5.02-5.36,9.19-11.44,12.92-17.74c1.86-3.16,3.55-6.41,4.98-9.79
				c1.43-3.37,2.83-6.78,4.56-10.02c1.71-3.25,3.64-6.39,5.65-9.46c2.02-3.07,4.15-6.07,6.54-8.86c2.37-2.81,4.94-5.43,7.21-8.31
				c2.3-2.85,4.35-5.89,6.32-8.98c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-1.99,3.09-4.05,6.14-6.36,9
				c-2.28,2.89-4.86,5.51-7.23,8.3c-2.39,2.78-4.52,5.76-6.54,8.82c-2.01,3.06-3.94,6.18-5.65,9.42c-1.74,3.23-3.14,6.62-4.58,9.99
				c-1.44,3.38-3.14,6.65-5.01,9.81c-1.88,3.15-3.86,6.25-6,9.23c-2.13,2.99-4.5,5.81-6.98,8.52c-5.03,5.35-9.21,11.42-12.96,17.72
				c-1.87,3.15-3.56,6.41-5,9.78c-1.44,3.37-2.84,6.77-4.58,10.01c-1.71,3.25-3.65,6.37-5.66,9.44c-2.02,3.06-4.16,6.05-6.55,8.84
				c-2.37,2.8-4.95,5.42-7.23,8.3C241.41,353.66,239.35,356.7,237.37,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_107_"
            gradientUnits="userSpaceOnUse"
            x1="229.3933"
            y1="348.0107"
            x2="345.8174"
            y2="231.5866"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-107"
            d="M241.43,359.79c1.89-2.96,3.86-5.87,6.08-8.6c2.19-2.75,4.67-5.24,6.95-7.91c2.3-2.65,4.35-5.52,6.28-8.45
				c1.92-2.93,3.77-5.93,5.39-9.04c1.65-3.1,2.95-6.36,4.3-9.61c1.35-3.25,2.95-6.38,4.74-9.41c1.79-3.02,3.68-5.99,5.73-8.85
				c2.04-2.87,4.32-5.55,6.71-8.13c4.85-5.09,8.85-10.9,12.41-16.94c1.78-3.02,3.38-6.15,4.72-9.39c1.35-3.24,2.66-6.51,4.31-9.62
				c1.62-3.12,3.47-6.12,5.4-9.06c1.93-2.93,3.99-5.8,6.29-8.46c2.29-2.68,4.77-5.17,6.95-7.91c2.21-2.72,4.18-5.63,6.07-8.58
				c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.26l0,0c-1.9,2.96-3.88,5.87-6.11,8.6c-2.2,2.75-4.69,5.24-6.97,7.9
				c-2.3,2.64-4.35,5.5-6.29,8.42c-1.93,2.93-3.78,5.92-5.4,9.02c-1.65,3.09-2.97,6.35-4.32,9.59c-1.36,3.24-2.97,6.38-4.76,9.41
				c-1.79,3.02-3.69,5.98-5.75,8.84c-2.04,2.86-4.33,5.54-6.72,8.12c-4.86,5.08-8.87,10.88-12.45,16.92
				c-1.79,3.02-3.39,6.15-4.75,9.38c-1.35,3.24-2.67,6.5-4.33,9.6c-1.63,3.11-3.48,6.11-5.41,9.04c-1.94,2.93-3.99,5.79-6.3,8.44
				c-2.29,2.67-4.78,5.15-6.97,7.9C245.31,353.93,243.33,356.84,241.43,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_108_"
            gradientUnits="userSpaceOnUse"
            x1="234.0426"
            y1="348.5923"
            x2="345.2358"
            y2="237.3991"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-108"
            d="M245.5,359.79c1.81-2.82,3.7-5.61,5.83-8.2c2.1-2.62,4.5-4.97,6.7-7.51c2.21-2.52,4.18-5.25,6.02-8.05
				c1.84-2.8,3.6-5.67,5.13-8.65c1.56-2.97,2.78-6.1,4.05-9.2c1.26-3.11,2.78-6.12,4.48-9.01c1.7-2.89,3.51-5.72,5.48-8.45
				c1.95-2.73,4.15-5.29,6.45-7.73c4.68-4.82,8.51-10.37,11.9-16.14c1.7-2.89,3.21-5.88,4.47-8.99c1.26-3.1,2.49-6.24,4.05-9.22
				c1.54-2.99,3.3-5.86,5.14-8.66c1.85-2.8,3.82-5.54,6.04-8.07c2.2-2.54,4.6-4.9,6.69-7.51c2.13-2.58,4.01-5.36,5.82-8.18
				c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.25l0,0c-1.82,2.82-3.72,5.61-5.86,8.2c-2.12,2.62-4.52,4.97-6.72,7.5
				c-2.21,2.51-4.18,5.23-6.03,8.02c-1.85,2.8-3.61,5.65-5.15,8.62c-1.57,2.96-2.8,6.08-4.07,9.19c-1.27,3.11-2.8,6.12-4.5,9.01
				c-1.71,2.89-3.53,5.72-5.5,8.44c-1.96,2.73-4.16,5.28-6.47,7.71c-4.69,4.81-8.53,10.35-11.94,16.12
				c-1.7,2.89-3.22,5.88-4.49,8.98c-1.27,3.1-2.5,6.23-4.07,9.2c-1.54,2.98-3.31,5.84-5.16,8.64c-1.86,2.79-3.83,5.52-6.05,8.04
				c-2.2,2.53-4.6,4.88-6.71,7.5C249.21,354.19,247.32,356.97,245.5,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_109_"
            gradientUnits="userSpaceOnUse"
            x1="238.6919"
            y1="349.1739"
            x2="344.6541"
            y2="243.2117"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-109"
            d="M249.57,359.79c1.72-2.69,3.53-5.34,5.58-7.8c2.02-2.48,4.33-4.71,6.44-7.11
				c2.13-2.39,4.01-4.98,5.77-7.65c1.76-2.67,3.43-5.4,4.88-8.25c1.48-2.84,2.61-5.83,3.79-8.8c1.18-2.98,2.61-5.85,4.23-8.61
				c1.62-2.76,3.34-5.46,5.22-8.05c1.87-2.6,3.98-5.02,6.2-7.33c4.5-4.55,8.17-9.84,11.39-15.35c1.61-2.76,3.04-5.62,4.21-8.59
				c1.18-2.97,2.32-5.97,3.8-8.82c1.46-2.86,3.13-5.59,4.89-8.27c1.77-2.67,3.65-5.27,5.79-7.67c2.12-2.41,4.42-4.63,6.44-7.1
				c2.04-2.45,3.84-5.09,5.56-7.78c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.73,2.69-3.55,5.34-5.61,7.8
				c-2.03,2.49-4.35,4.71-6.46,7.1c-2.13,2.38-4.01,4.96-5.78,7.62c-1.76,2.66-3.44,5.39-4.89,8.23c-1.48,2.83-2.63,5.81-3.81,8.79
				c-1.19,2.98-2.63,5.85-4.25,8.61c-1.63,2.76-3.36,5.45-5.24,8.04c-1.88,2.6-3.99,5.01-6.21,7.31c-2.22,2.3-4.33,4.7-6.2,7.3
				c-1.88,2.58-3.61,5.27-5.23,8.03c-1.62,2.75-3.06,5.62-4.24,8.58c-1.18,2.97-2.33,5.96-3.82,8.8c-1.46,2.85-3.14,5.58-4.9,8.24
				c-1.77,2.66-3.66,5.26-5.79,7.64c-2.12,2.4-4.43,4.62-6.46,7.09C253.11,354.46,251.3,357.1,249.57,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_110_"
            gradientUnits="userSpaceOnUse"
            x1="243.3412"
            y1="349.7556"
            x2="344.0725"
            y2="249.0242"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-110"
            d="M253.64,359.79c1.64-2.56,3.36-5.08,5.32-7.4c1.94-2.35,4.16-4.44,6.18-6.71
				c2.04-2.25,3.84-4.72,5.52-7.25c1.67-2.54,3.26-5.14,4.63-7.85c1.39-2.7,2.44-5.56,3.54-8.4c1.09-2.84,2.44-5.58,3.97-8.21
				c1.53-2.63,3.18-5.19,4.97-7.65c1.79-2.47,3.81-4.75,5.94-6.93c2.13-2.17,4.14-4.45,5.92-6.91c1.79-2.45,3.43-5.02,4.96-7.64
				c1.53-2.62,2.87-5.35,3.96-8.19c1.09-2.83,2.15-5.7,3.54-8.41c1.37-2.72,2.96-5.33,4.64-7.87c1.68-2.54,3.48-5.01,5.53-7.27
				c2.03-2.28,4.25-4.36,6.18-6.7c1.96-2.32,3.67-4.83,5.31-7.38c0.05-0.09,0.17-0.11,0.26-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0
				c-1.65,2.56-3.38,5.08-5.36,7.41c-1.95,2.35-4.18,4.44-6.2,6.7c-2.04,2.24-3.84,4.7-5.52,7.22c-1.68,2.53-3.27,5.12-4.64,7.83
				c-1.4,2.7-2.45,5.54-3.56,8.38c-1.1,2.84-2.46,5.58-4,8.21c-1.54,2.63-3.19,5.19-4.99,7.65c-1.79,2.46-3.83,4.75-5.96,6.91
				c-2.13,2.17-4.16,4.44-5.94,6.89c-1.8,2.45-3.44,5.01-4.98,7.63c-1.53,2.62-2.89,5.35-3.98,8.18c-1.1,2.83-2.16,5.69-3.56,8.4
				c-1.38,2.72-2.97,5.31-4.65,7.85c-1.69,2.53-3.49,4.99-5.54,7.24c-2.03,2.27-4.26,4.35-6.2,6.69
				C257.01,354.72,255.28,357.23,253.64,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_111_"
            gradientUnits="userSpaceOnUse"
            x1="247.9904"
            y1="350.3372"
            x2="343.4909"
            y2="254.8367"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-111"
            d="M257.7,359.79c1.55-2.43,3.19-4.81,5.07-7c1.85-2.22,3.99-4.17,5.92-6.3c1.96-2.12,3.67-4.45,5.26-6.85
				c1.59-2.4,3.09-4.87,4.37-7.45c1.31-2.57,2.27-5.29,3.28-8c1.01-2.71,2.27-5.31,3.72-7.81c1.45-2.5,3.01-4.93,4.72-7.26
				c1.7-2.34,3.65-4.49,5.69-6.53c2.04-2.04,3.97-4.18,5.67-6.51c1.71-2.32,3.26-4.75,4.71-7.24c1.44-2.49,2.7-5.09,3.7-7.79
				c1.01-2.7,1.98-5.43,3.29-8.01c1.29-2.59,2.79-5.06,4.38-7.47c1.6-2.4,3.32-4.74,5.28-6.87c1.95-2.14,4.08-4.09,5.92-6.3
				c1.87-2.18,3.5-4.56,5.06-6.98c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.56,2.43-3.21,4.81-5.1,7.01
				c-1.86,2.22-4.01,4.17-5.94,6.29c-1.96,2.11-3.67,4.43-5.27,6.82c-1.59,2.4-3.1,4.86-4.39,7.43c-1.31,2.56-2.28,5.28-3.3,7.98
				c-1.02,2.71-2.29,5.32-3.74,7.81c-1.46,2.49-3.02,4.93-4.74,7.25c-1.71,2.33-3.66,4.48-5.7,6.51c-2.05,2.03-3.98,4.17-5.69,6.49
				c-1.71,2.32-3.27,4.74-4.73,7.23c-1.45,2.49-2.72,5.09-3.73,7.78c-1.01,2.7-1.99,5.42-3.31,8c-1.29,2.58-2.8,5.05-4.4,7.45
				c-1.6,2.4-3.32,4.73-5.29,6.84c-1.94,2.13-4.09,4.08-5.94,6.29C260.91,354.99,259.27,357.37,257.7,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_112_"
            gradientUnits="userSpaceOnUse"
            x1="252.6397"
            y1="350.9188"
            x2="342.9093"
            y2="260.6492"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-112"
            d="M261.77,359.79c1.47-2.3,3.03-4.55,4.82-6.61c1.77-2.08,3.81-3.9,5.67-5.9c1.87-1.99,3.5-4.18,5.01-6.45
				c1.5-2.27,2.92-4.61,4.12-7.06c1.22-2.44,2.1-5.02,3.03-7.59c0.92-2.57,2.1-5.05,3.46-7.41c1.37-2.36,2.84-4.67,4.47-6.86
				c1.62-2.2,3.48-4.22,5.43-6.13c1.95-1.9,3.8-3.91,5.41-6.11c1.62-2.19,3.09-4.49,4.45-6.84c1.36-2.36,2.53-4.82,3.45-7.39
				c0.92-2.56,1.8-5.16,3.03-7.61c1.2-2.46,2.62-4.8,4.13-7.08c1.51-2.27,3.15-4.48,5.03-6.47c1.86-2.01,3.9-3.82,5.66-5.9
				c1.79-2.05,3.34-4.29,4.8-6.58c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.48,2.3-3.05,4.55-4.85,6.61
				c-1.78,2.09-3.84,3.9-5.69,5.89c-1.87,1.97-3.5,4.16-5.02,6.42c-1.51,2.26-2.93,4.59-4.13,7.03c-1.23,2.43-2.11,5.01-3.04,7.57
				c-0.93,2.57-2.12,5.05-3.49,7.41c-1.37,2.36-2.85,4.66-4.49,6.85c-1.62,2.2-3.49,4.21-5.45,6.11c-1.96,1.9-3.81,3.9-5.43,6.09
				c-1.63,2.18-3.11,4.48-4.48,6.83c-1.37,2.36-2.55,4.82-3.48,7.38c-0.93,2.56-1.82,5.15-3.05,7.59c-1.21,2.45-2.63,4.78-4.14,7.05
				c-1.52,2.27-3.16,4.46-5.04,6.44c-1.86,2-3.91,3.81-5.68,5.89C264.81,355.25,263.25,357.5,261.77,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_113_"
            gradientUnits="userSpaceOnUse"
            x1="257.289"
            y1="351.5004"
            x2="342.3276"
            y2="266.4618"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-113"
            d="M265.84,359.79c1.39-2.16,2.86-4.28,4.57-6.21c1.68-1.95,3.64-3.63,5.41-5.5
				c1.79-1.85,3.33-3.92,4.76-6.05c1.42-2.14,2.76-4.34,3.87-6.66c1.14-2.31,1.93-4.76,2.77-7.19c0.84-2.43,1.93-4.78,3.21-7.01
				c1.28-2.23,2.67-4.4,4.22-6.47c1.53-2.07,3.31-3.96,5.18-5.72c1.87-1.77,3.63-3.64,5.16-5.7c1.54-2.05,2.92-4.22,4.2-6.45
				c1.27-2.23,2.36-4.56,3.19-6.99c0.84-2.42,1.63-4.89,2.78-7.21c1.12-2.33,2.46-4.54,3.88-6.68c1.43-2.14,2.98-4.22,4.78-6.08
				c1.78-1.88,3.73-3.55,5.4-5.49c1.7-1.91,3.17-4.03,4.55-6.18c0.05-0.09,0.17-0.11,0.25-0.06s0.11,0.17,0.06,0.25l0,0
				c-1.4,2.16-2.88,4.29-4.6,6.22c-1.7,1.95-3.66,3.63-5.43,5.49c-1.78,1.84-3.33,3.89-4.76,6.02c-1.42,2.13-2.76,4.33-3.88,6.64
				c-1.14,2.3-1.94,4.74-2.79,7.17c-0.85,2.44-1.95,4.78-3.23,7.01c-1.29,2.23-2.69,4.4-4.24,6.46c-1.54,2.07-3.32,3.95-5.19,5.71
				c-1.87,1.76-3.64,3.63-5.18,5.69c-1.54,2.05-2.94,4.21-4.22,6.44c-1.28,2.23-2.38,4.56-3.22,6.98c-0.84,2.42-1.65,4.88-2.8,7.19
				c-1.12,2.32-2.46,4.52-3.89,6.66c-1.43,2.13-2.99,4.2-4.78,6.04c-1.77,1.86-3.74,3.54-5.42,5.48
				C268.71,355.52,267.23,357.63,265.84,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_114_"
            gradientUnits="userSpaceOnUse"
            x1="261.9382"
            y1="352.0821"
            x2="341.746"
            y2="272.2743"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-114"
            d="M269.91,359.79c1.3-2.03,2.69-4.02,4.32-5.81c1.6-1.82,3.47-3.36,5.15-5.1c1.7-1.72,3.16-3.65,4.51-5.65
				c1.34-2.01,2.59-4.08,3.61-6.26c1.05-2.17,1.76-4.49,2.52-6.78c0.75-2.3,1.76-4.51,2.95-6.62c1.2-2.1,2.5-4.14,3.96-6.07
				c1.45-1.94,3.14-3.69,4.92-5.32c1.78-1.63,3.45-3.37,4.9-5.3c1.45-1.92,2.76-3.96,3.95-6.05c1.19-2.1,2.19-4.29,2.94-6.58
				c0.75-2.29,1.46-4.62,2.52-6.8c1.03-2.2,2.29-4.27,3.63-6.29c1.35-2.01,2.82-3.95,4.53-5.68c1.69-1.74,3.55-3.28,5.14-5.09
				c1.62-1.78,3-3.76,4.3-5.79c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.31,2.03-2.71,4.02-4.35,5.82
				c-1.61,1.82-3.49,3.37-5.17,5.08c-1.7,1.7-3.16,3.62-4.51,5.62c-1.34,2-2.6,4.07-3.63,6.24c-1.06,2.17-1.77,4.47-2.53,6.76
				c-0.76,2.3-1.78,4.52-2.98,6.62c-1.21,2.1-2.52,4.14-3.99,6.06c-1.46,1.94-3.15,3.68-4.94,5.31c-1.78,1.63-3.47,3.36-4.92,5.29
				c-1.46,1.92-2.77,3.95-3.97,6.04c-1.2,2.09-2.21,4.29-2.96,6.58c-0.76,2.29-1.48,4.61-2.54,6.78c-1.04,2.19-2.3,4.26-3.64,6.26
				c-1.35,2-2.82,3.93-4.53,5.65c-1.69,1.73-3.56,3.27-5.16,5.08C272.61,355.78,271.22,357.76,269.91,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_115_"
            gradientUnits="userSpaceOnUse"
            x1="266.5875"
            y1="352.6637"
            x2="341.1644"
            y2="278.0868"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-115"
            d="M273.98,359.79c1.22-1.9,2.53-3.76,4.07-5.41c1.51-1.68,3.29-3.09,4.89-4.69c1.61-1.58,3-3.38,4.25-5.25
				c1.25-1.88,2.42-3.82,3.36-5.87c0.97-2.04,1.59-4.22,2.26-6.38c0.67-2.16,1.59-4.24,2.7-6.22c1.11-1.97,2.34-3.88,3.71-5.68
				c1.37-1.81,2.98-3.42,4.67-4.92c1.69-1.5,3.28-3.1,4.64-4.9c1.37-1.79,2.59-3.69,3.7-5.65c1.11-1.96,2.02-4.03,2.68-6.18
				c0.67-2.15,1.29-4.34,2.27-6.4c0.95-2.06,2.12-4.01,3.38-5.89c1.26-1.88,2.65-3.69,4.28-5.28c1.61-1.61,3.38-3.01,4.88-4.69
				c1.53-1.65,2.83-3.49,4.04-5.39c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-1.23,1.9-2.55,3.76-4.1,5.42
				c-1.53,1.69-3.32,3.1-4.91,4.68c-1.61,1.57-2.99,3.36-4.26,5.22c-1.26,1.87-2.43,3.8-3.37,5.84c-0.97,2.03-1.6,4.2-2.28,6.36
				c-0.68,2.16-1.61,4.25-2.72,6.22c-1.12,1.97-2.35,3.87-3.73,5.67c-1.37,1.8-2.99,3.42-4.68,4.91c-1.69,1.49-3.29,3.09-4.66,4.88
				c-1.38,1.78-2.6,3.69-3.72,5.64c-1.11,1.96-2.04,4.03-2.71,6.18c-0.67,2.15-1.31,4.33-2.29,6.38c-0.95,2.05-2.13,3.99-3.39,5.87
				c-1.27,1.87-2.66,3.67-4.28,5.25c-1.6,1.6-3.39,3-4.9,4.67C276.51,356.04,275.2,357.89,273.98,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_116_"
            gradientUnits="userSpaceOnUse"
            x1="271.2368"
            y1="353.2453"
            x2="340.5827"
            y2="283.8994"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-116"
            d="M278.04,359.79c1.13-1.77,2.36-3.49,3.82-5.02c1.43-1.55,3.12-2.82,4.63-4.29c1.53-1.45,2.83-3.11,4-4.86
				c1.17-1.74,2.25-3.56,3.11-5.47c0.88-1.91,1.42-3.95,2-5.97c0.58-2.03,1.42-3.98,2.44-5.82c1.03-1.84,2.17-3.62,3.46-5.28
				c1.28-1.68,2.81-3.16,4.41-4.52c1.6-1.36,3.11-2.83,4.38-4.5c1.29-1.66,2.42-3.43,3.45-5.26c1.02-1.83,1.85-3.77,2.43-5.78
				c0.58-2.01,1.12-4.07,2.01-5.99c0.86-1.93,1.95-3.75,3.12-5.5c1.18-1.75,2.49-3.43,4.03-4.88c1.52-1.47,3.2-2.74,4.62-4.28
				c1.44-1.51,2.66-3.23,3.79-4.99c0.05-0.09,0.17-0.11,0.25-0.06s0.11,0.17,0.06,0.25l0,0c-1.14,1.77-2.38,3.5-3.85,5.03
				c-1.45,1.55-3.14,2.83-4.65,4.28c-1.52,1.43-2.82,3.09-4,4.82c-1.17,1.74-2.26,3.54-3.12,5.45c-0.89,1.9-1.43,3.93-2.02,5.95
				c-0.59,2.03-1.43,3.98-2.47,5.82c-1.04,1.84-2.18,3.61-3.48,5.27c-1.29,1.67-2.82,3.15-4.43,4.51c-1.6,1.36-3.12,2.82-4.4,4.48
				c-1.29,1.65-2.44,3.42-3.47,5.25c-1.03,1.83-1.87,3.77-2.46,5.78c-0.59,2.01-1.14,4.06-2.03,5.97c-0.87,1.92-1.96,3.73-3.13,5.48
				c-1.18,1.74-2.49,3.41-4.03,4.85c-1.52,1.46-3.21,2.73-4.64,4.27C280.42,356.31,279.18,358.02,278.04,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_117_"
            gradientUnits="userSpaceOnUse"
            x1="275.8861"
            y1="353.8269"
            x2="340.0011"
            y2="289.7119"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-117"
            d="M282.11,359.79c1.05-1.64,2.19-3.23,3.57-4.62c1.35-1.41,2.94-2.55,4.37-3.88
				c1.44-1.31,2.66-2.85,3.75-4.46c1.09-1.61,2.09-3.29,2.86-5.08c0.8-1.78,1.25-3.68,1.75-5.56c0.5-1.89,1.25-3.71,2.19-5.42
				c0.95-1.71,2-3.35,3.21-4.89c1.2-1.54,2.64-2.89,4.15-4.12c1.51-1.23,2.93-2.56,4.13-4.09c1.2-1.52,2.26-3.17,3.19-4.86
				c0.94-1.7,1.68-3.5,2.17-5.38c0.5-1.87,0.95-3.79,1.76-5.59c0.78-1.8,1.78-3.49,2.87-5.11c1.1-1.62,2.33-3.16,3.78-4.49
				c1.44-1.34,3.02-2.47,4.36-3.88c1.36-1.38,2.5-2.96,3.54-4.59c0.05-0.09,0.17-0.11,0.25-0.06s0.11,0.17,0.06,0.25l0,0
				c-1.06,1.64-2.22,3.24-3.61,4.63c-1.36,1.42-2.97,2.56-4.39,3.87c-1.44,1.3-2.66,2.82-3.75,4.42c-1.09,1.6-2.09,3.28-2.87,5.05
				c-0.8,1.77-1.26,3.66-1.77,5.54c-0.51,1.89-1.26,3.72-2.21,5.42c-0.95,1.71-2.02,3.35-3.24,4.88c-1.21,1.54-2.65,2.88-4.17,4.11
				c-1.51,1.22-2.94,2.55-4.15,4.08c-1.21,1.52-2.27,3.16-3.22,4.85c-0.95,1.7-1.7,3.5-2.2,5.38c-0.51,1.88-0.97,3.79-1.77,5.57
				c-0.78,1.79-1.79,3.47-2.88,5.08c-1.1,1.61-2.33,3.14-3.78,4.45c-1.43,1.33-3.03,2.46-4.38,3.86
				C284.32,356.57,283.17,358.16,282.11,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_118_"
            gradientUnits="userSpaceOnUse"
            x1="280.5354"
            y1="354.4085"
            x2="339.4195"
            y2="295.5244"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-118"
            d="M286.18,359.79c0.97-1.51,2.03-2.97,3.32-4.22c1.26-1.28,2.77-2.28,4.1-3.48c1.35-1.18,2.49-2.58,3.5-4.06
				c1-1.48,1.92-3.03,2.6-4.69c0.71-1.65,1.08-3.41,1.49-5.15c0.41-1.75,1.07-3.44,1.93-5.02c0.86-1.58,1.84-3.09,2.97-4.5
				c1.12-1.41,2.48-2.62,3.9-3.72c1.42-1.09,2.76-2.29,3.87-3.69c1.12-1.39,2.09-2.9,2.94-4.47c0.85-1.57,1.5-3.24,1.91-4.98
				c0.41-1.73,0.78-3.52,1.5-5.18c0.69-1.67,1.61-3.23,2.62-4.72c1.01-1.49,2.16-2.9,3.53-4.09c1.35-1.21,2.84-2.2,4.09-3.47
				c1.27-1.24,2.33-2.69,3.29-4.19c0.05-0.09,0.17-0.11,0.25-0.06s0.11,0.17,0.06,0.25l0,0c-0.98,1.51-2.06,2.98-3.36,4.24
				c-1.28,1.29-2.79,2.29-4.12,3.47c-1.35,1.16-2.49,2.55-3.5,4.02c-1.01,1.47-1.93,3.01-2.62,4.66c-0.72,1.64-1.09,3.39-1.51,5.13
				c-0.42,1.75-1.09,3.45-1.96,5.03c-0.87,1.58-1.85,3.09-2.99,4.49c-1.13,1.41-2.49,2.62-3.91,3.7c-1.42,1.09-2.77,2.27-3.89,3.67
				c-1.13,1.39-2.11,2.89-2.97,4.46c-0.86,1.57-1.52,3.24-1.94,4.98c-0.42,1.74-0.8,3.51-1.52,5.16c-0.7,1.66-1.62,3.21-2.63,4.69
				c-1.01,1.48-2.16,2.88-3.53,4.05c-1.34,1.19-2.85,2.19-4.12,3.46C288.22,356.83,287.15,358.29,286.18,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_119_"
            gradientUnits="userSpaceOnUse"
            x1="285.2041"
            y1="355.0096"
            x2="338.8573"
            y2="301.3564"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-119"
            d="M290.25,359.79c0.88-1.38,1.87-2.71,3.07-3.83c1.18-1.15,2.59-2.01,3.84-3.07
				c1.27-1.04,2.33-2.31,3.25-3.66c0.92-1.35,1.75-2.77,2.35-4.3c0.63-1.52,0.9-3.14,1.24-4.74c0.33-1.61,0.9-3.17,1.68-4.62
				c0.78-1.45,1.67-2.84,2.72-4.1c1.04-1.28,2.31-2.35,3.64-3.31c1.33-0.96,2.58-2.02,3.61-3.29c1.04-1.26,1.92-2.64,2.69-4.07
				c0.77-1.44,1.33-2.98,1.66-4.58c0.33-1.59,0.61-3.24,1.25-4.77c0.61-1.54,1.45-2.97,2.37-4.33c0.93-1.36,2-2.64,3.28-3.69
				c1.26-1.07,2.66-1.93,3.83-3.07c1.19-1.11,2.16-2.42,3.04-3.79c0.05-0.09,0.17-0.11,0.25-0.06s0.11,0.17,0.06,0.25l0,0
				c-0.89,1.38-1.89,2.72-3.11,3.85c-1.2,1.15-2.62,2.02-3.86,3.06c-1.26,1.03-2.32,2.28-3.24,3.62c-0.92,1.34-1.76,2.75-2.36,4.26
				c-0.63,1.5-0.92,3.11-1.26,4.72c-0.34,1.61-0.92,3.18-1.7,4.63c-0.79,1.45-1.68,2.83-2.74,4.1c-1.05,1.28-2.32,2.35-3.66,3.3
				c-1.33,0.95-2.59,2-3.63,3.27c-1.04,1.25-1.94,2.63-2.72,4.07c-0.78,1.44-1.35,2.98-1.69,4.58c-0.34,1.6-0.63,3.23-1.26,4.75
				c-0.61,1.53-1.45,2.95-2.38,4.3c-0.93,1.35-2,2.62-3.28,3.65c-1.26,1.06-2.67,1.91-3.85,3.05
				C292.13,357.09,291.13,358.42,290.25,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_120_"
            gradientUnits="userSpaceOnUse"
            x1="289.8539"
            y1="355.5918"
            x2="338.2762"
            y2="307.1695"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-120"
            d="M294.31,359.79c0.8-1.25,1.71-2.45,2.82-3.43c1.1-1.01,2.41-1.74,3.57-2.67c1.18-0.91,2.16-2.04,3-3.26
				c0.84-1.22,1.59-2.52,2.1-3.91c0.54-1.39,0.73-2.86,0.98-4.33c0.24-1.47,0.73-2.9,1.42-4.22c0.69-1.32,1.51-2.58,2.47-3.72
				c0.96-1.15,2.15-2.09,3.38-2.91c1.23-0.82,2.4-1.74,3.35-2.88c0.95-1.12,1.76-2.38,2.45-3.68c0.69-1.31,1.16-2.71,1.4-4.17
				c0.25-1.45,0.45-2.95,0.99-4.36c0.52-1.41,1.28-2.71,2.12-3.94c0.85-1.23,1.85-2.38,3.04-3.3c1.18-0.94,2.48-1.66,3.56-2.66
				c1.1-0.97,2-2.15,2.79-3.39c0.05-0.09,0.17-0.11,0.25-0.06c0.09,0.05,0.11,0.17,0.06,0.25l0,0c-0.81,1.26-1.73,2.46-2.87,3.45
				c-1.12,1.02-2.44,1.75-3.59,2.66c-1.17,0.89-2.15,2.01-2.99,3.21c-0.84,1.21-1.59,2.49-2.11,3.87c-0.54,1.37-0.75,2.84-1,4.31
				c-0.25,1.47-0.74,2.91-1.45,4.23c-0.7,1.32-1.52,2.58-2.49,3.71c-0.96,1.15-2.16,2.08-3.4,2.9c-1.24,0.82-2.41,1.73-3.36,2.86
				c-0.96,1.12-1.78,2.37-2.47,3.67c-0.7,1.31-1.18,2.72-1.43,4.18c-0.26,1.46-0.46,2.95-1.01,4.34c-0.52,1.4-1.28,2.69-2.13,3.91
				c-0.85,1.22-1.84,2.35-3.03,3.26c-1.17,0.92-2.48,1.64-3.58,2.65C296.03,357.35,295.12,358.54,294.31,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_121_"
            gradientUnits="userSpaceOnUse"
            x1="294.5038"
            y1="356.174"
            x2="337.6951"
            y2="312.9826"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-121"
            d="M298.38,359.79c0.71-1.12,1.55-2.19,2.58-3.04c1.01-0.88,2.22-1.47,3.29-2.26
				c1.09-0.77,1.99-1.77,2.75-2.86c0.76-1.09,1.42-2.26,1.85-3.52c0.45-1.25,0.56-2.59,0.73-3.91c0.16-1.33,0.55-2.63,1.16-3.82
				c0.61-1.19,1.34-2.32,2.23-3.33c0.88-1.02,1.99-1.82,3.12-2.51c1.14-0.69,2.22-1.47,3.08-2.47c0.87-0.99,1.6-2.12,2.2-3.29
				c0.6-1.18,0.98-2.45,1.14-3.77c0.17-1.31,0.28-2.67,0.74-3.95c0.43-1.28,1.11-2.46,1.87-3.56c0.76-1.1,1.69-2.12,2.8-2.9
				c1.09-0.8,2.29-1.39,3.28-2.25c1.01-0.83,1.83-1.88,2.54-2.99c0.05-0.09,0.17-0.11,0.25-0.06s0.11,0.17,0.06,0.25l0,0
				c-0.73,1.13-1.58,2.2-2.63,3.06c-1.04,0.88-2.25,1.48-3.32,2.25c-1.08,0.76-1.98,1.73-2.74,2.81c-0.76,1.08-1.43,2.24-1.86,3.48
				c-0.46,1.24-0.57,2.56-0.75,3.89c-0.17,1.33-0.57,2.64-1.19,3.84c-0.62,1.19-1.36,2.32-2.25,3.33c-0.89,1.02-2,1.81-3.14,2.49
				c-1.14,0.68-2.23,1.45-3.1,2.46c-0.88,0.99-1.61,2.11-2.23,3.28c-0.61,1.18-1,2.46-1.17,3.78c-0.17,1.31-0.29,2.66-0.76,3.93
				c-0.44,1.27-1.11,2.44-1.88,3.53c-0.77,1.09-1.68,2.09-2.78,2.86c-1.08,0.78-2.29,1.37-3.31,2.24
				C299.94,357.61,299.1,358.67,298.38,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_122_"
            gradientUnits="userSpaceOnUse"
            x1="299.1537"
            y1="356.7562"
            x2="337.1141"
            y2="318.7957"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-122"
            d="M302.45,359.79c0.63-1,1.39-1.93,2.34-2.64c0.93-0.74,2.04-1.2,3.02-1.85c1-0.63,1.83-1.49,2.51-2.46
				c0.68-0.96,1.26-2,1.59-3.13c0.36-1.12,0.39-2.31,0.48-3.49c0.08-1.18,0.37-2.36,0.9-3.43c0.53-1.06,1.18-2.07,1.99-2.95
				c0.8-0.89,1.82-1.54,2.86-2.1c1.04-0.56,2.03-1.2,2.82-2.07c0.79-0.86,1.44-1.85,1.95-2.9c0.52-1.05,0.8-2.19,0.88-3.36
				c0.09-1.17,0.11-2.38,0.49-3.53c0.35-1.15,0.94-2.21,1.62-3.18c0.68-0.98,1.54-1.86,2.56-2.5c1-0.66,2.1-1.12,3.01-1.85
				c0.93-0.7,1.67-1.61,2.29-2.59c0.05-0.09,0.17-0.11,0.26-0.06s0.11,0.17,0.06,0.26l0,0c-0.65,1-1.42,1.95-2.39,2.67
				c-0.95,0.75-2.07,1.21-3.04,1.84c-0.99,0.62-1.81,1.46-2.49,2.41c-0.68,0.95-1.26,1.98-1.6,3.09c-0.37,1.11-0.4,2.29-0.5,3.47
				c-0.09,1.19-0.39,2.37-0.93,3.44c-0.53,1.07-1.19,2.07-2.01,2.94c-0.81,0.89-1.84,1.54-2.88,2.09c-1.04,0.55-2.04,1.18-2.84,2.05
				c-0.8,0.85-1.45,1.85-1.98,2.89c-0.53,1.05-0.83,2.2-0.91,3.38c-0.1,1.17-0.13,2.37-0.5,3.51c-0.35,1.14-0.94,2.18-1.63,3.15
				c-0.68,0.97-1.53,1.83-2.54,2.46c-0.99,0.65-2.1,1.1-3.03,1.83C303.85,357.87,303.09,358.8,302.45,359.79z"
          />
        </g>
        <g>
          <linearGradient
            id="CERTIFICATE_MOBILE_123_"
            gradientUnits="userSpaceOnUse"
            x1="303.8903"
            y1="357.4266"
            x2="336.6198"
            y2="324.6971"
          >
            <stop
              offset="0"
              style={{ stopColor: "#E8E8E8", stopOpacity: "0.1" }}
            />
            <stop offset="1" style={{ stopColor: "#F4F4F4" }} />
          </linearGradient>
          <path
            className="certificate-mobile-123"
            d="M333.91,322.24c-0.55,0.87-1.24,1.67-2.1,2.25c-0.85,0.6-1.84,0.93-2.73,1.45
				c-0.9,0.5-1.67,1.22-2.26,2.06c-0.6,0.83-1.09,1.75-1.34,2.75c-0.27,1-0.21,2.03-0.23,3.07c0,1.04-0.19,2.09-0.64,3.03
				c-0.44,0.94-1.02,1.82-1.75,2.57c-0.73,0.76-1.67,1.27-2.6,1.69c-0.94,0.43-1.84,0.92-2.55,1.66c-0.71,0.72-1.28,1.59-1.71,2.51
				c-0.44,0.92-0.62,1.93-0.62,2.96c-0.01,1.02,0.05,2.08-0.23,3.11c-0.26,1.03-0.77,1.96-1.37,2.81c-0.6,0.86-1.4,1.6-2.32,2.11
				c-0.91,0.53-1.9,0.86-2.72,1.44c-0.84,0.56-1.51,1.33-2.05,2.19c-0.05,0.09-0.17,0.11-0.25,0.06c-0.09-0.05-0.11-0.17-0.06-0.25
				l0,0c0.56-0.88,1.28-1.7,2.16-2.28c0.87-0.61,1.88-0.94,2.75-1.44c0.89-0.48,1.64-1.18,2.24-2c0.6-0.82,1.1-1.73,1.35-2.7
				c0.28-0.98,0.23-2.01,0.25-3.04c0.01-1.04,0.21-2.1,0.67-3.05c0.45-0.94,1.03-1.82,1.77-2.56c0.73-0.76,1.68-1.26,2.62-1.68
				c0.94-0.42,1.85-0.91,2.57-1.64c0.72-0.72,1.3-1.59,1.74-2.51c0.45-0.92,0.65-1.94,0.65-2.97c0.02-1.02-0.03-2.08,0.25-3.09
				c0.26-1.01,0.77-1.93,1.38-2.77c0.6-0.84,1.38-1.56,2.29-2.06c0.9-0.51,1.89-0.84,2.74-1.43
				C332.66,323.9,333.35,323.11,333.91,322.24z"
          />
        </g>
      </g>
    </g>
    <rect
      x="3.42"
      y="23.01"
      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 350.0893 9.7992)"
      className="certificate-mobile-124"
      width="333.45"
      height="313.86"
    />
    <g>
      <g>
        <g>
          <path
            className="certificate-mobile-125"
            d="M110.49,119.22c0-1.69,1.4-2.92,3.28-2.92c0.95,0,1.78,0.3,2.35,0.89l-0.57,0.51
				c-0.48-0.46-1.06-0.68-1.74-0.68c-1.4,0-2.43,0.93-2.43,2.2c0,1.27,1.03,2.2,2.43,2.2c0.68,0,1.26-0.22,1.74-0.69l0.57,0.51
				c-0.57,0.59-1.4,0.9-2.36,0.9C111.88,122.14,110.49,120.91,110.49,119.22z"
          />
          <path
            className="certificate-mobile-125"
            d="M123.63,121.36v0.71h-4.49v-5.71h4.36v0.71h-3.48v1.75h3.1v0.69h-3.1v1.84H123.63z"
          />
          <path
            className="certificate-mobile-125"
            d="M130.96,122.07l-1.33-1.75c-0.12,0.01-0.26,0.02-0.39,0.02h-1.53v1.74h-0.88v-5.71h2.41
				c1.61,0,2.58,0.75,2.58,1.99c0,0.88-0.49,1.52-1.36,1.8l1.47,1.92H130.96z M130.94,118.35c0-0.82-0.59-1.28-1.72-1.28h-1.5v2.57
				h1.5C130.34,119.64,130.94,119.17,130.94,118.35z"
          />
          <path
            className="certificate-mobile-125"
            d="M136.15,117.07h-2.12v-0.71h5.12v0.71h-2.12v5h-0.88V117.07z"
          />
          <path
            className="certificate-mobile-125"
            d="M141.88,116.36h0.88v5.71h-0.88V116.36z"
          />
          <path
            className="certificate-mobile-125"
            d="M147.26,117.07v1.99h3.1v0.7h-3.1v2.31h-0.88v-5.71h4.36v0.71H147.26z"
          />
          <path
            className="certificate-mobile-125"
            d="M153.75,116.36h0.88v5.71h-0.88V116.36z"
          />
          <path
            className="certificate-mobile-125"
            d="M157.75,119.22c0-1.69,1.4-2.92,3.28-2.92c0.95,0,1.78,0.3,2.35,0.89l-0.57,0.51
				c-0.48-0.46-1.06-0.68-1.74-0.68c-1.4,0-2.43,0.93-2.43,2.2c0,1.27,1.03,2.2,2.43,2.2c0.68,0,1.26-0.22,1.74-0.69l0.57,0.51
				c-0.57,0.59-1.4,0.9-2.36,0.9C159.15,122.14,157.75,120.91,157.75,119.22z"
          />
          <path
            className="certificate-mobile-125"
            d="M170.2,120.65h-3.29l-0.68,1.43h-0.91l2.8-5.71H169l2.81,5.71h-0.93L170.2,120.65z M169.89,119.99
				l-1.33-2.8l-1.33,2.8H169.89z"
          />
          <path
            className="certificate-mobile-125"
            d="M180.22,122.07l-0.01-4.16l-2.24,3.47h-0.41l-2.24-3.44v4.14h-0.85v-5.71h0.72l2.58,4.02l2.54-4.02h0.72
				l0.01,5.71H180.22z"
          />
          <path
            className="certificate-mobile-125"
            d="M184.19,119.22c0-1.67,1.4-2.92,3.3-2.92c1.88,0,3.28,1.24,3.28,2.92s-1.4,2.92-3.28,2.92
				C185.58,122.14,184.19,120.89,184.19,119.22z M189.88,119.22c0-1.26-1.02-2.2-2.39-2.2c-1.39,0-2.41,0.93-2.41,2.2
				c0,1.27,1.02,2.2,2.41,2.2C188.85,121.41,189.88,120.48,189.88,119.22z"
          />
          <path
            className="certificate-mobile-125"
            d="M193.31,121.42l0.33-0.64c0.46,0.38,1.25,0.66,2.04,0.66c1.07,0,1.53-0.38,1.53-0.88
				c0-1.4-3.75-0.51-3.75-2.64c0-0.88,0.74-1.63,2.37-1.63c0.72,0,1.48,0.18,1.99,0.51l-0.29,0.65c-0.54-0.32-1.15-0.47-1.7-0.47
				c-1.05,0-1.5,0.41-1.5,0.91c0,1.4,3.75,0.52,3.75,2.62c0,0.87-0.76,1.62-2.39,1.62C194.73,122.14,193.81,121.85,193.31,121.42z"
          />
          <path
            className="certificate-mobile-125"
            d="M211.64,122.6c-0.4,0.43-0.94,0.67-1.56,0.67c-0.78,0-1.39-0.31-2.25-1.13c-1.78-0.09-3.07-1.31-3.07-2.92
				c0-1.67,1.4-2.92,3.3-2.92c1.88,0,3.28,1.24,3.28,2.92c0,1.42-0.99,2.52-2.43,2.83c0.42,0.41,0.79,0.57,1.18,0.57
				c0.44,0,0.81-0.16,1.13-0.49L211.64,122.6z M208.06,121.41c1.37,0,2.39-0.94,2.39-2.2c0-1.26-1.02-2.2-2.39-2.2
				c-1.39,0-2.41,0.94-2.41,2.2C205.65,120.48,206.67,121.41,208.06,121.41z"
          />
          <path
            className="certificate-mobile-125"
            d="M214.4,119.61v-3.25h0.88v3.22c0,1.27,0.63,1.84,1.74,1.84c1.11,0,1.75-0.57,1.75-1.84v-3.22h0.86v3.25
				c0,1.66-0.98,2.53-2.61,2.53C215.39,122.14,214.4,121.27,214.4,119.61z"
          />
          <path
            className="certificate-mobile-125"
            d="M227.7,121.36v0.71h-4.49v-5.71h4.36v0.71h-3.48v1.75h3.1v0.69h-3.1v1.84H227.7z"
          />
        </g>
        <g>
          <path
            className="certificate-mobile-126"
            d="M110.49,119.22c0-1.69,1.4-2.92,3.28-2.92c0.95,0,1.78,0.3,2.35,0.89l-0.57,0.51
				c-0.48-0.46-1.06-0.68-1.74-0.68c-1.4,0-2.43,0.93-2.43,2.2c0,1.27,1.03,2.2,2.43,2.2c0.68,0,1.26-0.22,1.74-0.69l0.57,0.51
				c-0.57,0.59-1.4,0.9-2.36,0.9C111.88,122.14,110.49,120.91,110.49,119.22z"
          />
          <path
            className="certificate-mobile-126"
            d="M123.63,121.36v0.71h-4.49v-5.71h4.36v0.71h-3.48v1.75h3.1v0.69h-3.1v1.84H123.63z"
          />
          <path
            className="certificate-mobile-126"
            d="M130.96,122.07l-1.33-1.75c-0.12,0.01-0.26,0.02-0.39,0.02h-1.53v1.74h-0.88v-5.71h2.41
				c1.61,0,2.58,0.75,2.58,1.99c0,0.88-0.49,1.52-1.36,1.8l1.47,1.92H130.96z M130.94,118.35c0-0.82-0.59-1.28-1.72-1.28h-1.5v2.57
				h1.5C130.34,119.64,130.94,119.17,130.94,118.35z"
          />
          <path
            className="certificate-mobile-126"
            d="M136.15,117.07h-2.12v-0.71h5.12v0.71h-2.12v5h-0.88V117.07z"
          />
          <path
            className="certificate-mobile-126"
            d="M141.88,116.36h0.88v5.71h-0.88V116.36z"
          />
          <path
            className="certificate-mobile-126"
            d="M147.26,117.07v1.99h3.1v0.7h-3.1v2.31h-0.88v-5.71h4.36v0.71H147.26z"
          />
          <path
            className="certificate-mobile-126"
            d="M153.75,116.36h0.88v5.71h-0.88V116.36z"
          />
          <path
            className="certificate-mobile-126"
            d="M157.75,119.22c0-1.69,1.4-2.92,3.28-2.92c0.95,0,1.78,0.3,2.35,0.89l-0.57,0.51
				c-0.48-0.46-1.06-0.68-1.74-0.68c-1.4,0-2.43,0.93-2.43,2.2c0,1.27,1.03,2.2,2.43,2.2c0.68,0,1.26-0.22,1.74-0.69l0.57,0.51
				c-0.57,0.59-1.4,0.9-2.36,0.9C159.15,122.14,157.75,120.91,157.75,119.22z"
          />
          <path
            className="certificate-mobile-126"
            d="M170.2,120.65h-3.29l-0.68,1.43h-0.91l2.8-5.71H169l2.81,5.71h-0.93L170.2,120.65z M169.89,119.99
				l-1.33-2.8l-1.33,2.8H169.89z"
          />
          <path
            className="certificate-mobile-126"
            d="M180.22,122.07l-0.01-4.16l-2.24,3.47h-0.41l-2.24-3.44v4.14h-0.85v-5.71h0.72l2.58,4.02l2.54-4.02h0.72
				l0.01,5.71H180.22z"
          />
          <path
            className="certificate-mobile-126"
            d="M184.19,119.22c0-1.67,1.4-2.92,3.3-2.92c1.88,0,3.28,1.24,3.28,2.92s-1.4,2.92-3.28,2.92
				C185.58,122.14,184.19,120.89,184.19,119.22z M189.88,119.22c0-1.26-1.02-2.2-2.39-2.2c-1.39,0-2.41,0.93-2.41,2.2
				c0,1.27,1.02,2.2,2.41,2.2C188.85,121.41,189.88,120.48,189.88,119.22z"
          />
          <path
            className="certificate-mobile-126"
            d="M193.31,121.42l0.33-0.64c0.46,0.38,1.25,0.66,2.04,0.66c1.07,0,1.53-0.38,1.53-0.88
				c0-1.4-3.75-0.51-3.75-2.64c0-0.88,0.74-1.63,2.37-1.63c0.72,0,1.48,0.18,1.99,0.51l-0.29,0.65c-0.54-0.32-1.15-0.47-1.7-0.47
				c-1.05,0-1.5,0.41-1.5,0.91c0,1.4,3.75,0.52,3.75,2.62c0,0.87-0.76,1.62-2.39,1.62C194.73,122.14,193.81,121.85,193.31,121.42z"
          />
          <path
            className="certificate-mobile-126"
            d="M211.64,122.6c-0.4,0.43-0.94,0.67-1.56,0.67c-0.78,0-1.39-0.31-2.25-1.13c-1.78-0.09-3.07-1.31-3.07-2.92
				c0-1.67,1.4-2.92,3.3-2.92c1.88,0,3.28,1.24,3.28,2.92c0,1.42-0.99,2.52-2.43,2.83c0.42,0.41,0.79,0.57,1.18,0.57
				c0.44,0,0.81-0.16,1.13-0.49L211.64,122.6z M208.06,121.41c1.37,0,2.39-0.94,2.39-2.2c0-1.26-1.02-2.2-2.39-2.2
				c-1.39,0-2.41,0.94-2.41,2.2C205.65,120.48,206.67,121.41,208.06,121.41z"
          />
          <path
            className="certificate-mobile-126"
            d="M214.4,119.61v-3.25h0.88v3.22c0,1.27,0.63,1.84,1.74,1.84c1.11,0,1.75-0.57,1.75-1.84v-3.22h0.86v3.25
				c0,1.66-0.98,2.53-2.61,2.53C215.39,122.14,214.4,121.27,214.4,119.61z"
          />
          <path
            className="certificate-mobile-126"
            d="M227.7,121.36v0.71h-4.49v-5.71h4.36v0.71h-3.48v1.75h3.1v0.69h-3.1v1.84H227.7z"
          />
        </g>
      </g>
    </g>
    <line
      className="certificate-mobile-127"
      x1="53.7"
      y1="168.72"
      x2="286.33"
      y2="168.72"
    />
    <g>
      <g>
        <path
          className="certificate-mobile-128"
          d="M81.49,93.4c0-5.26,4.03-9.06,9.44-9.06c2.87,0,5.33,1.03,6.97,2.94l-2.11,1.99
			c-1.28-1.38-2.87-2.06-4.71-2.06c-3.65,0-6.29,2.57-6.29,6.19s2.64,6.19,6.29,6.19c1.84,0,3.42-0.68,4.71-2.09l2.11,2.01
			c-1.64,1.91-4.1,2.94-7,2.94C85.51,102.46,81.49,98.66,81.49,93.4z"
        />
        <path
          className="certificate-mobile-128"
          d="M114.25,99.46v2.74h-13.21V84.59h12.86v2.74h-9.59v4.58h8.5v2.69h-8.5v4.86H114.25z"
        />
        <path
          className="certificate-mobile-128"
          d="M129.42,102.2l-3.6-5.16c-0.23,0.03-0.45,0.03-0.68,0.03h-3.98v5.13h-3.27V84.59h7.25
			c4.63,0,7.5,2.37,7.5,6.27c0,2.67-1.36,4.63-3.75,5.56l4.05,5.79H129.42z M125,87.36h-3.83v7.02H125c2.87,0,4.35-1.31,4.35-3.52
			C129.35,88.64,127.86,87.36,125,87.36z"
        />
        <path
          className="certificate-mobile-128"
          d="M139.62,87.36h-5.84v-2.77h14.95v2.77h-5.84v14.85h-3.27V87.36z"
        />
        <path
          className="certificate-mobile-128"
          d="M151.19,84.59h3.27v17.61h-3.27V84.59z"
        />
        <path
          className="certificate-mobile-128"
          d="M162.49,87.33v5.36h8.5v2.77h-8.5v6.74h-3.27V84.59h12.86v2.74H162.49z"
        />
        <path
          className="certificate-mobile-128"
          d="M175.25,84.59h3.27v17.61h-3.27V84.59z"
        />
        <path
          className="certificate-mobile-128"
          d="M182.02,93.4c0-5.26,4.03-9.06,9.44-9.06c2.87,0,5.33,1.03,6.97,2.94l-2.11,1.99
			c-1.28-1.38-2.87-2.06-4.71-2.06c-3.65,0-6.29,2.57-6.29,6.19s2.64,6.19,6.29,6.19c1.84,0,3.42-0.68,4.71-2.09l2.11,2.01
			c-1.64,1.91-4.1,2.94-7,2.94C186.04,102.46,182.02,98.66,182.02,93.4z"
        />
        <path
          className="certificate-mobile-128"
          d="M212.62,98.13h-8.81l-1.74,4.08h-3.37l7.93-17.61h3.22l7.95,17.61h-3.42L212.62,98.13z M211.53,95.56
			l-3.32-7.7l-3.3,7.7H211.53z"
        />
        <path
          className="certificate-mobile-128"
          d="M220.04,84.59h7.7c5.71,0,9.61,3.52,9.61,8.81s-3.9,8.81-9.61,8.81h-7.7V84.59z M227.59,99.44
			c3.92,0,6.47-2.39,6.47-6.04s-2.54-6.04-6.47-6.04h-4.28v12.08H227.59z"
        />
        <path
          className="certificate-mobile-128"
          d="M239.57,93.4c0-5.21,4.03-9.06,9.49-9.06s9.49,3.83,9.49,9.06s-4.03,9.06-9.49,9.06
			S239.57,98.61,239.57,93.4z M255.24,93.4c0-3.6-2.64-6.19-6.19-6.19s-6.19,2.59-6.19,6.19c0,3.6,2.64,6.19,6.19,6.19
			S255.24,97,255.24,93.4z"
        />
      </g>
      <g>
        <path
          className="certificate-mobile-129"
          d="M81.49,93.4c0-5.26,4.03-9.06,9.44-9.06c2.87,0,5.33,1.03,6.97,2.94l-2.11,1.99
			c-1.28-1.38-2.87-2.06-4.71-2.06c-3.65,0-6.29,2.57-6.29,6.19s2.64,6.19,6.29,6.19c1.84,0,3.42-0.68,4.71-2.09l2.11,2.01
			c-1.64,1.91-4.1,2.94-7,2.94C85.51,102.46,81.49,98.66,81.49,93.4z"
        />
        <path
          className="certificate-mobile-129"
          d="M114.25,99.46v2.74h-13.21V84.59h12.86v2.74h-9.59v4.58h8.5v2.69h-8.5v4.86H114.25z"
        />
        <path
          className="certificate-mobile-129"
          d="M129.42,102.2l-3.6-5.16c-0.23,0.03-0.45,0.03-0.68,0.03h-3.98v5.13h-3.27V84.59h7.25
			c4.63,0,7.5,2.37,7.5,6.27c0,2.67-1.36,4.63-3.75,5.56l4.05,5.79H129.42z M125,87.36h-3.83v7.02H125c2.87,0,4.35-1.31,4.35-3.52
			C129.35,88.64,127.86,87.36,125,87.36z"
        />
        <path
          className="certificate-mobile-129"
          d="M139.62,87.36h-5.84v-2.77h14.95v2.77h-5.84v14.85h-3.27V87.36z"
        />
        <path
          className="certificate-mobile-129"
          d="M151.19,84.59h3.27v17.61h-3.27V84.59z"
        />
        <path
          className="certificate-mobile-129"
          d="M162.49,87.33v5.36h8.5v2.77h-8.5v6.74h-3.27V84.59h12.86v2.74H162.49z"
        />
        <path
          className="certificate-mobile-129"
          d="M175.25,84.59h3.27v17.61h-3.27V84.59z"
        />
        <path
          className="certificate-mobile-129"
          d="M182.02,93.4c0-5.26,4.03-9.06,9.44-9.06c2.87,0,5.33,1.03,6.97,2.94l-2.11,1.99
			c-1.28-1.38-2.87-2.06-4.71-2.06c-3.65,0-6.29,2.57-6.29,6.19s2.64,6.19,6.29,6.19c1.84,0,3.42-0.68,4.71-2.09l2.11,2.01
			c-1.64,1.91-4.1,2.94-7,2.94C186.04,102.46,182.02,98.66,182.02,93.4z"
        />
        <path
          className="certificate-mobile-129"
          d="M212.62,98.13h-8.81l-1.74,4.08h-3.37l7.93-17.61h3.22l7.95,17.61h-3.42L212.62,98.13z M211.53,95.56
			l-3.32-7.7l-3.3,7.7H211.53z"
        />
        <path
          className="certificate-mobile-129"
          d="M220.04,84.59h7.7c5.71,0,9.61,3.52,9.61,8.81s-3.9,8.81-9.61,8.81h-7.7V84.59z M227.59,99.44
			c3.92,0,6.47-2.39,6.47-6.04s-2.54-6.04-6.47-6.04h-4.28v12.08H227.59z"
        />
        <path
          className="certificate-mobile-129"
          d="M239.57,93.4c0-5.21,4.03-9.06,9.49-9.06s9.49,3.83,9.49,9.06s-4.03,9.06-9.49,9.06
			S239.57,98.61,239.57,93.4z M255.24,93.4c0-3.6-2.64-6.19-6.19-6.19s-6.19,2.59-6.19,6.19c0,3.6,2.64,6.19,6.19,6.19
			S255.24,97,255.24,93.4z"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          className="certificate-mobile-125"
          d="M45.4,186.64c0-1.65,1.26-2.8,2.99-2.8c0.98,0,1.81,0.37,2.27,1.09l-0.58,0.38
			c-0.4-0.56-1.02-0.82-1.69-0.82c-1.27,0-2.2,0.86-2.2,2.15c0,1.3,0.93,2.15,2.2,2.15c0.67,0,1.29-0.25,1.69-0.81l0.58,0.38
			c-0.46,0.71-1.29,1.09-2.27,1.09C46.65,189.45,45.4,188.29,45.4,186.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M51.5,186.64c0-1.64,1.26-2.8,2.96-2.8s2.95,1.17,2.95,2.8s-1.25,2.81-2.95,2.81S51.5,188.28,51.5,186.64z
			 M56.61,186.64c0-1.29-0.92-2.15-2.15-2.15s-2.16,0.86-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15S56.61,187.93,56.61,186.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M68.58,186.2v3.2h-0.78v-3.13c0-1.17-0.62-1.76-1.67-1.76c-1.19,0-1.94,0.73-1.94,1.99v2.9H63.4v-3.13
			c0-1.17-0.62-1.76-1.68-1.76c-1.18,0-1.94,0.73-1.94,1.99v2.9H59v-5.52h0.75v1.01c0.41-0.66,1.16-1.05,2.13-1.05
			c0.96,0,1.71,0.39,2.06,1.17c0.42-0.71,1.25-1.17,2.29-1.17C67.65,183.84,68.58,184.61,68.58,186.2z"
        />
        <path
          className="certificate-mobile-125"
          d="M76.58,186.64c0,1.68-1.24,2.81-2.89,2.81c-0.93,0-1.72-0.38-2.21-1.09v3.08H70.7v-7.56h0.75v1.09
			c0.47-0.73,1.28-1.13,2.24-1.13C75.34,183.84,76.58,184.97,76.58,186.64z M75.8,186.64c0-1.28-0.94-2.15-2.16-2.15
			c-1.24,0-2.16,0.87-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15C74.86,188.79,75.8,187.93,75.8,186.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M78.17,181.61h0.78v7.79h-0.78V181.61z"
        />
        <path
          className="certificate-mobile-125"
          d="M86.19,186.87h-4.85c0.09,1.16,1.01,1.92,2.28,1.92c0.71,0,1.33-0.24,1.78-0.72l0.44,0.48
			c-0.52,0.59-1.32,0.9-2.24,0.9c-1.81,0-3.04-1.18-3.04-2.81s1.2-2.8,2.83-2.8s2.81,1.14,2.81,2.8
			C86.2,186.7,86.19,186.79,86.19,186.87z M81.34,186.32h4.11c-0.1-1.08-0.93-1.84-2.06-1.84
			C82.27,184.48,81.44,185.23,81.34,186.32z"
        />
        <path
          className="certificate-mobile-125"
          d="M90.86,189.07c-0.31,0.26-0.77,0.39-1.22,0.39c-1.09,0-1.7-0.59-1.7-1.61v-3.34h-1.04v-0.63h1.04v-1.21
			h0.78v1.21h1.77v0.63h-1.77v3.3c0,0.65,0.35,1.01,1,1.01c0.32,0,0.64-0.09,0.86-0.28L90.86,189.07z"
        />
        <path
          className="certificate-mobile-125"
          d="M91.48,186.64c0-1.64,1.26-2.8,2.96-2.8c1.7,0,2.95,1.17,2.95,2.8s-1.25,2.81-2.95,2.81
			C92.74,189.45,91.48,188.28,91.48,186.64z M96.59,186.64c0-1.29-0.92-2.15-2.15-2.15s-2.16,0.86-2.16,2.15
			c0,1.29,0.93,2.15,2.16,2.15S96.59,187.93,96.59,186.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M104.21,183.88v5.52h-0.75v-1.01c-0.41,0.67-1.16,1.06-2.08,1.06c-1.48,0-2.45-0.79-2.45-2.37v-3.2h0.78
			v3.13c0,1.18,0.64,1.77,1.74,1.77c1.21,0,1.97-0.75,1.97-2.01v-2.9H104.21z"
        />
        <path
          className="certificate-mobile-125"
          d="M112.34,189.07c-0.31,0.26-0.77,0.39-1.22,0.39c-1.09,0-1.7-0.59-1.7-1.61v-3.34h-1.04v-0.63h1.04v-1.21
			h0.78v1.21h1.77v0.63h-1.77v3.3c0,0.65,0.35,1.01,1,1.01c0.32,0,0.64-0.09,0.86-0.28L112.34,189.07z"
        />
        <path
          className="certificate-mobile-125"
          d="M112.97,186.64c0-1.64,1.26-2.8,2.96-2.8c1.7,0,2.95,1.17,2.95,2.8s-1.25,2.81-2.95,2.81
			C114.23,189.45,112.97,188.28,112.97,186.64z M118.08,186.64c0-1.29-0.92-2.15-2.15-2.15s-2.16,0.86-2.16,2.15
			c0,1.29,0.93,2.15,2.16,2.15S118.08,187.93,118.08,186.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M125.76,181.61v7.79h-0.75v-1.09c-0.47,0.75-1.28,1.14-2.24,1.14c-1.65,0-2.89-1.14-2.89-2.81
			c0-1.67,1.24-2.8,2.89-2.8c0.93,0,1.72,0.38,2.21,1.09v-3.32H125.76z M124.99,186.64c0-1.29-0.93-2.15-2.15-2.15
			c-1.24,0-2.16,0.86-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15C124.06,188.79,124.99,187.93,124.99,186.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M132.32,185.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C131.47,183.84,132.32,184.55,132.32,185.98z M131.54,187.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C130.58,188.87,131.23,188.47,131.54,187.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M133.64,188.77l0.35-0.59c0.46,0.35,1.25,0.62,2.06,0.62c1.1,0,1.56-0.36,1.56-0.9
			c0-1.44-3.76-0.3-3.76-2.49c0-0.9,0.82-1.57,2.29-1.57c0.75,0,1.57,0.2,2.05,0.51l-0.34,0.6c-0.51-0.34-1.11-0.47-1.71-0.47
			c-1.05,0-1.51,0.39-1.51,0.91c0,1.49,3.76,0.37,3.76,2.49c0,0.96-0.88,1.58-2.39,1.58C135.04,189.45,134.11,189.16,133.64,188.77z
			"
        />
        <path
          className="certificate-mobile-125"
          d="M147.11,185.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C146.26,183.84,147.11,184.55,147.11,185.98z M146.32,187.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C145.36,188.87,146.01,188.47,146.32,187.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M148.43,188.77l0.35-0.59c0.46,0.35,1.25,0.62,2.06,0.62c1.1,0,1.56-0.36,1.56-0.9
			c0-1.44-3.76-0.3-3.76-2.49c0-0.9,0.82-1.57,2.29-1.57c0.75,0,1.57,0.2,2.05,0.51l-0.34,0.6c-0.51-0.34-1.11-0.47-1.71-0.47
			c-1.05,0-1.51,0.39-1.51,0.91c0,1.49,3.76,0.37,3.76,2.49c0,0.96-0.88,1.58-2.39,1.58C149.83,189.45,148.9,189.16,148.43,188.77z"
        />
        <path
          className="certificate-mobile-125"
          d="M162.56,186.87h-4.85c0.09,1.16,1.01,1.92,2.28,1.92c0.71,0,1.33-0.24,1.78-0.72l0.44,0.48
			c-0.52,0.59-1.32,0.9-2.24,0.9c-1.81,0-3.04-1.18-3.04-2.81s1.2-2.8,2.83-2.8s2.81,1.14,2.81,2.8
			C162.57,186.7,162.56,186.79,162.56,186.87z M157.71,186.32h4.11c-0.1-1.08-0.93-1.84-2.06-1.84
			C158.64,184.48,157.81,185.23,157.71,186.32z"
        />
        <path
          className="certificate-mobile-125"
          d="M167.23,189.07c-0.31,0.26-0.77,0.39-1.22,0.39c-1.09,0-1.7-0.59-1.7-1.61v-3.34h-1.04v-0.63h1.04v-1.21
			h0.78v1.21h1.77v0.63h-1.77v3.3c0,0.65,0.35,1.01,1,1.01c0.32,0,0.64-0.09,0.86-0.28L167.23,189.07z"
        />
        <path
          className="certificate-mobile-125"
          d="M173.03,185.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C172.18,183.84,173.03,184.55,173.03,185.98z M172.24,187.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C171.28,188.87,171.93,188.47,172.24,187.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M181.03,186.64c0,1.68-1.24,2.81-2.89,2.81c-0.93,0-1.72-0.38-2.21-1.09v3.08h-0.78v-7.56h0.75v1.09
			c0.47-0.73,1.28-1.13,2.24-1.13C179.79,183.84,181.03,184.97,181.03,186.64z M180.25,186.64c0-1.28-0.94-2.15-2.16-2.15
			c-1.24,0-2.16,0.87-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15C179.31,188.79,180.25,187.93,180.25,186.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M186.97,185.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C186.12,183.84,186.97,184.55,186.97,185.98z M186.19,187.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C185.23,188.87,185.88,188.47,186.19,187.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M188.29,188.77l0.35-0.59c0.46,0.35,1.25,0.62,2.06,0.62c1.1,0,1.56-0.36,1.56-0.9
			c0-1.44-3.76-0.3-3.76-2.49c0-0.9,0.82-1.57,2.29-1.57c0.75,0,1.57,0.2,2.05,0.51l-0.34,0.6c-0.51-0.34-1.11-0.47-1.71-0.47
			c-1.05,0-1.51,0.39-1.51,0.91c0,1.49,3.76,0.37,3.76,2.49c0,0.96-0.88,1.58-2.39,1.58C189.69,189.45,188.77,189.16,188.29,188.77z
			"
        />
        <path
          className="certificate-mobile-125"
          d="M202.67,181.61v7.79h-0.75v-1.09c-0.47,0.75-1.28,1.14-2.24,1.14c-1.65,0-2.89-1.14-2.89-2.81
			c0-1.67,1.24-2.8,2.89-2.8c0.93,0,1.72,0.38,2.21,1.09v-3.32H202.67z M201.9,186.64c0-1.29-0.93-2.15-2.15-2.15
			c-1.24,0-2.16,0.86-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15C200.97,188.79,201.9,187.93,201.9,186.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M204.27,186.64c0-1.64,1.26-2.8,2.96-2.8c1.7,0,2.95,1.17,2.95,2.8s-1.25,2.81-2.95,2.81
			C205.53,189.45,204.27,188.28,204.27,186.64z M209.37,186.64c0-1.29-0.92-2.15-2.15-2.15s-2.16,0.86-2.16,2.15
			c0,1.29,0.93,2.15,2.16,2.15S209.37,187.93,209.37,186.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M214.82,182.05h0.82v6.68h4.32v0.67h-5.14V182.05z"
        />
        <path
          className="certificate-mobile-125"
          d="M226.18,187.44h-4.3l-0.93,1.96h-0.85l3.53-7.35h0.8l3.53,7.35h-0.86L226.18,187.44z M225.88,186.81
			l-1.85-3.95l-1.85,3.95H225.88z"
        />
        <path
          className="certificate-mobile-125"
          d="M235.69,187.44c0,1.25-0.97,1.96-2.9,1.96h-3.55v-7.35h3.33c1.72,0,2.71,0.69,2.71,1.89
			c0,0.84-0.47,1.4-1.18,1.67C235.1,185.82,235.69,186.44,235.69,187.44z M230.06,182.69v2.67h2.46c1.24,0,1.95-0.45,1.95-1.33
			s-0.72-1.33-1.95-1.33H230.06z M234.88,187.38c0-0.96-0.74-1.38-2.1-1.38h-2.72v2.76h2.72
			C234.14,188.76,234.88,188.34,234.88,187.38z"
        />
        <path
          className="certificate-mobile-125"
          d="M237.55,182.05h0.82v7.35h-0.82V182.05z"
        />
        <path
          className="certificate-mobile-125"
          d="M246.15,189.4l-1.77-2.36c-0.2,0.02-0.4,0.03-0.62,0.03h-2.07v2.33h-0.82v-7.35h2.89
			c1.96,0,3.16,0.94,3.16,2.52c0,1.15-0.64,1.96-1.77,2.31l1.89,2.52H246.15z M246.11,184.57c0-1.18-0.82-1.85-2.36-1.85h-2.05v3.68
			h2.05C245.29,186.41,246.11,185.73,246.11,184.57z"
        />
        <path
          className="certificate-mobile-125"
          d="M248.85,182.05h0.82v7.35h-0.82V182.05z"
        />
        <path
          className="certificate-mobile-125"
          d="M258.63,182.05v7.35h-0.67l-4.96-5.96v5.96h-0.82v-7.35h0.67l4.98,5.96v-5.96H258.63z"
        />
        <path
          className="certificate-mobile-125"
          d="M262.64,182.73h-2.71v-0.67h6.24v0.67h-2.71v6.68h-0.82V182.73z"
        />
        <path
          className="certificate-mobile-125"
          d="M266.68,185.73c0-2.14,1.72-3.74,4.06-3.74c2.32,0,4.05,1.59,4.05,3.74s-1.73,3.74-4.05,3.74
			C268.4,189.47,266.68,187.87,266.68,185.73z M273.97,185.73c0-1.75-1.38-3.04-3.23-3.04c-1.86,0-3.25,1.29-3.25,3.04
			s1.39,3.04,3.25,3.04C272.59,188.77,273.97,187.48,273.97,185.73z"
        />
        <path
          className="certificate-mobile-125"
          d="M279.5,182.05h3.14c2.47,0,4.14,1.51,4.14,3.67s-1.67,3.67-4.14,3.67h-3.14V182.05z M282.6,188.73
			c2.05,0,3.38-1.24,3.38-3s-1.32-3-3.38-3h-2.28v6H282.6z"
        />
        <path
          className="certificate-mobile-125"
          d="M293.34,187.44h-4.3l-0.93,1.96h-0.85l3.53-7.35h0.8l3.53,7.35h-0.86L293.34,187.44z M293.04,186.81
			l-1.85-3.95l-1.85,3.95H293.04z"
        />
        <path
          className="certificate-mobile-125"
          d="M67.89,201.73v0.67h-5.46v-7.35h5.29v0.67h-4.48v2.61h3.99V199h-3.99v2.73H67.89z"
        />
        <path
          className="certificate-mobile-125"
          d="M77.83,195.05v7.35h-0.78v-5.88l-3.03,4.94h-0.39l-3.03-4.91v5.85h-0.78v-7.35h0.67l3.35,5.45l3.32-5.45
			H77.83z"
        />
        <path
          className="certificate-mobile-125"
          d="M86.38,197.57c0,1.56-1.19,2.51-3.16,2.51h-2.07v2.32h-0.82v-7.35h2.89
			C85.19,195.05,86.38,196,86.38,197.57z M85.56,197.57c0-1.18-0.82-1.85-2.36-1.85h-2.05v3.67h2.05
			C84.75,199.4,85.56,198.73,85.56,197.57z"
        />
        <path
          className="certificate-mobile-125"
          d="M92.6,200.44h-4.3l-0.93,1.96h-0.85l3.53-7.35h0.8l3.53,7.35h-0.86L92.6,200.44z M92.3,199.81l-1.85-3.95
			l-1.85,3.95H92.3z"
        />
        <path
          className="certificate-mobile-125"
          d="M96.77,195.73h-2.71v-0.67h6.24v0.67h-2.71v6.68h-0.82V195.73z"
        />
        <path
          className="certificate-mobile-125"
          d="M101.6,195.05h0.82v7.35h-0.82V195.05z"
        />
        <path
          className="certificate-mobile-125"
          d="M109.77,200.44h-4.3l-0.93,1.96h-0.85l3.53-7.35h0.8l3.53,7.35h-0.86L109.77,200.44z M109.48,199.81
			l-1.85-3.95l-1.85,3.95H109.48z"
        />
        <path
          className="certificate-mobile-125"
          d="M120.61,199.87h-4.85c0.09,1.16,1.01,1.92,2.28,1.92c0.71,0,1.33-0.24,1.78-0.72l0.44,0.48
			c-0.52,0.59-1.32,0.9-2.24,0.9c-1.81,0-3.04-1.18-3.04-2.81s1.2-2.8,2.83-2.8s2.81,1.14,2.81,2.8
			C120.62,199.7,120.61,199.79,120.61,199.87z M115.76,199.32h4.11c-0.1-1.08-0.93-1.84-2.06-1.84
			C116.69,197.48,115.86,198.23,115.76,199.32z"
        />
        <path
          className="certificate-mobile-125"
          d="M130.16,199.87h-4.85c0.09,1.16,1.01,1.92,2.28,1.92c0.71,0,1.33-0.24,1.78-0.72l0.44,0.48
			c-0.52,0.59-1.32,0.9-2.24,0.9c-1.81,0-3.04-1.18-3.04-2.81s1.2-2.8,2.83-2.8s2.81,1.14,2.81,2.8
			C130.17,199.7,130.16,199.79,130.16,199.87z M125.31,199.32h4.11c-0.1-1.08-0.93-1.84-2.06-1.84
			C126.23,197.48,125.41,198.23,125.31,199.32z"
        />
        <path
          className="certificate-mobile-125"
          d="M130.96,201.77l0.35-0.59c0.46,0.35,1.25,0.62,2.06,0.62c1.1,0,1.56-0.36,1.56-0.9
			c0-1.44-3.76-0.3-3.76-2.49c0-0.9,0.82-1.57,2.29-1.57c0.75,0,1.57,0.2,2.05,0.51l-0.34,0.6c-0.51-0.34-1.11-0.47-1.71-0.47
			c-1.05,0-1.51,0.39-1.51,0.91c0,1.49,3.76,0.37,3.76,2.49c0,0.96-0.88,1.58-2.39,1.58C132.36,202.45,131.44,202.16,130.96,201.77z
			"
        />
        <path
          className="certificate-mobile-125"
          d="M140.21,202.07c-0.31,0.26-0.77,0.39-1.22,0.39c-1.09,0-1.7-0.59-1.7-1.61v-3.34h-1.04v-0.63h1.04v-1.21
			h0.78v1.21h1.77v0.63h-1.77v3.3c0,0.65,0.35,1.01,1,1.01c0.32,0,0.64-0.09,0.86-0.28L140.21,202.07z"
        />
        <path
          className="certificate-mobile-125"
          d="M146.01,198.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C145.16,196.84,146.01,197.55,146.01,198.98z M145.23,200.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C144.27,201.87,144.92,201.47,145.23,200.74z M143.74,196.03h-0.77
			l1.51-1.3h1.05L143.74,196.03z"
        />
        <path
          className="certificate-mobile-125"
          d="M156.37,199.2v3.2h-0.78v-3.13c0-1.17-0.64-1.76-1.74-1.76c-1.25,0-2.03,0.73-2.03,1.99v2.9h-0.78v-7.79
			h0.78v3.23c0.43-0.64,1.19-1.01,2.17-1.01C155.4,196.84,156.37,197.61,156.37,199.2z"
        />
        <path
          className="certificate-mobile-125"
          d="M162.87,198.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C162.02,196.84,162.87,197.55,162.87,198.98z M162.09,200.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C161.13,201.87,161.78,201.47,162.09,200.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M170.88,199.64c0,1.67-1.24,2.81-2.89,2.81c-0.96,0-1.77-0.4-2.24-1.14v1.09H165v-7.79h0.78v3.32
			c0.49-0.71,1.28-1.09,2.21-1.09C169.64,196.84,170.88,197.97,170.88,199.64z M170.09,199.64c0-1.29-0.94-2.15-2.16-2.15
			c-1.24,0-2.16,0.86-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15C169.16,201.79,170.09,200.93,170.09,199.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M172.29,195.14c0-0.28,0.25-0.52,0.57-0.52s0.57,0.23,0.57,0.51c0,0.3-0.24,0.55-0.57,0.55
			C172.55,195.67,172.29,195.43,172.29,195.14z M172.47,196.88h0.78v5.52h-0.78V196.88z"
        />
        <path
          className="certificate-mobile-125"
          d="M175.44,194.61h0.78v7.79h-0.78V194.61z"
        />
        <path
          className="certificate-mobile-125"
          d="M178.23,195.14c0-0.28,0.25-0.52,0.57-0.52s0.57,0.23,0.57,0.51c0,0.3-0.24,0.55-0.57,0.55
			C178.48,195.67,178.23,195.43,178.23,195.14z M178.4,196.88h0.78v5.52h-0.78V196.88z"
        />
        <path
          className="certificate-mobile-125"
          d="M184.42,202.07c-0.31,0.26-0.77,0.39-1.22,0.39c-1.09,0-1.7-0.59-1.7-1.61v-3.34h-1.04v-0.63h1.04v-1.21
			h0.78v1.21h1.77v0.63h-1.77v3.3c0,0.65,0.35,1.01,1,1.01c0.32,0,0.64-0.09,0.86-0.28L184.42,202.07z"
        />
        <path
          className="certificate-mobile-125"
          d="M190.22,198.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C189.37,196.84,190.22,197.55,190.22,198.98z M189.44,200.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C188.48,201.87,189.13,201.47,189.44,200.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M197.67,194.61v7.79h-0.75v-1.09c-0.47,0.75-1.28,1.14-2.24,1.14c-1.65,0-2.89-1.14-2.89-2.81
			c0-1.67,1.24-2.8,2.89-2.8c0.93,0,1.72,0.38,2.21,1.09v-3.32H197.67z M196.89,199.64c0-1.29-0.93-2.15-2.15-2.15
			c-1.24,0-2.16,0.86-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15C195.97,201.79,196.89,200.93,196.89,199.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M199.26,199.64c0-1.64,1.26-2.8,2.96-2.8c1.7,0,2.95,1.17,2.95,2.8s-1.25,2.81-2.95,2.81
			C200.52,202.45,199.26,201.28,199.26,199.64z M204.37,199.64c0-1.29-0.92-2.15-2.15-2.15s-2.16,0.86-2.16,2.15
			c0,1.29,0.93,2.15,2.16,2.15S204.37,200.93,204.37,199.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M206.81,199.53c0-1.93,0.46-3.66,1.28-4.91h0.75c-0.87,1.44-1.26,3.04-1.26,4.91s0.39,3.48,1.26,4.91h-0.75
			C207.28,203.17,206.81,201.46,206.81,199.53z"
        />
        <path
          className="certificate-mobile-125"
          d="M214.77,198.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C213.92,196.84,214.77,197.55,214.77,198.98z M213.98,200.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C213.02,201.87,213.67,201.47,213.98,200.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M216.24,204.44c0.88-1.44,1.27-3.04,1.27-4.91s-0.39-3.48-1.27-4.91h0.75c0.83,1.25,1.29,2.98,1.29,4.91
			s-0.46,3.64-1.29,4.91H216.24z"
        />
        <path
          className="certificate-mobile-125"
          d="M227.79,198.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56H227v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C226.94,196.84,227.79,197.55,227.79,198.98z M227,200.74v-0.9h-1.82c-1.12,0-1.49,0.42-1.49,0.99
			c0,0.64,0.54,1.04,1.47,1.04C226.04,201.87,226.69,201.47,227,200.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M238.03,196.88v5.52h-0.75v-1.01c-0.41,0.67-1.16,1.06-2.08,1.06c-1.48,0-2.45-0.79-2.45-2.37v-3.2h0.78
			v3.13c0,1.18,0.64,1.77,1.74,1.77c1.21,0,1.97-0.75,1.97-2.01v-2.9H238.03z"
        />
        <path
          className="certificate-mobile-125"
          d="M243.28,202.07c-0.31,0.26-0.77,0.39-1.22,0.39c-1.09,0-1.7-0.59-1.7-1.61v-3.34h-1.04v-0.63h1.04v-1.21
			h0.78v1.21h1.77v0.63h-1.77v3.3c0,0.65,0.35,1.01,1,1.01c0.32,0,0.64-0.09,0.86-0.28L243.28,202.07z"
        />
        <path
          className="certificate-mobile-125"
          d="M244.52,195.14c0-0.28,0.25-0.52,0.57-0.52s0.57,0.23,0.57,0.51c0,0.3-0.24,0.55-0.57,0.55
			C244.77,195.67,244.52,195.43,244.52,195.14z M244.7,196.88h0.78v5.52h-0.78V196.88z"
        />
        <path
          className="certificate-mobile-125"
          d="M247.66,194.61h0.78v7.79h-0.78V194.61z"
        />
        <path
          className="certificate-mobile-125"
          d="M250.45,195.14c0-0.28,0.25-0.52,0.57-0.52s0.57,0.23,0.57,0.51c0,0.3-0.24,0.55-0.57,0.55
			C250.7,195.67,250.45,195.43,250.45,195.14z M250.63,196.88h0.78v5.52h-0.78V196.88z"
        />
        <path
          className="certificate-mobile-125"
          d="M257.69,201.78v0.62h-4.72v-0.49l3.65-4.4h-3.59v-0.63h4.59v0.49l-3.65,4.41H257.69z"
        />
        <path
          className="certificate-mobile-125"
          d="M263.61,198.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C262.76,196.84,263.61,197.55,263.61,198.98z M262.82,200.74v-0.9H261c-1.12,0-1.49,0.42-1.49,0.99
			c0,0.64,0.54,1.04,1.47,1.04C261.86,201.87,262.51,201.47,262.82,200.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M268.66,196.84v0.72c-0.07,0-0.13-0.01-0.19-0.01c-1.21,0-1.95,0.73-1.95,2.04v2.81h-0.78v-5.52h0.75v1.08
			C266.85,197.23,267.58,196.84,268.66,196.84z"
        />
        <path
          className="certificate-mobile-125"
          d="M272.46,199.64c0-1.64,1.26-2.8,2.96-2.8c1.7,0,2.95,1.17,2.95,2.8s-1.25,2.81-2.95,2.81
			C273.71,202.45,272.46,201.28,272.46,199.64z M277.56,199.64c0-1.29-0.92-2.15-2.15-2.15s-2.16,0.86-2.16,2.15
			c0,1.29,0.93,2.15,2.16,2.15S277.56,200.93,277.56,199.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M58.13,208.14c0-0.28,0.25-0.52,0.57-0.52s0.57,0.23,0.57,0.51c0,0.3-0.24,0.55-0.57,0.55
			C58.38,208.67,58.13,208.43,58.13,208.14z M58.31,209.88h0.78v5.52h-0.78V209.88z"
        />
        <path
          className="certificate-mobile-125"
          d="M66.57,207.61v7.79h-0.75v-1.09c-0.47,0.75-1.28,1.14-2.24,1.14c-1.65,0-2.89-1.14-2.89-2.81
			c0-1.67,1.24-2.8,2.89-2.8c0.93,0,1.72,0.38,2.21,1.09v-3.32H66.57z M65.8,212.64c0-1.29-0.93-2.15-2.15-2.15
			c-1.24,0-2.16,0.86-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15C64.87,214.79,65.8,213.93,65.8,212.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M68.57,208.14c0-0.28,0.25-0.52,0.57-0.52s0.57,0.23,0.57,0.51c0,0.3-0.24,0.55-0.57,0.55
			C68.82,208.67,68.57,208.43,68.57,208.14z M68.75,209.88h0.78v5.52h-0.78V209.88z"
        />
        <path
          className="certificate-mobile-125"
          d="M71.13,212.64c0-1.64,1.26-2.8,2.96-2.8c1.7,0,2.95,1.17,2.95,2.8s-1.25,2.81-2.95,2.81
			C72.39,215.45,71.13,214.28,71.13,212.64z M76.24,212.64c0-1.29-0.92-2.15-2.15-2.15s-2.16,0.86-2.16,2.15
			c0,1.29,0.93,2.15,2.16,2.15S76.24,213.93,76.24,212.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M88.2,212.2v3.2h-0.78v-3.13c0-1.17-0.62-1.76-1.67-1.76c-1.19,0-1.94,0.73-1.94,1.99v2.9h-0.78v-3.13
			c0-1.17-0.62-1.76-1.68-1.76c-1.18,0-1.94,0.73-1.94,1.99v2.9h-0.78v-5.52h0.75v1.01c0.41-0.66,1.16-1.05,2.13-1.05
			c0.96,0,1.71,0.39,2.06,1.17c0.42-0.71,1.25-1.17,2.29-1.17C87.28,209.84,88.2,210.61,88.2,212.2z"
        />
        <path
          className="certificate-mobile-125"
          d="M94.7,211.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C93.85,209.84,94.7,210.55,94.7,211.98z M93.92,213.74v-0.9H92.1c-1.12,0-1.49,0.42-1.49,0.99
			c0,0.64,0.54,1.04,1.47,1.04C92.96,214.87,93.61,214.47,93.92,213.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M105.32,211.73h0.78v2.8c-0.73,0.62-1.76,0.93-2.83,0.93c-2.35,0-4.07-1.59-4.07-3.74s1.72-3.74,4.08-3.74
			c1.17,0,2.18,0.36,2.87,1.06l-0.51,0.49c-0.65-0.61-1.42-0.86-2.33-0.86c-1.91,0-3.31,1.29-3.31,3.04c0,1.74,1.4,3.04,3.3,3.04
			c0.74,0,1.42-0.16,2.02-0.57V211.73z"
        />
        <path
          className="certificate-mobile-125"
          d="M108.41,208.05h0.82v7.35h-0.82V208.05z"
        />
        <path
          className="certificate-mobile-125"
          d="M117.01,215.4l-1.77-2.36c-0.2,0.02-0.4,0.03-0.62,0.03h-2.07v2.33h-0.82v-7.35h2.89
			c1.96,0,3.16,0.94,3.16,2.52c0,1.15-0.64,1.96-1.77,2.31l1.89,2.52H117.01z M116.96,210.57c0-1.18-0.82-1.85-2.36-1.85h-2.05v3.68
			h2.05C116.15,212.41,116.96,211.73,116.96,210.57z"
        />
        <path
          className="certificate-mobile-125"
          d="M124.55,213.44h-4.3l-0.93,1.96h-0.85l3.53-7.35h0.8l3.53,7.35h-0.86L124.55,213.44z M124.25,212.81
			l-1.85-3.95l-1.85,3.95H124.25z"
        />
        <path
          className="certificate-mobile-125"
          d="M128.43,208.73v2.89h3.99v0.67h-3.99v3.12h-0.82v-7.35h5.29v0.67H128.43z"
        />
        <path
          className="certificate-mobile-125"
          d="M140.05,214.73v0.67h-5.46v-7.35h5.29v0.67h-4.48v2.61h3.99V212h-3.99v2.73H140.05z M136.16,207.45h-0.73
			l1.42-1.3h0.79l1.42,1.3h-0.73l-1.09-0.87L136.16,207.45z"
        />
        <path
          className="certificate-mobile-125"
          d="M141.23,214.51l0.32-0.6c0.55,0.51,1.53,0.89,2.55,0.89c1.45,0,2.07-0.58,2.07-1.3
			c0-2.02-4.75-0.78-4.75-3.5c0-1.08,0.88-2.02,2.85-2.02c0.87,0,1.78,0.24,2.39,0.65l-0.28,0.62c-0.66-0.41-1.43-0.61-2.12-0.61
			c-1.41,0-2.04,0.6-2.04,1.33c0,2.02,4.75,0.8,4.75,3.47c0,1.08-0.91,2.01-2.88,2.01C142.94,215.47,141.8,215.08,141.23,214.51z"
        />
        <path
          className="certificate-mobile-125"
          d="M156.81,212.2v3.2h-0.78v-3.13c0-1.17-0.64-1.76-1.74-1.76c-1.25,0-2.03,0.73-2.03,1.99v2.9h-0.78v-5.52
			h0.75v1.02c0.42-0.67,1.2-1.06,2.21-1.06C155.84,209.84,156.81,210.61,156.81,212.2z"
        />
        <path
          className="certificate-mobile-125"
          d="M163.31,211.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C162.46,209.84,163.31,210.55,163.31,211.98z M162.53,213.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C161.57,214.87,162.22,214.47,162.53,213.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M164.63,214.77l0.35-0.59c0.46,0.35,1.25,0.62,2.06,0.62c1.1,0,1.56-0.36,1.56-0.9
			c0-1.44-3.76-0.3-3.76-2.49c0-0.9,0.82-1.57,2.29-1.57c0.75,0,1.57,0.2,2.05,0.51l-0.34,0.6c-0.51-0.34-1.11-0.47-1.71-0.47
			c-1.05,0-1.51,0.39-1.51,0.91c0,1.49,3.76,0.37,3.76,2.49c0,0.96-0.88,1.58-2.39,1.58C166.03,215.45,165.11,215.16,164.63,214.77z
			"
        />
        <path
          className="certificate-mobile-125"
          d="M179.01,207.61v7.79h-0.75v-1.09c-0.47,0.75-1.28,1.14-2.24,1.14c-1.65,0-2.89-1.14-2.89-2.81
			c0-1.67,1.24-2.8,2.89-2.8c0.93,0,1.72,0.38,2.21,1.09v-3.32H179.01z M178.24,212.64c0-1.29-0.93-2.15-2.15-2.15
			c-1.24,0-2.16,0.86-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15C177.31,214.79,178.24,213.93,178.24,212.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M181.02,208.14c0-0.28,0.25-0.52,0.57-0.52s0.57,0.23,0.57,0.51c0,0.3-0.24,0.55-0.57,0.55
			C181.27,208.67,181.02,208.43,181.02,208.14z M181.19,209.88h0.78v5.52h-0.78V209.88z"
        />
        <path
          className="certificate-mobile-125"
          d="M185.05,209.23v0.65h1.79v0.63h-1.77v4.89h-0.78v-4.89h-1.04v-0.63h1.04v-0.68c0-0.99,0.62-1.64,1.75-1.64
			c0.43,0,0.87,0.12,1.16,0.35l-0.26,0.56c-0.22-0.18-0.53-0.27-0.86-0.27C185.4,208.19,185.05,208.55,185.05,209.23z"
        />
        <path
          className="certificate-mobile-125"
          d="M192.85,212.87h-4.85c0.09,1.16,1.01,1.92,2.28,1.92c0.71,0,1.33-0.24,1.78-0.72l0.44,0.48
			c-0.52,0.59-1.32,0.9-2.24,0.9c-1.81,0-3.04-1.18-3.04-2.81s1.2-2.8,2.83-2.8s2.81,1.14,2.81,2.8
			C192.86,212.7,192.85,212.79,192.85,212.87z M187.99,212.32h4.11c-0.1-1.08-0.93-1.84-2.06-1.84
			C188.92,210.48,188.09,211.23,187.99,212.32z"
        />
        <path
          className="certificate-mobile-125"
          d="M197.38,209.84v0.72c-0.07,0-0.13-0.01-0.19-0.01c-1.21,0-1.95,0.73-1.95,2.04v2.81h-0.78v-5.52h0.75v1.08
			C195.57,210.23,196.31,209.84,197.38,209.84z"
        />
        <path
          className="certificate-mobile-125"
          d="M203.78,212.87h-4.85c0.09,1.16,1.01,1.92,2.28,1.92c0.71,0,1.33-0.24,1.78-0.72l0.44,0.48
			c-0.52,0.59-1.32,0.9-2.24,0.9c-1.81,0-3.04-1.18-3.04-2.81s1.2-2.8,2.83-2.8s2.81,1.14,2.81,2.8
			C203.8,212.7,203.78,212.79,203.78,212.87z M198.93,212.32h4.11c-0.1-1.08-0.93-1.84-2.06-1.84
			C199.86,210.48,199.03,211.23,198.93,212.32z"
        />
        <path
          className="certificate-mobile-125"
          d="M210.73,212.2v3.2h-0.78v-3.13c0-1.17-0.64-1.76-1.74-1.76c-1.25,0-2.03,0.73-2.03,1.99v2.9h-0.78v-5.52
			h0.75v1.02c0.42-0.67,1.2-1.06,2.21-1.06C209.76,209.84,210.73,210.61,210.73,212.2z"
        />
        <path
          className="certificate-mobile-125"
          d="M215.91,215.07c-0.31,0.26-0.77,0.39-1.22,0.39c-1.09,0-1.7-0.59-1.7-1.61v-3.34h-1.04v-0.63h1.04v-1.21
			h0.78v1.21h1.77v0.63h-1.77v3.3c0,0.65,0.35,1.01,1,1.01c0.32,0,0.64-0.09,0.86-0.28L215.91,215.07z"
        />
        <path
          className="certificate-mobile-125"
          d="M222.17,212.87h-4.85c0.09,1.16,1.01,1.92,2.28,1.92c0.71,0,1.33-0.24,1.78-0.72l0.44,0.48
			c-0.52,0.59-1.32,0.9-2.24,0.9c-1.81,0-3.04-1.18-3.04-2.81s1.2-2.8,2.83-2.8s2.81,1.14,2.81,2.8
			C222.19,212.7,222.17,212.79,222.17,212.87z M217.32,212.32h4.11c-0.1-1.08-0.93-1.84-2.06-1.84
			C218.25,210.48,217.42,211.23,217.32,212.32z"
        />
        <path
          className="certificate-mobile-125"
          d="M222.98,214.77l0.35-0.59c0.46,0.35,1.25,0.62,2.06,0.62c1.1,0,1.56-0.36,1.56-0.9
			c0-1.44-3.76-0.3-3.76-2.49c0-0.9,0.82-1.57,2.29-1.57c0.75,0,1.57,0.2,2.05,0.51l-0.34,0.6c-0.51-0.34-1.11-0.47-1.71-0.47
			c-1.05,0-1.51,0.39-1.51,0.91c0,1.49,3.76,0.37,3.76,2.49c0,0.96-0.88,1.58-2.39,1.58C224.38,215.45,223.45,215.16,222.98,214.77z
			"
        />
        <path
          className="certificate-mobile-125"
          d="M231.26,214.77l0.35-0.59c0.46,0.35,1.25,0.62,2.06,0.62c1.1,0,1.56-0.36,1.56-0.9
			c0-1.44-3.76-0.3-3.76-2.49c0-0.9,0.82-1.57,2.29-1.57c0.75,0,1.57,0.2,2.05,0.51l-0.34,0.6c-0.51-0.34-1.11-0.47-1.71-0.47
			c-1.05,0-1.51,0.39-1.51,0.91c0,1.49,3.76,0.37,3.76,2.49c0,0.96-0.88,1.58-2.39,1.58C232.66,215.45,231.73,215.16,231.26,214.77z
			"
        />
        <path
          className="certificate-mobile-125"
          d="M237.28,208.14c0-0.28,0.25-0.52,0.57-0.52s0.57,0.23,0.57,0.51c0,0.3-0.24,0.55-0.57,0.55
			C237.53,208.67,237.28,208.43,237.28,208.14z M237.45,209.88h0.78v5.52h-0.78V209.88z"
        />
        <path
          className="certificate-mobile-125"
          d="M243.47,215.07c-0.31,0.26-0.77,0.39-1.22,0.39c-1.09,0-1.7-0.59-1.7-1.61v-3.34h-1.04v-0.63h1.04v-1.21
			h0.78v1.21h1.77v0.63h-1.77v3.3c0,0.65,0.35,1.01,1,1.01c0.32,0,0.64-0.09,0.86-0.28L243.47,215.07z"
        />
        <path
          className="certificate-mobile-125"
          d="M250.12,209.88v5.52h-0.75v-1.01c-0.41,0.67-1.16,1.06-2.08,1.06c-1.48,0-2.45-0.79-2.45-2.37v-3.2h0.78
			v3.13c0,1.18,0.64,1.77,1.74,1.77c1.21,0,1.97-0.75,1.97-2.01v-2.9H250.12z"
        />
        <path
          className="certificate-mobile-125"
          d="M256.69,211.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C255.84,209.84,256.69,210.55,256.69,211.98z M255.91,213.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C254.95,214.87,255.6,214.47,255.91,213.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M262.93,213.99l0.58,0.38c-0.46,0.71-1.29,1.09-2.27,1.09h-0.02l-0.13,0.49c0.61,0.05,0.94,0.39,0.94,0.85
			c0,0.57-0.51,0.91-1.25,0.91c-0.36,0-0.66-0.07-0.93-0.24l0.19-0.4c0.22,0.14,0.46,0.2,0.73,0.2c0.44,0,0.68-0.18,0.68-0.49
			c0-0.28-0.22-0.48-0.68-0.48h-0.25l0.24-0.87c-1.48-0.2-2.5-1.29-2.5-2.78c0-1.65,1.26-2.8,2.99-2.8c0.98,0,1.81,0.37,2.27,1.09
			l-0.58,0.38c-0.4-0.56-1.02-0.82-1.69-0.82c-1.27,0-2.2,0.86-2.2,2.15c0,1.3,0.93,2.15,2.2,2.15
			C261.92,214.79,262.54,214.54,262.93,213.99z"
        />
        <path
          className="certificate-mobile-125"
          d="M264.36,212.64c0-1.64,1.26-2.8,2.96-2.8c1.7,0,2.95,1.17,2.95,2.8s-1.25,2.81-2.95,2.81
			C265.62,215.45,264.36,214.28,264.36,212.64z M269.47,212.64c0-1.29-0.92-2.15-2.15-2.15s-2.16,0.86-2.16,2.15
			c0,1.29,0.93,2.15,2.16,2.15S269.47,213.93,269.47,212.64z M266.61,208.25c-0.33,0-0.55,0.25-0.57,0.67h-0.47
			c0.02-0.7,0.41-1.15,0.99-1.15c0.68,0,1.01,0.71,1.45,0.71c0.33,0,0.55-0.25,0.57-0.65h0.47c-0.02,0.68-0.41,1.13-0.99,1.13
			C267.38,208.97,267.06,208.25,266.61,208.25z"
        />
        <path
          className="certificate-mobile-125"
          d="M276.91,212.87h-4.85c0.09,1.16,1.01,1.92,2.28,1.92c0.71,0,1.33-0.24,1.78-0.72l0.44,0.48
			c-0.52,0.59-1.32,0.9-2.24,0.9c-1.81,0-3.04-1.18-3.04-2.81s1.2-2.8,2.83-2.8s2.81,1.14,2.81,2.8
			C276.93,212.7,276.91,212.79,276.91,212.87z M272.06,212.32h4.11c-0.1-1.08-0.93-1.84-2.06-1.84
			C272.99,210.48,272.16,211.23,272.06,212.32z"
        />
        <path
          className="certificate-mobile-125"
          d="M277.72,214.77l0.35-0.59c0.46,0.35,1.25,0.62,2.06,0.62c1.1,0,1.56-0.36,1.56-0.9
			c0-1.44-3.76-0.3-3.76-2.49c0-0.9,0.82-1.57,2.29-1.57c0.75,0,1.57,0.2,2.05,0.51l-0.34,0.6c-0.51-0.34-1.11-0.47-1.71-0.47
			c-1.05,0-1.51,0.39-1.51,0.91c0,1.49,3.76,0.37,3.76,2.49c0,0.96-0.88,1.58-2.39,1.58C279.12,215.45,278.19,215.16,277.72,214.77z
			"
        />
        <path
          className="certificate-mobile-125"
          d="M91.33,225.87h-4.85c0.09,1.16,1.01,1.92,2.28,1.92c0.71,0,1.33-0.24,1.78-0.72l0.44,0.48
			c-0.52,0.59-1.32,0.9-2.24,0.9c-1.81,0-3.04-1.18-3.04-2.81s1.2-2.8,2.83-2.8s2.81,1.14,2.81,2.8
			C91.34,225.7,91.33,225.79,91.33,225.87z M86.48,225.32h4.11c-0.1-1.08-0.93-1.84-2.06-1.84
			C87.4,223.48,86.57,224.23,86.48,225.32z"
        />
        <path
          className="certificate-mobile-125"
          d="M98.75,222.84v0.72c-0.07,0-0.13-0.01-0.19-0.01c-1.21,0-1.95,0.73-1.95,2.04v2.81h-0.78v-5.52h0.75v1.08
			C96.94,223.23,97.68,222.84,98.75,222.84z"
        />
        <path
          className="certificate-mobile-125"
          d="M105.15,225.87h-4.85c0.09,1.16,1.01,1.92,2.28,1.92c0.71,0,1.33-0.24,1.78-0.72l0.44,0.48
			c-0.52,0.59-1.32,0.9-2.24,0.9c-1.81,0-3.04-1.18-3.04-2.81s1.2-2.8,2.83-2.8s2.81,1.14,2.81,2.8
			C105.17,225.7,105.15,225.79,105.15,225.87z M100.3,225.32h4.11c-0.1-1.08-0.93-1.84-2.06-1.84
			C101.23,223.48,100.4,224.23,100.3,225.32z"
        />
        <path
          className="certificate-mobile-125"
          d="M106.76,220.61h0.78v7.79h-0.78V220.61z"
        />
        <path
          className="certificate-mobile-125"
          d="M114.11,224.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C113.26,222.84,114.11,223.55,114.11,224.98z M113.32,226.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C112.36,227.87,113.01,227.47,113.32,226.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M115.67,225.64c0-1.65,1.26-2.8,2.99-2.8c0.98,0,1.81,0.37,2.27,1.09l-0.58,0.38
			c-0.4-0.56-1.02-0.82-1.69-0.82c-1.27,0-2.2,0.86-2.2,2.15c0,1.3,0.93,2.15,2.2,2.15c0.67,0,1.29-0.25,1.69-0.81l0.58,0.38
			c-0.46,0.71-1.29,1.09-2.27,1.09C116.93,228.45,115.67,227.29,115.67,225.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M122.28,221.14c0-0.28,0.25-0.52,0.57-0.52s0.57,0.23,0.57,0.51c0,0.3-0.24,0.55-0.57,0.55
			C122.54,221.67,122.28,221.43,122.28,221.14z M122.46,222.88h0.78v5.52h-0.78V222.88z"
        />
        <path
          className="certificate-mobile-125"
          d="M124.84,225.64c0-1.64,1.26-2.8,2.96-2.8s2.95,1.17,2.95,2.8s-1.25,2.81-2.95,2.81
			S124.84,227.28,124.84,225.64z M129.95,225.64c0-1.29-0.92-2.15-2.15-2.15s-2.16,0.86-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15
			S129.95,226.93,129.95,225.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M137.68,225.2v3.2h-0.78v-3.13c0-1.17-0.64-1.76-1.74-1.76c-1.25,0-2.03,0.73-2.03,1.99v2.9h-0.78v-5.52
			h0.75v1.02c0.42-0.67,1.2-1.06,2.21-1.06C136.71,222.84,137.68,223.61,137.68,225.2z"
        />
        <path
          className="certificate-mobile-125"
          d="M144.18,224.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C143.33,222.84,144.18,223.55,144.18,224.98z M143.4,226.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C142.44,227.87,143.09,227.47,143.4,226.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M155.88,225.2v3.2h-0.78v-3.13c0-1.17-0.62-1.76-1.67-1.76c-1.19,0-1.94,0.73-1.94,1.99v2.9h-0.78v-3.13
			c0-1.17-0.62-1.76-1.68-1.76c-1.18,0-1.94,0.73-1.94,1.99v2.9h-0.78v-5.52h0.75v1.01c0.41-0.66,1.16-1.05,2.13-1.05
			c0.96,0,1.71,0.39,2.06,1.17c0.42-0.71,1.25-1.17,2.29-1.17C154.96,222.84,155.88,223.61,155.88,225.2z"
        />
        <path
          className="certificate-mobile-125"
          d="M163.08,225.87h-4.85c0.09,1.16,1.01,1.92,2.28,1.92c0.71,0,1.33-0.24,1.78-0.72l0.44,0.48
			c-0.52,0.59-1.32,0.9-2.24,0.9c-1.81,0-3.04-1.18-3.04-2.81s1.2-2.8,2.83-2.8s2.81,1.14,2.81,2.8
			C163.09,225.7,163.08,225.79,163.08,225.87z M158.23,225.32h4.11c-0.1-1.08-0.93-1.84-2.06-1.84
			C159.15,223.48,158.33,224.23,158.23,225.32z"
        />
        <path
          className="certificate-mobile-125"
          d="M170.03,225.2v3.2h-0.78v-3.13c0-1.17-0.64-1.76-1.74-1.76c-1.25,0-2.03,0.73-2.03,1.99v2.9h-0.78v-5.52
			h0.75v1.02c0.42-0.67,1.2-1.06,2.21-1.06C169.06,222.84,170.03,223.61,170.03,225.2z"
        />
        <path
          className="certificate-mobile-125"
          d="M175.21,228.07c-0.31,0.26-0.77,0.39-1.22,0.39c-1.09,0-1.7-0.59-1.7-1.61v-3.34h-1.04v-0.63h1.04v-1.21
			h0.78v1.21h1.77v0.63h-1.77v3.3c0,0.65,0.35,1.01,1,1.01c0.32,0,0.64-0.09,0.86-0.28L175.21,228.07z"
        />
        <path
          className="certificate-mobile-125"
          d="M175.83,225.64c0-1.64,1.26-2.8,2.96-2.8s2.95,1.17,2.95,2.8s-1.25,2.81-2.95,2.81
			S175.83,227.28,175.83,225.64z M180.94,225.64c0-1.29-0.92-2.15-2.15-2.15s-2.16,0.86-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15
			S180.94,226.93,180.94,225.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M182.53,227.77l0.35-0.59c0.46,0.35,1.25,0.62,2.06,0.62c1.1,0,1.56-0.36,1.56-0.9
			c0-1.44-3.76-0.3-3.76-2.49c0-0.9,0.82-1.57,2.29-1.57c0.75,0,1.57,0.2,2.05,0.51l-0.34,0.6c-0.51-0.34-1.11-0.47-1.71-0.47
			c-1.05,0-1.51,0.39-1.51,0.91c0,1.49,3.76,0.37,3.76,2.49c0,0.96-0.88,1.58-2.39,1.58C183.93,228.45,183,228.16,182.53,227.77z"
        />
        <path
          className="certificate-mobile-125"
          d="M196.91,220.61v7.79h-0.75v-1.09c-0.47,0.75-1.28,1.14-2.24,1.14c-1.65,0-2.89-1.14-2.89-2.81
			c0-1.67,1.24-2.8,2.89-2.8c0.93,0,1.72,0.38,2.21,1.09v-3.32H196.91z M196.13,225.64c0-1.29-0.93-2.15-2.15-2.15
			c-1.24,0-2.16,0.86-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15C195.21,227.79,196.13,226.93,196.13,225.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M203.46,224.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C202.62,222.84,203.46,223.55,203.46,224.98z M202.68,226.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C201.72,227.87,202.37,227.47,202.68,226.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M207.67,227.77l0.35-0.59c0.46,0.35,1.25,0.62,2.06,0.62c1.1,0,1.56-0.36,1.56-0.9
			c0-1.44-3.76-0.3-3.76-2.49c0-0.9,0.82-1.57,2.29-1.57c0.75,0,1.57,0.2,2.05,0.51l-0.34,0.6c-0.51-0.34-1.11-0.47-1.71-0.47
			c-1.05,0-1.51,0.39-1.51,0.91c0,1.49,3.76,0.37,3.76,2.49c0,0.96-0.88,1.58-2.39,1.58C209.07,228.45,208.15,228.16,207.67,227.77z
			"
        />
        <path
          className="certificate-mobile-125"
          d="M219.1,222.88v5.52h-0.75v-1.01c-0.41,0.67-1.16,1.06-2.08,1.06c-1.48,0-2.45-0.79-2.45-2.37v-3.2h0.78
			v3.13c0,1.18,0.64,1.77,1.74,1.77c1.21,0,1.97-0.75,1.97-2.01v-2.9H219.1z"
        />
        <path
          className="certificate-mobile-125"
          d="M225.67,224.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C224.82,222.84,225.67,223.55,225.67,224.98z M224.89,226.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C223.93,227.87,224.58,227.47,224.89,226.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M235.56,222.88l-2.58,5.52h-0.81l-2.57-5.52h0.82l2.17,4.7l2.2-4.7H235.56z"
        />
        <path
          className="certificate-mobile-125"
          d="M236.48,221.14c0-0.28,0.25-0.52,0.57-0.52s0.57,0.23,0.57,0.51c0,0.3-0.24,0.55-0.57,0.55
			C236.74,221.67,236.48,221.43,236.48,221.14z M236.66,222.88h0.78v5.52h-0.78V222.88z"
        />
        <path
          className="certificate-mobile-125"
          d="M244.92,220.61v7.79h-0.75v-1.09c-0.47,0.75-1.28,1.14-2.24,1.14c-1.65,0-2.89-1.14-2.89-2.81
			c0-1.67,1.24-2.8,2.89-2.8c0.93,0,1.72,0.38,2.21,1.09v-3.32H244.92z M244.15,225.64c0-1.29-0.93-2.15-2.15-2.15
			c-1.24,0-2.16,0.86-2.16,2.15c0,1.29,0.93,2.15,2.16,2.15C243.22,227.79,244.15,226.93,244.15,225.64z"
        />
        <path
          className="certificate-mobile-125"
          d="M251.48,224.98v3.42h-0.75v-0.86c-0.35,0.57-1.04,0.91-2,0.91c-1.31,0-2.12-0.65-2.12-1.61
			c0-0.85,0.57-1.56,2.24-1.56h1.84v-0.34c0-0.94-0.56-1.46-1.64-1.46c-0.75,0-1.46,0.25-1.93,0.65l-0.35-0.56
			c0.58-0.47,1.45-0.75,2.36-0.75C250.63,222.84,251.48,223.55,251.48,224.98z M250.7,226.74v-0.9h-1.82
			c-1.12,0-1.49,0.42-1.49,0.99c0,0.64,0.54,1.04,1.47,1.04C249.74,227.87,250.39,227.47,250.7,226.74z"
        />
        <path
          className="certificate-mobile-125"
          d="M253.1,227.88c0-0.33,0.26-0.57,0.58-0.57c0.32,0,0.6,0.24,0.6,0.57s-0.28,0.58-0.6,0.58
			C253.36,228.45,253.1,228.2,253.1,227.88z"
        />
      </g>
    </g>
    <g>
      <text
        //transform="matrix(1 0 0 1 250.71 325.373)"
        className="certificate-mobile-st-138 certificate-mobile-st-139 certificate-mobile-st-140"
        x="50%"
        y="45%"
        alignmentBaseline="middle"
        textAnchor="middle"
      >
        {data.name}
      </text>
    </g>
    <g>
      <image
        style={{ overflow: "visible", opacity: "0.4" }}
        width="145"
        height="266"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAAEPCAYAAACgHz3dAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAACyxJREFUeNrsnQt34jgSRmUj8u6d
nf//J5NJQjAYa8meUk91RX7xlM295+hAHqQdfPkkVZm0cwAAAAAAMAmKif5sOC0hp5Nf9PwMxJq+
WOFQ8YojZEqJhUzzEy2oMVgyP1Kq1Ch7ZIPpCRUSo0kId3RiaYnibWoUiZ+JZNNMp6ZlxK91yuVH
SvU9Fmp4dd/KhVDTE0yLs1OjVvejVI2c43CIWFaqKNIyMbRgyDU9qXRKRZG2+7GR21pud+pxzSkS
K4pztx/3ibFU4pWstyYnVVBSbWVU+7GW2yrxmKJtShyTWDGpviV6lPGk7t+r9CqRa3IL9cak1LdQ
XyowisSUGQ6ZCosesX7JeJah5YrphljTSau4lopSrfbjUwVFo75nodZYxSGJ5ZRYXon1JFL9JSMK
9iBTpVdisdbKWypnpsBKkupDzmWpvl6r9VdtgiOMTayyJbGeRaq/9+O/+/EiwunUQqzpLNp1Wlmp
NmqdVSkndscklusQ65dI9S3Xf0SuBzMvMx3mv8aKCVTJFGil+pLPx/Na9gXGmKmwTa4Xkepvuf8o
B8bucBpixRLDVtLqXq2hNiJUXOLojVnZ9cOH7gr1Al6vtR5k+nuR8Us+vkvUtByCZSWUM7WrrTpv
jaTXp5znO/ezVumOTay2ssNS1bQeVPnhSS3geyMTri6ZLjOUSqqYUncuXaMsjkks535e0VAm0mup
DiLK5pkKJzMVxkV4o86hVylVjp19fE9C9U2LqZ6hV8POxciV51QYd3a1OX/6HHddKjWq3DAkvYpE
kpUm1Vhf5S1WlCJ1lUqRKBkNOod+pFBttraJxsI9P1J1pzIhT9Fz/opTiNW1Wyx6plCEylOuVIK1
iTR6A1ae8EBJpvnIdvROvjzzgVJqyF+ms5yj8ozWw/STK8vEghsGsQCxALEAsQAQCxALEAsAsQCx
ALEAEAsQCxALALEAsQCxABALEAsQCwCxALEAsQAQCxALEAsAsQCxALEAEAsQCxALALEAsQCxABAL
EAsQCwCxALEAsQAQCxALEAsAsQCxALEAEAsQCxALALEAsQCxABALEAsQCwCxALEAsQAQCxALEAsA
sQCxALEAEAsQCxALALEAsQCxABALEAsQCwCxALEAsQAQCxALEAsAsQCxALEAEAsQCxALALEAsQCx
ALEAEAsQCxALALEAsQCxABALEAsQCwCxALEAsQAQCxALEAsAsQCxALEAEAsQCxALALEAsQCxABAL
EAsQCwCxALEAsQAQCxALEAsAsQCxALEAEAsQCxALALEAsQCxABALEAsQCwCxALEAsQAQCxALEAsA
sQCxALEAEAsQCxALALEAsQCxABALEAsQCwCxALEAsQAQCxALEAsAsQCxALEAEAsQCxALALEAsQCx
ABALEAsQCwCxALEAsQCxeAoAsQCxALEAEAsQC24Yz1OQJBzwmIKnDbGGSBXU6BKpkBEQDLGGCtUl
V5EYJBhi/ZDKjkYNK1cUqUzcOpVgBWLddkppeXZKqJ36OChZokTfY6Fug5KsuOX08jculU2oKFIt
t1t1vzFiLeT502OhRLvp9PI3KlRbStUi0/fYyIgf75QgpTx3y/24M7dWsMIs9BHrBlMqyrSWUcnY
yNejlAsR6Xs87Me9ul3KCIn0upmp0ZNSv1NKC/W1Hyu5/RK5tvL9hTxvUarH/XiSxz/K5+Iazavn
+KamRk9K/V+ISgn1aUYUzIp1L0J9j5f9eDZrsnuRL5j0uomFvSelfqdUFOljP95lfMjnvuR7o1hL
JdaLfH1t1mVPZodp116zTi9PSv1OqSjUP2q8y9eiNDuVWA8izy8lVqWmzTqRXstbSS8/Y6kaI1Vf
SkWZ3mT805FYcSp8NmuxtdlJWsEakSsm2GzLEn5mQrmEUENT6s1IFcVayWP0GmshibVSYq3VDrIy
gtn00sk1y/TyM0spvZayKVWZxXkqpeJ4V9Ng3BXWRqw72QWmhLLDCvYw9/TyM0upMCCl3ltS6k2t
q/QUuFZS6DrWskOmygz9NS3XbNPLzyil9FqqVmupWJOyi/MuqVamfqUr77Em5U0ibToEs9PjZu7p
5WeUUo37sx2zFkHapr3XhFAfiaJoraZBLdbOjNpMvankqoyMzyPTq0Cs65UR1kqqKMubkupV3b6b
Rbrd2dWmFuWU1PYKiCFyrVskS6XXpFtCfgYpZcsIbTu+VyWVLifYlNokSgSpi/3sNVtaMl1uqEak
l5b0zk24JeRnklJVz9T3qoYtJawSKVW79ov8dGoVHcdmG9s6tfSoErvHR/lZk20J+Zmk1FoJ9dGy
lnp1f1bTPwemVJtYwZxke+WpLnf0rbsqs1Go3Z8XGU6uJeRnkFJfPXUpnVJRqpVKi76UCiPlbyvQ
bk1NrW9qnHRLyGcs1SEtmbeWMsLQBXpfSnXJFXqSyxZrNx21rsm3hHxmQjl3mpZMqoK+apn6xqZU
37Gn1l1d6TVEsMm1hHxmKXWqlkyqgm6vBj0mpcakV9OSXnVHYXXyLSGfWUqdoiWjr0pIVdBrdZKP
SalzpdcsWkI+o5Q6R0tmbU7ILjH1hTO/aOz0rodNr7YpcnItIX8BcYoRZYRTt2RSZYTGHJ+70HPQ
VvNq3PVaQmd7DvyFkqlw6bewX6Ilc6oF+jnT69otoZC7WOEExc5ztGQumVKHptelW0Jjz2FWiRVa
6lPXaslcS6q+9LpGS6g45/PhL/DqDC7PlkwO5ZVrt4TcuV50/gyvxDCgNpVLSya3ovClW0Ku5bkK
OSaWlSm4Ye/lu1ZLJhe5Lt0SapMrq8Syr7ydWUfk3pLJKbku1RKKU2PR8oIM1xArDNhGx4PUUuXe
kskxvc7REtJTY0ytXccLNFwysUJCpPjLxwVpSqrcWzJTT6++lpB++/9OHChc+i8YHvxi9UdKlWrH
bE0lPU59U2vJ5FiWOFVLKI4HSa6Fml3sc3xQfcsf8EuGlrpU/OWcitZKVdCn2JLJrSxx6pZQlGsp
P792P9+V1DYrhFMmVqqEoH+ZhdwP8vFXS7Fzai2Z3NPr0JZQJdPivUqttncojXrefY9AbVK1XdUZ
lPmxkh6lmnpLJvf0GtsSWqvbR5kS9blr2ySlkisck1hWKn3gKzmoSr53Y6ZA3ZaZeksmx/Q6tCUU
N1XPklpefsbaFKB3ic3S0Wus0CFVTKWFfH6h1laf7mcTeS4tmdzSq6uwuu2ZGuNs8ShrLR0MVq7B
L3Q/8hfRBxulio3OtYnSrj9kNpeWTE6liUNaQvoSpbjWKuQxsTy0ThSke1/sfkRa6flb16VK+dxC
HrM15YX3jjLClpQ6S1F1aEtIX6H7rBbxjXz90yxV+t4dPnoq1HWTeEBRqp2aDhu1G/xUhc65t2Sm
WlStEuusZSIg7B9JaU5RbrBrqyiO3p4uVYTqy4pXRqi5t2RyTK9mQHrFIHhMnEu9wdp2FFAHi2Wt
r5Xlsb8U11ML9fHWpS9Gu5WWzNTSS8sVE6uU79e7fj0d9nY+hq6xdGKt1YFv3L/vCgkm1VLXB91S
S2YKRdXa7PDvjFh6TW3XWeGYxNLmxwOJ29sYlfHSi8b9/P9oUjs+ip35FVVr9+9/1eLVjGSvo+ta
xhy1xirMP7hQdrf9D1o7FujZp1etdvb2AkDb7D7Z4t3+A7r0YN9WpF8FDSk1mfTamaAoTIg0LSHR
ypB3x+p3dZTu5zs9UpXf4Pq743A9isR5Td13iXM6aCdfHHggqXfW2i2uQ6jJCZY6t22XLXfOPsUB
B9H1uIBQk5TLDQwMN3RJUxx4EGMWizAtwVLnOYw9vwXPJwxwg6AAAAAAyID/CTAAZ9xhmya4GusA
AAAASUVORK5CYII="
        transform="matrix(0.24 0 0 0.24 281.828 1.9655)"
      ></image>
      <g>
        <polygon
          className="certificate-mobile-131"
          points="283.75,3.93 283.75,62.16 298.3,52.16 312.85,62.16 312.85,3.93 		"
        />
      </g>
    </g>
    <g>
      <image
        style={{ overflow: "visible", opacity: "0.4" }}
        width="234"
        height="234"
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAO4AAADuCAYAAAA+7jsiAAAACXBIWXMAAC4jAAAuIwF4pT92AAAA
GXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAHKlJREFUeNrsnQlzGzfThLEHSVGS
jzjJ//+BiZ3X1slzP6mK+DxqzgDYi9yjuwpFSrJFiuSz3TMAdp2jKIqiKIqiKIqiKIqiqCsp40sw
ifesyftYJX6PIrhUzfcl6xHcVHgrQk1wKfv1zwLgarddvneVAWgVuSXQBHeWToqwZgqcodHk/awC
4FbK10fl+6lQUwR3UqDGgMyVW/xe1tF7WCnjKIaEtzK+tuAnyAR3VK9pDNQc7lujgFuEuA3ACBzC
ejjdP8DPjgGwjxGQCTHBHTSsIQe1wMRRGj/z35O/twu3RVAPytiL+0e4f4w4tebuFMEdDKwhSD14
JQBaiu8t4Gu8X4ADt2lUSbfVIN0LWN9vd/D1HmA+JMAsH5NOTHAvXrNasGouaoG5EF8vYJTwbxHw
HBw3qwmsFpP3xtjB2CtfW2Bb7hyqlSmC2xuwVuRFyBbKWJ7GQrnF+4sewJWAHAGsELB+bE9jB7fb
CNiaO2sA04UJbidxOAQsQrUEOLWxgtslwLwwHFird7Oa72eVEJUtcBHS97GB29AIgRxzYorg1gZW
q1VLxUUlkHLcKN+T/25hgFsqNW8Bz8fVhNcCV8ZlDd694qwS2tB4VQDfgUPvjdqYABPcxsBirboA
9/Rw4ljDzzR4LadFN88jjamupoM05z0o8O7AdT2QrwJWf/9FfP0KP5OOvA9ATIAJbm1gJVAeOAT0
fdyK+zfiFsHVgC0hEpdKoytlEUZWw23l/WMAYC1C75XYvAVo/fDgPp/uP8P3XwH+HTS6CDDBbQQs
xl4E9f32DsBdg+OGgC2VGlZbkBFa9tikq4yRWYvOFsjYxNoqDrwRgL4IaJ9OtxJkDWJZXxNggnv2
wS8UYGUUXgOsEtQ7cd8PGZOxjpXuqi2swOWNoVicNXw/q0hs1pY3IsTYidYaWRq8zwLeJwVkDeKt
AvBhzl3ocqYHKnTYUgEWndXDeX+6fw/w3ioua7lrYcRfed85e+NBVwff2Ba+EMQYq7Wm1tYAGOF9
H4/wNUKMAOfgwtbfRMedkMvidA5G4jW46fv4dIL1Hr5/K6C9URw2JQa7QAzOLvS+VZEGVsiRtUiN
XeltxH0fxe0DgIwAb6AbrUVoOu4E61jsEMvaVTrrvQBWA1eLxUuIwqUCawqk2YUPuJnygc8CTS2t
Kx3qTnuAZffZA+zhfBTjQQCMEC8EwLl4jGwu9W82cWCdAqyHSUZi75j3AOzn0y1Cewsd4yXUr9gR
1nYFpUA6lPcndqYMCcnRpS3s2EAH+hli84MYvwDqJwG9jNDYhZ4svNmEoZUDF03I6Zw7UbNKWD+f
hv8+uqwFrLZ751rx95IwpzgxArxVIvSzAu+v03gAJ34yamC5GmuS7ptN9ECEq50ksCvhsnfCUT8r
45PisjIS41LElGmbqb72oSkm5/TOtFUDo/v+MoYHGGtgrf6dFLzlxKC1XBaBvRVO+uUE6RdxX8Zj
7BYvEoGtU7NO1QQyANe/L0dIQHt3Pk+OzcF7o1xZC4D9+/J6OhBkUPseA9Gf4F4Z2tyIxf7DgA77
BaD9DB8OGYsRWJzG0T7Ac54rtyD2r5sE+ODsxS63kXGjNAdlfPa1rxbpCe4AoEWXvREuK2tYCetX
IxpjHRty2Lm5axflWCZg1nZbYWmDAK+VJqE8wD6J98rXvpmofUcNbzmBDwR2jP0b7mOUj1efAdav
4LKfwGVl4ykWiQlsdy6sJaZlwInlqrYbpf9QnurfXIF3tACXI/4AWNHYL6C4E8B+FrBKaL8IsOXR
G2NxCFjC2o0L5wbAOH2HLrxOgNf/nlfx/o06OpcTgRZr2TvRePKg/iFuvxi1rIzFJYG9CsQhgLHZ
qG2nxB1Y2gF49NG5HDG0WjS+hVj8B4yvAK2c4onFYgJ7PYCzSA2snbxAvqeLQHT2GhW85QihxXp2
BS77WTjrN3HrofUNKBmrFgGXJbDXB1g7a2Zp1MLW0Fa1+d8tF22MAt5ypND6N0tGY3TZb2J8dR+7
xmulDrJOB0Ngrw+wb2Lh50A779ciAK7Vt5CPJxdsENwOoV0IaGU0/qoAK53W17NrcFnG4uG//5X7
uHc6c/YadO2Mmlpcxm2Vm9Pv3wuAK4LbHbRyY4CPxgjsn1DT3ruPE/WayxLY8cXnUAOrNMDVFtHg
AXvw8JYjhVZ2jSWsCO1ngFY2oGKLKKjhx+eUM3GWEXBleSQfY9DwliOD1neOZTR+B/Wv062EFptQ
y0B9Q2jHG59zFz53mHZy+tKdny1T+wwMFt5yZNDeKdD+LeCV0N4JaOu8WdQ4a9/Y/K929pGUC6cN
Et5ypNB+E9D+FYBWa0I5QjuL6By7OqJ1AM/GAG85Umj/Ami/GdBqc7OMxvOLzhrEKXumBwtvOXJo
/45Ai51DAjsP98XoHALa+jxo59kaDLzlRKD9LKDFzjGhnSfAoXNR17mucKU479U3JpQDhdbP06ZC
e+/s6R5GY0bnJu9/7JxaV10eWQ4AWr9hQC5jvGsArRaPCSzhzVy7y5Fa1+3159C6CrzlQKAt3Mdl
jJ/c+ZRPDNqF+3iGRUJL4ftf1HRc7eoNCPBV4L224+YGtF8ILdUTwEUCrLFrKFkuPGlw0W3l1jy/
YQCh/YvQUheA17q0Cl6pUNtBdNFOc3klaK0O8ieoa/8ktNQF4A1d5Exe0Ey7SoJW+04KXJwML426
FqHFFVGElurLeTVo8UqECDBecuUi8F7DcSW0K/d7T62va7WteXJxhd8sQGipPuHFKy74qy7IC3rj
ZU7cpeAtL/hC4dSPPOWMthHeglaeF4rQUn3E5kqBVhtY916sWVVcEFrvkEvhtL6D7GPx36Ih9c2d
b4IntFRf8OLnFX9uXUL0KlcHvAS4ufvYQfbNKG3axw+5/vhW1LRyaxahpbqEN3ZFxaMRoQ/i64vB
W1zgRZFn5ZNnsPDTPrjT50933oySm+DrrDOlqLrwIrToutiw8ve1ZlU1RnCtDrKf9pFnr/Dw+oYU
rj/GTfB0W+qS7qvVvnit39Ac76jA9ZBZHeRvEJH/hLp2DRGZ0FKXqnetGtfqNsv7F6l3ix5fBHnW
AbnI4ouIyDImx+paQktdA14H8DpwXWuKqNfIXPb0x2NMxlOqyuv5/BGoaWPXnqWoPuEtnD499H7t
offzML+639fh9dfi3Tl7jrczgIue/nB5hj25DllG5L8gIuNmeOvkbhR1KYAt17UWZ+yMetcNHVxt
zlbrIiO0X93v+dol61pqIK4bOq0NwqtF5t6aVX2Ai0sa1+7jOmTZSfYrpO5Z11IDjswheA8A786F
l0RWQwMXN8ZrbosROTT1w/laasjOi+DulCE7zRXE5sGAiyukVu7jVj3ptNqSRkZkamzuWylx+X1s
A87biesWHf5huEJKTv9gQ0rur+U6ZGqskdlHYOm6WwGu1mHuxHW7AldzW3mWRum2PiJ/cfbqKEJL
DT0y44KMvQLu1ojMrV236OiPik3/YESWXWRtdRShpcbivrK7vAN4/ei81u0CXLlCCk+vGmpIycUW
eB5kgkuNuc71wG76ct2igz/GA7dw4cUWf9JtqYnBW7UAt5XrdgGu1Un+Q6ltv4LbyivpsSFFjVWV
qHMv4rpFR9DKTrI8f5S1QgqXNXLOlhpzZNZOddOr6+YdRWXZmJJ7bv1V4X00XjMeUxMCWJsCvYXP
P15FEns62SXBxR1AeGJz/+Q9tHdGTcuLclFjhVY7UYQ8AeInMK87w7gaffbzDo44EtobA1r5hH1d
S7el6LoNXbet42pXJLhVnBafcEa3pWboun6V4LKt6+YdQevnb9fCbe8EuNa5owgsNRfXteJy1sR1
20RlrSl1K4C9Cxxh6LbUXFz3HoZmZLWnQvOWT1ZrSkm3XdNtqRm6rpZA7wNmVpuHvCG00m2X4kne
gtvK2rZ0za4MTlFjct3QDAty0djM2oLrm1Ir8QRv4cjS6glS1Mhct1Aic8zQarOR13xiTsnz6Li3
IiL7IpwnNKfmWOsujd7PjQJu747rjypaJEBwuayRmiPAssO8UozNatomM5K3OKqg26Lj0m2puUKL
paQEdw3G1mj5b1PHLZ3emPJPqHXXjKJGDrHWvNXgXTSJy3mDownG5JUC79JxCoiaL7Sa664EsGuo
c2u7bl1wrYlmfDIr4cr4ZAgvNfW4jFteF2BwaxfvLGdd1rg4f4tPBjvJuWu5fYmiJuC6Gi9aSVl0
GZW1CeZSxOEbMSzrd3RbakbQIrwLAFfjpbyE48onsoKIvBQxmY0pau6RWcZlhBeZSb46ZR3HxbWY
6LbaggvO3VJzh9eKyxYzSQ2qus2p2NFj4ewOGeGl5hqXsUm1UmpcjMutalytvtWi8srpU0BsSlFz
hjc3kqpVXiZ3lvMGT2IRANdaKUVRcwcY0+pSYadIrXNTHVceNcpEt2V9SxFaG1zJz8J9bOhG69yy
RZEtjxpL1rcUFaxzLdddNkmreWJ9m0F9u1DcVk4iMyZTVNz4VgBv8mxM6nSQVmAvA7UtwaUou84t
3cdeUaipm7UFFx90Cbfaia8IL0Vo9enUhTufCipTS83UqJwrURmzeWm4LeGl5l7nOqPO1eBN2rte
p6us2bwcueMWPooKGWCMpeRys+6Sx0IBtnTnTSlCS1F6yYmuWwagbQyuNY+rPSAbUxQVjswheGsZ
YF7T3tHiC6dPARFcigonV8lS6c6bU0F484YPKB9YbgDGThgBpqg0IywC0GZ1wc2UuJwr0DIiU1Q4
JjuDo8LgqdHKqSwCLY7McX0yRdVJr7lSauYKR6rrto3KuUvsglEUgf1wv6jBVOOoHHLc6INQFGXy
lDdhKbZyyhnWzrlbimoOLm7csYzQuRYrp7AhVda1dYqizqJyrLPcynGtmBxrX1MUVS/FSq4azeNq
hXSomC4C/4eiKDvFWmYYhbfO7qBQ+5qOS1HptW0WMEErKmdNwLViM2tbimrnuDmk2SQzrHuWxyzg
tISXouq5b8wIzUUYda8dFF3RQXgpqhbAaICNr2QQ+k8WpISVotJArWOKtcCNAWsVzYSXotpBnMpg
rav1URTVL8TJJtgE3Krm9ymKslmpYFgsVTFwq4TvaQ9MUVS64bViJq/hntpRoUr8vxRFnbNxhFGJ
26ptVK6UcVRApiiqPk8WtEG+8gbAakcJQkxR9eCVDB3ECHFV1QVXPsBReZAjoaWoKEsuYIIHuEUj
rNWckg8Sg5bOS1FpAKPT7hO4SorK1hFCPgg+WPAIQVGU6rYSXo0pV7fGdcbR4WBkcsZliqoHb4ip
xs0py9r3hrVXdFqKirIkxyESlxs5bpXoupwaoqj68FpMJc3U5C0fKKmQpigCe8bUIQAu9oxqNaec
C883yQdmY4qiujNBDd5aUdlafBFzXYqidBNLMUPNbatUcF2kxsUmFV2XouIJNjaP6+F1baOyhDf0
QFqdS3gpyoZ2DyM5wdZZ8ohOuzuNvUtsYVPUzMENQZu8+KJOVMaVU7vAAxJeiko3wZ3husF+Ud4C
2h08KOd0KSpcdh4DLHW+AEMrpLensVMelNBS1Dm0uFpqL1iSHLWucbWCGutbOWJ7CSlq7jHZQ7sD
89u6835Rq6gcistbMfZwtCCsFPWRIcv8tkZURv5qRWWtoN7Bg26h1iW8FBU3vvexURg6pKTVvOYR
Y6847i5g84SXYo0b5gfBTTK+ptv6/NHCOmKwzqUIrZ1Yt4KfTcT8Oq1x5ZFiE6h1KWru0kxPY2fn
zqeDWjmuA3BlffsKzisfnG5L0XHtxu4G2JGm13o/rkt48FcBsHbkoKg5w6uVmK9gelpMdm0cV6tz
scbVwE0+clDUjNx2m2B4nV3JQHNdfHD5JGoV2RQ1YXCPUGJuI2aXVN92EZXfH/xFDAlv8pOgqAnD
K/tCG8XoNk1Sal7ziaDtbwS8lusyLlOMyXZK3TYxujpR2Sm2Lx33+XTbKLNT1ARj8sGIyTKhWrMx
nYCL1t8kLtN1qTm5rYfXx2SLk9BUUCeOi0cSmdmfA0/oQFipGQKszcDIZIrNqVrJtInjWnH5WQwZ
AfaMy9RMY/JeMPJslJW47qFzcK24nOq6jMvUXGLy0Sgnn8FxtZjsugZXi8tYcD8rUUCCS1FTBte5
8MzLs9ObuLUXKzV1XC0KvD+ZJ3hy1nYlui411YiMpaQE9uk0XgKJtHPHlUU3xmV5RHmKuC6BpaYM
cB0uGq8yzBs+QS0OvMBR5Vk5svDsGNTUa1ttxuWdh0fBxSswUTuB5i2fqNVdflKKcN8940oqaorw
aruA0Mye3HljqtF0aRtwrZb3EwyMBXRcaspuuxfQPiluG0qhVZ/gIrxaXH48jWclGhwcr+5HTQ/e
GAuPYGSt+j55yyeMRxl03Uend9HoutSU3Pbg7FmWRyUmY1PKXQrcyjjSYDGuRYQdXZeaWG0bYkA2
a3Edf+N+T97hk95Btn+AmPBM16UmWttqn3//ufccdOa27ypa/gEZjPz0O8u3sTyN1Wn4rxenn+en
4f+vE7cUNRa39dC+nAD99Tb+9za+i/Hf2/gp4nLrNfxFR3+Eh68Q4C5OA+H14JYALuGlxljbeqf1
jSgJ7b9v48cJ3AeROmVUvorjOsVx8wC8lutqAFPUkMGVzSjptv8JaDW33boOTjBRdPgHZaJu9s4r
wV0q4Pp/lwOwhJcaMrRyhZRvRj2cIP1huO2TO2/ONlbXUTkDF0XXXShxWYKb03WpgYPr3PmKwceT
q2rQ/nS/m1Otljn25bgO6lQrMvtb//3CsVFFjctt5SaCJyMifz/Vu53Wtn1GZewwa/AuxdfouoSX
Giq0uD7fR2R0W1/b/nIddpIv5bjO2c2qRaTWZaOKGiq0e4jIMbd96dpt+wTXGfVuqbiunBpiZKaG
Ci9G5Gd3Pmerue2ra3D61UuD6yAua1NE6LwavIzM1JCAxTlbP/2jReQf7mNDyrttpysF+wbXGfWu
dN7Sfewws96lxhaRrcUW0m07XZdf9AStVvPKyFwkuC7rXWoI4MoTI8ousozI/4DbysUWviHVqYqe
/3gJXB6Izei4hfs4r+voutQVoZXLGh9O0MqI/K+A1pr+6XQXXNEztFbNi9NEpQEvIzN17bp27z6u
Rf5pQCtXSPUWkS/luBq4meK6Vp3LZhU1hLr2xekLLbSGlHfbXreuXhLcmPPWgZfgUn1Ci3Xtsztv
Rv0jwPURGbvIvZ0oorggsFZsRvctDHjputQlwJWXxnw16tp/TuOH+73QwkfkTnb/DMVxEVpnOG+h
1LnWWmbCS12iGfWoNKN8F1nbstfpCqkhgYtfY7c5Bi3hpbqGVl46xNe1shmFde2/7nznjzxzY+/n
DS+uAGxluLCENze+JrxUn9Diyig5XyuhlRG514UWQ3Fc5+wFGhidNYhDZ8sgvFQTaLUrcmAH+bsB
7aNS115ExcBeTAQ4M4AlvFRX0O6dPu2DzShru551eZ3JgpsZX2eBGE14qT6g3bjz5YwWtNp87UWh
fVd5xRfvoMCp3abO2xaEl2oB7WME2v8pTtvoEplTispVwvfQfem81KWg9SujfrnzdcjHa/wxxcBe
WBc5ehFeqm9o/zWgxTM1XiUiXzsqS1APCfVvmwMS4SW0OE8bg9aD+wMisnYJnatc+6ocwIvqXXav
gJY1/H1amiDA8wNWWxG1rQktLme8Wl07JHDlEdEJeLtyYBmpK8JLaJ0+5RNzWg/tbgjQDq3GxRcd
3wDthcoCwwW+R80D2oMSj+tC+zI0aIcKbgjeY8CNLXi7dHBq2MA6AVaK034fI7RDicp1Y3PsKIug
VxCdCfC0XVZugkdote15/44N2iE7bmpsxhcyM1zYiteEd5r1rF/CKLfm+bXH1jztaKAdA7h14a0i
jsr4PF2X1TrHdaB9GAu0YwFXg1fWMUf4nozbFtCaAxPe8busFo3x5G4Yj3+6AXePxw6uU9wVQcVh
1bkp88QEeFwuuzdc9n9O3wQvNww8jA3asYHrlMiMsB4MiEOum9F9R++yG4D2p9PPfYzQ4oqoUUA7
RnBdIDIfYPjvVQrA2puSAjY1TJf1Z6x4AJfVpnu0rXmDWRE1ZXC1oy+6rgaxFqPrvDkE+PqlUchl
5fzsD+Gw/0ATyp/cTe7yGRW0YwfXAliulgkBbDWyCPAwgcUVUNJl/QndrGiMTagXgPY4JmjHDK4V
oTTH3Ucgrow3riLAgwHWd4tDLvsdXFaLxr4JJffTXm1r3lzB1d50KzbvlYGujNNKzqVPJ1H9Oaw2
L/sgGlDfFWh9A8ovqvD1bGiHTzWmF62Y4JtfGdDKD4IF78GogwnwdYDduN+nS/Xzsuiy1qlT5ZkY
X5zeOXZjhHZq4KbUvHvxodjB1/tEiBmju3ufUoDFWCxdVl4JXrrsT8VltWjsxgjtlMCNReeDAulO
xLCdAvMeIpXlwqFITYD1RKRdpycErLyagARWdoz/MxpQ1vxsNeYXtJjBB0WDdw/QbmFoEFtdaefS
5oYzwqqWMVvReLKA1WKxBFbWsqGusZsCtFMH12paHRLh3YI7WwBbELuZQVwF+g1yVRumnVf3e4N7
CNjvAKycl31IdFk3BWinDC4KV1hJCDEu1wE4ZYFH6qlnpwyrfM1xWgcdVjaefiiw/mfE4sm7rFQ5
YVhl8yNT6qoD1FX+g+SP/O9H8U/KuH8bd2/j9m2s38bN21i9jeVpLNz5pUK1ax7lAWizAb+eWk2v
nbwAXfYAB0dZz/quse8ce5AfxHvhY/CzaDq9woF175rNyY9Oc6m7QhfTXgjobk7j9gTn3QlUBFfC
eyv+342AdwEQaxcxi50T69JQVxFX1W6PRjkiD457AdhGQPcCrushtYB9SQB2si47166nBMNDI13R
A7w6jfVpSIBx+J+txViJIR0YnTj1CoQhkLMeYA0569GlrVjDzv1G1LMbAeGLSDjaeBLuivXrzp0v
npm0y84VXA0G7aLaFsC3AuA7GLciOq+F+66MGF0aEGeGE2c9gmvBWwUgrQI1LPYKXt3H9cUv4LRP
At4nMWLAHucSiwluO4BvBMByaODein9vAbxQAI5FaesMlllHsDqlVtWaTbJu3UPjSdavGxFvNWif
oW7F+pXAEtzWAC8FwDfgrrcC2LUy0IFXAYBLA2C8dYk1cUqTybn4VkmrI48OK2tYDdgXiMovULu+
uvP5dAJLcFsBjBCvDJglsAhvyIElxHlCPdwE3FAdi8DujdpVmwPfRKB9Afd9hf8jp9/2BJbgdg2w
bDQtYdwotfFNYCwB4DIQoa2ppTbvo9UZPihReGfUsFtoPr0q8MpadSP+vbVSjcAS3E4Bxk50KWBD
R74BR0Z3XiU6byxCd+G4sa2QOyMWa+Ci8yKsO3BVXNBSEViC2wfAcj64UEDWIMZ4rd1fGHVvrAvd
JbjaCQgQ2NAKsw3cWqvP/O8/KsBWBJbg9gVwpjSMcgPiEkBGqPF+qYC7EL8T692m72MVcNyQ2+4M
B7Z+ZsGqnX3EEViC2/drZk3T5AE3LhWgywSX1Rw369BxtV07GsB7Z+9j1rZDhmCNbZGkCO4gIMal
loUBpdWUKgFaLSo3bVB5kA5GZNZgPhhwHtz5NFIsBhNWgnv11zFLAFnrVmtfFwb8qZcTTQU3tKvn
aAAt/y3G7ZCr0l0J7mggdgkgW3Bnzl4S2fZ9jO3usS7tEoLz6MJ7kwkrwR1dnE6J1yG3Drls1gBY
y3ktkEP/ztFVCe5c3Fi7DTm16ygehyKz5cR14CSsBHdWr3/W8GddwhsCsarh4BTB5XsS+FnWAaip
EBJQgksN8H0jmBRFURRFUf+v/xNgANbatReD+XnYAAAAAElFTkSuQmCC"
        transform="matrix(0.24 0 0 0.24 270.519 63.8565)"
      ></image>
      <g>
        <path
          className="certificate-mobile-131"
          d="M297.88,65.62c-14.09,0-25.51,11.42-25.51,25.51s11.42,25.51,25.51,25.51c14.08,0,25.5-11.42,25.5-25.51
			S311.96,65.62,297.88,65.62z"
        />
      </g>
    </g>
    <radialGradient
      id="CERTIFICATE_MOBILE_124_"
      cx="297.6661"
      cy="93.5449"
      r="30.3425"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.2527" style={{ stopColor: "#525252" }} />
      <stop offset="0.3606" style={{ stopColor: "#787878" }} />
      <stop offset="0.5128" style={{ stopColor: "#A8A8A8" }} />
      <stop offset="0.6576" style={{ stopColor: "#CECECE" }} />
      <stop offset="0.7912" style={{ stopColor: "#E9E9E9" }} />
      <stop offset="0.9095" style={{ stopColor: "#F9F9F9" }} />
      <stop offset="1" style={{ stopColor: "#FFFFFF" }} />
    </radialGradient>
    <path
      className="certificate-mobile-132"
      d="M328.01,93.54c0,16.76-13.59,30.34-30.34,30.34c-16.76,0-30.34-13.59-30.34-30.34s13.58-30.34,30.34-30.34
	C314.42,63.2,328.01,76.79,328.01,93.54z"
    />
    <linearGradient
      id="CERTIFICATE_MOBILE_125_"
      gradientUnits="userSpaceOnUse"
      x1="278.9757"
      y1="69.9746"
      x2="316.4251"
      y2="111.8882"
    >
      <stop offset="0" style={{ stopColor: "#A1A1A1" }} />
      <stop offset="0.0768" style={{ stopColor: "#A7A7A7" }} />
      <stop offset="0.187" style={{ stopColor: "#BABABA" }} />
      <stop offset="0.317" style={{ stopColor: "#D9D9D9" }} />
      <stop offset="0.3333" style={{ stopColor: "#DEDEDE" }} />
      <stop offset="0.5161" style={{ stopColor: "#EDEDED" }} />
      <stop offset="0.6613" style={{ stopColor: "#E0E0E0" }} />
      <stop offset="1" style={{ stopColor: "#9E9E9E" }} />
    </linearGradient>
    <path
      className="certificate-mobile-133"
      d="M323.38,91.12c0,14.09-11.42,25.51-25.5,25.51c-14.09,0-25.51-11.42-25.51-25.51s11.42-25.51,25.51-25.51
	C311.96,65.62,323.38,77.04,323.38,91.12z"
    />
    <linearGradient
      id="CERTIFICATE_MOBILE_126_"
      gradientUnits="userSpaceOnUse"
      x1="273.9517"
      y1="91.1248"
      x2="321.7951"
      y2="91.1248"
    >
      <stop offset="0" style={{ stopColor: "#818181" }} />
      <stop offset="0.0634" style={{ stopColor: "#888888" }} />
      <stop offset="0.1545" style={{ stopColor: "#9B9B9B" }} />
      <stop offset="0.2619" style={{ stopColor: "#BABABA" }} />
      <stop offset="0.3333" style={{ stopColor: "#D2D2D2" }} />
      <stop offset="0.5161" style={{ stopColor: "#DBDBDB" }} />
      <stop offset="0.6613" style={{ stopColor: "#D2D2D2" }} />
      <stop offset="1" style={{ stopColor: "#818181" }} />
    </linearGradient>
    <path
      className="certificate-mobile-134"
      d="M297.88,67.71c12.91,0,23.41,10.5,23.41,23.42c0,12.91-10.5,23.42-23.41,23.42
	c-12.91,0-23.42-10.5-23.42-23.42C274.46,78.21,284.96,67.71,297.88,67.71 M297.88,67.2c-13.21,0-23.92,10.71-23.92,23.92
	s10.71,23.92,23.92,23.92c13.21,0,23.92-10.71,23.92-23.92S311.09,67.2,297.88,67.2L297.88,67.2z"
    />
    <g>
      <linearGradient
        id="CERTIFICATE_MOBILE_127_"
        gradientUnits="userSpaceOnUse"
        x1="283.7501"
        y1="33.2349"
        x2="312.8483"
        y2="33.2349"
      >
        <stop offset="0" style={{ stopColor: "#C3C3C3" }} />
        <stop offset="0.4719" style={{ stopColor: "#F6F6F6" }} />
        <stop offset="0.4859" style={{ stopColor: "#909090" }} />
        <stop offset="1" style={{ stopColor: "#D7D7D7" }} />
      </linearGradient>
      <polygon
        className="certificate-mobile-135"
        points="312.85,62.16 298.3,52.23 283.75,62.16 283.75,4.31 312.85,4.31 	"
      />
    </g>
    <g>
      <g>
        <g>
          <path
            className="certificate-mobile-125"
            d="M112.55,331.42c0.03,0,0.06,0.01,0.09,0.04l0.24,0.25c-0.17,0.18-0.37,0.33-0.62,0.43
				c-0.24,0.1-0.53,0.15-0.88,0.15c-0.3,0-0.57-0.05-0.81-0.14c-0.24-0.1-0.45-0.23-0.62-0.4c-0.17-0.17-0.3-0.38-0.4-0.62
				c-0.09-0.24-0.14-0.5-0.14-0.79c0-0.29,0.05-0.55,0.15-0.79c0.1-0.24,0.24-0.45,0.42-0.62c0.18-0.17,0.39-0.31,0.64-0.4
				c0.25-0.1,0.52-0.14,0.82-0.14c0.29,0,0.55,0.04,0.78,0.13c0.23,0.09,0.43,0.21,0.6,0.35l-0.2,0.26
				c-0.01,0.02-0.03,0.04-0.05,0.05c-0.02,0.01-0.05,0.02-0.08,0.02c-0.04,0-0.08-0.02-0.13-0.05c-0.05-0.03-0.11-0.07-0.19-0.11
				c-0.08-0.04-0.18-0.08-0.29-0.11c-0.12-0.03-0.26-0.05-0.44-0.05c-0.21,0-0.39,0.03-0.56,0.1s-0.32,0.16-0.44,0.29
				c-0.12,0.12-0.22,0.28-0.29,0.46s-0.1,0.39-0.1,0.62c0,0.23,0.04,0.44,0.11,0.62c0.07,0.18,0.17,0.33,0.29,0.46
				s0.27,0.22,0.44,0.29s0.35,0.1,0.55,0.1c0.12,0,0.23-0.01,0.32-0.02c0.09-0.01,0.18-0.03,0.26-0.06c0.08-0.03,0.16-0.06,0.23-0.1
				c0.07-0.04,0.14-0.09,0.21-0.15c0.01-0.01,0.03-0.02,0.05-0.03C112.52,331.42,112.53,331.42,112.55,331.42z"
          />
          <path
            className="certificate-mobile-125"
            d="M118.42,330.33c0,0.29-0.05,0.55-0.15,0.79c-0.1,0.24-0.24,0.44-0.42,0.62c-0.18,0.17-0.4,0.31-0.65,0.4
				c-0.25,0.1-0.53,0.14-0.84,0.14c-0.3,0-0.58-0.05-0.84-0.14s-0.47-0.23-0.65-0.4s-0.32-0.38-0.42-0.62
				c-0.1-0.24-0.15-0.5-0.15-0.79c0-0.28,0.05-0.55,0.15-0.79c0.1-0.24,0.24-0.45,0.42-0.62s0.4-0.31,0.65-0.4s0.53-0.14,0.84-0.14
				c0.31,0,0.59,0.05,0.84,0.14c0.25,0.1,0.47,0.23,0.65,0.4c0.18,0.17,0.32,0.38,0.42,0.62S118.42,330.04,118.42,330.33z
				 M117.79,330.33c0-0.23-0.03-0.43-0.1-0.61s-0.16-0.33-0.29-0.46s-0.27-0.22-0.45-0.29c-0.17-0.07-0.37-0.1-0.59-0.1
				s-0.41,0.03-0.59,0.1c-0.17,0.07-0.33,0.16-0.45,0.29c-0.12,0.13-0.22,0.28-0.29,0.46c-0.07,0.18-0.1,0.38-0.1,0.61
				c0,0.23,0.03,0.43,0.1,0.61c0.07,0.18,0.16,0.33,0.29,0.46c0.12,0.13,0.28,0.22,0.45,0.29c0.18,0.07,0.37,0.1,0.59,0.1
				s0.41-0.03,0.59-0.1c0.18-0.07,0.33-0.16,0.45-0.29s0.22-0.28,0.29-0.46C117.76,330.76,117.79,330.56,117.79,330.33z"
          />
          <path
            className="certificate-mobile-125"
            d="M124.19,330.33c0,0.29-0.05,0.55-0.15,0.79c-0.1,0.24-0.24,0.44-0.42,0.62c-0.18,0.17-0.4,0.31-0.65,0.4
				c-0.25,0.1-0.53,0.14-0.84,0.14c-0.3,0-0.58-0.05-0.84-0.14s-0.47-0.23-0.65-0.4s-0.32-0.38-0.42-0.62
				c-0.1-0.24-0.15-0.5-0.15-0.79c0-0.28,0.05-0.55,0.15-0.79c0.1-0.24,0.24-0.45,0.42-0.62s0.4-0.31,0.65-0.4s0.53-0.14,0.84-0.14
				c0.31,0,0.59,0.05,0.84,0.14c0.25,0.1,0.47,0.23,0.65,0.4c0.18,0.17,0.32,0.38,0.42,0.62S124.19,330.04,124.19,330.33z
				 M123.56,330.33c0-0.23-0.03-0.43-0.1-0.61s-0.16-0.33-0.29-0.46s-0.27-0.22-0.45-0.29c-0.17-0.07-0.37-0.1-0.59-0.1
				s-0.41,0.03-0.59,0.1c-0.17,0.07-0.33,0.16-0.45,0.29c-0.12,0.13-0.22,0.28-0.29,0.46c-0.07,0.18-0.1,0.38-0.1,0.61
				c0,0.23,0.03,0.43,0.1,0.61c0.07,0.18,0.16,0.33,0.29,0.46c0.12,0.13,0.28,0.22,0.45,0.29c0.18,0.07,0.37,0.1,0.59,0.1
				s0.41-0.03,0.59-0.1c0.18-0.07,0.33-0.16,0.45-0.29s0.22-0.28,0.29-0.46C123.53,330.76,123.56,330.56,123.56,330.33z"
          />
          <path
            className="certificate-mobile-125"
            d="M129.21,332.24h-0.55c-0.11,0-0.19-0.04-0.24-0.12l-1.02-1.33c-0.03-0.04-0.07-0.08-0.11-0.09
				c-0.04-0.02-0.1-0.03-0.17-0.03h-0.4v1.57h-0.61v-3.82h1.19c0.27,0,0.5,0.03,0.69,0.08s0.35,0.12,0.48,0.22
				c0.13,0.09,0.22,0.21,0.28,0.34c0.06,0.13,0.09,0.28,0.09,0.44c0,0.13-0.02,0.26-0.07,0.38s-0.11,0.22-0.19,0.32
				c-0.08,0.09-0.19,0.17-0.31,0.24c-0.12,0.07-0.26,0.12-0.42,0.15c0.08,0.04,0.14,0.1,0.2,0.18L129.21,332.24z M127.28,330.26
				c0.16,0,0.3-0.02,0.42-0.05c0.12-0.04,0.22-0.08,0.3-0.15c0.08-0.06,0.14-0.14,0.18-0.23c0.04-0.09,0.06-0.19,0.06-0.3
				c0-0.22-0.08-0.39-0.23-0.5c-0.16-0.11-0.39-0.17-0.7-0.17h-0.58v1.4H127.28z"
          />
          <path
            className="certificate-mobile-125"
            d="M134.49,330.33c0,0.29-0.05,0.54-0.15,0.78c-0.1,0.23-0.24,0.43-0.42,0.6c-0.18,0.17-0.4,0.3-0.65,0.39
				c-0.25,0.09-0.53,0.14-0.84,0.14h-1.55v-3.82h1.55c0.31,0,0.59,0.05,0.84,0.14c0.25,0.09,0.47,0.22,0.65,0.39
				c0.18,0.17,0.32,0.37,0.42,0.6C134.45,329.78,134.49,330.04,134.49,330.33z M133.87,330.33c0-0.23-0.03-0.43-0.1-0.61
				c-0.07-0.18-0.16-0.33-0.29-0.46c-0.12-0.12-0.27-0.22-0.45-0.29s-0.37-0.1-0.59-0.1h-0.94v2.91h0.94c0.22,0,0.41-0.03,0.59-0.1
				c0.18-0.07,0.33-0.16,0.45-0.29c0.12-0.12,0.22-0.28,0.29-0.46C133.83,330.76,133.87,330.56,133.87,330.33z"
          />
          <path
            className="certificate-mobile-125"
            d="M138.98,331.78l0,0.46h-2.56v-3.82h2.56v0.46h-1.95v1.21h1.57v0.44h-1.57v1.25H138.98z"
          />
          <path
            className="certificate-mobile-125"
            d="M144.27,328.42v3.82h-0.31c-0.05,0-0.09-0.01-0.12-0.02c-0.03-0.02-0.06-0.04-0.1-0.08l-2.33-2.79
				c0,0.05,0.01,0.09,0.01,0.14c0,0.05,0,0.09,0,0.13v2.62h-0.54v-3.82h0.32c0.05,0,0.09,0.01,0.12,0.02
				c0.03,0.01,0.06,0.04,0.09,0.08l2.34,2.79c-0.01-0.05-0.01-0.1-0.01-0.15s0-0.09,0-0.14v-2.6H144.27z"
          />
          <path
            className="certificate-mobile-125"
            d="M149.85,332.24h-0.47c-0.05,0-0.1-0.01-0.13-0.04c-0.03-0.03-0.06-0.06-0.07-0.1l-0.37-0.89h-1.82
				l-0.37,0.89c-0.01,0.03-0.04,0.06-0.07,0.09c-0.04,0.03-0.08,0.04-0.13,0.04h-0.47l1.65-3.82h0.62L149.85,332.24z M148.64,330.81
				l-0.61-1.48c-0.04-0.1-0.09-0.23-0.13-0.38c-0.02,0.08-0.04,0.15-0.06,0.21c-0.02,0.07-0.04,0.12-0.06,0.18l-0.61,1.48H148.64z"
          />
          <path
            className="certificate-mobile-125"
            d="M154.62,331.7c-0.16,0.17-0.34,0.3-0.56,0.4c-0.22,0.1-0.48,0.16-0.78,0.17l-0.05,0.15
				c0.18,0.04,0.31,0.08,0.38,0.15c0.08,0.06,0.12,0.14,0.12,0.24c0,0.06-0.02,0.11-0.05,0.16c-0.03,0.05-0.08,0.09-0.13,0.12
				c-0.06,0.03-0.12,0.06-0.2,0.08c-0.08,0.02-0.17,0.03-0.26,0.03c-0.08,0-0.15-0.01-0.22-0.02c-0.07-0.01-0.14-0.03-0.2-0.06
				l0.05-0.16c0.01-0.03,0.04-0.05,0.07-0.05c0.01,0,0.03,0,0.04,0.01c0.01,0.01,0.03,0.01,0.05,0.02c0.02,0.01,0.04,0.01,0.07,0.02
				c0.03,0.01,0.06,0.01,0.1,0.01c0.08,0,0.13-0.01,0.17-0.04c0.04-0.03,0.06-0.06,0.06-0.11c0-0.06-0.04-0.11-0.12-0.13
				c-0.08-0.03-0.19-0.05-0.34-0.07l0.12-0.33c-0.27-0.02-0.52-0.08-0.75-0.18s-0.41-0.24-0.57-0.41c-0.16-0.17-0.28-0.37-0.36-0.6
				c-0.08-0.23-0.13-0.48-0.13-0.76c0-0.29,0.05-0.55,0.15-0.79c0.1-0.24,0.24-0.45,0.42-0.62c0.18-0.17,0.39-0.31,0.64-0.4
				c0.25-0.1,0.52-0.14,0.82-0.14c0.29,0,0.55,0.04,0.78,0.13c0.23,0.09,0.43,0.21,0.6,0.35l-0.2,0.26
				c-0.01,0.02-0.03,0.04-0.05,0.05c-0.02,0.01-0.05,0.02-0.08,0.02c-0.04,0-0.08-0.02-0.13-0.05c-0.05-0.03-0.11-0.07-0.19-0.11
				c-0.08-0.04-0.18-0.08-0.29-0.11c-0.12-0.03-0.26-0.05-0.44-0.05c-0.21,0-0.39,0.03-0.56,0.1s-0.32,0.16-0.44,0.29
				c-0.12,0.12-0.22,0.28-0.29,0.46s-0.1,0.39-0.1,0.62c0,0.23,0.04,0.44,0.11,0.62c0.07,0.18,0.17,0.33,0.29,0.46
				s0.27,0.22,0.44,0.29s0.35,0.1,0.55,0.1c0.12,0,0.23-0.01,0.32-0.02c0.09-0.01,0.18-0.03,0.26-0.06c0.08-0.03,0.16-0.06,0.23-0.1
				c0.07-0.04,0.14-0.09,0.21-0.15c0.01-0.01,0.03-0.02,0.05-0.03c0.02-0.01,0.04-0.01,0.05-0.01c0.03,0,0.06,0.01,0.09,0.04
				L154.62,331.7z"
          />
          <path
            className="certificate-mobile-125"
            d="M159.8,332.24h-0.47c-0.05,0-0.1-0.01-0.13-0.04c-0.03-0.03-0.06-0.06-0.07-0.1l-0.37-0.89h-1.82
				l-0.37,0.89c-0.01,0.03-0.04,0.06-0.07,0.09c-0.04,0.03-0.08,0.04-0.13,0.04h-0.47l1.65-3.82h0.62L159.8,332.24z M158.2,327.78
				c0.04,0,0.08-0.01,0.12-0.02c0.03-0.01,0.06-0.04,0.08-0.06c0.02-0.02,0.04-0.05,0.05-0.08s0.02-0.06,0.02-0.09h0.34
				c0,0.08-0.01,0.16-0.04,0.23c-0.02,0.07-0.06,0.14-0.11,0.19c-0.05,0.06-0.1,0.1-0.17,0.13c-0.07,0.03-0.14,0.05-0.23,0.05
				c-0.09,0-0.17-0.01-0.24-0.04s-0.13-0.06-0.19-0.09c-0.06-0.03-0.11-0.07-0.17-0.1s-0.1-0.04-0.16-0.04
				c-0.04,0-0.08,0.01-0.11,0.02c-0.03,0.02-0.06,0.04-0.08,0.06s-0.04,0.05-0.05,0.09c-0.01,0.03-0.02,0.06-0.02,0.09h-0.35
				c0-0.08,0.01-0.16,0.04-0.23c0.02-0.07,0.06-0.14,0.11-0.2s0.1-0.1,0.17-0.14c0.07-0.03,0.14-0.05,0.23-0.05
				c0.09,0,0.17,0.01,0.24,0.04c0.07,0.03,0.13,0.06,0.19,0.1c0.06,0.04,0.11,0.07,0.16,0.09S158.15,327.78,158.2,327.78z
				 M158.59,330.81l-0.61-1.48c-0.04-0.1-0.09-0.23-0.13-0.38c-0.02,0.08-0.04,0.15-0.06,0.21c-0.02,0.07-0.04,0.12-0.06,0.18
				l-0.61,1.48H158.59z"
          />
          <path
            className="certificate-mobile-125"
            d="M165.2,330.33c0,0.29-0.05,0.55-0.15,0.79c-0.1,0.24-0.24,0.44-0.42,0.62c-0.18,0.17-0.4,0.31-0.65,0.4
				c-0.25,0.1-0.53,0.14-0.84,0.14c-0.3,0-0.58-0.05-0.84-0.14s-0.47-0.23-0.65-0.4s-0.32-0.38-0.42-0.62
				c-0.1-0.24-0.15-0.5-0.15-0.79c0-0.28,0.05-0.55,0.15-0.79c0.1-0.24,0.24-0.45,0.42-0.62s0.4-0.31,0.65-0.4s0.53-0.14,0.84-0.14
				c0.31,0,0.59,0.05,0.84,0.14c0.25,0.1,0.47,0.23,0.65,0.4c0.18,0.17,0.32,0.38,0.42,0.62S165.2,330.04,165.2,330.33z
				 M164.57,330.33c0-0.23-0.03-0.43-0.1-0.61s-0.16-0.33-0.29-0.46s-0.27-0.22-0.45-0.29c-0.17-0.07-0.37-0.1-0.59-0.1
				s-0.41,0.03-0.59,0.1c-0.17,0.07-0.33,0.16-0.45,0.29c-0.12,0.13-0.22,0.28-0.29,0.46c-0.07,0.18-0.1,0.38-0.1,0.61
				c0,0.23,0.03,0.43,0.1,0.61c0.07,0.18,0.16,0.33,0.29,0.46c0.12,0.13,0.28,0.22,0.45,0.29c0.18,0.07,0.37,0.1,0.59,0.1
				s0.41-0.03,0.59-0.1c0.18-0.07,0.33-0.16,0.45-0.29s0.22-0.28,0.29-0.46C164.54,330.76,164.57,330.56,164.57,330.33z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            className="certificate-mobile-125"
            d="M173.32,332.02l-1.24-1.68c-0.11,0.01-0.24,0.02-0.36,0.02h-1.42v1.66h-0.82v-5.46h2.23
				c1.49,0,2.39,0.72,2.39,1.9c0,0.84-0.46,1.45-1.26,1.72l1.36,1.83H173.32z M173.3,328.47c0-0.78-0.55-1.22-1.59-1.22h-1.39v2.46
				h1.39C172.75,329.7,173.3,329.25,173.3,328.47z"
          />
          <path
            className="certificate-mobile-125"
            d="M179.23,330.21h-3.52c0.1,0.72,0.71,1.21,1.57,1.21c0.51,0,0.93-0.16,1.25-0.5l0.43,0.48
				c-0.39,0.44-0.99,0.67-1.71,0.67c-1.4,0-2.33-0.88-2.33-2.11c0-1.22,0.92-2.11,2.18-2.11c1.25,0,2.14,0.86,2.14,2.13
				C179.25,330.04,179.24,330.14,179.23,330.21z M175.72,329.67h2.78c-0.08-0.69-0.63-1.19-1.39-1.19
				C176.35,328.48,175.8,328.97,175.72,329.67z"
          />
          <path
            className="certificate-mobile-125"
            d="M184.45,329.65v2.38h-0.79v-2.29c0-0.81-0.43-1.21-1.17-1.21c-0.83,0-1.37,0.48-1.37,1.37v2.13h-0.79
				v-4.13h0.75v0.62c0.32-0.42,0.88-0.66,1.56-0.66C183.71,327.85,184.45,328.43,184.45,329.65z"
          />
          <path
            className="certificate-mobile-125"
            d="M189.35,329.53v2.5h-0.75v-0.55c-0.26,0.37-0.74,0.59-1.42,0.59c-0.98,0-1.6-0.5-1.6-1.22
				c0-0.66,0.45-1.21,1.74-1.21h1.24v-0.15c0-0.63-0.38-1-1.16-1c-0.51,0-1.04,0.17-1.37,0.44l-0.33-0.56
				c0.45-0.34,1.1-0.53,1.79-0.53C188.68,327.85,189.35,328.4,189.35,329.53z M188.56,330.76v-0.58h-1.21c-0.78,0-1,0.29-1,0.64
				c0,0.41,0.36,0.67,0.96,0.67C187.9,331.49,188.37,331.24,188.56,330.76z"
          />
          <path
            className="certificate-mobile-125"
            d="M193.24,331.78c-0.24,0.2-0.6,0.29-0.95,0.29c-0.88,0-1.37-0.46-1.37-1.29v-2.27h-0.74v-0.62h0.74v-0.9
				h0.79v0.9h1.24v0.62h-1.24v2.24c0,0.44,0.25,0.69,0.68,0.69c0.23,0,0.45-0.07,0.61-0.19L193.24,331.78z"
          />
          <path
            className="certificate-mobile-125"
            d="M197.63,329.53v2.5h-0.74v-0.55c-0.26,0.37-0.75,0.59-1.42,0.59c-0.98,0-1.6-0.5-1.6-1.22
				c0-0.66,0.45-1.21,1.74-1.21h1.24v-0.15c0-0.63-0.38-1-1.16-1c-0.51,0-1.04,0.17-1.37,0.44l-0.33-0.56
				c0.45-0.34,1.1-0.53,1.79-0.53C196.96,327.85,197.63,328.4,197.63,329.53z M196.85,330.76v-0.58h-1.21c-0.78,0-1,0.29-1,0.64
				c0,0.41,0.36,0.67,0.96,0.67C196.18,331.49,196.65,331.24,196.85,330.76z"
          />
          <path
            className="certificate-mobile-125"
            d="M200.88,331.4l0.3-0.61c0.43,0.37,1.15,0.63,1.89,0.63c0.99,0,1.42-0.37,1.42-0.84
				c0-1.33-3.47-0.49-3.47-2.52c0-0.84,0.69-1.56,2.19-1.56c0.67,0,1.37,0.17,1.84,0.48l-0.27,0.62c-0.5-0.3-1.06-0.44-1.57-0.44
				c-0.97,0-1.39,0.39-1.39,0.87c0,1.33,3.47,0.5,3.47,2.5c0,0.83-0.7,1.55-2.22,1.55C202.2,332.09,201.34,331.81,200.88,331.4z"
          />
          <path
            className="certificate-mobile-125"
            d="M209.81,329.53v2.5h-0.75v-0.55c-0.26,0.37-0.74,0.59-1.42,0.59c-0.98,0-1.6-0.5-1.6-1.22
				c0-0.66,0.45-1.21,1.74-1.21h1.24v-0.15c0-0.63-0.38-1-1.16-1c-0.51,0-1.04,0.17-1.37,0.44l-0.33-0.56
				c0.45-0.34,1.1-0.53,1.79-0.53C209.14,327.85,209.81,328.4,209.81,329.53z M209.02,330.76v-0.58h-1.21c-0.78,0-1,0.29-1,0.64
				c0,0.41,0.36,0.67,0.96,0.67C208.36,331.49,208.83,331.24,209.02,330.76z"
          />
          <path
            className="certificate-mobile-125"
            d="M215.38,329.65v2.38h-0.79v-2.29c0-0.81-0.43-1.21-1.17-1.21c-0.83,0-1.37,0.48-1.37,1.37v2.13h-0.79
				v-4.13h0.75v0.62c0.32-0.42,0.88-0.66,1.56-0.66C214.64,327.85,215.38,328.43,215.38,329.65z"
          />
          <path
            className="certificate-mobile-125"
            d="M216.29,331.59l0.33-0.59c0.37,0.25,0.96,0.43,1.52,0.43c0.73,0,1.03-0.21,1.03-0.56
				c0-0.93-2.74-0.12-2.74-1.77c0-0.74,0.7-1.24,1.81-1.24c0.56,0,1.2,0.14,1.58,0.38l-0.34,0.59c-0.39-0.24-0.83-0.33-1.25-0.33
				c-0.69,0-1.02,0.24-1.02,0.57c0,0.97,2.75,0.18,2.75,1.79c0,0.75-0.72,1.22-1.87,1.22C217.36,332.07,216.65,331.86,216.29,331.59
				z"
          />
          <path
            className="certificate-mobile-125"
            d="M220.54,329.96c0-1.23,0.96-2.11,2.26-2.11c1.3,0,2.25,0.87,2.25,2.11c0,1.23-0.95,2.11-2.25,2.11
				C221.49,332.07,220.54,331.19,220.54,329.96z M224.25,329.96c0-0.88-0.62-1.45-1.46-1.45s-1.46,0.57-1.46,1.45
				s0.63,1.46,1.46,1.46S224.25,330.84,224.25,329.96z"
          />
          <path
            className="certificate-mobile-125"
            d="M230.25,329.65v2.38h-0.79v-2.29c0-0.81-0.43-1.21-1.17-1.21c-0.83,0-1.37,0.48-1.37,1.37v2.13h-0.79
				v-4.13h0.75v0.62c0.32-0.42,0.88-0.66,1.56-0.66C229.5,327.85,230.25,328.43,230.25,329.65z"
          />
          <path
            className="certificate-mobile-125"
            d="M231.57,326.61c0-0.27,0.23-0.49,0.53-0.49s0.53,0.21,0.53,0.48c0,0.28-0.22,0.5-0.53,0.5
				C231.8,327.1,231.57,326.89,231.57,326.61z M231.71,327.89h0.79v4.13h-0.79V327.89z"
          />
        </g>
      </g>
    </g>
    <g>
      <g>
        <g>
          <g>
            <path
              className="certificate-mobile-125"
              d="M199.91,296.37v1.5h1.94v0.54h-1.94v1.9h-0.72v-4.48h3v0.54H199.91z"
            />
            <path
              className="certificate-mobile-125"
              d="M207.96,300.31h-0.55c-0.06,0-0.11-0.01-0.16-0.04c-0.04-0.03-0.07-0.07-0.09-0.11l-0.43-1.04h-2.14
					l-0.43,1.04c-0.02,0.04-0.04,0.08-0.09,0.11c-0.04,0.03-0.09,0.05-0.16,0.05h-0.55l1.93-4.48h0.73L207.96,300.31z
					 M206.54,298.64l-0.72-1.74c-0.05-0.12-0.1-0.27-0.16-0.45c-0.03,0.09-0.05,0.17-0.08,0.25c-0.03,0.08-0.05,0.15-0.08,0.21
					l-0.71,1.73H206.54z"
            />
            <path
              className="certificate-mobile-125"
              d="M213.17,299.35c0.04,0,0.08,0.01,0.1,0.04l0.28,0.29c-0.2,0.22-0.44,0.38-0.72,0.5
					c-0.29,0.12-0.63,0.18-1.03,0.18c-0.35,0-0.67-0.06-0.95-0.17c-0.29-0.11-0.53-0.27-0.73-0.47s-0.36-0.44-0.47-0.72
					c-0.11-0.28-0.17-0.59-0.17-0.93s0.06-0.65,0.17-0.93c0.12-0.28,0.28-0.52,0.49-0.72c0.21-0.2,0.46-0.36,0.75-0.47
					c0.29-0.11,0.61-0.17,0.97-0.17c0.34,0,0.65,0.05,0.92,0.16c0.27,0.1,0.5,0.24,0.7,0.42l-0.24,0.31
					c-0.02,0.02-0.04,0.04-0.06,0.06s-0.05,0.02-0.09,0.02c-0.04,0-0.09-0.02-0.15-0.06c-0.06-0.04-0.13-0.08-0.23-0.13
					c-0.09-0.05-0.21-0.09-0.35-0.13c-0.14-0.04-0.31-0.06-0.51-0.06c-0.24,0-0.46,0.04-0.66,0.12c-0.2,0.08-0.37,0.19-0.52,0.34
					s-0.26,0.33-0.34,0.54c-0.08,0.21-0.12,0.45-0.12,0.72c0,0.27,0.04,0.51,0.12,0.73c0.08,0.21,0.2,0.39,0.34,0.54
					c0.15,0.15,0.32,0.26,0.52,0.34s0.41,0.12,0.64,0.12c0.14,0,0.26-0.01,0.38-0.02s0.21-0.04,0.31-0.07
					c0.09-0.03,0.18-0.07,0.27-0.12s0.17-0.11,0.25-0.18c0.02-0.01,0.04-0.03,0.06-0.04C213.12,299.36,213.14,299.35,213.17,299.35z
					"
            />
            <path
              className="certificate-mobile-125"
              d="M216.35,300.31h-0.72v-4.48h0.72V300.31z"
            />
            <path
              className="certificate-mobile-125"
              d="M221.67,299.76v0.56h-2.77v-4.48h0.72v3.92H221.67z"
            />
            <path
              className="certificate-mobile-125"
              d="M224.45,300.31h-0.72v-4.48h0.72V300.31z"
            />
            <path
              className="certificate-mobile-125"
              d="M230.3,296.39h-1.54v3.93h-0.71v-3.93h-1.55v-0.56h3.8V296.39z"
            />
            <path
              className="certificate-mobile-125"
              d="M235.87,300.31h-0.55c-0.06,0-0.11-0.01-0.16-0.04c-0.04-0.03-0.07-0.07-0.09-0.11l-0.43-1.04h-2.14
					l-0.43,1.04c-0.02,0.04-0.04,0.08-0.09,0.11c-0.04,0.03-0.09,0.05-0.16,0.05h-0.55l1.93-4.48h0.73L235.87,300.31z
					 M234.45,298.64l-0.72-1.74c-0.05-0.12-0.1-0.27-0.16-0.45c-0.03,0.09-0.05,0.17-0.08,0.25c-0.03,0.08-0.05,0.15-0.08,0.21
					l-0.71,1.73H234.45z"
            />
            <path
              className="certificate-mobile-125"
              d="M242.05,298.07c0,0.33-0.06,0.64-0.17,0.91c-0.12,0.28-0.28,0.51-0.49,0.71
					c-0.21,0.2-0.46,0.35-0.76,0.46s-0.62,0.16-0.98,0.16h-1.82v-4.48h1.82c0.36,0,0.69,0.05,0.98,0.16s0.55,0.26,0.76,0.46
					c0.21,0.2,0.38,0.43,0.49,0.71C241.99,297.44,242.05,297.74,242.05,298.07z M241.32,298.07c0-0.27-0.04-0.5-0.12-0.72
					c-0.08-0.21-0.19-0.39-0.34-0.54c-0.14-0.15-0.32-0.26-0.53-0.33s-0.44-0.12-0.69-0.12h-1.1v3.41h1.1
					c0.25,0,0.48-0.04,0.69-0.12s0.38-0.19,0.53-0.34c0.15-0.15,0.26-0.33,0.34-0.54C241.28,298.58,241.32,298.34,241.32,298.07z"
            />
            <path
              className="certificate-mobile-125"
              d="M248.82,298.07c0,0.33-0.06,0.64-0.17,0.92c-0.12,0.28-0.28,0.52-0.49,0.72
					c-0.21,0.2-0.46,0.36-0.76,0.47s-0.62,0.17-0.98,0.17c-0.36,0-0.68-0.06-0.98-0.17s-0.55-0.27-0.76-0.47
					c-0.21-0.2-0.37-0.44-0.49-0.72c-0.12-0.28-0.17-0.59-0.17-0.92c0-0.33,0.06-0.64,0.17-0.92c0.12-0.28,0.28-0.52,0.49-0.73
					c0.21-0.2,0.46-0.36,0.76-0.47s0.62-0.17,0.98-0.17c0.36,0,0.69,0.06,0.98,0.17s0.55,0.27,0.76,0.47
					c0.21,0.2,0.38,0.44,0.49,0.73C248.76,297.43,248.82,297.74,248.82,298.07z M248.09,298.07c0-0.27-0.04-0.51-0.12-0.72
					c-0.08-0.21-0.19-0.39-0.34-0.54c-0.15-0.15-0.32-0.26-0.53-0.34s-0.44-0.12-0.69-0.12s-0.48,0.04-0.69,0.12
					s-0.38,0.19-0.53,0.34c-0.15,0.15-0.26,0.33-0.34,0.54c-0.08,0.21-0.12,0.45-0.12,0.72c0,0.27,0.04,0.51,0.12,0.72
					c0.08,0.21,0.19,0.39,0.34,0.54s0.32,0.26,0.53,0.34s0.44,0.12,0.69,0.12s0.48-0.04,0.69-0.12s0.38-0.19,0.53-0.34
					s0.26-0.33,0.34-0.54C248.05,298.58,248.09,298.34,248.09,298.07z"
            />
            <path
              className="certificate-mobile-125"
              d="M254.71,300.31h-0.64c-0.13,0-0.22-0.05-0.28-0.14l-1.2-1.55c-0.04-0.05-0.08-0.09-0.12-0.11
					c-0.04-0.02-0.11-0.03-0.2-0.03h-0.47v1.84h-0.72v-4.48h1.4c0.31,0,0.58,0.03,0.81,0.09c0.23,0.06,0.41,0.14,0.56,0.25
					s0.26,0.24,0.33,0.39c0.07,0.15,0.11,0.33,0.11,0.52c0,0.16-0.03,0.3-0.08,0.44c-0.05,0.14-0.13,0.26-0.23,0.37
					c-0.1,0.11-0.22,0.2-0.37,0.28c-0.14,0.08-0.31,0.14-0.49,0.18c0.09,0.05,0.17,0.12,0.23,0.21L254.71,300.31z M252.45,297.99
					c0.18,0,0.35-0.02,0.49-0.06c0.14-0.04,0.26-0.1,0.35-0.18s0.17-0.17,0.21-0.27c0.05-0.1,0.07-0.22,0.07-0.35
					c0-0.26-0.09-0.45-0.28-0.58s-0.46-0.2-0.83-0.2h-0.68v1.64H252.45z"
            />
            <path
              className="certificate-mobile-125"
              d="M260.68,300.31h-0.55c-0.06,0-0.11-0.01-0.16-0.04c-0.04-0.03-0.07-0.07-0.09-0.11l-0.43-1.04h-2.14
					l-0.43,1.04c-0.02,0.04-0.04,0.08-0.09,0.11c-0.04,0.03-0.09,0.05-0.16,0.05h-0.55l1.93-4.48h0.73L260.68,300.31z
					 M259.26,298.64l-0.72-1.74c-0.05-0.12-0.1-0.27-0.16-0.45c-0.03,0.09-0.05,0.17-0.08,0.25c-0.03,0.08-0.05,0.15-0.08,0.21
					l-0.71,1.73H259.26z"
            />
          </g>
        </g>
        <line
          className="certificate-mobile-136"
          x1="169.58"
          y1="288.89"
          x2="290.63"
          y2="288.89"
        />
        <g>
          <path
            className="certificate-mobile-137"
            d="M176.29,276.67c0-0.82-0.38-1.22-1.15-1.22c-0.11,0-0.24,0.01-0.39,0.03c-0.15,0.02-0.26-0.02-0.32-0.12
				c-0.09-0.13,0.06-0.22,0.46-0.27c0.14-0.02,0.28-0.04,0.43-0.04c0.69,0,1.15,0.3,1.4,0.89c0.11,0.25,0.16,0.56,0.16,0.95
				s-0.17,0.87-0.5,1.44c-0.58,1.05-1.49,1.84-2.73,2.36c-0.41,0.18-0.84,0.27-1.29,0.27s-0.9-0.11-1.37-0.34
				c-0.88-0.41-1.48-1.11-1.83-2.07c-0.13-0.38-0.19-0.85-0.19-1.43s0.16-1.3,0.48-2.17c0.73-1.71,1.94-3.17,3.62-4.38
				c1.8-1.3,3.47-1.95,5.03-1.95c1.32,0,2.21,0.53,2.66,1.6c0.2,0.46,0.3,1.02,0.3,1.67c0,0.21-0.01,0.43-0.04,0.66
				c-0.12,1.03-0.43,2.2-0.92,3.53c-0.08,0.22-0.12,0.38-0.12,0.45c0,0.08,0.02,0.12,0.05,0.12c0.06,0.01,0.16-0.11,0.3-0.37
				c0.87-1.59,1.84-3.02,2.89-4.29c0.34-0.41,0.76-0.75,1.24-1.01c0.53-0.28,1.01-0.43,1.43-0.43c0.42,0,0.75,0.15,0.99,0.46
				c0.24,0.31,0.36,0.72,0.36,1.22c0,1.19-0.47,3.18-1.4,5.97c-0.19,0.56-0.31,0.91-0.35,1.06c0.96-1.5,2.16-3.15,3.61-4.96
				c1.45-1.8,2.73-2.87,3.86-3.2c0.2-0.06,0.38-0.09,0.53-0.09c0.45,0,0.74,0.22,0.87,0.67c0.05,0.15,0.07,0.33,0.07,0.53
				c0,0.6-0.24,1.38-0.71,2.32c-0.97,1.94-1.63,3.48-1.99,4.63c-0.48,1.51-0.73,2.55-0.73,3.1s0.05,1.02,0.16,1.38
				c0.21,0.73,0.77,1.1,1.68,1.1c0.05,0,0.14,0,0.29-0.01c0.15-0.01,0.23,0.02,0.25,0.08c0.02,0.06-0.02,0.11-0.12,0.16
				c-0.1,0.05-0.2,0.07-0.3,0.07s-0.18-0.01-0.24-0.02c-1.87-0.19-2.8-1.15-2.8-2.87c0-0.63,0.13-1.34,0.38-2.14
				c0.25-0.8,0.47-1.41,0.65-1.83c0.28-0.69,0.88-2.03,1.79-4.02c0.32-0.74,0.48-1.31,0.48-1.7c0-0.39-0.17-0.58-0.5-0.58
				c-0.46,0-1.18,0.47-2.14,1.42c-0.5,0.5-1.03,1.1-1.61,1.82c-0.58,0.72-1.44,1.97-2.57,3.76c-1.13,1.79-1.86,3.05-2.16,3.78
				c-0.27,0.64-0.63,0.96-1.08,0.96c-0.32,0-0.51-0.11-0.57-0.34c-0.04-0.09-0.04-0.17,0-0.22c0.04-0.05,0.15-0.25,0.34-0.59
				c0.19-0.34,0.38-0.7,0.58-1.08s0.44-0.89,0.72-1.54s0.54-1.31,0.77-1.99c0.57-1.67,0.95-3.38,1.15-5.14
				c0.02-0.2,0.04-0.45,0.04-0.75c0-0.3-0.08-0.54-0.24-0.72c-0.16-0.18-0.33-0.27-0.5-0.27c-0.58,0-1.22,0.44-1.92,1.31
				c-0.95,1.17-1.77,2.32-2.46,3.45s-1.23,2.08-1.6,2.84c-0.37,0.77-0.8,1.69-1.29,2.77s-0.75,1.66-0.78,1.73
				c-0.03,0.07-0.07,0.16-0.12,0.27c-0.05,0.11-0.1,0.21-0.13,0.3c-0.15,0.34-0.48,0.51-0.99,0.51c-0.3,0-0.46-0.09-0.48-0.28
				c0-0.04,0.01-0.08,0.02-0.12c0.78-1.21,1.64-3.03,2.59-5.48c1.21-3.1,1.81-5.35,1.81-6.77c0-1.69-0.7-2.54-2.09-2.54
				c-0.9,0-2.03,0.43-3.39,1.29c-1.37,0.86-2.5,1.97-3.39,3.31c-0.35,0.53-0.62,1.05-0.8,1.54c-0.31,0.91-0.46,1.63-0.46,2.16
				c0,1.52,0.51,2.53,1.52,3.01c0.35,0.18,0.71,0.27,1.08,0.27c1.28,0,2.32-0.69,3.14-2.07
				C176.09,277.8,176.29,277.19,176.29,276.67z"
          />
          <path
            className="certificate-mobile-137"
            d="M197.35,279.66c-0.39,0.75-0.62,1.31-0.7,1.68c-0.08,0.37-0.12,0.67-0.12,0.9s0.09,0.35,0.27,0.35
				c0.22,0,0.5-0.21,0.83-0.62c0.48-0.6,0.96-1.39,1.44-2.36c0.05-0.11,0.1-0.17,0.16-0.18c0.09-0.04,0.14,0,0.14,0.11
				c0,0.06-0.02,0.13-0.05,0.21c-1.09,2.2-2,3.3-2.75,3.3c-0.34,0-0.62-0.17-0.83-0.5c-0.11-0.18-0.16-0.41-0.16-0.71
				c0-0.29,0.06-0.66,0.18-1.08c-0.95,1.52-1.76,2.29-2.45,2.29c-0.19,0-0.36-0.06-0.51-0.18c-0.35-0.25-0.53-0.67-0.53-1.28
				c0-1.16,0.5-2.33,1.51-3.53c0.57-0.66,1.26-1.09,2.09-1.29c0.22-0.06,0.44-0.09,0.64-0.09c0.54,0,1,0.19,1.38,0.57
				c0.12,0.13,0.15,0.25,0.11,0.37c-0.05,0.12-0.15,0.17-0.3,0.14c-0.13-0.43-0.41-0.64-0.85-0.64c-0.51,0-1.13,0.4-1.88,1.21
				c-0.43,0.47-0.82,1.03-1.19,1.67c-0.46,0.77-0.69,1.38-0.69,1.84s0.15,0.69,0.44,0.69c0.71,0,1.64-1.09,2.78-3.26
				c0.22-0.44,0.41-0.74,0.57-0.9c0.15-0.16,0.35-0.24,0.58-0.24c0.05,0,0.17,0.02,0.36,0.05c0.2,0.04,0.34,0.04,0.43,0.02
				C198.04,278.43,197.74,278.91,197.35,279.66z"
          />
          <path
            className="certificate-mobile-137"
            d="M201.11,275.91c0,0.53-0.34,1.03-1.01,1.51c0.24,0.01,0.55,0.02,0.95,0.02c0.4,0,0.89-0.03,1.5-0.09
				c0.02,0.04,0.04,0.13,0.04,0.28s-0.07,0.38-0.21,0.68c-0.14,0.3-0.27,0.56-0.38,0.78c-0.11,0.22-0.22,0.43-0.32,0.62
				c-0.1,0.19-0.21,0.43-0.34,0.72c-0.12,0.28-0.23,0.54-0.31,0.78c-0.29,0.9-0.25,1.35,0.14,1.35c0.5,0,1.23-0.99,2.2-2.96
				c0.08-0.15,0.15-0.21,0.2-0.18c0.05,0.04,0.08,0.08,0.08,0.13c0,0.05-0.08,0.24-0.23,0.55c-0.15,0.31-0.32,0.64-0.5,0.97
				c-0.18,0.33-0.46,0.72-0.83,1.15c-0.45,0.54-0.88,0.82-1.29,0.82c-0.46,0-0.78-0.28-0.96-0.83c-0.04-0.11-0.05-0.27-0.05-0.48
				s0.07-0.5,0.2-0.85s0.35-0.79,0.65-1.29c0.29-0.51,0.46-0.81,0.5-0.9c0.04-0.09,0.05-0.17,0.05-0.26c0-0.37-0.47-0.56-1.42-0.58
				l-0.21,0.57c-0.75,1.95-1.46,3.26-2.15,3.92c-0.12,0.11-0.18,0.07-0.18-0.11c0-0.04,0.01-0.08,0.04-0.12
				c0.66-0.87,1.24-1.91,1.72-3.1c0.48-1.19,0.82-2.33,1.01-3.4c0.07-0.37,0.24-0.55,0.5-0.55c0.26,0,0.45,0.17,0.57,0.5
				C201.09,275.64,201.11,275.77,201.11,275.91z"
          />
          <path
            className="certificate-mobile-137"
            d="M203.66,282.63c0.22,0,0.46-0.11,0.71-0.34c0.44-0.44,0.99-1.33,1.67-2.68c0.08-0.18,0.17-0.23,0.25-0.16
				c0.02,0.02,0.04,0.06,0.04,0.12c0,0.05-0.05,0.18-0.14,0.39s-0.18,0.39-0.26,0.56c-0.08,0.17-0.22,0.43-0.44,0.81
				c-0.22,0.37-0.42,0.66-0.59,0.86c-0.48,0.56-0.94,0.83-1.37,0.83s-0.74-0.13-0.93-0.39c-0.19-0.26-0.28-0.55-0.28-0.87
				c0-0.32,0.08-0.67,0.23-1.05l1.13-2.89c0.02-0.07,0.04-0.17,0.04-0.28s-0.07-0.27-0.21-0.46l0.97-0.02
				c0.27,0,0.43,0.05,0.48,0.15c0.05,0.1,0.01,0.26-0.11,0.47l-1.42,3.32c-0.2,0.44-0.3,0.81-0.3,1.12
				C203.11,282.45,203.3,282.63,203.66,282.63z M205.4,276.12c-0.17,0.14-0.35,0.21-0.55,0.21c-0.2,0-0.36-0.07-0.48-0.21
				c-0.12-0.14-0.17-0.31-0.14-0.51c0.04-0.2,0.14-0.37,0.3-0.51c0.17-0.14,0.35-0.21,0.55-0.21c0.2,0,0.36,0.07,0.49,0.21
				s0.17,0.31,0.13,0.51S205.57,275.98,205.4,276.12z"
          />
          <path
            className="certificate-mobile-137"
            d="M210.19,279.66c-0.39,0.75-0.62,1.31-0.7,1.68c-0.08,0.37-0.12,0.67-0.12,0.9s0.09,0.35,0.27,0.35
				c0.22,0,0.5-0.21,0.83-0.62c0.48-0.6,0.96-1.39,1.44-2.36c0.05-0.11,0.1-0.17,0.16-0.18c0.09-0.04,0.14,0,0.14,0.11
				c0,0.06-0.02,0.13-0.05,0.21c-1.09,2.2-2,3.3-2.75,3.3c-0.34,0-0.62-0.17-0.83-0.5c-0.11-0.18-0.16-0.41-0.16-0.71
				c0-0.29,0.06-0.66,0.18-1.08c-0.95,1.52-1.76,2.29-2.45,2.29c-0.19,0-0.36-0.06-0.51-0.18c-0.35-0.25-0.53-0.67-0.53-1.28
				c0-1.16,0.5-2.33,1.51-3.53c0.57-0.66,1.26-1.09,2.09-1.29c0.22-0.06,0.44-0.09,0.64-0.09c0.54,0,1,0.19,1.38,0.57
				c0.12,0.13,0.15,0.25,0.11,0.37c-0.05,0.12-0.15,0.17-0.3,0.14c-0.13-0.43-0.41-0.64-0.85-0.64c-0.51,0-1.13,0.4-1.88,1.21
				c-0.43,0.47-0.82,1.03-1.19,1.67c-0.46,0.77-0.69,1.38-0.69,1.84s0.15,0.69,0.44,0.69c0.71,0,1.64-1.09,2.78-3.26
				c0.22-0.44,0.41-0.74,0.57-0.9c0.15-0.16,0.35-0.24,0.58-0.24c0.05,0,0.17,0.02,0.36,0.05c0.2,0.04,0.34,0.04,0.43,0.02
				C210.88,278.43,210.58,278.91,210.19,279.66z"
          />
          <path
            className="certificate-mobile-137"
            d="M226.55,275.13c0.27,0,0.41,0.17,0.41,0.51c0,0.37-0.15,0.71-0.44,1.03c-0.52,0.58-1,1.31-1.44,2.18
				c-0.66,1.32-0.99,2.52-0.99,3.58c0,0.41,0.07,0.71,0.22,0.9c0.15,0.19,0.32,0.28,0.51,0.28c0.57,0,1.23-0.46,1.99-1.38
				c0.67-0.86,1.19-1.7,1.54-2.52c0.09-0.19,0.19-0.27,0.28-0.25s0.1,0.13,0.02,0.32c-0.49,1.08-1.03,2.02-1.65,2.84
				c-0.32,0.44-0.71,0.8-1.19,1.09c-0.47,0.29-0.94,0.43-1.41,0.43s-0.82-0.19-1.05-0.56s-0.35-0.81-0.35-1.32
				c0-0.51,0.06-1.01,0.19-1.51c0.12-0.5,0.29-0.88,0.49-1.15c-1.19,1.43-2.46,2.58-3.81,3.44c-0.71,0.44-1.5,0.7-2.36,0.78
				c-0.08,0.01-0.22,0.02-0.42,0.02c-0.2,0-0.43-0.07-0.7-0.21c-0.85-0.35-1.28-1.22-1.28-2.6c0-1.38,0.45-2.87,1.35-4.48
				c0.47-0.85,0.99-1.6,1.56-2.25c-0.79-0.38-1.38-0.79-1.77-1.22c-0.28-0.32-0.42-0.7-0.42-1.15s0.09-0.73,0.27-0.85
				c0.12-0.15,0.22-0.18,0.32-0.09c0.07,0.11,0.07,0.24,0.01,0.39c-0.07,0.15-0.1,0.3-0.1,0.44c0,0.32,0.17,0.67,0.51,1.06
				c0.34,0.39,0.84,0.74,1.51,1.05c2.78-3.08,5.66-4.63,8.65-4.63c0.93,0,1.68,0.21,2.22,0.63c0.55,0.42,0.82,0.96,0.82,1.62
				c0,0.72-0.4,1.42-1.21,2.09c-0.4,0.35-0.97,0.66-1.72,0.92c-1.13,0.4-2.48,0.6-4.05,0.6c-1.57,0-2.86-0.17-3.89-0.5
				c-1.19,1.69-2.01,3.09-2.46,4.21c-0.45,1.12-0.67,1.98-0.67,2.6c0,0.13,0.01,0.25,0.02,0.35c0.14,0.98,0.64,1.47,1.49,1.47
				c0.96,0,2.07-0.52,3.33-1.56c0.9-0.76,1.78-1.65,2.64-2.69c0.86-1.04,1.5-1.96,1.92-2.75
				C225.84,275.52,226.22,275.13,226.55,275.13z M229.29,271.35c0-0.51-0.21-0.9-0.63-1.17c-0.42-0.27-1-0.41-1.73-0.41
				s-1.46,0.12-2.19,0.35c-0.73,0.24-1.41,0.58-2.04,1.05c-0.63,0.46-1.19,0.93-1.68,1.42s-1.02,1.06-1.59,1.74
				c0.89,0.28,1.97,0.42,3.26,0.42c2.42,0,4.19-0.39,5.3-1.17C228.86,272.98,229.29,272.24,229.29,271.35z"
          />
          <path
            className="certificate-mobile-137"
            d="M236.44,277.2c-1.44,2.56-2.16,4.22-2.16,4.96c0,0.33,0.11,0.5,0.34,0.5c0.28,0,0.61-0.21,0.97-0.64
				c0.56-0.67,1.04-1.48,1.45-2.43c0.07-0.17,0.14-0.23,0.21-0.2c0.07,0.04,0.11,0.08,0.11,0.14c0,0.06-0.06,0.21-0.18,0.44
				s-0.21,0.43-0.28,0.57c-0.07,0.14-0.23,0.41-0.47,0.82s-0.45,0.7-0.62,0.89c-0.17,0.19-0.39,0.38-0.66,0.56
				c-0.27,0.18-0.57,0.28-0.9,0.28s-0.62-0.17-0.86-0.5c-0.11-0.15-0.16-0.36-0.16-0.63s0.06-0.59,0.18-0.97s0.44-0.99,0.98-1.84
				c0.13-0.21,0.19-0.38,0.19-0.5s-0.1-0.19-0.29-0.19s-0.55,0.24-1.05,0.71c-0.51,0.47-1.04,1.11-1.6,1.91
				c-0.56,0.8-0.89,1.45-1,1.93c-0.2,0-0.35-0.08-0.44-0.25s-0.14-0.31-0.14-0.43c0-0.48,0.32-1.41,0.97-2.76
				c0.21-0.45,0.33-0.75,0.34-0.91c0.01-0.16-0.01-0.24-0.06-0.25c-0.05-0.01-0.13,0.01-0.22,0.06c-0.21,0.11-0.54,0.37-0.97,0.78
				c-0.83,0.76-1.64,1.91-2.45,3.46c-0.06,0.13-0.13,0.21-0.22,0.24c-0.09,0.03-0.26,0.05-0.52,0.06s-0.43,0.04-0.51,0.09
				c0.21-0.41,0.58-1.19,1.12-2.32c0.96-2.03,1.6-3.2,1.91-3.51c0.14-0.14,0.34-0.21,0.58-0.21c0.05,0,0.17,0.02,0.36,0.05
				c0.2,0.04,0.34,0.04,0.43,0.02c-0.44,0.24-1.15,1.35-2.14,3.33c0.55-0.69,0.95-1.13,1.2-1.35c0.24-0.21,0.42-0.37,0.54-0.48
				c0.12-0.11,0.21-0.19,0.27-0.25c0.06-0.06,0.16-0.14,0.28-0.26s0.22-0.19,0.28-0.25c0.06-0.05,0.16-0.13,0.27-0.22
				s0.21-0.16,0.28-0.19c0.06-0.03,0.15-0.08,0.27-0.15c0.11-0.07,0.2-0.11,0.25-0.11s0.13-0.01,0.22-0.04s0.16-0.02,0.2,0.03
				c0.24,0.09,0.35,0.24,0.35,0.44c0,0.18-0.2,0.63-0.6,1.37c-0.4,0.73-0.66,1.22-0.78,1.45c0.52-0.65,1.02-1.18,1.49-1.6
				c0.47-0.42,1.03-0.81,1.68-1.16c0.64-0.36,1.11-0.53,1.41-0.53C236.33,277.17,236.39,277.18,236.44,277.2z"
          />
          <path
            className="certificate-mobile-137"
            d="M237.47,280.29c-0.17,0.4-0.25,0.85-0.25,1.35c0,0.33,0.07,0.59,0.22,0.79s0.36,0.29,0.63,0.29
				c1.08,0,2.05-1.01,2.92-3.03c0.08-0.19,0.18-0.27,0.3-0.25c0.07,0.04,0.08,0.12,0.02,0.27c-0.5,1.08-0.88,1.79-1.15,2.14
				c-0.6,0.83-1.35,1.24-2.23,1.24c-0.47,0-0.87-0.15-1.21-0.46c-0.33-0.31-0.5-0.73-0.5-1.26c0-0.53,0.13-1.1,0.38-1.69
				c0.25-0.6,0.6-1.15,1.04-1.67c0.44-0.51,0.94-0.89,1.51-1.12c0.27-0.12,0.53-0.18,0.78-0.18c0.25,0,0.48,0.07,0.69,0.21
				c0.2,0.17,0.3,0.35,0.3,0.55c0,0.71-0.43,1.39-1.28,2.04c-0.26,0.2-0.63,0.38-1.1,0.54
				C238.07,280.21,237.71,280.29,237.47,280.29z M240.44,277.45c0-0.24-0.09-0.35-0.28-0.35c-0.33,0-0.83,0.41-1.51,1.22
				c-0.4,0.5-0.76,1.05-1.06,1.67c0.2,0,0.49-0.07,0.88-0.21s0.72-0.33,1-0.57C240.12,278.65,240.44,278.07,240.44,277.45z
				 M239.36,276.03c-0.01,0-0.03-0.01-0.06-0.03s-0.04-0.05-0.04-0.09c0-0.04,0.01-0.08,0.04-0.12c0.02-0.04,0.19-0.17,0.5-0.41
				s0.65-0.48,1.02-0.74c0.37-0.25,0.63-0.44,0.8-0.57c0.17-0.12,0.33-0.19,0.49-0.19s0.3,0.03,0.43,0.09s0.19,0.13,0.19,0.22
				c0,0.09-0.02,0.17-0.05,0.24c-0.06,0.14-0.59,0.44-1.6,0.89C240.06,275.8,239.49,276.03,239.36,276.03z"
          />
          <path
            className="certificate-mobile-137"
            d="M241.51,280.08c-0.09,0.28-0.17,0.54-0.24,0.8c-0.07,0.25-0.1,0.51-0.1,0.76c0,0.25,0.06,0.49,0.18,0.71
				c0.12,0.22,0.31,0.33,0.57,0.33c0.43,0,0.84-0.29,1.24-0.86c0.4-0.57,0.83-1.3,1.28-2.19c0.09-0.17,0.18-0.23,0.27-0.2
				c0.06,0.05,0.07,0.11,0.04,0.19c-0.04,0.08-0.11,0.24-0.22,0.5c-0.11,0.25-0.19,0.43-0.25,0.53c-0.05,0.1-0.15,0.28-0.28,0.53
				c-0.14,0.25-0.25,0.44-0.35,0.57c-0.1,0.12-0.24,0.28-0.41,0.48c-0.17,0.2-0.33,0.34-0.49,0.43c-0.41,0.26-0.8,0.39-1.17,0.39
				s-0.65-0.08-0.85-0.24c-0.2-0.16-0.32-0.33-0.36-0.51c-0.04-0.18-0.06-0.35-0.06-0.49c0-0.14,0.01-0.33,0.02-0.55
				c0.04-0.38,0.11-0.74,0.22-1.1s0.17-0.56,0.19-0.6c0.27-0.98,0.66-2.05,1.16-3.21c0.5-1.16,0.93-2.03,1.28-2.61
				c0.35-0.58,0.62-0.98,0.82-1.2c0.4-0.42,0.8-0.64,1.19-0.64c0.27,0,0.46,0.2,0.55,0.58c0.01,0.07,0.02,0.16,0.02,0.27
				c0,0.4-0.14,0.91-0.43,1.52c-1.02,2.26-2.23,4.03-3.63,5.32C241.66,279.64,241.6,279.81,241.51,280.08z M243.6,276.71
				c0.59-0.93,1.02-1.73,1.3-2.38c0.28-0.66,0.42-1.1,0.42-1.34s-0.04-0.35-0.12-0.35c-0.12,0-0.34,0.23-0.67,0.7
				s-0.78,1.31-1.34,2.52c-0.56,1.21-1.02,2.27-1.37,3.18C242.41,278.41,243.01,277.64,243.6,276.71z"
          />
          <path
            className="certificate-mobile-137"
            d="M244.79,282.63c0.22,0,0.46-0.11,0.71-0.34c0.44-0.44,0.99-1.33,1.67-2.68c0.08-0.18,0.17-0.23,0.25-0.16
				c0.02,0.02,0.04,0.06,0.04,0.12c0,0.05-0.05,0.18-0.14,0.39s-0.18,0.39-0.26,0.56c-0.08,0.17-0.22,0.43-0.44,0.81
				c-0.22,0.37-0.42,0.66-0.59,0.86c-0.48,0.56-0.94,0.83-1.37,0.83s-0.74-0.13-0.93-0.39c-0.19-0.26-0.28-0.55-0.28-0.87
				c0-0.32,0.08-0.67,0.23-1.05l1.13-2.89c0.02-0.07,0.04-0.17,0.04-0.28s-0.07-0.27-0.21-0.46l0.97-0.02
				c0.27,0,0.43,0.05,0.48,0.15c0.05,0.1,0.01,0.26-0.11,0.47l-1.42,3.32c-0.2,0.44-0.3,0.81-0.3,1.12
				C244.24,282.45,244.42,282.63,244.79,282.63z M246.52,276.12c-0.17,0.14-0.35,0.21-0.55,0.21c-0.2,0-0.36-0.07-0.48-0.21
				c-0.12-0.14-0.17-0.31-0.14-0.51c0.04-0.2,0.14-0.37,0.3-0.51c0.17-0.14,0.35-0.21,0.55-0.21c0.2,0,0.36,0.07,0.49,0.21
				s0.17,0.31,0.13,0.51S246.69,275.98,246.52,276.12z"
          />
          <path
            className="certificate-mobile-137"
            d="M251.31,279.66c-0.39,0.75-0.62,1.31-0.7,1.68c-0.08,0.37-0.12,0.67-0.12,0.9s0.09,0.35,0.27,0.35
				c0.22,0,0.5-0.21,0.83-0.62c0.48-0.6,0.96-1.39,1.44-2.36c0.05-0.11,0.1-0.17,0.16-0.18c0.09-0.04,0.14,0,0.14,0.11
				c0,0.06-0.02,0.13-0.05,0.21c-1.09,2.2-2,3.3-2.75,3.3c-0.34,0-0.62-0.17-0.83-0.5c-0.11-0.18-0.16-0.41-0.16-0.71
				c0-0.29,0.06-0.66,0.18-1.08c-0.95,1.52-1.76,2.29-2.45,2.29c-0.19,0-0.36-0.06-0.51-0.18c-0.35-0.25-0.53-0.67-0.53-1.28
				c0-1.16,0.5-2.33,1.51-3.53c0.57-0.66,1.26-1.09,2.09-1.29c0.22-0.06,0.44-0.09,0.64-0.09c0.54,0,1,0.19,1.38,0.57
				c0.12,0.13,0.15,0.25,0.11,0.37c-0.05,0.12-0.15,0.17-0.3,0.14c-0.13-0.43-0.41-0.64-0.85-0.64c-0.51,0-1.13,0.4-1.88,1.21
				c-0.43,0.47-0.82,1.03-1.19,1.67c-0.46,0.77-0.69,1.38-0.69,1.84s0.15,0.69,0.44,0.69c0.71,0,1.64-1.09,2.78-3.26
				c0.22-0.44,0.41-0.74,0.57-0.9c0.15-0.16,0.35-0.24,0.58-0.24c0.05,0,0.17,0.02,0.36,0.05c0.2,0.04,0.34,0.04,0.43,0.02
				C252,278.43,251.7,278.91,251.31,279.66z"
          />
          <path
            className="certificate-mobile-137"
            d="M272.9,269.54c0,1.03-0.33,2.1-1,3.23c-0.67,1.12-1.59,2.1-2.77,2.93c-1.18,0.83-2.39,1.36-3.64,1.57
				c-0.72,1.45-1.24,2.47-1.56,3.05l-0.37,0.6c2.34,0.58,4.28,1.46,5.81,2.64c0.78,0.6,1.31,1.34,1.58,2.22
				c0.14,0.45,0.21,0.83,0.21,1.15c0,0.32-0.06,0.59-0.19,0.81s-0.27,0.37-0.43,0.45c-0.17,0.08-0.28,0.12-0.35,0.12
				c-0.07,0-0.11-0.03-0.11-0.09c0-0.06,0.07-0.23,0.2-0.52c0.14-0.29,0.2-0.63,0.2-1.02c0-0.39-0.08-0.82-0.25-1.29
				c-0.17-0.47-0.47-0.91-0.92-1.3s-1.07-0.77-1.87-1.12c-0.8-0.35-1.49-0.6-2.07-0.75c-0.58-0.15-1.37-0.32-2.35-0.5
				c-1,1.41-2.05,2.37-3.14,2.89c-0.65,0.32-1.23,0.48-1.73,0.48c-0.5,0-0.95-0.1-1.34-0.3c-0.67-0.33-1.01-0.83-1.01-1.51
				c0-0.67,0.44-1.26,1.33-1.77c1-0.59,2.36-0.89,4.08-0.89c0.62,0,1.17,0.05,1.67,0.14c0.53-0.83,1.15-1.97,1.86-3.42
				c-0.07,0-0.14,0-0.2,0c-0.74,0-1.44-0.12-2.07-0.37c-0.64-0.25-1.15-0.58-1.52-1.01c-0.5-0.56-0.74-1.18-0.74-1.88
				c0-0.37,0.09-0.76,0.27-1.19c0.18-0.42,0.59-0.86,1.22-1.29c0.63-0.44,1.43-0.77,2.4-0.99c0.15-0.04,0.28-0.05,0.39-0.05
				c0.11,0,0.17,0.03,0.19,0.09c0.02,0.06-0.02,0.11-0.14,0.16c-0.12,0.05-0.25,0.08-0.41,0.11c-0.51,0.06-0.98,0.23-1.42,0.51
				c-0.76,0.48-1.28,1.23-1.58,2.23c-0.07,0.24-0.11,0.46-0.11,0.68s0.04,0.48,0.12,0.79c0.08,0.31,0.26,0.62,0.53,0.95
				c0.27,0.33,0.64,0.55,1.11,0.68c0.47,0.13,0.87,0.2,1.22,0.2c0.35,0,0.67-0.02,0.97-0.05l1.51-3.79
				c1.04-2.67,2.28-4.31,3.72-4.93c0.38-0.15,0.75-0.23,1.11-0.23s0.7,0.12,1.03,0.35c0.32,0.23,0.5,0.51,0.52,0.84
				C272.89,269.28,272.9,269.41,272.9,269.54z M262.33,281.62c-0.58-0.13-1.18-0.2-1.79-0.2c-0.62,0-1.25,0.09-1.9,0.27
				c-1.36,0.35-2.16,0.86-2.39,1.51c-0.05,0.12-0.07,0.23-0.07,0.34c0,0.31,0.17,0.56,0.51,0.75s0.71,0.29,1.11,0.29
				c0.56,0,1.16-0.15,1.81-0.46C260.59,283.64,261.5,282.81,262.33,281.62z M272.16,269.56c0-0.45-0.21-0.67-0.62-0.67
				c-0.6,0-1.34,0.53-2.2,1.58c-0.67,0.84-1.32,1.85-1.94,3.04c-0.62,1.19-1.17,2.28-1.66,3.29c1.64-0.37,3.12-1.27,4.44-2.7
				C271.5,272.65,272.16,271.15,272.16,269.56z"
          />
          <path
            className="certificate-mobile-137"
            d="M269.96,280.29c-0.17,0.4-0.25,0.85-0.25,1.35c0,0.33,0.07,0.59,0.22,0.79s0.36,0.29,0.63,0.29
				c1.08,0,2.05-1.01,2.92-3.03c0.08-0.19,0.18-0.27,0.3-0.25c0.05,0.01,0.07,0.04,0.07,0.09c0,0.05-0.13,0.36-0.4,0.93
				c-0.27,0.57-0.53,1.04-0.81,1.39c-0.6,0.83-1.35,1.24-2.23,1.24c-0.47,0-0.87-0.15-1.21-0.46c-0.33-0.31-0.5-0.73-0.5-1.26
				c0-0.53,0.13-1.1,0.38-1.69c0.25-0.6,0.6-1.15,1.04-1.67c0.44-0.51,0.94-0.89,1.51-1.12c0.27-0.12,0.53-0.18,0.78-0.18
				c0.25,0,0.48,0.07,0.69,0.21c0.2,0.17,0.3,0.35,0.3,0.55c0,0.71-0.43,1.39-1.28,2.04c-0.26,0.2-0.63,0.38-1.1,0.54
				C270.57,280.21,270.2,280.29,269.96,280.29z M272.94,277.45c0-0.24-0.09-0.35-0.28-0.35c-0.33,0-0.83,0.41-1.51,1.22
				c-0.4,0.5-0.76,1.05-1.06,1.67c0.2,0,0.49-0.07,0.88-0.21s0.72-0.33,1-0.57C272.61,278.65,272.94,278.07,272.94,277.45z"
          />
          <path
            className="certificate-mobile-137"
            d="M281.57,277.2c-1.44,2.56-2.16,4.22-2.16,4.96c0,0.33,0.11,0.5,0.34,0.5c0.28,0,0.61-0.21,0.97-0.64
				c0.56-0.67,1.04-1.48,1.45-2.43c0.07-0.17,0.14-0.23,0.21-0.2c0.07,0.04,0.11,0.08,0.11,0.14c0,0.06-0.06,0.21-0.18,0.44
				s-0.21,0.43-0.28,0.57c-0.07,0.14-0.23,0.41-0.47,0.82s-0.45,0.7-0.62,0.89c-0.17,0.19-0.39,0.38-0.66,0.56
				c-0.27,0.18-0.57,0.28-0.9,0.28s-0.62-0.17-0.86-0.5c-0.11-0.15-0.16-0.36-0.16-0.63s0.06-0.59,0.18-0.97s0.44-0.99,0.98-1.84
				c0.13-0.21,0.19-0.38,0.19-0.5s-0.1-0.19-0.29-0.19s-0.55,0.24-1.05,0.71c-0.51,0.47-1.04,1.11-1.6,1.91
				c-0.56,0.8-0.89,1.45-1,1.93c-0.2,0-0.35-0.08-0.44-0.25s-0.14-0.31-0.14-0.43c0-0.48,0.32-1.41,0.97-2.76
				c0.21-0.45,0.33-0.75,0.34-0.91c0.01-0.16-0.01-0.24-0.06-0.25c-0.05-0.01-0.13,0.01-0.22,0.06c-0.21,0.11-0.54,0.37-0.97,0.78
				c-0.83,0.76-1.64,1.91-2.45,3.46c-0.06,0.13-0.13,0.21-0.22,0.24c-0.09,0.03-0.26,0.05-0.52,0.06s-0.43,0.04-0.51,0.09
				c0.21-0.41,0.58-1.19,1.12-2.32c0.96-2.03,1.6-3.2,1.91-3.51c0.14-0.14,0.34-0.21,0.58-0.21c0.05,0,0.17,0.02,0.36,0.05
				c0.2,0.04,0.34,0.04,0.43,0.02c-0.44,0.24-1.15,1.35-2.14,3.33c0.55-0.69,0.95-1.13,1.2-1.35c0.24-0.21,0.42-0.37,0.54-0.48
				c0.12-0.11,0.21-0.19,0.27-0.25c0.06-0.06,0.16-0.14,0.28-0.26s0.22-0.19,0.28-0.25c0.06-0.05,0.16-0.13,0.27-0.22
				s0.21-0.16,0.28-0.19c0.06-0.03,0.15-0.08,0.27-0.15c0.11-0.07,0.2-0.11,0.25-0.11s0.13-0.01,0.22-0.04s0.16-0.02,0.2,0.03
				c0.24,0.09,0.35,0.24,0.35,0.44c0,0.18-0.2,0.63-0.6,1.37c-0.4,0.73-0.66,1.22-0.78,1.45c0.52-0.65,1.02-1.18,1.49-1.6
				c0.47-0.42,1.03-0.81,1.68-1.16c0.64-0.36,1.11-0.53,1.41-0.53C281.46,277.17,281.52,277.18,281.57,277.2z"
          />
          <path
            className="certificate-mobile-137"
            d="M285.04,276.81c0.86,0,1.29,0.54,1.29,1.63c0,0.5-0.13,1.03-0.39,1.6c-0.33,0.77-0.83,1.46-1.49,2.09
				c0.15,0.3,0.43,0.44,0.83,0.44c0.32,0,0.62-0.16,0.9-0.48c0.35-0.37,0.61-0.69,0.76-0.97c0.15-0.28,0.27-0.48,0.34-0.61
				c0.07-0.13,0.16-0.32,0.28-0.56s0.18-0.39,0.2-0.44c0.02-0.05,0.06-0.08,0.12-0.08c0.12-0.01,0.18,0.03,0.18,0.12
				c0,0.04-0.01,0.08-0.04,0.12l-0.11,0.25c-0.07,0.17-0.12,0.28-0.16,0.35c-0.04,0.07-0.1,0.2-0.19,0.38s-0.17,0.33-0.23,0.43
				c-0.07,0.11-0.15,0.25-0.27,0.42c-0.11,0.18-0.21,0.32-0.3,0.43s-0.2,0.22-0.33,0.35s-0.25,0.23-0.37,0.3
				c-0.29,0.18-0.57,0.27-0.83,0.27c-0.48,0-0.86-0.15-1.13-0.46c-0.54,0.4-1.05,0.6-1.51,0.6c-0.86,0-1.29-0.54-1.29-1.63
				c0-0.5,0.13-1.03,0.39-1.6c0.35-0.83,0.85-1.53,1.5-2.11C283.85,277.1,284.46,276.81,285.04,276.81z M285.24,277.36
				c-0.32,0-0.7,0.21-1.13,0.64s-0.83,1-1.17,1.74c-0.41,0.85-0.62,1.55-0.62,2.09s0.21,0.82,0.62,0.82c0.3,0,0.63-0.16,0.99-0.48
				c-0.19-0.27-0.28-0.56-0.28-0.87c0.02-0.51,0.23-0.76,0.62-0.76c0.15,0,0.28,0.05,0.38,0.16c0.1,0.11,0.15,0.2,0.15,0.29
				c0,0.09-0.01,0.18-0.04,0.27c0.2-0.29,0.43-0.71,0.69-1.26c0.29-0.6,0.44-1.19,0.44-1.77S285.67,277.36,285.24,277.36z"
          />
          <path
            className="certificate-mobile-137"
            d="M288.92,282.68c0.15,0.02,0.26,0.04,0.32,0.04c0.47,0,0.94-0.28,1.4-0.83c0.25-0.31,0.69-1.08,1.33-2.32
				c0.02-0.06,0.06-0.09,0.12-0.09c0.11-0.01,0.16,0.03,0.16,0.12c0,0.02-0.03,0.09-0.08,0.2c-0.05,0.11-0.1,0.22-0.15,0.34
				c-0.05,0.11-0.12,0.28-0.23,0.49c-0.11,0.21-0.2,0.38-0.27,0.51c-0.08,0.13-0.17,0.29-0.29,0.48c-0.12,0.19-0.24,0.34-0.35,0.44
				l-0.37,0.39c-0.2,0.2-0.44,0.34-0.71,0.43c-0.44,0.14-0.86,0.15-1.28,0.04c-0.27,0.12-0.51,0.18-0.73,0.18
				c-0.84,0-1.35-0.33-1.52-0.99c-0.04-0.15-0.05-0.34-0.05-0.56c0-0.22,0.07-0.43,0.22-0.63c0.15-0.2,0.3-0.31,0.47-0.32
				c0.6-1.15,1.12-2.18,1.54-3.1c0.47-1,1.02-1.51,1.65-1.51c0.17,0,0.33,0.04,0.49,0.12c0.16,0.08,0.22,0.16,0.18,0.26
				c-0.04,0.09-0.13,0.11-0.26,0.05c-0.13-0.06-0.24-0.09-0.34-0.09s-0.18,0.02-0.25,0.05c-0.4,0.19-0.6,0.6-0.6,1.22
				c0,0.33,0.05,0.6,0.14,0.82c0.09,0.21,0.2,0.44,0.32,0.69c0.27,0.62,0.41,1.12,0.41,1.51c0,0.39-0.12,0.77-0.37,1.15
				C289.56,282.14,289.27,282.44,288.92,282.68z M286.99,282.08c0,0.07,0.01,0.15,0.02,0.23c0.09,0.32,0.31,0.48,0.66,0.48
				c0.14,0,0.26-0.02,0.35-0.07c-0.44-0.27-0.67-0.71-0.71-1.33C287.1,281.6,286.99,281.83,286.99,282.08z M288.32,282.56
				c0.52-0.4,0.78-1.05,0.78-1.95c0-0.37-0.04-0.87-0.12-1.51c-0.08-0.64-0.12-1.07-0.12-1.29c0-0.22,0.01-0.38,0.02-0.46
				c-0.9,1.8-1.45,2.89-1.67,3.28c0.25,0,0.37,0.18,0.37,0.53C287.58,281.85,287.82,282.32,288.32,282.56z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="certificate-mobile-125"
              d="M91.49,298.07c0,0.33-0.06,0.64-0.17,0.91c-0.12,0.28-0.28,0.51-0.49,0.71c-0.21,0.2-0.46,0.35-0.76,0.46
					s-0.62,0.16-0.98,0.16h-1.82v-4.48h1.82c0.36,0,0.69,0.05,0.98,0.16s0.55,0.26,0.76,0.46c0.21,0.2,0.38,0.43,0.49,0.71
					C91.43,297.44,91.49,297.74,91.49,298.07z M90.76,298.07c0-0.27-0.04-0.5-0.12-0.72c-0.08-0.21-0.19-0.39-0.34-0.54
					c-0.14-0.15-0.32-0.26-0.53-0.33s-0.44-0.12-0.69-0.12h-1.1v3.41h1.1c0.25,0,0.48-0.04,0.69-0.12s0.38-0.19,0.53-0.34
					c0.15-0.15,0.26-0.33,0.34-0.54C90.72,298.58,90.76,298.34,90.76,298.07z"
            />
            <path
              className="certificate-mobile-125"
              d="M97.61,300.31h-0.55c-0.06,0-0.11-0.01-0.16-0.04c-0.04-0.03-0.07-0.07-0.09-0.11l-0.43-1.04h-2.14
					l-0.43,1.04c-0.02,0.04-0.04,0.08-0.09,0.11c-0.04,0.03-0.09,0.05-0.16,0.05h-0.55l1.93-4.48h0.73L97.61,300.31z M96.19,298.64
					l-0.72-1.74c-0.05-0.12-0.1-0.27-0.16-0.45c-0.03,0.09-0.05,0.17-0.08,0.25c-0.03,0.08-0.05,0.15-0.08,0.21l-0.71,1.73H96.19z"
            />
            <path
              className="certificate-mobile-125"
              d="M102.39,296.39h-1.54v3.93h-0.71v-3.93h-1.55v-0.56h3.8V296.39z"
            />
            <path
              className="certificate-mobile-125"
              d="M107.96,300.31h-0.55c-0.06,0-0.11-0.01-0.16-0.04c-0.04-0.03-0.07-0.07-0.09-0.11l-0.43-1.04h-2.14
					l-0.43,1.04c-0.02,0.04-0.04,0.08-0.09,0.11c-0.04,0.03-0.09,0.05-0.16,0.05h-0.55l1.93-4.48h0.73L107.96,300.31z
					 M106.54,298.64l-0.72-1.74c-0.05-0.12-0.1-0.27-0.16-0.45c-0.03,0.09-0.05,0.17-0.08,0.25c-0.03,0.08-0.05,0.15-0.08,0.21
					l-0.71,1.73H106.54z"
            />
          </g>
        </g>
        <line
          className="certificate-mobile-136"
          x1="46.84"
          y1="288.89"
          x2="147.78"
          y2="288.89"
        />
        <g>
          <g>
            <text
               x="28%"
			   y="78.5%"
			   alignmentBaseline="middle"
			   textAnchor="middle"
              className="certificate-mobile-125 certificate-mobile-st-136 certificate-mobile-st-180"
            >
              {new Date(data?.finish_date).toLocaleDateString("pt-br", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </text>
          </g>
        </g>
      </g>
    </g>
    <radialGradient
      id="CERTIFICATE_MOBILE_128_"
      cx="-1357.7695"
      cy="344.0479"
      r="89.6828"
      gradientTransform="matrix(0.7728 0 0 1.5066 1092.7053 -338.2502)"
      gradientUnits="userSpaceOnUse"
    >
      <stop offset="0.0618" style={{ stopColor: "#BDCAE5" }} />
      <stop offset="0.1353" style={{ stopColor: "#B4C3E2" }} />
      <stop offset="0.5196" style={{ stopColor: "#8AA1D1" }} />
      <stop offset="0.8208" style={{ stopColor: "#708DC7" }} />
      <stop offset="1" style={{ stopColor: "#6685C3" }} />
    </radialGradient>
    <path
      className="certificate-mobile-138"
      d="M81.94,4.33H5.03v351.52h66.18C49.05,313.53,35.3,254.51,35.3,189.2C35.3,113.9,53.58,46.95,81.94,4.33z"
    />
    <linearGradient
      id="CERTIFICATE_MOBILE_129_"
      gradientUnits="userSpaceOnUse"
      x1="97.7644"
      y1="378.1404"
      x2="242.3501"
      y2="-19.1056"
    >
      <stop offset="0.0478" style={{ stopColor: "#9E9E9E" }} />
      <stop offset="0.0769" style={{ stopColor: "#A1A1A1" }} />
      <stop offset="0.1001" style={{ stopColor: "#ABABAB" }} />
      <stop offset="0.1214" style={{ stopColor: "#BCBCBC" }} />
      <stop offset="0.1413" style={{ stopColor: "#D4D4D4" }} />
      <stop offset="0.159" style={{ stopColor: "#F0F0F0" }} />
      <stop offset="0.2102" style={{ stopColor: "#EDEDED" }} />
      <stop offset="0.251" style={{ stopColor: "#E3E4E4" }} />
      <stop offset="0.2882" style={{ stopColor: "#D2D4D5" }} />
      <stop offset="0.3001" style={{ stopColor: "#CBCDCE" }} />
      <stop offset="0.6573" style={{ stopColor: "#C2C4C5" }} />
      <stop offset="0.8112" style={{ stopColor: "#E2E2E3" }} />
      <stop offset="0.8731" style={{ stopColor: "#F0F0F0" }} />
      <stop offset="0.9092" style={{ stopColor: "#EDEDED" }} />
      <stop offset="0.9381" style={{ stopColor: "#E3E3E3" }} />
      <stop offset="0.9645" style={{ stopColor: "#D2D2D2" }} />
      <stop offset="0.9893" style={{ stopColor: "#BABABA" }} />
      <stop offset="1" style={{ stopColor: "#ADADAD" }} />
    </linearGradient>
    <path
      className="certificate-mobile-139"
      d="M13,11.75v335.54h314.12V11.75H13z M326.5,346.34H13.61V12.7H326.5V346.34z"
    />
    <g>
      <path
        className="certificate-mobile-140"
        d="M135,64.16l0-3.21l0-1c0-1.84-0.41-3.28-2.09-4.15c-0.51-0.26-1.38-0.76-1.38-0.76
		c-5.55-3.31-9.26-9.37-9.26-16.3c0-10.48,8.49-18.97,18.97-18.97s18.97,8.49,18.97,18.97c0,8.83-6.03,16.25-14.19,18.37
		c-0.03,0.01-0.07,0.02-0.11,0.03c-0.08,0.02-0.16,0.04-0.23,0.06c-0.09,0.02-0.19,0.05-0.3,0.07c-0.06,0.01-0.13,0.03-0.19,0.04
		c-0.02,0-0.04,0.01-0.06,0.01c-0.04,0.01-0.08,0.02-0.12,0.03c-0.12,0.02-0.23,0.05-0.35,0.07c-0.04,0.01-0.07,0.01-0.11,0.02
		c-0.12,0.02-0.24,0.04-0.36,0.06c-0.03,0.01-0.07,0.01-0.1,0.02c-0.14,0.02-0.28,0.04-0.42,0.06c0,0-0.01,0-0.01,0
		c-0.47,0.06-0.95,0.1-1.44,0.13c0,0,0,0,0,0c-0.11,0.01-0.23,0.01-0.34,0.01c-0.1,0-0.19,0.01-0.27,0.01c-0.12,0-0.24,0-0.35,0
		c-0.01,0-0.01,0-0.01,0c0,0,0,0,0,0c-0.47,0-0.85-0.38-0.85-0.85c0-0.47,0.38-0.85,0.85-0.85c0,0,0.35,0,0.94-0.03
		c0.01,0,0.02,0,0.03,0c0.12-0.01,0.26-0.02,0.4-0.03c0.11-0.01,0.24-0.02,0.37-0.03c8.75-0.85,15.58-8.22,15.58-17.19
		c0-9.54-7.73-17.27-17.27-17.27c-9.54,0-17.27,7.73-17.27,17.27c0,5.33,2.42,10.08,6.21,13.25c0,0,0.47,0.36,0.72,0.5
		c1.38,0.78,3.02,0.84,4.44,0.25c0,0,0,0,0.01,0c0.07-0.03,0.14-0.06,0.21-0.09c0.01,0,0.02-0.01,0.02-0.01
		c0.06-0.03,0.13-0.06,0.19-0.1c0.01-0.01,0.03-0.02,0.04-0.02c0.06-0.03,0.11-0.06,0.17-0.1c0.02-0.01,0.04-0.02,0.06-0.04
		c0.05-0.03,0.1-0.06,0.15-0.09c0.02-0.02,0.05-0.03,0.07-0.05c0.04-0.03,0.09-0.06,0.13-0.09c0.03-0.02,0.05-0.04,0.08-0.06
		c0.04-0.03,0.08-0.06,0.11-0.09c0.03-0.03,0.06-0.05,0.09-0.08c0.03-0.03,0.06-0.05,0.1-0.08c0.04-0.03,0.07-0.07,0.11-0.1
		c0.03-0.02,0.05-0.05,0.08-0.07c0.04-0.04,0.08-0.09,0.13-0.13c0.02-0.02,0.03-0.03,0.05-0.05c0.06-0.06,0.11-0.13,0.17-0.2
		c0.02-0.02,0.04-0.04,0.06-0.07l0-0.01c1.2-1.53,1.38-3.54,0.63-5.2c-0.25-0.55-0.71-1.14-1.28-1.71c0,0,0,0,0,0
		c-1.6-1.31-2.62-3.31-2.62-5.54c0-1.61,0.53-3.09,1.42-4.29c1.31-1.75,3.39-2.88,5.74-2.88c0.06,0,0.12,0,0.18,0
		c0.03,0,0.06,0,0.09,0c0.03,0,0.06,0,0.09,0c0.03,0,0.07,0,0.1,0.01c0.03,0,0.05,0,0.08,0c0.04,0,0.07,0.01,0.11,0.01
		c0.02,0,0.05,0,0.07,0.01c0.04,0,0.08,0.01,0.11,0.01c0.02,0,0.04,0,0.06,0.01c0.04,0,0.08,0.01,0.12,0.02
		c0.02,0,0.04,0.01,0.06,0.01c0.04,0.01,0.08,0.01,0.12,0.02c0.02,0,0.04,0.01,0.06,0.01c0.04,0.01,0.08,0.02,0.12,0.02
		c0.02,0,0.04,0.01,0.05,0.01c0.04,0.01,0.08,0.02,0.12,0.03c0.02,0,0.03,0.01,0.05,0.01c0.04,0.01,0.08,0.02,0.12,0.03
		c0.02,0,0.03,0.01,0.05,0.01c0.04,0.01,0.08,0.02,0.12,0.03c0.02,0,0.03,0.01,0.05,0.01c0.04,0.01,0.08,0.02,0.12,0.04
		c0.01,0,0.03,0.01,0.04,0.01c0.04,0.01,0.08,0.03,0.13,0.04c0.01,0,0.03,0.01,0.04,0.01c0.04,0.01,0.08,0.03,0.13,0.04
		c0.01,0,0.02,0.01,0.04,0.01c0.04,0.02,0.09,0.03,0.13,0.05c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.03,0.13,0.05
		c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.04,0.13,0.06c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.04,0.13,0.06
		c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.04,0.13,0.06c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.04,0.13,0.07
		c0.01,0,0.02,0.01,0.02,0.01c0.04,0.02,0.09,0.05,0.13,0.07c0.01,0,0.01,0.01,0.02,0.01c0.04,0.02,0.09,0.05,0.13,0.08
		c0.01,0,0.01,0.01,0.02,0.01c0.04,0.03,0.09,0.05,0.13,0.08c0.01,0,0.01,0.01,0.02,0.01c0.04,0.03,0.09,0.06,0.13,0.08
		c0,0,0.01,0.01,0.01,0.01c0.04,0.03,0.09,0.06,0.13,0.09c0,0,0.01,0,0.01,0.01c0.04,0.03,0.09,0.06,0.13,0.09c0,0,0.01,0,0.01,0.01
		c0.04,0.03,0.09,0.06,0.13,0.1c0,0,0,0,0.01,0c0.04,0.03,0.09,0.07,0.13,0.1c0,0,0,0,0,0c0.04,0.04,0.09,0.07,0.13,0.11
		c0,0,0,0,0,0c1.51,1.29,2.48,3.2,2.51,5.34c0,0,0,0,0,0c0,0.04,0,0.08,0,0.11c0,1.67-0.6,3.18-1.53,4.43
		c-2.27,3.04-4.72,2.97-4.72,2.97c-0.33-1.02-1.06-2.5-1.85-3.52c-0.62-0.79-1.46-1.75-1.93-2.57c-0.58-1.01-0.69-1.48-0.37-1.96
		c0.34-0.51,1.09-0.42,1.7,0.2c0.79,0.8,1.52,0.79,1.52,0.79h0.01c0,0,0.72,0.05,1.51-0.69c0.68-0.64,1.44-0.83,1.83-0.36
		c0.24,0.29,0.32,0.92-0.32,2.08c-0.07,0.13-0.29,0.52-0.61,0.85c-0.39,0.41-0.46-0.46-1.14-0.85c-0.75-0.43-1.92,0.34-0.85,1.76
		c1.24,1.64,1.13,2.13,1.45,2.05c0.63-0.16,1.43-0.9,1.64-1.11c3.03-3.23,2.04-5.57,1.63-6.27c-0.41-0.7-1.08-1.15-1.91-1.25
		c-0.1-0.01-0.21-0.02-0.31-0.02c0,0,0,0,0,0c0,0,0,0,0,0c-0.03,0-0.06,0-0.09,0c-0.77,0.03-1.52,0.47-2.32,1.34
		c0,0.01-0.26,0.38-0.49,0.38c-0.17,0.01-0.27-0.14-0.51-0.41c-0.85-0.95-1.53-1.29-2.3-1.31c-0.03,0-0.06,0-0.09,0c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.11,0-0.21,0-0.31,0.02c-0.73,0.08-1.36,0.43-1.75,1c-0.57,0.84-0.64,1.64-0.55,2.63c0.09,0.99,0.73,2.29,2.01,3.79
		c0.22,0.26,0.48,0.58,0.69,0.83c0,0,0,0,0.01,0c0,0,0.1,0.11,0.23,0.28c0.01,0.01,0.01,0.01,0.02,0.02
		c0.02,0.03,0.05,0.06,0.07,0.09c0.01,0.02,0.03,0.04,0.04,0.05c0.12,0.15,0.24,0.32,0.35,0.48c0.01,0.02,0.02,0.03,0.03,0.05
		c0.24,0.37,0.45,0.77,0.62,1.2c0.01,0.02,0.01,0.03,0.01,0.03c0,0,0,0,0,0c0.24,0.61,0.39,1.3,0.43,2.09
		c0.02,0.34-0.06,1.01-0.06,1.01c-0.01,0.06-0.01,0.12-0.01,0.18c0,0.59,0.47,1.07,1.06,1.07c0.01,0,0.02,0,0.02,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0.11,0,0.21,0,0.32,0c0.03,0,0.07,0,0.1,0c0.07,0,0.15-0.01,0.22-0.01c0.04,0,0.08,0,0.12-0.01
		c0.07,0,0.13-0.01,0.2-0.01c0.04,0,0.08-0.01,0.12-0.01c0.07-0.01,0.13-0.01,0.2-0.02c0.04,0,0.08-0.01,0.12-0.01
		c0.07-0.01,0.14-0.02,0.21-0.03c0.03,0,0.07-0.01,0.1-0.01c0.08-0.01,0.17-0.03,0.25-0.04c0.02,0,0.04-0.01,0.05-0.01
		c0.1-0.02,0.21-0.04,0.31-0.06c0.02,0,0.04-0.01,0.05-0.01c0.08-0.02,0.16-0.04,0.25-0.05c0.03-0.01,0.06-0.02,0.09-0.02
		c0.07-0.02,0.14-0.03,0.2-0.05c0.03-0.01,0.07-0.02,0.1-0.03c0.07-0.02,0.13-0.04,0.19-0.06c0.03-0.01,0.07-0.02,0.1-0.03
		c0.07-0.02,0.13-0.04,0.2-0.06c0.03-0.01,0.06-0.02,0.09-0.03c0.07-0.02,0.15-0.05,0.22-0.08c0.02-0.01,0.04-0.02,0.07-0.02
		c0.19-0.07,0.38-0.14,0.57-0.22c0.02-0.01,0.04-0.02,0.07-0.03c0.07-0.03,0.14-0.06,0.21-0.09c0.03-0.01,0.06-0.03,0.08-0.04
		c0.06-0.03,0.13-0.06,0.19-0.09c0.01-0.01,0.03-0.01,0.04-0.02c0.02-0.01,0.04-0.02,0.06-0.03c0.04-0.02,0.09-0.04,0.13-0.07
		c0.02-0.01,0.04-0.02,0.06-0.03c2.44-1.29,4.36-3.43,5.35-6.04c0-0.01,0-0.01,0.01-0.02c0.13-0.33,0.24-0.67,0.33-1.02
		c0-0.01,0.01-0.03,0.01-0.04c0.03-0.11,0.06-0.22,0.08-0.33c0,0,0-0.01,0-0.01c0.05-0.23,0.1-0.46,0.14-0.7
		c0-0.02,0.01-0.04,0.01-0.06c0.02-0.1,0.03-0.21,0.05-0.31c0-0.01,0-0.03,0.01-0.04c0.03-0.24,0.05-0.47,0.07-0.71
		c0-0.02,0-0.04,0-0.07c0.01-0.1,0.01-0.2,0.02-0.3c0-0.02,0-0.04,0-0.06c0-0.12,0.01-0.24,0.01-0.36c0-6.18-4.94-11.22-11.08-11.37
		c-0.1,0-0.2,0-0.29,0c-6.28,0-11.38,5.09-11.38,11.38c0,0.12,0,0.25,0.01,0.37c0,0.02,0,0.03,0,0.05c0,0.1,0.01,0.21,0.02,0.31
		c0,0.02,0,0.05,0.01,0.07c0.01,0.1,0.01,0.19,0.02,0.29c0,0.02,0,0.05,0.01,0.07c0.01,0.1,0.02,0.2,0.03,0.3
		c0,0.01,0,0.03,0.01,0.04c0.38,2.87,1.83,5.4,3.94,7.18l0,0c0.36,0.3,0.42,0.82,0.12,1.19c-0.3,0.36-0.82,0.42-1.19,0.12l0,0
		c-0.12-0.1-0.23-0.2-0.34-0.3c-0.03-0.03-0.06-0.05-0.09-0.08c-0.1-0.09-0.2-0.19-0.3-0.29c-2.42-2.37-3.93-5.67-3.93-9.33
		c0-7.22,5.85-13.07,13.07-13.07s13.07,5.85,13.07,13.07c0,6.73-5.09,12.27-11.62,12.98c0,0,0,0,0,0c-0.5,0.05-1,0.08-1.5,0.08
		c-2.79,0-5.06,2.26-5.06,5.06c0,2.79,2.26,5.06,5.06,5.06c0.04,0,0.07,0,0.11,0c12.75-0.06,23.07-10.41,23.07-23.17
		c0-12.8-10.38-23.18-23.18-23.18c-12.8,0-23.18,10.38-23.18,23.18c0,6.57,2.73,12.5,7.13,16.71c1.11,1.06,2.44,2.11,3.74,2.92
		c0.78,0.49,1.27,0.68,1.57,1.2c0.26,0.46,0.25,1.01,0.25,1.67l0,0.93v1.98"
      />
      <g>
        <defs>
          <path
            id="CERTIFICATE_MOBILE_284_"
            d="M130.76,64.16c1.54,0,2.98,0,4.25,0l0-4.22l0,0c0-1.84-0.41-3.28-2.09-4.15
				c-0.51-0.26-1.38-0.76-1.38-0.76c-5.55-3.31-9.26-9.37-9.26-16.3c0-10.48,8.49-18.97,18.97-18.97s18.97,8.49,18.97,18.97
				c0,8.83-6.03,16.25-14.19,18.37c-0.03,0.01-0.07,0.02-0.11,0.03c-0.08,0.02-0.16,0.04-0.23,0.06c-0.09,0.02-0.19,0.05-0.3,0.07
				c-0.06,0.01-0.13,0.03-0.19,0.04c-0.02,0-0.04,0.01-0.06,0.01c-0.04,0.01-0.08,0.02-0.12,0.03c-0.12,0.02-0.23,0.05-0.35,0.07
				c-0.04,0.01-0.07,0.01-0.11,0.02c-0.12,0.02-0.24,0.04-0.36,0.06c-0.03,0.01-0.07,0.01-0.1,0.02c-0.14,0.02-0.28,0.04-0.42,0.06
				c0,0-0.01,0-0.01,0c-0.47,0.06-0.95,0.1-1.44,0.13c0,0,0,0,0,0c-0.11,0.01-0.23,0.01-0.34,0.01c-0.1,0-0.19,0.01-0.27,0.01
				c-0.12,0-0.24,0-0.35,0c-0.01,0-0.01,0-0.01,0c0,0,0,0,0,0c-0.47,0-0.85-0.38-0.85-0.85c0-0.47,0.38-0.85,0.85-0.85
				c0,0,0.35,0,0.94-0.03c0.01,0,0.02,0,0.03,0c0.12-0.01,0.26-0.02,0.4-0.03c0.11-0.01,0.24-0.02,0.37-0.03
				c8.75-0.85,15.58-8.22,15.58-17.19c0-9.54-7.73-17.27-17.27-17.27c-9.54,0-17.27,7.73-17.27,17.27c0,5.33,2.42,10.08,6.21,13.25
				c0,0,0.47,0.36,0.72,0.5c1.38,0.78,3.02,0.84,4.44,0.25c0,0,0,0,0.01,0c0.07-0.03,0.14-0.06,0.21-0.09
				c0.01,0,0.02-0.01,0.02-0.01c0.06-0.03,0.13-0.06,0.19-0.1c0.01-0.01,0.03-0.02,0.04-0.02c0.06-0.03,0.11-0.06,0.17-0.1
				c0.02-0.01,0.04-0.02,0.06-0.04c0.05-0.03,0.1-0.06,0.15-0.09c0.02-0.02,0.05-0.03,0.07-0.05c0.04-0.03,0.09-0.06,0.13-0.09
				c0.03-0.02,0.05-0.04,0.08-0.06c0.04-0.03,0.08-0.06,0.11-0.09c0.03-0.03,0.06-0.05,0.09-0.08c0.03-0.03,0.06-0.05,0.1-0.08
				c0.04-0.03,0.07-0.07,0.11-0.1c0.03-0.02,0.05-0.05,0.08-0.07c0.04-0.04,0.08-0.09,0.13-0.13c0.02-0.02,0.03-0.03,0.05-0.05
				c0.06-0.06,0.11-0.13,0.17-0.2c0.02-0.02,0.04-0.04,0.06-0.07l0-0.01c1.2-1.53,1.38-3.54,0.63-5.2
				c-0.25-0.55-0.71-1.14-1.28-1.71c0,0,0,0,0,0c-1.6-1.31-2.62-3.31-2.62-5.54c0-1.61,0.53-3.09,1.42-4.29
				c1.31-1.75,3.39-2.88,5.74-2.88c0.06,0,0.12,0,0.18,0c0.03,0,0.06,0,0.09,0c0.03,0,0.06,0,0.09,0c0.03,0,0.07,0,0.1,0.01
				c0.03,0,0.05,0,0.08,0c0.04,0,0.07,0.01,0.11,0.01c0.02,0,0.05,0,0.07,0.01c0.04,0,0.08,0.01,0.11,0.01c0.02,0,0.04,0,0.06,0.01
				c0.04,0,0.08,0.01,0.12,0.02c0.02,0,0.04,0.01,0.06,0.01c0.04,0.01,0.08,0.01,0.12,0.02c0.02,0,0.04,0.01,0.06,0.01
				c0.04,0.01,0.08,0.02,0.12,0.02c0.02,0,0.04,0.01,0.05,0.01c0.04,0.01,0.08,0.02,0.12,0.03c0.02,0,0.03,0.01,0.05,0.01
				c0.04,0.01,0.08,0.02,0.12,0.03c0.02,0,0.03,0.01,0.05,0.01c0.04,0.01,0.08,0.02,0.12,0.03c0.02,0,0.03,0.01,0.05,0.01
				c0.04,0.01,0.08,0.02,0.12,0.04c0.01,0,0.03,0.01,0.04,0.01c0.04,0.01,0.08,0.03,0.13,0.04c0.01,0,0.03,0.01,0.04,0.01
				c0.04,0.01,0.08,0.03,0.13,0.04c0.01,0,0.02,0.01,0.04,0.01c0.04,0.02,0.09,0.03,0.13,0.05c0.01,0,0.02,0.01,0.03,0.01
				c0.04,0.02,0.09,0.03,0.13,0.05c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.04,0.13,0.06c0.01,0,0.02,0.01,0.03,0.01
				c0.04,0.02,0.09,0.04,0.13,0.06c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.04,0.13,0.06c0.01,0,0.02,0.01,0.03,0.01
				c0.04,0.02,0.09,0.04,0.13,0.07c0.01,0,0.02,0.01,0.02,0.01c0.04,0.02,0.09,0.05,0.13,0.07c0.01,0,0.01,0.01,0.02,0.01
				c0.04,0.02,0.09,0.05,0.13,0.08c0.01,0,0.01,0.01,0.02,0.01c0.04,0.03,0.09,0.05,0.13,0.08c0.01,0,0.01,0.01,0.02,0.01
				c0.04,0.03,0.09,0.06,0.13,0.08c0,0,0.01,0.01,0.01,0.01c0.04,0.03,0.09,0.06,0.13,0.09c0,0,0.01,0,0.01,0.01
				c0.04,0.03,0.09,0.06,0.13,0.09c0,0,0.01,0,0.01,0.01c0.04,0.03,0.09,0.06,0.13,0.1c0,0,0,0,0.01,0c0.04,0.03,0.09,0.07,0.13,0.1
				c0,0,0,0,0,0c0.04,0.04,0.09,0.07,0.13,0.11c0,0,0,0,0,0c1.51,1.29,2.48,3.2,2.51,5.34c0,0,0,0,0,0c0,0.04,0,0.08,0,0.11
				c0,1.67-0.6,3.18-1.53,4.43c-2.27,3.04-4.72,2.97-4.72,2.97c-0.33-1.02-1.06-2.5-1.85-3.52c-0.62-0.79-1.46-1.75-1.93-2.57
				c-0.58-1.01-0.69-1.48-0.37-1.96c0.34-0.51,1.09-0.42,1.7,0.2c0.79,0.8,1.52,0.79,1.52,0.79h0.01c0,0,0.72,0.05,1.51-0.69
				c0.68-0.64,1.44-0.83,1.83-0.36c0.24,0.29,0.32,0.92-0.32,2.08c-0.07,0.13-0.29,0.52-0.61,0.85c-0.39,0.41-0.46-0.46-1.14-0.85
				c-0.75-0.43-1.92,0.34-0.85,1.76c1.24,1.64,1.13,2.13,1.45,2.05c0.63-0.16,1.43-0.9,1.64-1.11c3.03-3.23,2.04-5.57,1.63-6.27
				c-0.41-0.7-1.08-1.15-1.91-1.25c-0.1-0.01-0.21-0.02-0.31-0.02c0,0,0,0,0,0c0,0,0,0,0,0c-0.03,0-0.06,0-0.09,0
				c-0.77,0.03-1.52,0.47-2.32,1.34c0,0.01-0.26,0.38-0.49,0.38c-0.17,0.01-0.27-0.14-0.51-0.41c-0.85-0.95-1.53-1.29-2.3-1.31
				c-0.03,0-0.06,0-0.09,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.11,0-0.21,0-0.31,0.02c-0.73,0.08-1.36,0.43-1.75,1
				c-0.57,0.84-0.64,1.64-0.55,2.63c0.09,0.99,0.73,2.29,2.01,3.79c0.22,0.26,0.48,0.58,0.69,0.83c0,0,0,0,0.01,0
				c0,0,0.1,0.11,0.23,0.28c0.01,0.01,0.01,0.01,0.02,0.02c0.02,0.03,0.05,0.06,0.07,0.09c0.01,0.02,0.03,0.04,0.04,0.05
				c0.12,0.15,0.24,0.32,0.35,0.48c0.01,0.02,0.02,0.03,0.03,0.05c0.24,0.37,0.45,0.77,0.62,1.2c0.01,0.02,0.01,0.03,0.01,0.03
				c0,0,0,0,0,0c0.24,0.61,0.39,1.3,0.43,2.09c0.02,0.34-0.06,1.01-0.06,1.01c-0.01,0.06-0.01,0.12-0.01,0.18
				c0,0.59,0.47,1.07,1.06,1.07c0.01,0,0.02,0,0.02,0c0,0,0,0,0,0c0,0,0,0,0,0c0.11,0,0.21,0,0.32,0c0.03,0,0.07,0,0.1,0
				c0.07,0,0.15-0.01,0.22-0.01c0.04,0,0.08,0,0.12-0.01c0.07,0,0.13-0.01,0.2-0.01c0.04,0,0.08-0.01,0.12-0.01
				c0.07-0.01,0.13-0.01,0.2-0.02c0.04,0,0.08-0.01,0.12-0.01c0.07-0.01,0.14-0.02,0.21-0.03c0.03,0,0.07-0.01,0.1-0.01
				c0.08-0.01,0.17-0.03,0.25-0.04c0.02,0,0.04-0.01,0.05-0.01c0.1-0.02,0.21-0.04,0.31-0.06c0.02,0,0.04-0.01,0.05-0.01
				c0.08-0.02,0.16-0.04,0.25-0.05c0.03-0.01,0.06-0.02,0.09-0.02c0.07-0.02,0.14-0.03,0.2-0.05c0.03-0.01,0.07-0.02,0.1-0.03
				c0.07-0.02,0.13-0.04,0.19-0.06c0.03-0.01,0.07-0.02,0.1-0.03c0.07-0.02,0.13-0.04,0.2-0.06c0.03-0.01,0.06-0.02,0.09-0.03
				c0.07-0.02,0.15-0.05,0.22-0.08c0.02-0.01,0.04-0.02,0.07-0.02c0.19-0.07,0.38-0.14,0.57-0.22c0.02-0.01,0.04-0.02,0.07-0.03
				c0.07-0.03,0.14-0.06,0.21-0.09c0.03-0.01,0.06-0.03,0.08-0.04c0.06-0.03,0.13-0.06,0.19-0.09c0.01-0.01,0.03-0.01,0.04-0.02
				c0.02-0.01,0.04-0.02,0.06-0.03c0.04-0.02,0.09-0.04,0.13-0.07c0.02-0.01,0.04-0.02,0.06-0.03c2.44-1.29,4.36-3.43,5.35-6.04
				c0-0.01,0-0.01,0.01-0.02c0.13-0.33,0.24-0.67,0.33-1.02c0-0.01,0.01-0.03,0.01-0.04c0.03-0.11,0.06-0.22,0.08-0.33
				c0,0,0-0.01,0-0.01c0.05-0.23,0.1-0.46,0.14-0.7c0-0.02,0.01-0.04,0.01-0.06c0.02-0.1,0.03-0.21,0.05-0.31
				c0-0.01,0-0.03,0.01-0.04c0.03-0.24,0.05-0.47,0.07-0.71c0-0.02,0-0.04,0-0.07c0.01-0.1,0.01-0.2,0.02-0.3c0-0.02,0-0.04,0-0.06
				c0-0.12,0.01-0.24,0.01-0.36c0-6.18-4.94-11.22-11.08-11.37c-0.1,0-0.2,0-0.29,0c-6.28,0-11.38,5.09-11.38,11.38
				c0,0.12,0,0.25,0.01,0.37c0,0.02,0,0.03,0,0.05c0,0.1,0.01,0.21,0.02,0.31c0,0.02,0,0.05,0.01,0.07c0.01,0.1,0.01,0.19,0.02,0.29
				c0,0.02,0,0.05,0.01,0.07c0.01,0.1,0.02,0.2,0.03,0.3c0,0.01,0,0.03,0.01,0.04c0.38,2.87,1.83,5.4,3.94,7.18l0,0
				c0.36,0.3,0.42,0.82,0.12,1.19c-0.3,0.36-0.82,0.42-1.19,0.12l0,0c-0.12-0.1-0.23-0.2-0.34-0.3c-0.03-0.03-0.06-0.05-0.09-0.08
				c-0.1-0.09-0.2-0.19-0.3-0.29c-2.42-2.37-3.93-5.67-3.93-9.33c0-7.22,5.85-13.07,13.07-13.07s13.07,5.85,13.07,13.07
				c0,6.73-5.09,12.27-11.62,12.98c0,0,0,0,0,0c-0.5,0.05-1,0.08-1.5,0.08c-2.79,0-5.06,2.26-5.06,5.06c0,2.79,2.26,5.06,5.06,5.06
				c0.04,0,0.07,0,0.11,0c12.75-0.06,23.07-10.41,23.07-23.17c0-12.8-10.38-23.18-23.18-23.18c-12.8,0-23.18,10.38-23.18,23.18
				c0,6.57,2.73,12.5,7.13,16.71c1.11,1.06,2.44,2.11,3.74,2.92c0.78,0.49,1.27,0.68,1.57,1.2c0.26,0.46,0.25,1.01,0.25,1.67
				c0,0.15,0-1.02,0,0.87L130.76,64.16z"
          />
        </defs>
        <clipPath id="CERTIFICATE_MOBILE_130_">
          <use
            xlinkHref="#CERTIFICATE_MOBILE_284_"
            style={{ overflow: "visible" }}
          />
        </clipPath>
        <path
          className="certificate-mobile-141"
          d="M141.26,26.37c-6.74,0-12.22,5.48-12.22,12.22s5.48,12.22,12.22,12.22c6.74,0,12.22-5.48,12.22-12.22
			S148,26.37,141.26,26.37z M141.35,46.13c-3.83,0-6.94-3.25-6.94-7.24s3.11-7.24,6.94-7.24s6.94,3.25,6.94,7.24
			S145.18,46.13,141.35,46.13z"
        />
        <path
          className="certificate-mobile-142"
          d="M141.26,31.57c-3.88,0-7.03,3.27-7.03,7.28c0,3.01,2.16,5.29,4.3,6.71c0.84,0.37,1.77,0.57,2.73,0.57
			c3.88,0,7.03-3.27,7.03-7.28S145.14,31.57,141.26,31.57z"
        />
        <path
          className="certificate-mobile-143"
          d="M141.27,20.39c-10.06,0-18.25,8.19-18.25,18.25s8.19,18.25,18.25,18.25c10.06,0,18.25-8.19,18.25-18.25
			S151.34,20.39,141.27,20.39z M141.27,50.82c-6.74,0-12.22-5.48-12.22-12.22c0-6.74,5.48-12.22,12.22-12.22s12.22,5.48,12.22,12.22
			C153.49,45.34,148.01,50.82,141.27,50.82z"
        />
        <path
          className="certificate-mobile-144"
          d="M141.23,15.55c-12.78,0-23.18,10.4-23.18,23.17c0,7.95,4.02,14.97,10.13,19.15
			c0.34,0.23,0.68,0.46,1.04,0.67c0.55,0.33,0.98,0.54,1.25,0.97c0.25,0.39,0.28,0.88,0.28,1.51c0,0.39,0,2.23,0,3.1
			c1.64,0,3.59-0.01,4.25-0.01c0-0.74,0-2.89,0-3.06c1.98,0.55,4.07,0.85,6.23,0.85c12.78,0,23.18-10.4,23.18-23.17
			S154.01,15.55,141.23,15.55z M141.23,56.89c-10.02,0-18.17-8.15-18.17-18.17s8.15-18.17,18.17-18.17
			c10.02,0,18.17,8.15,18.17,18.17S151.25,56.89,141.23,56.89z"
        />
      </g>
      <linearGradient
        id="CERTIFICATE_MOBILE_131_"
        gradientUnits="userSpaceOnUse"
        x1="155.8639"
        y1="57.0432"
        x2="125.7479"
        y2="21.1525"
      >
        <stop offset="0" style={{ stopColor: "#DBDBDB" }} />
        <stop offset="1" style={{ stopColor: "#8C8C8C" }} />
      </linearGradient>
      <path
        className="certificate-mobile-145"
        d="M135,64.16l0-3.21l0-1c0-1.84-0.41-3.28-2.09-4.15c-0.51-0.26-1.38-0.76-1.38-0.76
		c-5.55-3.31-9.26-9.37-9.26-16.3c0-10.48,8.49-18.97,18.97-18.97s18.97,8.49,18.97,18.97c0,8.83-6.03,16.25-14.19,18.37
		c-0.03,0.01-0.07,0.02-0.11,0.03c-0.08,0.02-0.16,0.04-0.23,0.06c-0.09,0.02-0.19,0.05-0.3,0.07c-0.06,0.01-0.13,0.03-0.19,0.04
		c-0.02,0-0.04,0.01-0.06,0.01c-0.04,0.01-0.08,0.02-0.12,0.03c-0.12,0.02-0.23,0.05-0.35,0.07c-0.04,0.01-0.07,0.01-0.11,0.02
		c-0.12,0.02-0.24,0.04-0.36,0.06c-0.03,0.01-0.07,0.01-0.1,0.02c-0.14,0.02-0.28,0.04-0.42,0.06c0,0-0.01,0-0.01,0
		c-0.47,0.06-0.95,0.1-1.44,0.13c0,0,0,0,0,0c-0.11,0.01-0.23,0.01-0.34,0.01c-0.1,0-0.19,0.01-0.27,0.01c-0.12,0-0.24,0-0.35,0
		c-0.01,0-0.01,0-0.01,0c0,0,0,0,0,0c-0.47,0-0.85-0.38-0.85-0.85c0-0.47,0.38-0.85,0.85-0.85c0,0,0.35,0,0.94-0.03
		c0.01,0,0.02,0,0.03,0c0.12-0.01,0.26-0.02,0.4-0.03c0.11-0.01,0.24-0.02,0.37-0.03c8.75-0.85,15.58-8.22,15.58-17.19
		c0-9.54-7.73-17.27-17.27-17.27c-9.54,0-17.27,7.73-17.27,17.27c0,5.33,2.42,10.08,6.21,13.25c0,0,0.47,0.36,0.72,0.5
		c1.38,0.78,3.02,0.84,4.44,0.25c0,0,0,0,0.01,0c0.07-0.03,0.14-0.06,0.21-0.09c0.01,0,0.02-0.01,0.02-0.01
		c0.06-0.03,0.13-0.06,0.19-0.1c0.01-0.01,0.03-0.02,0.04-0.02c0.06-0.03,0.11-0.06,0.17-0.1c0.02-0.01,0.04-0.02,0.06-0.04
		c0.05-0.03,0.1-0.06,0.15-0.09c0.02-0.02,0.05-0.03,0.07-0.05c0.04-0.03,0.09-0.06,0.13-0.09c0.03-0.02,0.05-0.04,0.08-0.06
		c0.04-0.03,0.08-0.06,0.11-0.09c0.03-0.03,0.06-0.05,0.09-0.08c0.03-0.03,0.06-0.05,0.1-0.08c0.04-0.03,0.07-0.07,0.11-0.1
		c0.03-0.02,0.05-0.05,0.08-0.07c0.04-0.04,0.08-0.09,0.13-0.13c0.02-0.02,0.03-0.03,0.05-0.05c0.06-0.06,0.11-0.13,0.17-0.2
		c0.02-0.02,0.04-0.04,0.06-0.07l0-0.01c1.2-1.53,1.38-3.54,0.63-5.2c-0.25-0.55-0.71-1.14-1.28-1.71c0,0,0,0,0,0
		c-1.6-1.31-2.62-3.31-2.62-5.54c0-1.61,0.53-3.09,1.42-4.29c1.31-1.75,3.39-2.88,5.74-2.88c0.06,0,0.12,0,0.18,0
		c0.03,0,0.06,0,0.09,0c0.03,0,0.06,0,0.09,0c0.03,0,0.07,0,0.1,0.01c0.03,0,0.05,0,0.08,0c0.04,0,0.07,0.01,0.11,0.01
		c0.02,0,0.05,0,0.07,0.01c0.04,0,0.08,0.01,0.11,0.01c0.02,0,0.04,0,0.06,0.01c0.04,0,0.08,0.01,0.12,0.02
		c0.02,0,0.04,0.01,0.06,0.01c0.04,0.01,0.08,0.01,0.12,0.02c0.02,0,0.04,0.01,0.06,0.01c0.04,0.01,0.08,0.02,0.12,0.02
		c0.02,0,0.04,0.01,0.05,0.01c0.04,0.01,0.08,0.02,0.12,0.03c0.02,0,0.03,0.01,0.05,0.01c0.04,0.01,0.08,0.02,0.12,0.03
		c0.02,0,0.03,0.01,0.05,0.01c0.04,0.01,0.08,0.02,0.12,0.03c0.02,0,0.03,0.01,0.05,0.01c0.04,0.01,0.08,0.02,0.12,0.04
		c0.01,0,0.03,0.01,0.04,0.01c0.04,0.01,0.08,0.03,0.13,0.04c0.01,0,0.03,0.01,0.04,0.01c0.04,0.01,0.08,0.03,0.13,0.04
		c0.01,0,0.02,0.01,0.04,0.01c0.04,0.02,0.09,0.03,0.13,0.05c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.03,0.13,0.05
		c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.04,0.13,0.06c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.04,0.13,0.06
		c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.04,0.13,0.06c0.01,0,0.02,0.01,0.03,0.01c0.04,0.02,0.09,0.04,0.13,0.07
		c0.01,0,0.02,0.01,0.02,0.01c0.04,0.02,0.09,0.05,0.13,0.07c0.01,0,0.01,0.01,0.02,0.01c0.04,0.02,0.09,0.05,0.13,0.08
		c0.01,0,0.01,0.01,0.02,0.01c0.04,0.03,0.09,0.05,0.13,0.08c0.01,0,0.01,0.01,0.02,0.01c0.04,0.03,0.09,0.06,0.13,0.08
		c0,0,0.01,0.01,0.01,0.01c0.04,0.03,0.09,0.06,0.13,0.09c0,0,0.01,0,0.01,0.01c0.04,0.03,0.09,0.06,0.13,0.09c0,0,0.01,0,0.01,0.01
		c0.04,0.03,0.09,0.06,0.13,0.1c0,0,0,0,0.01,0c0.04,0.03,0.09,0.07,0.13,0.1c0,0,0,0,0,0c0.04,0.04,0.09,0.07,0.13,0.11
		c0,0,0,0,0,0c1.51,1.29,2.48,3.2,2.51,5.34c0,0,0,0,0,0c0,0.04,0,0.08,0,0.11c0,1.67-0.6,3.18-1.53,4.43
		c-2.27,3.04-4.72,2.97-4.72,2.97c-0.33-1.02-1.06-2.5-1.85-3.52c-0.62-0.79-1.46-1.75-1.93-2.57c-0.58-1.01-0.69-1.48-0.37-1.96
		c0.34-0.51,1.09-0.42,1.7,0.2c0.79,0.8,1.52,0.79,1.52,0.79h0.01c0,0,0.72,0.05,1.51-0.69c0.68-0.64,1.44-0.83,1.83-0.36
		c0.24,0.29,0.32,0.92-0.32,2.08c-0.07,0.13-0.29,0.52-0.61,0.85c-0.39,0.41-0.46-0.46-1.14-0.85c-0.75-0.43-1.92,0.34-0.85,1.76
		c1.24,1.64,1.13,2.13,1.45,2.05c0.63-0.16,1.43-0.9,1.64-1.11c3.03-3.23,2.04-5.57,1.63-6.27c-0.41-0.7-1.08-1.15-1.91-1.25
		c-0.1-0.01-0.21-0.02-0.31-0.02c0,0,0,0,0,0c0,0,0,0,0,0c-0.03,0-0.06,0-0.09,0c-0.77,0.03-1.52,0.47-2.32,1.34
		c0,0.01-0.26,0.38-0.49,0.38c-0.17,0.01-0.27-0.14-0.51-0.41c-0.85-0.95-1.53-1.29-2.3-1.31c-0.03,0-0.06,0-0.09,0c0,0,0,0,0,0
		c0,0,0,0,0,0c-0.11,0-0.21,0-0.31,0.02c-0.73,0.08-1.36,0.43-1.75,1c-0.57,0.84-0.64,1.64-0.55,2.63c0.09,0.99,0.73,2.29,2.01,3.79
		c0.22,0.26,0.48,0.58,0.69,0.83c0,0,0,0,0.01,0c0,0,0.1,0.11,0.23,0.28c0.01,0.01,0.01,0.01,0.02,0.02
		c0.02,0.03,0.05,0.06,0.07,0.09c0.01,0.02,0.03,0.04,0.04,0.05c0.12,0.15,0.24,0.32,0.35,0.48c0.01,0.02,0.02,0.03,0.03,0.05
		c0.24,0.37,0.45,0.77,0.62,1.2c0.01,0.02,0.01,0.03,0.01,0.03c0,0,0,0,0,0c0.24,0.61,0.39,1.3,0.43,2.09
		c0.02,0.34-0.06,1.01-0.06,1.01c-0.01,0.06-0.01,0.12-0.01,0.18c0,0.59,0.47,1.07,1.06,1.07c0.01,0,0.02,0,0.02,0c0,0,0,0,0,0
		c0,0,0,0,0,0c0.11,0,0.21,0,0.32,0c0.03,0,0.07,0,0.1,0c0.07,0,0.15-0.01,0.22-0.01c0.04,0,0.08,0,0.12-0.01
		c0.07,0,0.13-0.01,0.2-0.01c0.04,0,0.08-0.01,0.12-0.01c0.07-0.01,0.13-0.01,0.2-0.02c0.04,0,0.08-0.01,0.12-0.01
		c0.07-0.01,0.14-0.02,0.21-0.03c0.03,0,0.07-0.01,0.1-0.01c0.08-0.01,0.17-0.03,0.25-0.04c0.02,0,0.04-0.01,0.05-0.01
		c0.1-0.02,0.21-0.04,0.31-0.06c0.02,0,0.04-0.01,0.05-0.01c0.08-0.02,0.16-0.04,0.25-0.05c0.03-0.01,0.06-0.02,0.09-0.02
		c0.07-0.02,0.14-0.03,0.2-0.05c0.03-0.01,0.07-0.02,0.1-0.03c0.07-0.02,0.13-0.04,0.19-0.06c0.03-0.01,0.07-0.02,0.1-0.03
		c0.07-0.02,0.13-0.04,0.2-0.06c0.03-0.01,0.06-0.02,0.09-0.03c0.07-0.02,0.15-0.05,0.22-0.08c0.02-0.01,0.04-0.02,0.07-0.02
		c0.19-0.07,0.38-0.14,0.57-0.22c0.02-0.01,0.04-0.02,0.07-0.03c0.07-0.03,0.14-0.06,0.21-0.09c0.03-0.01,0.06-0.03,0.08-0.04
		c0.06-0.03,0.13-0.06,0.19-0.09c0.01-0.01,0.03-0.01,0.04-0.02c0.02-0.01,0.04-0.02,0.06-0.03c0.04-0.02,0.09-0.04,0.13-0.07
		c0.02-0.01,0.04-0.02,0.06-0.03c2.44-1.29,4.36-3.43,5.35-6.04c0-0.01,0-0.01,0.01-0.02c0.13-0.33,0.24-0.67,0.33-1.02
		c0-0.01,0.01-0.03,0.01-0.04c0.03-0.11,0.06-0.22,0.08-0.33c0,0,0-0.01,0-0.01c0.05-0.23,0.1-0.46,0.14-0.7
		c0-0.02,0.01-0.04,0.01-0.06c0.02-0.1,0.03-0.21,0.05-0.31c0-0.01,0-0.03,0.01-0.04c0.03-0.24,0.05-0.47,0.07-0.71
		c0-0.02,0-0.04,0-0.07c0.01-0.1,0.01-0.2,0.02-0.3c0-0.02,0-0.04,0-0.06c0-0.12,0.01-0.24,0.01-0.36c0-6.18-4.94-11.22-11.08-11.37
		c-0.1,0-0.2,0-0.29,0c-6.28,0-11.38,5.09-11.38,11.38c0,0.12,0,0.25,0.01,0.37c0,0.02,0,0.03,0,0.05c0,0.1,0.01,0.21,0.02,0.31
		c0,0.02,0,0.05,0.01,0.07c0.01,0.1,0.01,0.19,0.02,0.29c0,0.02,0,0.05,0.01,0.07c0.01,0.1,0.02,0.2,0.03,0.3
		c0,0.01,0,0.03,0.01,0.04c0.38,2.87,1.83,5.4,3.94,7.18l0,0c0.36,0.3,0.42,0.82,0.12,1.19c-0.3,0.36-0.82,0.42-1.19,0.12l0,0
		c-0.12-0.1-0.23-0.2-0.34-0.3c-0.03-0.03-0.06-0.05-0.09-0.08c-0.1-0.09-0.2-0.19-0.3-0.29c-2.42-2.37-3.93-5.67-3.93-9.33
		c0-7.22,5.85-13.07,13.07-13.07s13.07,5.85,13.07,13.07c0,6.73-5.09,12.27-11.62,12.98c0,0,0,0,0,0c-0.5,0.05-1,0.08-1.5,0.08
		c-2.79,0-5.06,2.26-5.06,5.06c0,2.79,2.26,5.06,5.06,5.06c0.04,0,0.07,0,0.11,0c12.75-0.06,23.07-10.41,23.07-23.17
		c0-12.8-10.38-23.18-23.18-23.18c-12.8,0-23.18,10.38-23.18,23.18c0,6.57,2.73,12.5,7.13,16.71c1.11,1.06,2.44,2.11,3.74,2.92
		c0.78,0.49,1.27,0.68,1.57,1.2c0.26,0.46,0.25,1.01,0.25,1.67l0,0.93v1.98"
      />
      <g className="certificate-mobile-146">
        <g>
          <path
            className="certificate-mobile-147"
            d="M134.99,64.31c0-1.41,0.02-2.81,0-4.22c-0.02-1.37-0.26-2.75-1.36-3.67c-0.97-0.81-2.27-1.29-3.31-2.02
				c-5.52-3.88-8.58-10.55-7.98-17.26c0.58-6.44,4.57-12.27,10.34-15.18c6.24-3.14,13.92-2.47,19.55,1.65
				c5.01,3.66,7.96,9.66,7.78,15.87c-0.19,6.68-4,12.89-9.86,16.09c-2.36,1.29-5.71,2.51-8.48,2.27c-0.55-0.05-1.65-0.28-1.23-1.15
				c0.14-0.3,0.62-0.4,0.9-0.47c0.58-0.13,1.27-0.06,1.87-0.13c1.31-0.16,2.6-0.46,3.84-0.9c5.26-1.88,9.4-6.31,10.88-11.7
				c1.58-5.73,0.05-12-3.99-16.36c-3.92-4.23-9.81-6.23-15.51-5.32c-5.31,0.86-10.04,4.22-12.58,8.96
				c-3.06,5.71-2.6,12.87,1.16,18.15c1.54,2.17,3.7,4.6,6.61,4.4c1.31-0.09,2.57-0.68,3.47-1.64c2.01-2.16,1.63-5.27-0.37-7.27
				c-1.22-1.22-2.16-2.45-2.49-4.19c-0.35-1.83,0.04-3.77,1.08-5.32c2.12-3.17,6.47-4.03,9.62-2.03c2.01,1.27,3.29,3.54,3.33,5.92
				c0.03,1.66-0.58,3.25-1.56,4.57c-0.61,0.81-1.33,1.54-2.19,2.08c-0.45,0.28-1.6,0.99-2.19,0.81c-0.35-0.1-0.62-1.19-0.81-1.57
				c-0.34-0.68-0.73-1.33-1.19-1.94c-0.57-0.75-1.22-1.45-1.73-2.25c-0.24-0.37-0.51-0.78-0.63-1.21c-0.26-0.88,0.29-1.58,1.43-0.94
				c0.65,0.37,1.07,0.99,1.9,0.93c0.23-0.02,0.45-0.1,0.65-0.18c0.17-0.07,0.31-0.18,0.48-0.26c0.49-0.25,1.93-0.88,2.16-0.22
				c0.17,0.49-0.3,1.71-0.69,2.04c-0.78,0.67-0.93-0.31-1.59-0.48c-0.4-0.1-0.9,0.05-1.12,0.41c-0.3,0.5,0.05,1.07,0.32,1.49
				c0.35,0.54,0.7,1.24,1.11,1.74c0.26,0.32,0.21,0.34,0.6,0.26c0.96-0.19,1.92-1.44,2.42-2.15c0.73-1.02,1.29-2.23,1.25-3.51
				c-0.06-1.64-1.22-3.18-3-2.99c-1.06,0.11-1.46,0.87-2.29,1.37c-0.79,0.47-1.22-0.58-1.88-0.98c-0.41-0.25-0.85-0.4-1.33-0.41
				c-2.2-0.05-3.16,2.15-2.75,4.1c0.31,1.48,1.36,2.7,2.3,3.83c0.22,0.27,0.45,0.53,0.67,0.8c0.35,0.45,0.8,1.64,1.21,1.74
				c-0.03-0.01-0.18,0.01-0.16,0.06c0.26,0.75,0.44,1.53,0.43,2.33c0,0.31-0.1,0.65-0.07,0.95c0.07,0.69,0.5,1.01,1.14,1.08
				c1.07,0.12,2.34-0.19,3.35-0.5c3.61-1.09,6.53-3.98,7.65-7.59c1.27-4.11,0.04-8.7-3.09-11.65c-3.15-2.97-7.83-3.88-11.88-2.37
				c-4.2,1.56-7.17,5.54-7.44,10.02c-0.14,2.28,0.42,4.57,1.59,6.53c0.63,1.05,1.44,1.87,2.29,2.74c-0.16-0.16,0.29,0.19,0.23,0.12
				c0.46,0.65,0.19,0.57-0.39,0.9c-0.73,0.42-0.48,0.47-1.03-0.04c-4.28-3.93-5.45-10.41-2.78-15.58c2.75-5.34,9-8.15,14.82-6.6
				c5.64,1.5,9.69,6.78,9.68,12.62c-0.01,6.57-5.02,12.12-11.52,12.92c-0.02,0-0.11,0.02-0.11,0.06c-0.01-0.21-2.73,0.19-3.1,0.32
				c-0.95,0.33-1.79,0.93-2.4,1.73c-1.54,2-1.35,4.9,0.43,6.68c1.77,1.77,4.23,1.59,6.52,1.31c2.35-0.29,4.66-0.94,6.81-1.93
				c9.66-4.42,15.2-15.16,13.16-25.59c-2.07-10.63-11.61-18.56-22.43-18.72c-10.13-0.15-19.48,6.48-22.58,16.16
				c-2.06,6.44-1.17,13.64,2.43,19.37c1.86,2.96,4.39,5.5,7.35,7.35c0.76,0.48,1.57,0.82,1.76,1.79c0.11,0.56,0.05,1.19,0.05,1.77
				c0,0.74,0,1.48,0,2.22c0,0.03,0.18,0.01,0.18-0.05c0-1.25,0.3-3.13-0.12-4.3c-0.35-0.99-1.62-1.42-2.42-1.96
				c-1.28-0.88-2.49-1.88-3.57-2.99c-3.9-4-6.26-9.36-6.55-14.95c-0.53-10.23,5.96-19.88,15.7-23.15c9.96-3.34,21.19,0.7,26.82,9.53
				c5.34,8.37,4.64,19.57-1.7,27.21c-3.79,4.57-9.29,7.56-15.19,8.21c-1.57,0.17-3.29,0.34-4.75-0.39c-1.27-0.63-2.23-1.8-2.6-3.17
				c-0.39-1.41-0.14-2.95,0.68-4.16c0.57-0.85,1.38-1.47,2.33-1.83c1.05-0.4,2.14-0.32,3.24-0.43c0.03,0,0.13-0.02,0.13-0.06
				c0.01,0.2,2.96-0.65,3.23-0.76c1.14-0.44,2.23-1.05,3.2-1.79c1.98-1.5,3.52-3.56,4.38-5.9c1.88-5.07,0.36-10.92-3.73-14.45
				c-3.71-3.2-9.05-4.05-13.58-2.19c-4.55,1.86-7.76,6.2-8.15,11.11c-0.26,3.33,0.77,6.67,2.88,9.26c0.51,0.63,1.48,2.06,2.44,1.97
				c0.84-0.08,1.04-0.97,0.55-1.56c-0.02-0.02-0.1,0-0.12,0.01c-0.05,0.02-1.07-1.16-1.21-1.33c-0.49-0.57-0.92-1.19-1.28-1.85
				c-0.62-1.12-1.05-2.35-1.26-3.61c-0.33-1.96-0.14-4,0.55-5.86c1.94-5.21,7.56-8.3,12.99-7.08c4.57,1.02,8.13,4.9,8.76,9.54
				c0.63,4.67-1.75,9.38-5.94,11.58c-1.13,0.6-2.36,0.99-3.63,1.17c-0.8,0.11-2.21,0.38-2.55-0.61c-0.13-0.39,0.01-0.89,0.02-1.29
				c0.01-0.5,0-2.12-0.6-2.27c0.02,0.01,0.18-0.01,0.16-0.06c-0.7-1.74-2.14-2.9-3.13-4.47c-0.61-0.97-1.03-2-0.97-3.17
				c0.1-2.04,2.17-3.47,3.96-2.29c0.4,0.26,0.97,1.26,1.48,1.24c0.52-0.02,1.11-0.98,1.46-1.22c2.08-1.38,4.07,0.44,4.09,2.58
				c0.01,1.1-0.43,2.14-1.02,3.05c-0.37,0.56-0.81,1.11-1.3,1.57c-0.24,0.22-1.1,0.98-1.43,0.83c-0.1-0.05-0.18-0.33-0.24-0.41
				c-0.29-0.36-0.51-0.85-0.77-1.23c-0.16-0.24-0.37-0.46-0.5-0.73c-0.31-0.66-0.27-0.6,0.49-0.89c1.23-0.46,0.36-0.03,1.16,0.35
				c0.02,0.01,0.1,0.12,0.13,0.13c0.1,0.01,0.13,0.03,0.22,0.02c0.85-0.08,1.7-2.46,1.16-3.1c-0.29-0.34-0.72-0.29-1.09-0.17
				c-1.15,0.37-1.86,1.66-3.14,0.93c-0.62-0.36-1.16-1.23-1.98-1.02c-0.47,0.12-0.73,0.55-0.71,1.02c0.05,1.17,1.3,2.39,1.98,3.26
				c1,1.27,1.82,2.6,2.34,4.13c0,0.01,0.02,0.01,0.03,0.01c3.57,0.03,6.38-4.28,6.4-7.46c0.02-2.8-1.7-5.41-4.24-6.55
				c-1.65-0.74-3.55-0.83-5.27-0.26c-4.71,1.54-6.55,7.48-3.41,11.38c0.93,1.15,2.12,1.9,2.55,3.42c0.4,1.42,0.17,2.97-0.65,4.2
				c-1.14,1.72-3.3,2.57-5.33,2.03c-2.63-0.7-4.75-3.71-5.98-5.95c-1.35-2.47-2.08-5.25-2.11-8.07c-0.06-5.34,2.42-10.5,6.63-13.8
				c4.55-3.56,10.74-4.56,16.19-2.65c10.08,3.53,14.59,15.8,9.18,25.02c-2.9,4.94-8.16,8.15-13.88,8.46
				c-0.41,0.02-1.01-0.07-1.39,0.13c-0.88,0.45-0.55,1.51,0.33,1.67c1.28,0.24,2.95-0.18,4.22-0.46
				c9.36-2.08,15.86-11.12,14.81-20.67c-1.07-9.72-9.69-17.16-19.44-16.89c-9.37,0.26-17.44,7.57-18.46,16.93
				c-0.56,5.11,0.99,10.3,4.29,14.25c1.53,1.84,3.4,3.35,5.49,4.51c1.16,0.64,2.15,1.2,2.58,2.55c0.29,0.91,0.25,1.87,0.25,2.81
				c0,1.14,0,2.28,0,3.41C134.81,64.39,134.99,64.36,134.99,64.31L134.99,64.31z"
          />
        </g>
      </g>
      <g>
        <g>
          <g>
            <path
              className="certificate-mobile-130"
              d="M169.91,29.42c0.15-0.03,0.35-0.04,0.59-0.04c0.24,0,0.44,0.02,0.6,0.04c0.16,0.03,0.19,0.08,0.19,0.23
					v6.02c0,0.09,0.04,0.14,0.14,0.14h3.85c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.26,0.03,0.43c0,0.17-0.01,0.32-0.03,0.43
					c-0.02,0.12-0.06,0.13-0.17,0.13h-5.26c-0.2,0-0.29-0.06-0.29-0.28v-7.01C169.72,29.5,169.75,29.45,169.91,29.42z"
            />
            <path
              className="certificate-mobile-130"
              d="M180.12,36.78l-0.08-0.38c-0.01-0.06-0.04-0.08-0.1-0.08h-0.07c-0.06,0-0.08,0.01-0.13,0.05
					c-0.28,0.24-0.83,0.67-1.97,0.67c-1.33,0-2.31-0.47-2.31-1.79c0-1.51,1.13-1.79,2.83-1.79h1.46c0.08,0,0.13-0.04,0.13-0.13
					c0-0.94-0.72-1.15-1.75-1.15c-0.83,0-1.59,0.18-1.86,0.24c-0.12,0.03-0.17,0.01-0.2-0.1c-0.04-0.21-0.06-0.49-0.06-0.7
					c0-0.17,0.02-0.2,0.16-0.24c0.32-0.1,1.13-0.24,2.19-0.24c2.08,0,2.97,0.71,2.97,2.55v2.04c0,0.08,0.04,0.12,0.12,0.12h0.41
					c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.27,0.03,0.42s-0.01,0.31-0.03,0.42c-0.02,0.12-0.06,0.13-0.17,0.13h-1.51
					C180.2,36.94,180.14,36.9,180.12,36.78z M176.99,35.18c0,0.69,0.56,0.83,1.28,0.83c0.98,0,1.59-0.43,1.59-1.51
					c0-0.09-0.04-0.13-0.13-0.13h-1.38C177.49,34.37,176.99,34.48,176.99,35.18z"
            />
            <path
              className="certificate-mobile-130"
              d="M184.25,29.06v2.55c0,0.07,0.02,0.08,0.09,0.08h0.07c0.06,0,0.07,0,0.13-0.04
					c0.21-0.15,0.69-0.51,1.72-0.51c1.55,0,2.72,0.85,2.72,2.93c0,2.03-1.1,2.97-2.65,2.97c-0.76,0-1.33-0.17-1.8-0.57
					c-0.04-0.04-0.06-0.04-0.13-0.04h-0.06c-0.06,0-0.09,0.01-0.1,0.07l-0.07,0.3c-0.03,0.11-0.07,0.13-0.19,0.15
					c-0.14,0.02-0.35,0.03-0.54,0.03c-0.17,0-0.33-0.01-0.5-0.03c-0.15-0.02-0.18-0.08-0.18-0.21v-6.71c0-0.08-0.04-0.12-0.12-0.12
					h-0.54c-0.11,0-0.15-0.02-0.17-0.13c-0.02-0.11-0.03-0.27-0.03-0.42s0.01-0.31,0.03-0.42c0.02-0.12,0.06-0.13,0.17-0.13h1.87
					C184.16,28.79,184.25,28.84,184.25,29.06z M185.81,32.18c-1.24,0-1.67,0.7-1.67,1.91c0,1.2,0.44,1.9,1.67,1.9s1.67-0.7,1.67-1.9
					C187.48,32.88,187.05,32.18,185.81,32.18z"
            />
            <path
              className="certificate-mobile-130"
              d="M191.87,35.84h0.41c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.27,0.03,0.42s-0.01,0.31-0.03,0.42
					c-0.02,0.12-0.06,0.13-0.17,0.13h-0.79c-0.87,0-1.24-0.17-1.24-1.11v-3.38c0-0.08-0.04-0.12-0.12-0.12h-0.54
					c-0.11,0-0.15-0.02-0.17-0.13c-0.02-0.11-0.03-0.27-0.03-0.42s0.01-0.31,0.03-0.42c0.02-0.12,0.06-0.13,0.17-0.13h1.87
					c0.21,0,0.29,0.04,0.29,0.27v4.22C191.75,35.8,191.79,35.84,191.87,35.84z M190.93,28.57c0.46,0,0.8,0.33,0.8,0.79
					c0,0.46-0.34,0.79-0.8,0.79c-0.46,0-0.8-0.33-0.8-0.79C190.13,28.91,190.47,28.57,190.93,28.57z"
            />
            <path
              className="certificate-mobile-130"
              d="M196.91,32.36c-1.07,0-1.67,0.4-1.67,1.87v2.52c0,0.13-0.03,0.19-0.17,0.21
					c-0.17,0.03-0.37,0.04-0.58,0.04c-0.22,0-0.41-0.01-0.58-0.04c-0.13-0.02-0.17-0.08-0.17-0.21v-4.29c0-0.08-0.04-0.12-0.12-0.12
					h-0.54c-0.11,0-0.15-0.02-0.17-0.13c-0.02-0.11-0.03-0.27-0.03-0.42s0.01-0.31,0.03-0.42c0.02-0.12,0.06-0.13,0.17-0.13h1.72
					c0.13,0,0.21,0.04,0.22,0.16l0.07,0.42c0.01,0.06,0.04,0.07,0.1,0.07h0.06c0.05,0,0.08,0,0.13-0.05c0.29-0.32,0.77-0.7,1.65-0.7
					c0.21,0,0.36,0.01,0.45,0.03c0.12,0.02,0.15,0.04,0.16,0.15c0.03,0.26,0.01,0.71-0.02,0.92c-0.02,0.15-0.08,0.16-0.22,0.15
					C197.28,32.37,197.1,32.36,196.91,32.36z"
            />
            <path
              className="certificate-mobile-130"
              d="M198.68,28.57c0.46,0,0.8,0.33,0.8,0.79c0,0.46-0.34,0.79-0.8,0.79c-0.46,0-0.8-0.33-0.8-0.79
					C197.88,28.91,198.21,28.57,198.68,28.57z M198.07,31.24c0.17-0.03,0.37-0.04,0.58-0.04c0.21,0,0.42,0.01,0.58,0.04
					c0.13,0.03,0.17,0.07,0.17,0.2v4.28c0,0.08,0.04,0.12,0.12,0.12h0.41c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.27,0.03,0.42
					s-0.01,0.31-0.03,0.42c-0.02,0.12-0.06,0.13-0.17,0.13h-0.79c-0.87,0-1.24-0.17-1.24-1.11v-4.4
					C197.91,31.31,197.93,31.27,198.07,31.24z"
            />
            <path
              className="certificate-mobile-130"
              d="M204.89,31.13c1.56,0,2.25,0.7,2.25,2.21v2.38c0,0.08,0.04,0.12,0.12,0.12h0.41
					c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.27,0.03,0.42s-0.01,0.31-0.03,0.42c-0.02,0.12-0.06,0.13-0.17,0.13h-0.79
					c-0.87,0-1.24-0.17-1.24-1.11v-2.15c0-1.01-0.31-1.45-1.29-1.45c-1.02,0-1.47,0.53-1.47,1.53v2.97c0,0.13-0.03,0.19-0.17,0.21
					c-0.17,0.03-0.37,0.04-0.58,0.04c-0.22,0-0.41-0.01-0.58-0.04c-0.13-0.02-0.17-0.08-0.17-0.21v-4.29c0-0.08-0.04-0.12-0.12-0.12
					h-0.54c-0.11,0-0.15-0.02-0.17-0.13c-0.02-0.11-0.03-0.27-0.03-0.42s0.01-0.31,0.03-0.42c0.02-0.12,0.06-0.13,0.17-0.13h1.72
					c0.13,0,0.21,0.04,0.22,0.16l0.07,0.42c0.01,0.06,0.04,0.07,0.1,0.07h0.06c0.05,0,0.08,0,0.13-0.05
					C203.21,31.65,203.75,31.13,204.89,31.13z"
            />
            <path
              className="certificate-mobile-130"
              d="M211.19,35.92c0.35,0,0.67-0.06,0.82-0.09c0.1-0.02,0.19-0.01,0.21,0.1c0.04,0.23,0.06,0.55,0.03,0.78
					c-0.01,0.11-0.05,0.13-0.15,0.16c-0.2,0.05-0.61,0.15-1.25,0.15c-1.35,0-2.26-0.53-2.26-2.06v-5.2c0-0.13,0.03-0.17,0.16-0.2
					c0.17-0.03,0.37-0.04,0.58-0.04c0.21,0,0.42,0.01,0.58,0.04c0.13,0.03,0.17,0.07,0.17,0.2v1.35c0,0.08,0.04,0.12,0.12,0.12h1.63
					c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.26,0.03,0.41s-0.01,0.3-0.03,0.41c-0.02,0.12-0.06,0.13-0.17,0.13h-1.63
					c-0.08,0-0.12,0.04-0.12,0.12v2.38C210.08,35.73,210.46,35.92,211.19,35.92z"
            />
            <path
              className="certificate-mobile-130"
              d="M215.69,31.13c2.1,0,3.2,1.01,3.2,2.95c0,1.94-1.1,2.95-3.2,2.95s-3.2-1.01-3.2-2.95
					C212.5,32.14,213.6,31.13,215.69,31.13z M215.69,36c1.26,0,1.69-0.7,1.69-1.92s-0.43-1.92-1.69-1.92s-1.69,0.7-1.69,1.92
					S214.43,36,215.69,36z"
            />
          </g>
        </g>
        <g>
          <path
            className="certificate-mobile-130"
            d="M173.88,43.76c-0.07-0.01-0.09-0.02-0.11-0.09l-0.04-0.18c-0.01-0.03-0.03-0.04-0.06-0.04h-0.03
				c-0.04,0-0.05,0-0.08,0.03c-0.1,0.09-0.36,0.33-0.99,0.33c-0.93,0-1.61-0.49-1.61-1.7c0-1.21,0.68-1.75,1.58-1.75
				c0.63,0,0.88,0.21,1.01,0.3c0.03,0.03,0.04,0.03,0.07,0.03h0.04c0.04,0,0.06-0.01,0.06-0.05V39.1c0-0.08,0.01-0.1,0.09-0.12
				c0.1-0.02,0.22-0.03,0.34-0.03s0.24,0.01,0.34,0.03c0.08,0.02,0.1,0.04,0.1,0.12v4.53c0,0.07-0.02,0.11-0.1,0.12
				c-0.1,0.01-0.19,0.02-0.29,0.02C174.08,43.77,173.96,43.77,173.88,43.76z M172.81,40.98c-0.72,0-0.98,0.41-0.98,1.11
				c0,0.7,0.25,1.11,0.98,1.11c0.72,0,0.97-0.41,0.97-1.11C173.78,41.39,173.53,40.98,172.81,40.98z"
          />
          <path
            className="certificate-mobile-130"
            d="M177.99,43.66l-0.04-0.22c-0.01-0.04-0.02-0.04-0.06-0.04h-0.04c-0.04,0-0.05,0-0.08,0.03
				c-0.16,0.14-0.48,0.39-1.15,0.39c-0.77,0-1.35-0.27-1.35-1.05c0-0.88,0.66-1.05,1.65-1.05h0.85c0.05,0,0.08-0.02,0.08-0.07
				c0-0.55-0.42-0.67-1.02-0.67c-0.49,0-0.93,0.1-1.08,0.14c-0.07,0.02-0.1,0-0.12-0.06c-0.03-0.12-0.03-0.29-0.03-0.41
				c0-0.1,0.01-0.12,0.09-0.14c0.19-0.06,0.66-0.14,1.28-0.14c1.21,0,1.73,0.42,1.73,1.49v1.19c0,0.05,0.02,0.07,0.07,0.07H179
				c0.06,0,0.09,0.01,0.1,0.08c0.01,0.06,0.02,0.16,0.02,0.24s0,0.18-0.02,0.24c-0.01,0.07-0.04,0.08-0.1,0.08h-0.88
				C178.04,43.76,178.01,43.73,177.99,43.66z M176.17,42.73c0,0.4,0.33,0.48,0.75,0.48c0.57,0,0.93-0.25,0.93-0.88
				c0-0.05-0.02-0.08-0.08-0.08h-0.81C176.46,42.25,176.17,42.32,176.17,42.73z"
          />
        </g>
        <g>
          <path
            className="certificate-mobile-130"
            d="M180.79,38.85h5.58c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.26,0.03,0.43c0,0.17-0.01,0.32-0.03,0.43
				c-0.02,0.12-0.06,0.13-0.17,0.13h-4.17c-0.1,0-0.14,0.05-0.14,0.14v1.69c0,0.09,0.04,0.14,0.14,0.14h2.68
				c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.26,0.03,0.43c0,0.17-0.01,0.32-0.03,0.43c-0.02,0.12-0.06,0.13-0.17,0.13h-2.68
				c-0.1,0-0.14,0.05-0.14,0.14v1.89c0,0.09,0.04,0.14,0.14,0.14h4.34c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.26,0.03,0.43
				c0,0.17-0.01,0.32-0.03,0.43c-0.02,0.12-0.06,0.13-0.17,0.13h-5.75c-0.2,0-0.29-0.06-0.29-0.28v-6.97
				C180.5,38.91,180.59,38.85,180.79,38.85z"
          />
          <path
            className="certificate-mobile-130"
            d="M187.88,41.77h-0.54c-0.11,0-0.15-0.02-0.17-0.13c-0.02-0.11-0.03-0.27-0.03-0.42s0.01-0.31,0.03-0.42
				c0.02-0.12,0.06-0.13,0.17-0.13h1.72c0.13,0,0.21,0.04,0.22,0.16l0.07,0.42c0.01,0.06,0.04,0.07,0.1,0.07h0.06
				c0.05,0,0.08,0,0.13-0.05c0.17-0.18,0.64-0.7,1.72-0.7c0.97,0,1.44,0.28,1.83,0.79c0.04,0.06,0.06,0.06,0.11,0.06h0.05
				c0.04,0,0.06,0,0.12-0.05c0.35-0.37,0.85-0.79,1.91-0.79c1.52,0,2.17,0.7,2.17,2.21v2.38c0,0.08,0.04,0.12,0.12,0.12h0.41
				c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.27,0.03,0.42s-0.01,0.31-0.03,0.42c-0.02,0.12-0.06,0.13-0.17,0.13h-0.79
				c-0.87,0-1.24-0.17-1.24-1.11v-2.15c0-1.01-0.24-1.45-1.17-1.45c-0.97,0-1.36,0.53-1.36,1.53v2.97c0,0.13-0.03,0.19-0.17,0.21
				c-0.17,0.03-0.37,0.04-0.58,0.04s-0.41-0.01-0.58-0.04c-0.13-0.02-0.17-0.08-0.17-0.21v-3.05c0-1.01-0.25-1.45-1.18-1.45
				c-0.97,0-1.35,0.53-1.35,1.53v2.97c0,0.13-0.03,0.19-0.17,0.21c-0.17,0.03-0.37,0.04-0.58,0.04c-0.22,0-0.41-0.01-0.58-0.04
				c-0.13-0.02-0.17-0.08-0.17-0.21v-4.29C188,41.81,187.96,41.77,187.88,41.77z"
          />
          <path
            className="certificate-mobile-130"
            d="M200.54,48.3c-0.17,0.03-0.37,0.04-0.58,0.04c-0.22,0-0.41-0.01-0.58-0.04c-0.13-0.02-0.17-0.08-0.17-0.21
				v-6.21c0-0.08-0.04-0.12-0.12-0.12h-0.54c-0.11,0-0.15-0.02-0.17-0.13c-0.02-0.11-0.03-0.27-0.03-0.42s0.01-0.31,0.03-0.42
				c0.02-0.12,0.06-0.13,0.17-0.13h1.72c0.13,0,0.21,0.04,0.22,0.16l0.07,0.42c0.01,0.06,0.04,0.07,0.1,0.07h0.06
				c0.05,0,0.08,0,0.13-0.05c0.19-0.18,0.78-0.69,1.92-0.69c1.54,0,2.67,0.88,2.67,2.92c0,2.03-1.17,2.98-2.65,2.98
				c-0.81,0-1.34-0.18-1.79-0.51c-0.06-0.04-0.06-0.04-0.12-0.04h-0.08c-0.07,0-0.09,0.02-0.09,0.09v2.09
				C200.71,48.23,200.68,48.28,200.54,48.3z M202.27,41.62c-1.24,0-1.67,0.7-1.67,1.91c0,1.2,0.44,1.9,1.67,1.9s1.67-0.7,1.67-1.9
				C203.93,42.32,203.5,41.62,202.27,41.62z"
          />
          <path
            className="certificate-mobile-130"
            d="M210.6,46.22l-0.08-0.38c-0.01-0.06-0.04-0.08-0.1-0.08h-0.07c-0.06,0-0.08,0.01-0.13,0.05
				c-0.28,0.24-0.83,0.67-1.97,0.67c-1.33,0-2.31-0.47-2.31-1.79c0-1.51,1.13-1.79,2.83-1.79h1.46c0.08,0,0.13-0.04,0.13-0.13
				c0-0.94-0.72-1.15-1.75-1.15c-0.83,0-1.59,0.18-1.86,0.24c-0.12,0.03-0.17,0.01-0.2-0.1c-0.04-0.21-0.06-0.49-0.06-0.7
				c0-0.17,0.02-0.2,0.16-0.24c0.32-0.1,1.13-0.24,2.19-0.24c2.08,0,2.97,0.71,2.97,2.55v2.04c0,0.08,0.04,0.12,0.12,0.12h0.41
				c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.27,0.03,0.42s-0.01,0.31-0.03,0.42c-0.02,0.12-0.06,0.13-0.17,0.13h-1.51
				C210.69,46.38,210.63,46.34,210.6,46.22z M207.48,44.62c0,0.69,0.56,0.83,1.28,0.83c0.98,0,1.59-0.43,1.59-1.51
				c0-0.09-0.04-0.13-0.13-0.13h-1.38C207.98,43.81,207.48,43.91,207.48,44.62z"
          />
          <path
            className="certificate-mobile-130"
            d="M215.65,45.36c0.35,0,0.67-0.06,0.82-0.09c0.1-0.02,0.19-0.01,0.21,0.1c0.04,0.23,0.06,0.55,0.03,0.78
				c-0.01,0.11-0.05,0.13-0.15,0.16c-0.2,0.05-0.61,0.15-1.25,0.15c-1.35,0-2.26-0.53-2.26-2.06v-5.2c0-0.13,0.03-0.17,0.16-0.2
				c0.17-0.03,0.37-0.04,0.58-0.04c0.21,0,0.42,0.01,0.58,0.04c0.13,0.03,0.17,0.07,0.17,0.2v1.35c0,0.08,0.04,0.12,0.12,0.12h1.63
				c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.26,0.03,0.41s-0.01,0.3-0.03,0.41c-0.02,0.12-0.06,0.13-0.17,0.13h-1.63
				c-0.08,0-0.12,0.04-0.12,0.12v2.38C214.54,45.17,214.93,45.36,215.65,45.36z"
          />
          <path
            className="certificate-mobile-130"
            d="M219.37,45.28h0.41c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.27,0.03,0.42s-0.01,0.31-0.03,0.42
				c-0.02,0.12-0.06,0.13-0.17,0.13h-0.79c-0.87,0-1.24-0.17-1.24-1.11v-3.38c0-0.08-0.04-0.12-0.12-0.12h-0.54
				c-0.11,0-0.15-0.02-0.17-0.13c-0.02-0.11-0.03-0.27-0.03-0.42s0.01-0.31,0.03-0.42c0.02-0.12,0.06-0.13,0.17-0.13h1.87
				c0.21,0,0.29,0.04,0.29,0.27v4.22C219.25,45.24,219.29,45.28,219.37,45.28z M218.43,38.01c0.46,0,0.8,0.33,0.8,0.79
				c0,0.46-0.34,0.79-0.8,0.79c-0.46,0-0.8-0.33-0.8-0.79C217.63,38.35,217.97,38.01,218.43,38.01z"
          />
          <path
            className="certificate-mobile-130"
            d="M225.14,46.22l-0.08-0.38c-0.01-0.06-0.04-0.08-0.1-0.08h-0.07c-0.06,0-0.08,0.01-0.13,0.05
				c-0.28,0.24-0.83,0.67-1.97,0.67c-1.33,0-2.31-0.47-2.31-1.79c0-1.51,1.13-1.79,2.83-1.79h1.46c0.08,0,0.13-0.04,0.13-0.13
				c0-0.94-0.72-1.15-1.75-1.15c-0.83,0-1.59,0.18-1.86,0.24c-0.12,0.03-0.17,0.01-0.2-0.1c-0.04-0.21-0.06-0.49-0.06-0.7
				c0-0.17,0.02-0.2,0.16-0.24c0.32-0.1,1.13-0.24,2.19-0.24c2.08,0,2.97,0.71,2.97,2.55v2.04c0,0.08,0.04,0.12,0.12,0.12h0.41
				c0.11,0,0.15,0.02,0.17,0.13c0.02,0.11,0.03,0.27,0.03,0.42s-0.01,0.31-0.03,0.42c-0.02,0.12-0.06,0.13-0.17,0.13h-1.51
				C225.22,46.38,225.17,46.34,225.14,46.22z M222.02,44.62c0,0.69,0.56,0.83,1.28,0.83c0.98,0,1.59-0.43,1.59-1.51
				c0-0.09-0.04-0.13-0.13-0.13h-1.38C222.52,43.81,222.02,43.91,222.02,44.62z"
          />
        </g>
        <g>
          <path
            className="certificate-mobile-130"
            d="M189.12,52.6c-0.02-0.01-0.02-0.01-0.04-0.01h-0.02c-0.02,0-0.03,0-0.03,0.03v0.83
				c0,0.04-0.01,0.06-0.05,0.07c-0.05,0.01-0.1,0.01-0.15,0.01s-0.1,0-0.15-0.01c-0.04-0.01-0.05-0.02-0.05-0.07v-2.5
				c0-0.03-0.01-0.04-0.04-0.04h-0.21c-0.03,0-0.05-0.01-0.05-0.04c0-0.04-0.01-0.08-0.01-0.13c0-0.04,0-0.09,0.01-0.13
				c0-0.04,0.02-0.04,0.05-0.04h0.49c0.05,0,0.07,0.01,0.08,0.06l0.02,0.16c0,0.02,0.01,0.02,0.03,0.02h0.01
				c0.02,0,0.02,0,0.04-0.02c0.18-0.19,0.41-0.26,0.7-0.26c0.54,0,0.98,0.29,0.98,1.12c0,0.81-0.42,1.15-0.95,1.15
				C189.51,52.8,189.31,52.75,189.12,52.6z M188.99,51.67c0,0.5,0.17,0.81,0.67,0.81c0.49,0,0.67-0.31,0.67-0.81
				s-0.18-0.82-0.67-0.82C189.16,50.85,188.99,51.17,188.99,51.67z"
          />
          <path
            className="certificate-mobile-130"
            d="M191.02,51.67c0-0.73,0.36-1.14,1.08-1.14c0.72,0,1.08,0.41,1.08,1.14s-0.36,1.14-1.08,1.14
				C191.38,52.8,191.02,52.39,191.02,51.67z M192.78,51.67c0-0.49-0.18-0.82-0.68-0.82c-0.5,0-0.68,0.32-0.68,0.82
				s0.17,0.82,0.68,0.82C192.6,52.48,192.78,52.16,192.78,51.67z"
          />
          <path
            className="certificate-mobile-130"
            d="M194.73,50.89c-0.25,0-0.43,0.08-0.53,0.27c-0.06,0.12-0.09,0.27-0.09,0.57v0.97
				c0,0.04-0.01,0.06-0.05,0.07c-0.05,0.01-0.1,0.01-0.15,0.01s-0.1,0-0.15-0.01c-0.04-0.01-0.05-0.02-0.05-0.07v-1.75
				c0-0.03-0.01-0.04-0.04-0.04h-0.2c-0.04,0-0.05-0.01-0.05-0.04c0-0.04-0.01-0.08-0.01-0.13c0-0.04,0-0.09,0.01-0.13
				c0-0.04,0.02-0.04,0.05-0.04h0.49c0.05,0,0.07,0.01,0.08,0.06l0.02,0.17c0,0.02,0.01,0.02,0.03,0.02h0.03
				c0.01,0,0.02,0,0.04-0.02c0.14-0.17,0.34-0.27,0.6-0.27c0.07,0,0.11,0,0.15,0.01c0.04,0,0.05,0.02,0.05,0.05
				c0,0.03,0.01,0.08,0.01,0.12c0,0.05,0,0.11-0.01,0.15c-0.01,0.03-0.02,0.04-0.06,0.04C194.83,50.89,194.78,50.89,194.73,50.89z"
          />
          <path
            className="certificate-mobile-130"
            d="M197.37,51.72c-0.15,0-0.52-0.01-0.62-0.02h-0.01c-0.04,0-0.05,0.01-0.05,0.05v0.94
				c0,0.04-0.01,0.06-0.05,0.07c-0.03,0.01-0.09,0.01-0.16,0.01s-0.13,0-0.16-0.01c-0.04-0.01-0.05-0.02-0.05-0.07v-2.43
				c0-0.03-0.01-0.05-0.05-0.05h-0.25c-0.03,0-0.05-0.01-0.05-0.05c0-0.04-0.01-0.08-0.01-0.13s0-0.09,0.01-0.13
				c0-0.04,0.02-0.05,0.05-0.05h0.3c0,0,0.45-0.02,0.92-0.02c0.71,0,1.25,0.12,1.25,0.94c0,0.55-0.37,0.77-0.55,0.82
				c-0.01,0-0.02,0.02-0.02,0.03c0,0.01,0,0.02,0.01,0.02l0.7,1.03c0.01,0.02,0.02,0.03,0.02,0.05c0,0.02-0.01,0.03-0.04,0.03
				c-0.04,0.01-0.11,0.01-0.17,0.01c-0.07,0-0.16,0-0.2-0.01c-0.05-0.01-0.07-0.01-0.09-0.05l-0.63-0.95
				C197.45,51.73,197.42,51.72,197.37,51.72z M196.74,51.36c0.07,0.01,0.28,0.01,0.46,0.01c0.43,0,0.82-0.05,0.82-0.59
				c0-0.54-0.39-0.59-0.82-0.59c-0.18,0-0.39,0.01-0.46,0.02c-0.04,0-0.05,0.01-0.05,0.06v1.03
				C196.69,51.34,196.7,51.35,196.74,51.36z"
          />
          <path
            className="certificate-mobile-130"
            d="M199.19,51.86c0.03,0.47,0.38,0.61,0.79,0.61c0.32,0,0.61-0.11,0.68-0.14c0.01,0,0.02,0,0.03,0
				c0.02,0,0.04,0.01,0.04,0.04c0.01,0.05,0.02,0.13,0.02,0.2c0,0.07,0,0.08-0.05,0.11c-0.07,0.03-0.33,0.13-0.72,0.13
				c-0.7,0-1.19-0.28-1.19-1.14c0-0.69,0.31-1.14,1.05-1.14c0.69,0,1.05,0.36,1.05,1.05c0,0.07,0,0.14-0.01,0.18
				c0,0.04-0.02,0.05-0.06,0.05h-1.57C199.2,51.81,199.19,51.82,199.19,51.86z M199.19,51.46c0,0.03,0.01,0.04,0.05,0.04h1.19
				c0.03,0,0.05-0.01,0.05-0.05c0-0.4-0.22-0.62-0.63-0.62S199.21,51.04,199.19,51.46L199.19,51.46z"
          />
          <path
            className="certificate-mobile-130"
            d="M202.4,50.87c-0.42,0-0.6,0.24-0.6,0.67v1.17c0,0.04-0.01,0.06-0.05,0.07c-0.05,0.01-0.1,0.01-0.15,0.01
				s-0.1,0-0.15-0.01c-0.04-0.01-0.05-0.02-0.05-0.07v-1.75c0-0.03-0.01-0.04-0.04-0.04h-0.21c-0.03,0-0.05-0.01-0.05-0.04
				c0-0.04-0.01-0.08-0.01-0.13c0-0.04,0-0.09,0.01-0.13c0-0.04,0.02-0.04,0.05-0.04h0.49c0.05,0,0.07,0.01,0.08,0.06l0.02,0.17
				c0,0.02,0.01,0.02,0.03,0.02h0.02c0.02,0,0.02,0,0.04-0.02c0.07-0.07,0.25-0.27,0.66-0.27c0.58,0,0.83,0.26,0.83,0.85v1
				c0,0.03,0.01,0.04,0.04,0.04h0.16c0.04,0,0.05,0.01,0.05,0.04c0,0.04,0.01,0.08,0.01,0.13c0,0.04,0,0.09-0.01,0.13
				c0,0.04-0.02,0.04-0.05,0.04h-0.2c-0.27,0-0.4-0.04-0.4-0.37v-0.9C202.92,51.05,202.8,50.87,202.4,50.87z"
          />
          <path
            className="certificate-mobile-130"
            d="M203.81,52.12c0-0.57,0.38-0.68,1-0.68h0.54c0.03,0,0.05-0.01,0.05-0.05c0-0.43-0.27-0.53-0.66-0.53
				c-0.29,0-0.56,0.07-0.66,0.1c-0.01,0-0.02,0-0.03,0c-0.02,0-0.03-0.01-0.03-0.04C204,50.89,204,50.82,204,50.76
				c0-0.02,0-0.05,0-0.07c0-0.04,0.01-0.05,0.05-0.07c0.08-0.03,0.36-0.09,0.71-0.09c0.74,0,1.02,0.29,1.02,0.98v0.88
				c0,0.03,0.01,0.04,0.04,0.04h0.16c0.03,0,0.05,0.01,0.05,0.04c0,0.04,0.01,0.08,0.01,0.13c0,0.04,0,0.09-0.01,0.13
				c0,0.04-0.02,0.04-0.05,0.04h-0.44c-0.05,0-0.07-0.01-0.08-0.06l-0.03-0.16c0-0.02-0.01-0.03-0.03-0.03h-0.02
				c-0.02,0-0.03,0-0.05,0.02c-0.06,0.05-0.24,0.26-0.72,0.26C204.16,52.8,203.81,52.63,203.81,52.12z M205.4,51.8
				c0-0.03-0.01-0.05-0.05-0.05h-0.55c-0.38,0-0.57,0.05-0.57,0.36c0,0.31,0.23,0.37,0.52,0.37C205.18,52.48,205.4,52.27,205.4,51.8
				z"
          />
          <path
            className="certificate-mobile-130"
            d="M206.77,50.56h0.62c0.03,0,0.05,0.01,0.05,0.04c0,0.03,0.01,0.08,0.01,0.13c0,0.04,0,0.09-0.01,0.13
				c0,0.03-0.02,0.04-0.05,0.04h-0.62c-0.03,0-0.04,0.02-0.04,0.04v1.04c0,0.39,0.15,0.46,0.41,0.46c0.14,0,0.25-0.02,0.3-0.03
				c0.01,0,0.01,0,0.02,0c0.03,0,0.03,0.02,0.04,0.05c0.01,0.04,0.01,0.1,0.01,0.16c0,0.02,0,0.04,0,0.06
				c0,0.04-0.01,0.05-0.05,0.06c-0.07,0.02-0.18,0.04-0.4,0.04c-0.45,0-0.73-0.21-0.73-0.75V50c0-0.04,0.01-0.06,0.05-0.07
				c0.05-0.01,0.1-0.01,0.15-0.01c0.05,0,0.1,0,0.15,0.01c0.04,0.01,0.05,0.02,0.05,0.07v0.52
				C206.73,50.55,206.74,50.56,206.77,50.56z"
          />
          <path
            className="certificate-mobile-130"
            d="M207.74,52.12c0-0.57,0.38-0.68,1-0.68h0.54c0.03,0,0.05-0.01,0.05-0.05c0-0.43-0.27-0.53-0.66-0.53
				c-0.29,0-0.56,0.07-0.66,0.1c-0.01,0-0.02,0-0.03,0c-0.02,0-0.03-0.01-0.03-0.04c-0.01-0.04-0.02-0.11-0.02-0.17
				c0-0.02,0-0.05,0-0.07c0-0.04,0.01-0.05,0.05-0.07c0.08-0.03,0.36-0.09,0.71-0.09c0.74,0,1.02,0.29,1.02,0.98v0.88
				c0,0.03,0.01,0.04,0.04,0.04h0.16c0.03,0,0.05,0.01,0.05,0.04c0,0.04,0.01,0.08,0.01,0.13c0,0.04,0,0.09-0.01,0.13
				c0,0.04-0.02,0.04-0.05,0.04h-0.44c-0.05,0-0.07-0.01-0.08-0.06l-0.03-0.16c0-0.02-0.01-0.03-0.03-0.03h-0.02
				c-0.02,0-0.03,0-0.05,0.02c-0.06,0.05-0.24,0.26-0.72,0.26C208.1,52.8,207.74,52.63,207.74,52.12z M209.33,51.8
				c0-0.03-0.01-0.05-0.05-0.05h-0.55c-0.38,0-0.57,0.05-0.57,0.36c0,0.31,0.23,0.37,0.52,0.37
				C209.12,52.48,209.33,52.27,209.33,51.8z"
          />
          <path
            className="certificate-mobile-130"
            d="M211.08,50.63c0-0.63,0.54-0.81,1.1-0.81c0.42,0,0.77,0.08,0.92,0.12c0.05,0.01,0.06,0.03,0.06,0.08
				c0,0.02,0,0.04,0,0.06c0,0.06,0,0.13-0.01,0.18c-0.01,0.03-0.01,0.05-0.03,0.05c-0.01,0-0.02,0-0.04-0.01
				c-0.28-0.07-0.59-0.13-0.9-0.13c-0.33,0-0.67,0.08-0.67,0.41c0,0.33,0.24,0.38,0.73,0.46c0.61,0.11,1.07,0.23,1.07,0.88
				c0,0.69-0.61,0.86-1.2,0.86c-0.44,0-0.86-0.08-0.97-0.12c-0.05-0.01-0.05-0.03-0.06-0.08c0-0.02,0-0.04,0-0.07
				c0-0.07,0-0.14,0.01-0.2c0.01-0.03,0.01-0.05,0.03-0.05c0.01,0,0.02,0,0.04,0.01c0.32,0.08,0.61,0.13,0.99,0.13
				c0.31,0,0.74-0.05,0.74-0.47c0-0.35-0.24-0.42-0.78-0.52C211.56,51.35,211.08,51.23,211.08,50.63z"
          />
          <path
            className="certificate-mobile-130"
            d="M213.55,52.12c0-0.57,0.38-0.68,1-0.68h0.54c0.03,0,0.05-0.01,0.05-0.05c0-0.43-0.27-0.53-0.66-0.53
				c-0.29,0-0.56,0.07-0.66,0.1c-0.01,0-0.02,0-0.03,0c-0.02,0-0.03-0.01-0.03-0.04c-0.01-0.04-0.02-0.11-0.02-0.17
				c0-0.02,0-0.05,0-0.07c0-0.04,0.01-0.05,0.05-0.07c0.08-0.03,0.36-0.09,0.71-0.09c0.74,0,1.02,0.29,1.02,0.98v0.88
				c0,0.03,0.01,0.04,0.04,0.04h0.16c0.03,0,0.05,0.01,0.05,0.04c0,0.04,0.01,0.08,0.01,0.13c0,0.04,0,0.09-0.01,0.13
				c0,0.04-0.02,0.04-0.05,0.04h-0.44c-0.05,0-0.07-0.01-0.08-0.06l-0.03-0.16c0-0.02-0.01-0.03-0.03-0.03h-0.02
				c-0.02,0-0.03,0-0.05,0.02c-0.06,0.05-0.24,0.26-0.72,0.26C213.9,52.8,213.55,52.63,213.55,52.12z M215.14,51.8
				c0-0.03-0.01-0.05-0.05-0.05h-0.55c-0.38,0-0.57,0.05-0.57,0.36c0,0.31,0.23,0.37,0.52,0.37
				C214.92,52.48,215.14,52.27,215.14,51.8z"
          />
          <path
            className="certificate-mobile-130"
            d="M217.24,50.87c-0.42,0-0.6,0.24-0.6,0.67v1.17c0,0.04-0.01,0.06-0.05,0.07c-0.05,0.01-0.1,0.01-0.15,0.01
				s-0.1,0-0.15-0.01c-0.04-0.01-0.05-0.02-0.05-0.07v-1.75c0-0.03-0.01-0.04-0.04-0.04h-0.21c-0.03,0-0.05-0.01-0.05-0.04
				c0-0.04-0.01-0.08-0.01-0.13c0-0.04,0-0.09,0.01-0.13c0-0.04,0.02-0.04,0.05-0.04h0.49c0.05,0,0.07,0.01,0.08,0.06l0.02,0.17
				c0,0.02,0.01,0.02,0.03,0.02h0.02c0.02,0,0.02,0,0.04-0.02c0.07-0.07,0.25-0.27,0.66-0.27c0.58,0,0.83,0.26,0.83,0.85v1
				c0,0.03,0.01,0.04,0.04,0.04h0.16c0.04,0,0.05,0.01,0.05,0.04c0,0.04,0.01,0.08,0.01,0.13c0,0.04,0,0.09-0.01,0.13
				c0,0.04-0.02,0.04-0.05,0.04h-0.2c-0.27,0-0.4-0.04-0.4-0.37v-0.9C217.76,51.05,217.65,50.87,217.24,50.87z"
          />
          <path
            className="certificate-mobile-130"
            d="M219.59,50.84c-0.26,0-0.53,0.05-0.53,0.29c0,0.21,0.19,0.24,0.57,0.31c0.54,0.09,0.89,0.19,0.89,0.69
				c0,0.56-0.52,0.67-0.99,0.67c-0.35,0-0.71-0.07-0.82-0.11c-0.04-0.02-0.05-0.03-0.05-0.07c-0.01-0.07,0-0.17,0.02-0.24
				c0.01-0.04,0.03-0.05,0.07-0.04c0.12,0.04,0.44,0.13,0.8,0.13c0.29,0,0.58-0.04,0.58-0.31c0-0.2-0.1-0.26-0.53-0.33
				c-0.52-0.08-0.92-0.17-0.92-0.67c0-0.55,0.51-0.63,0.96-0.63c0.36,0,0.66,0.09,0.72,0.11c0.04,0.01,0.05,0.03,0.05,0.06
				c0,0.02,0,0.04,0,0.06c0,0.06,0,0.12-0.02,0.17c-0.01,0.03-0.02,0.04-0.04,0.04c-0.01,0-0.02,0-0.03,0
				C220.26,50.95,219.9,50.84,219.59,50.84z"
          />
          <path
            className="certificate-mobile-130"
            d="M220.78,51.67c0-0.73,0.36-1.14,1.08-1.14c0.72,0,1.08,0.41,1.08,1.14s-0.36,1.14-1.08,1.14
				C221.13,52.8,220.78,52.39,220.78,51.67z M222.54,51.67c0-0.49-0.18-0.82-0.68-0.82c-0.5,0-0.68,0.32-0.68,0.82
				s0.17,0.82,0.68,0.82C222.36,52.48,222.54,52.16,222.54,51.67z"
          />
          <path
            className="certificate-mobile-130"
            d="M224.47,50.87c-0.42,0-0.6,0.24-0.6,0.67v1.17c0,0.04-0.01,0.06-0.05,0.07c-0.05,0.01-0.1,0.01-0.15,0.01
				s-0.1,0-0.15-0.01c-0.04-0.01-0.05-0.02-0.05-0.07v-1.75c0-0.03-0.01-0.04-0.04-0.04h-0.2c-0.04,0-0.05-0.01-0.05-0.04
				c0-0.04-0.01-0.08-0.01-0.13c0-0.04,0-0.09,0.01-0.13c0-0.04,0.02-0.04,0.05-0.04h0.49c0.05,0,0.07,0.01,0.08,0.06l0.02,0.17
				c0,0.02,0.01,0.02,0.03,0.02h0.02c0.02,0,0.02,0,0.04-0.02c0.07-0.07,0.25-0.27,0.66-0.27c0.58,0,0.83,0.26,0.83,0.85v1
				c0,0.03,0.01,0.04,0.04,0.04h0.16c0.04,0,0.05,0.01,0.05,0.04c0,0.04,0.01,0.08,0.01,0.13c0,0.04,0,0.09-0.01,0.13
				c0,0.04-0.02,0.04-0.05,0.04h-0.2c-0.27,0-0.4-0.04-0.4-0.37v-0.9C224.99,51.05,224.88,50.87,224.47,50.87z"
          />
          <path
            className="certificate-mobile-130"
            d="M226.1,50.9h-0.21c-0.03,0-0.05-0.01-0.05-0.04c0-0.04-0.01-0.08-0.01-0.13c0-0.04,0-0.09,0.01-0.13
				c0-0.04,0.02-0.04,0.05-0.04h0.56c0.06,0,0.09,0.02,0.09,0.09v1.73c0,0.03,0.01,0.04,0.04,0.04h0.16c0.03,0,0.05,0.01,0.05,0.04
				c0,0.04,0.01,0.08,0.01,0.13c0,0.04,0,0.09-0.01,0.13c0,0.04-0.02,0.04-0.05,0.04h-0.2c-0.27,0-0.4-0.04-0.4-0.37v-1.45
				C226.14,50.92,226.13,50.9,226.1,50.9z M226.31,50.08c-0.14,0-0.24-0.1-0.24-0.25c0-0.14,0.1-0.25,0.24-0.25
				c0.13,0,0.23,0.11,0.23,0.25C226.54,49.98,226.44,50.08,226.31,50.08z"
          />
        </g>
        <g>
          <path
            className="certificate-mobile-130"
            d="M228.99,39.23c1.21,0,1.94,0.7,1.94,1.87s-0.72,1.87-1.94,1.87c-1.21,0-1.94-0.7-1.94-1.87
				S227.78,39.23,228.99,39.23z M228.99,42.63c0.97,0,1.54-0.54,1.54-1.53s-0.58-1.53-1.54-1.53c-0.96,0-1.54,0.54-1.54,1.53
				S228.03,42.63,228.99,42.63z M228.61,42.07c-0.05,0.01-0.12,0.01-0.18,0.01c-0.05,0-0.13,0-0.18-0.01
				c-0.04-0.01-0.05-0.02-0.05-0.06v-1.58c0-0.03-0.01-0.04-0.04-0.04H228c-0.04,0-0.05-0.01-0.05-0.08c0-0.1-0.02-0.25,0.05-0.25
				h0.21c0.03,0,0.43-0.01,0.78-0.01c0.58,0,0.98,0.1,0.98,0.65c0,0.39-0.27,0.53-0.41,0.57c-0.03,0.01-0.03,0.02-0.01,0.05
				l0.51,0.68c0.03,0.04,0.02,0.06-0.02,0.07c-0.06,0.01-0.14,0.01-0.21,0.01c-0.06,0-0.12,0-0.18-0.01
				c-0.05-0.01-0.06-0.01-0.11-0.07l-0.42-0.57c-0.02-0.03-0.04-0.03-0.07-0.03c-0.1,0-0.3-0.01-0.35-0.01
				c-0.03,0-0.04,0.01-0.04,0.04v0.59C228.66,42.05,228.65,42.06,228.61,42.07z M229.01,40.34c-0.09,0-0.24,0-0.31,0.01
				c-0.04,0.01-0.05,0.01-0.05,0.06v0.6c0,0.04,0.01,0.05,0.05,0.06c0.07,0.01,0.22,0.01,0.31,0.01c0.28,0,0.5-0.05,0.5-0.37
				S229.29,40.34,229.01,40.34z"
          />
        </g>
      </g>
    </g>
    <g>
      <defs>
        <path
          id="CERTIFICATE_MOBILE_287_"
          d="M297.88,67.71c12.91,0,23.41,10.5,23.41,23.42c0,12.91-10.5,23.42-23.41,23.42
			c-12.91,0-23.42-10.5-23.42-23.42C274.46,78.21,284.96,67.71,297.88,67.71"
        />
      </defs>
      <clipPath id="CERTIFICATE_MOBILE_132_">
        <use
          xlinkHref="#CERTIFICATE_MOBILE_287_"
          style={{ overflow: "visible" }}
        />
      </clipPath>
      <path
        className="certificate-mobile-148"
        d="M316.02,79.28c-0.04-0.16-0.15-0.3-0.3-0.38c-0.16-0.09-0.34-0.11-0.52-0.07c-1.84,0.5-4.67,0.91-6.39,2.18
		c-0.78,0.58-1.41,1.23-1.87,1.94c-0.13,0.21-0.3,0.3-0.44,0.6c-0.03-0.09-0.06-0.18-0.09-0.26c-1.63-4.71-1.13-7.07-1.15-7.26
		c0.39-0.29,0.62-0.76,0.72-1.14c0.18-0.73-0.01-1.48-0.52-2.01c-0.52-0.54-1.25-0.79-2-0.69c-0.75,0.11-1.4,0.57-1.75,1.23
		c-0.16,0.3-0.25,0.63-0.28,0.98c-0.03,0.37,0.05,0.68,0.13,0.99c0.02,0.06,0.03,0.13,0.05,0.2c0.08,0.32-0.06,1.56-0.43,2.62
		s-0.74,2.21-1.37,2.41c-0.78,0.25-2.22,0.45-3.05,0.43c-0.83-0.82-2.01-2.56-2.8-3.82c-0.02-0.05-0.05-0.11-0.07-0.16
		c-0.03-0.07-0.09-0.13-0.15-0.2c-0.36-0.58-0.61-1-0.64-1.07c0.38-0.42,0.49-0.74,0.56-1.21c0.09-0.7-0.14-1.17-0.56-1.61
		c-0.91-0.97-2.31-0.9-3.29-0.17c-0.3,0.22-1.65,1.56-0.71,2.66c0.01,0.03,0.4,0.5,0.65,1.19c0.3,0.8,1.92,4.95,1.35,6.78
		c-0.54-0.32-1.04-0.36-1.53-0.78c-0.22-0.19-0.45-0.4-0.66-0.6c-0.42-0.38-0.85-0.77-1.31-1.12c-1.53-1.17-3.41-1.73-5.27-1.6
		c-0.49,0.04-0.96,0.11-1.38,0.22c-0.55,0.15-0.9,0.45-1.03,0.9c-0.29,1.03,0.05,2.19,0.38,2.98c0.73,1.72,1.98,3.41,3.44,4.64
		c0.84,0.71,1.76,1.25,2.73,1.61c0.54,0.2,1.14,0.5,1.69,0.57c0.17,0.02,0.81,0.18,0.74,0.32c-0.02,0.02-0.05,0.05-0.07,0.08
		c-0.11,0.09-0.28,0.21-0.26,0.3c0.03,0.12,0.1,0.31,0.2,0.36c0.2,0.1,0.42,0.12,0.63,0.11c-0.03,0.18-0.02,0.2-0.04,0.28
		c-0.17,0.5-0.11,1.06-0.05,1.53c-1,1.83-2.53,3.38-4.02,4.87c-0.53,0.53-4.25,4.23-4.75,4.8c-0.79,0.9-0.87,1.36-0.87,1.36
		c-0.24,1.53,1.49,6.18,5.42,12.21c2.15,3.3,4.04,4.95,4.92,5.04l0.25,0.03l0.09-0.24c0.3-0.8,2.74-6.81,3.35-8.24
		c0.4-0.92,1.45-3.58,2.01-5.54c0.27,0.78,0.65,1.44,0.65,1.44c0.64,1.02,1.71,1.82,3.02,2.19c0.77,0.21,1.48,0.27,2.52-0.1
		c1.09-0.39,0.8-1.13,0-0.86c-1.34,0.45-2.51,0.18-3.46-0.43c-1.42-0.92-2.05-2.4-2.29-3.18c0.34,0.42,0.81,1.32,2.37,2.08
		c1.76,0.86,4.01,1.18,5.72,0.2c1.3-0.74,1.92-1.34,2.45-2.49c0.53-1.14,0.82-2.49,0.2-3.56c-0.76-1.31-1.68-1.92-1.47-3.11
		c0.12-0.71,0.34-2.02,0.62-2.52c0.32-0.56,0.65-1.14,0.77-1.39c0.08-0.17,0.19-0.32,0.3-0.48c0.17-0.25,0.34-0.51,0.45-0.84
		c0.09-0.29,0.18-0.61,0.18-0.93c0.02-0.08,0.03-0.15,0.03-0.23c0.09-0.22,0.36-0.37,0.42-0.57c0.16-0.49-0.2-0.53-0.2-0.53
		c0.17-0.41,0.48-1.38,0.45-1.8c0.34-0.03,0.58,0.17,0.81-0.14c0.09-0.12-0.13-0.32-0.15-0.35c0.06-0.04,1.55-0.6,2.23-1.03
		c2.26-1.42,4.05-4.57,4.39-6.2C316.01,81.38,316.18,79.97,316.02,79.28z"
      />
      <path
        className="certificate-mobile-149"
        d="M315.53,79.28c-0.04-0.16-0.15-0.3-0.3-0.38c-0.16-0.09-0.34-0.11-0.52-0.07c-1.84,0.5-4.67,0.91-6.39,2.18
		c-0.78,0.58-1.41,1.23-1.87,1.94c-0.13,0.21-0.3,0.3-0.44,0.6c-0.03-0.09-0.06-0.18-0.09-0.26c-1.63-4.71-1.13-7.07-1.15-7.26
		c0.39-0.29,0.62-0.76,0.72-1.14c0.18-0.73-0.01-1.48-0.52-2.01c-0.52-0.54-1.25-0.79-2-0.69c-0.75,0.11-1.4,0.57-1.75,1.23
		c-0.16,0.3-0.25,0.63-0.28,0.98c-0.03,0.37,0.05,0.68,0.13,0.99c0.02,0.06,0.03,0.13,0.05,0.2c0.08,0.32-0.06,1.56-0.43,2.62
		s-0.74,2.21-1.37,2.41c-0.78,0.25-2.22,0.45-3.05,0.43c-0.83-0.82-2.01-2.56-2.8-3.82c-0.02-0.05-0.05-0.11-0.07-0.16
		c-0.03-0.07-0.09-0.13-0.15-0.2c-0.36-0.58-0.61-1-0.64-1.07c0.38-0.42,0.49-0.74,0.56-1.21c0.09-0.7-0.14-1.17-0.56-1.61
		c-0.91-0.97-2.31-0.9-3.29-0.17c-0.3,0.22-1.65,1.56-0.71,2.66c0.01,0.03,0.4,0.5,0.65,1.19c0.3,0.8,1.92,4.95,1.35,6.78
		c-0.54-0.32-1.04-0.36-1.53-0.78c-0.22-0.19-0.45-0.4-0.66-0.6c-0.42-0.38-0.85-0.77-1.31-1.12c-1.53-1.17-3.41-1.73-5.27-1.6
		c-0.49,0.04-0.96,0.11-1.38,0.22c-0.55,0.15-0.9,0.45-1.03,0.9c-0.29,1.03,0.05,2.19,0.38,2.98c0.73,1.72,1.98,3.41,3.44,4.64
		c0.84,0.71,1.76,1.25,2.73,1.61c0.54,0.2,1.14,0.5,1.69,0.57c0.17,0.02,0.81,0.18,0.74,0.32c-0.02,0.02-0.05,0.05-0.07,0.08
		c-0.11,0.09-0.28,0.21-0.26,0.3c0.03,0.12,0.1,0.31,0.2,0.36c0.2,0.1,0.42,0.12,0.63,0.11c-0.03,0.18-0.02,0.2-0.04,0.28
		c-0.17,0.5-0.11,1.06-0.05,1.53c-1,1.83-2.53,3.38-4.02,4.87c-0.53,0.53-4.25,4.23-4.75,4.8c-0.79,0.9-0.87,1.36-0.87,1.36
		c-0.24,1.53,1.49,6.18,5.42,12.21c2.15,3.3,4.04,4.95,4.92,5.04l0.25,0.03l0.09-0.24c0.3-0.8,2.74-6.81,3.35-8.24
		c0.4-0.92,1.45-3.58,2.01-5.54c0.27,0.78,0.65,1.44,0.65,1.44c0.64,1.02,1.71,1.82,3.02,2.19c0.77,0.21,1.48,0.27,2.52-0.1
		c1.09-0.39,0.8-1.13,0-0.86c-1.34,0.45-2.51,0.18-3.46-0.43c-1.42-0.92-2.05-2.4-2.29-3.18c0.34,0.42,0.81,1.32,2.37,2.08
		c1.76,0.86,4.01,1.18,5.72,0.2c1.3-0.74,1.92-1.34,2.45-2.49c0.53-1.14,0.82-2.49,0.2-3.56c-0.76-1.31-1.68-1.92-1.47-3.11
		c0.12-0.71,0.34-2.02,0.62-2.52c0.32-0.56,0.65-1.14,0.77-1.39c0.08-0.17,0.19-0.32,0.3-0.48c0.17-0.25,0.34-0.51,0.45-0.84
		c0.09-0.29,0.18-0.61,0.18-0.93c0.02-0.08,0.03-0.15,0.03-0.23c0.09-0.22,0.36-0.37,0.42-0.57c0.16-0.49-0.2-0.53-0.2-0.53
		c0.17-0.41,0.48-1.38,0.45-1.8c0.34-0.03,0.58,0.17,0.81-0.14c0.09-0.12-0.13-0.32-0.15-0.35c0.06-0.04,1.55-0.6,2.23-1.03
		c2.26-1.42,4.05-4.57,4.39-6.2C315.51,81.38,315.69,79.97,315.53,79.28z"
      />
      <linearGradient
        id="CERTIFICATE_MOBILE_133_"
        gradientUnits="userSpaceOnUse"
        x1="279.1446"
        y1="96.5673"
        x2="315.5897"
        y2="96.5673"
      >
        <stop offset="0" style={{ stopColor: "#8C8C8C" }} />
        <stop offset="0.2432" style={{ stopColor: "#A1A1A1" }} />
        <stop offset="0.334" style={{ stopColor: "#C6C6C6" }} />
        <stop offset="0.4468" style={{ stopColor: "#F0F0F0" }} />
        <stop offset="0.6095" style={{ stopColor: "#D9D9D9" }} />
        <stop offset="1" style={{ stopColor: "#B8B8B8" }} />
      </linearGradient>
      <path
        className="certificate-mobile-150"
        d="M315.53,78.98c-0.04-0.16-0.15-0.3-0.3-0.38c-0.16-0.09-0.34-0.11-0.52-0.07c-1.84,0.5-4.67,0.91-6.39,2.18
		c-0.78,0.58-1.41,1.23-1.87,1.94c-0.13,0.21-0.3,0.3-0.44,0.6c-0.03-0.09-0.06-0.18-0.09-0.26c-1.63-4.71-1.13-7.07-1.15-7.26
		c0.39-0.29,0.62-0.76,0.72-1.14c0.18-0.73-0.01-1.48-0.52-2.01c-0.52-0.54-1.25-0.79-2-0.69c-0.75,0.11-1.4,0.57-1.75,1.23
		c-0.16,0.3-0.25,0.63-0.28,0.98c-0.03,0.37,0.05,0.68,0.13,0.99c0.02,0.06,0.03,0.13,0.05,0.2c0.08,0.32-0.06,1.56-0.43,2.62
		s-0.74,2.21-1.37,2.41c-0.78,0.25-2.22,0.45-3.05,0.43c-0.83-0.82-2.01-2.56-2.8-3.82c-0.02-0.05-0.05-0.11-0.07-0.16
		c-0.03-0.07-0.09-0.13-0.15-0.2c-0.36-0.58-0.61-1-0.64-1.07c0.38-0.42,0.49-0.74,0.56-1.21c0.09-0.7-0.14-1.17-0.56-1.61
		c-0.91-0.97-2.31-0.9-3.29-0.17c-0.3,0.22-1.65,1.56-0.71,2.66c0.01,0.03,0.4,0.5,0.65,1.19c0.3,0.8,1.92,4.95,1.35,6.78
		c-0.54-0.32-1.04-0.36-1.53-0.78c-0.22-0.19-0.45-0.4-0.66-0.6c-0.42-0.38-0.85-0.77-1.31-1.12c-1.53-1.17-3.41-1.73-5.27-1.6
		c-0.49,0.04-0.96,0.11-1.38,0.22c-0.55,0.15-0.9,0.45-1.03,0.9c-0.29,1.03,0.05,2.19,0.38,2.98c0.73,1.72,1.98,3.41,3.44,4.64
		c0.84,0.71,1.76,1.25,2.73,1.61c0.54,0.2,1.14,0.5,1.69,0.57c0.17,0.02,0.81,0.18,0.74,0.32c-0.02,0.02-0.05,0.05-0.07,0.08
		c-0.11,0.09-0.28,0.21-0.26,0.3c0.03,0.12,0.1,0.31,0.2,0.36c0.2,0.1,0.42,0.12,0.63,0.11c-0.03,0.18-0.02,0.2-0.04,0.28
		c-0.17,0.5-0.11,1.06-0.05,1.53c-1,1.83-2.53,3.38-4.02,4.87c-0.53,0.53-4.25,4.23-4.75,4.8c-0.79,0.9-0.87,1.36-0.87,1.36
		c-0.24,1.53,1.49,6.18,5.42,12.21c2.15,3.3,4.04,4.95,4.92,5.04l0.25,0.03l0.09-0.24c0.3-0.8,2.74-6.81,3.35-8.24
		c0.4-0.92,1.45-3.58,2.01-5.54c0.27,0.78,0.65,1.44,0.65,1.44c0.64,1.02,1.71,1.82,3.02,2.19c0.77,0.21,1.48,0.27,2.52-0.1
		c1.09-0.39,0.8-1.13,0-0.86c-1.34,0.45-2.51,0.18-3.46-0.43c-1.42-0.92-2.05-2.4-2.29-3.18c0.34,0.42,0.81,1.32,2.37,2.08
		c1.76,0.86,4.01,1.18,5.72,0.2c1.3-0.74,1.92-1.34,2.45-2.49c0.53-1.14,0.82-2.49,0.2-3.56c-0.76-1.31-1.68-1.92-1.47-3.11
		c0.12-0.71,0.34-2.02,0.62-2.52c0.32-0.56,0.65-1.14,0.77-1.39c0.08-0.17,0.19-0.32,0.3-0.48c0.17-0.25,0.34-0.51,0.45-0.84
		c0.09-0.29,0.18-0.61,0.18-0.93c0.02-0.08,0.03-0.15,0.03-0.23c0.09-0.22,0.36-0.37,0.42-0.57c0.16-0.49-0.2-0.53-0.2-0.53
		c0.17-0.41,0.48-1.38,0.45-1.8c0.34-0.03,0.58,0.17,0.81-0.14c0.09-0.12-0.13-0.32-0.15-0.35c0.06-0.04,1.55-0.6,2.23-1.03
		c2.26-1.42,4.05-4.57,4.39-6.2C315.51,81.08,315.69,79.68,315.53,78.98z"
      />
      <linearGradient
        id="CERTIFICATE_MOBILE_134_"
        gradientUnits="userSpaceOnUse"
        x1="279.1446"
        y1="96.5673"
        x2="315.5897"
        y2="96.5673"
      >
        <stop offset="0" style={{ stopColor: "#8C8C8C" }} />
        <stop offset="0.2526" style={{ stopColor: "#F0F0F0" }} />
        <stop offset="0.4269" style={{ stopColor: "#F0F0F0" }} />
        <stop offset="0.4444" style={{ stopColor: "#F0F0F0" }} />
        <stop offset="0.7579" style={{ stopColor: "#F0F0F0" }} />
        <stop offset="0.7893" style={{ stopColor: "#F0F0F0" }} />
        <stop offset="0.8536" style={{ stopColor: "#EDEDED" }} />
        <stop offset="0.905" style={{ stopColor: "#E3E3E3" }} />
        <stop offset="0.9519" style={{ stopColor: "#D2D2D2" }} />
        <stop offset="0.9959" style={{ stopColor: "#BABABA" }} />
        <stop offset="1" style={{ stopColor: "#B8B8B8" }} />
      </linearGradient>
      <path
        className="certificate-mobile-151"
        d="M315.53,78.98c-0.04-0.16-0.15-0.3-0.3-0.38c-0.16-0.09-0.34-0.11-0.52-0.07c-1.84,0.5-4.67,0.91-6.39,2.18
		c-0.78,0.58-1.41,1.23-1.87,1.94c-0.13,0.21-0.3,0.3-0.44,0.6c-0.03-0.09-0.06-0.18-0.09-0.26c-1.63-4.71-1.13-7.07-1.15-7.26
		c0.39-0.29,0.62-0.76,0.72-1.14c0.18-0.73-0.01-1.48-0.52-2.01c-0.52-0.54-1.25-0.79-2-0.69c-0.75,0.11-1.4,0.57-1.75,1.23
		c-0.16,0.3-0.25,0.63-0.28,0.98c-0.03,0.37,0.05,0.68,0.13,0.99c0.02,0.06,0.03,0.13,0.05,0.2c0.08,0.32-0.06,1.56-0.43,2.62
		s-0.74,2.21-1.37,2.41c-0.78,0.25-2.22,0.45-3.05,0.43c-0.83-0.82-2.01-2.56-2.8-3.82c-0.02-0.05-0.05-0.11-0.07-0.16
		c-0.03-0.07-0.09-0.13-0.15-0.2c-0.36-0.58-0.61-1-0.64-1.07c0.38-0.42,0.49-0.74,0.56-1.21c0.09-0.7-0.14-1.17-0.56-1.61
		c-0.91-0.97-2.31-0.9-3.29-0.17c-0.3,0.22-1.65,1.56-0.71,2.66c0.01,0.03,0.4,0.5,0.65,1.19c0.3,0.8,1.92,4.95,1.35,6.78
		c-0.54-0.32-1.04-0.36-1.53-0.78c-0.22-0.19-0.45-0.4-0.66-0.6c-0.42-0.38-0.85-0.77-1.31-1.12c-1.53-1.17-3.41-1.73-5.27-1.6
		c-0.49,0.04-0.96,0.11-1.38,0.22c-0.55,0.15-0.9,0.45-1.03,0.9c-0.29,1.03,0.05,2.19,0.38,2.98c0.73,1.72,1.98,3.41,3.44,4.64
		c0.84,0.71,1.76,1.25,2.73,1.61c0.54,0.2,1.14,0.5,1.69,0.57c0.17,0.02,0.81,0.18,0.74,0.32c-0.02,0.02-0.05,0.05-0.07,0.08
		c-0.11,0.09-0.28,0.21-0.26,0.3c0.03,0.12,0.1,0.31,0.2,0.36c0.2,0.1,0.42,0.12,0.63,0.11c-0.03,0.18-0.02,0.2-0.04,0.28
		c-0.17,0.5-0.11,1.06-0.05,1.53c-1,1.83-2.53,3.38-4.02,4.87c-0.53,0.53-4.25,4.23-4.75,4.8c-0.79,0.9-0.87,1.36-0.87,1.36
		c-0.24,1.53,1.49,6.18,5.42,12.21c2.15,3.3,4.04,4.95,4.92,5.04l0.25,0.03l0.09-0.24c0.3-0.8,2.74-6.81,3.35-8.24
		c0.4-0.92,1.45-3.58,2.01-5.54c0.27,0.78,0.65,1.44,0.65,1.44c0.64,1.02,1.71,1.82,3.02,2.19c0.77,0.21,1.48,0.27,2.52-0.1
		c1.09-0.39,0.8-1.13,0-0.86c-1.34,0.45-2.51,0.18-3.46-0.43c-1.42-0.92-2.05-2.4-2.29-3.18c0.34,0.42,0.81,1.32,2.37,2.08
		c1.76,0.86,4.01,1.18,5.72,0.2c1.3-0.74,1.92-1.34,2.45-2.49c0.53-1.14,0.82-2.49,0.2-3.56c-0.76-1.31-1.68-1.92-1.47-3.11
		c0.12-0.71,0.34-2.02,0.62-2.52c0.32-0.56,0.65-1.14,0.77-1.39c0.08-0.17,0.19-0.32,0.3-0.48c0.17-0.25,0.34-0.51,0.45-0.84
		c0.09-0.29,0.18-0.61,0.18-0.93c0.02-0.08,0.03-0.15,0.03-0.23c0.09-0.22,0.36-0.37,0.42-0.57c0.16-0.49-0.2-0.53-0.2-0.53
		c0.17-0.41,0.48-1.38,0.45-1.8c0.34-0.03,0.58,0.17,0.81-0.14c0.09-0.12-0.13-0.32-0.15-0.35c0.06-0.04,1.55-0.6,2.23-1.03
		c2.26-1.42,4.05-4.57,4.39-6.2C315.51,81.08,315.69,79.68,315.53,78.98z"
      />
      <g className="certificate-mobile-152">
        <linearGradient
          id="CERTIFICATE_MOBILE_135_"
          gradientUnits="userSpaceOnUse"
          x1="283.5921"
          y1="119.1095"
          x2="301.1258"
          y2="70.936"
        >
          <stop offset="0.2527" style={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" style={{ stopColor: "#909090" }} />
          <stop offset="0.7204" style={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" style={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-153"
          d="M315.53,78.98c-0.04-0.16-0.15-0.3-0.3-0.38c-0.16-0.09-0.34-0.11-0.52-0.07c-1.84,0.5-4.67,0.91-6.39,2.18
			c-0.78,0.58-1.41,1.23-1.87,1.94c-0.13,0.21-0.3,0.3-0.44,0.6c-0.03-0.09-0.06-0.18-0.09-0.26c-1.64-4.71-1.13-7.07-1.15-7.26
			c0.39-0.29,0.62-0.76,0.72-1.14c0.18-0.73-0.01-1.48-0.52-2.01c-0.52-0.54-1.25-0.79-2-0.69c-0.75,0.11-1.4,0.57-1.75,1.23
			c-0.16,0.3-0.25,0.63-0.28,0.98c-0.03,0.37,0.05,0.68,0.13,0.99c0.02,0.06,0.03,0.13,0.05,0.2c0.08,0.32-0.06,1.56-0.43,2.62
			c-0.37,1.06-0.74,2.21-1.37,2.41c-0.78,0.25-2.22,0.45-3.05,0.43c-1.34-1.32-3.57-5.04-3.67-5.24c0.38-0.42,0.49-0.74,0.56-1.21
			c0.09-0.7-0.14-1.17-0.56-1.61c-0.91-0.97-2.31-0.9-3.29-0.17c-0.3,0.22-1.65,1.56-0.71,2.66c0.01,0.03,0.4,0.5,0.65,1.19
			c0.3,0.8,1.92,4.95,1.35,6.78c-0.54-0.32-1.04-0.36-1.53-0.78c-0.22-0.19-0.45-0.4-0.66-0.6c-0.42-0.38-0.85-0.77-1.31-1.12
			c-1.53-1.17-3.41-1.73-5.27-1.6c-0.49,0.04-0.96,0.11-1.38,0.22c-0.55,0.15-0.9,0.45-1.03,0.9c-0.29,1.03,0.05,2.19,0.38,2.98
			c0.73,1.72,1.98,3.41,3.44,4.64c0.84,0.71,1.76,1.25,2.73,1.61c0.54,0.2,1.14,0.5,1.69,0.57c0.17,0.02,0.81,0.18,0.74,0.32
			c-0.02,0.02-0.05,0.05-0.07,0.08c-0.11,0.09-0.28,0.21-0.26,0.3c0.03,0.12,0.1,0.31,0.2,0.36c0.2,0.1,0.42,0.12,0.63,0.11
			c-0.03,0.18-0.02,0.2-0.04,0.28c-0.17,0.5-0.11,1.06-0.05,1.53c-1,1.83-2.53,3.38-4.02,4.87c-0.53,0.53-4.25,4.23-4.75,4.8
			c-0.79,0.9-0.87,1.36-0.87,1.36c-0.24,1.53,1.49,6.18,5.42,12.21c2.15,3.3,4.04,4.95,4.92,5.04l0.25,0.03l0.09-0.24
			c0.3-0.8,2.74-6.81,3.35-8.24c0.4-0.92,1.45-3.58,2.01-5.54c0.27,0.78,0.65,1.44,0.65,1.44c0.64,1.02,1.71,1.82,3.02,2.19
			c0.77,0.21,1.48,0.27,2.52-0.1c1.09-0.39,0.8-1.13,0-0.86c-1.34,0.45-2.51,0.18-3.46-0.43c-1.42-0.92-2.05-2.4-2.29-3.18
			c0.34,0.42,0.81,1.32,2.37,2.08c1.76,0.86,4.01,1.18,5.72,0.2c1.3-0.74,1.92-1.34,2.45-2.49c0.53-1.14,0.82-2.49,0.2-3.56
			c-0.76-1.31-1.68-1.92-1.47-3.11c0.12-0.71,0.34-2.02,0.62-2.52c0.32-0.56,0.65-1.14,0.77-1.39c0.08-0.17,0.19-0.32,0.3-0.48
			c0.17-0.25,0.34-0.51,0.45-0.84c0.09-0.29,0.18-0.61,0.18-0.93c0.02-0.08,0.03-0.15,0.03-0.23c0.09-0.22,0.36-0.37,0.42-0.57
			c0.16-0.49-0.2-0.53-0.2-0.53c0.17-0.41,0.48-1.38,0.45-1.8c0.34-0.03,0.58,0.17,0.81-0.14c0.09-0.12-0.13-0.32-0.15-0.35
			c0.06-0.04,1.55-0.6,2.23-1.03c2.26-1.42,4.05-4.57,4.39-6.2C315.51,81.08,315.69,79.68,315.53,78.98z M307.34,89.18
			c0.02-0.01,0.03-0.02,0.05-0.03c0.01,0.01,0.01,0.01,0.02,0.02C307.39,89.18,307.36,89.18,307.34,89.18z M314.28,81.97
			c-0.34,1.53-1.12,3.68-3.7,5.48c-1.04,0.73-2.7,1.06-2.7,1.06c-0.01,0.01-0.04,0.02-0.08,0.03c3.21-1.69,4.82-3.29,6.14-7.26
			c0.05-0.14,0.4-0.76,0.28-0.68c-0.38,0.25-0.81,0.28-1.2,0.35c-0.68,0.07-1.4,0.4-1.99,0.7c-0.7,0.36-1.26,0.87-1.71,1.51
			c-0.43,0.62-0.74,1.3-1.1,1.95c-0.17,0.31-0.36,0.62-0.57,0.9c-0.14,0.19-0.4,0.41-0.51,0.65c-0.02,0.03-0.05,0.05-0.06,0.08
			c-0.62,0.99-0.24,1.61,0.08,1.86c-0.03,0.01-0.06,0.01-0.09,0.02c-0.17-0.01-0.35,0.02-0.49,0.03c-0.28,0.01-0.55,0.07-0.81,0.17
			c-0.36,0.15-0.8,0.41-0.97,0.79c-0.31,0.4-0.45,0.97-0.24,1.44c0.01,0.17,0.04,0.33,0.11,0.46c0.01,0.12,0.06,0.24,0.13,0.34
			c0.25,0.36,0.79,0.37,1.15,0.19c0.75-0.38,1.12-1.36,0.93-2.19c0.12,0,0.27,0.02,0.42,0.04c-0.04,0.61-0.27,1.71-0.42,2.1
			l-0.04-0.07c0.01,0.04,0.01,0.08,0.01,0.12c0,0,0,0.01,0,0.01c-0.01,0.03-0.06,0.2-0.12,0.37c-0.09,0.1-0.23,0.16-0.4,0.21
			c-0.58,0.15-1.14,0-1.51-0.47c-0.04-0.05-0.11,0-0.09,0.05c0.25,0.76,1.14,1.02,1.86,0.81c-0.01,0.05-0.01,0.09-0.02,0.11
			c0,0-0.13,0.92-0.51,1.43c-0.31,0.22-1.3,1.94-1.63,2.96c-0.33,1.04-0.44,1.8-0.4,2.37c-0.03-0.09-0.07-0.18-0.11-0.25
			c-0.15-0.31-0.34-0.37-0.42-0.38c-0.66-0.09-1.34,0.44-1.72,1.34c-0.27,0.65-0.3,1.51-0.29,1.82c0,0.4,0.05,0.8,0.15,1.17
			c0.02,0.08,0.05-0.1,0.08-0.38c0.16-0.55,0.61-1.57,1.89-2.14c0,0,0.51,0.07,0.55-0.46c0.1,0.3,0.24,0.54,0.4,0.76
			c0.33,0.45,1.02,1.39,1.02,1.68c0,0,0.29,0.9-0.08,1.92c-0.39,1.09-0.74,1.92-2.04,2.66c-1.12,0.63-2.71,0.48-3.6,0.29
			c-1.8-0.39-3.14-1.66-3.8-2.57c-0.94-1.31-0.7-1.57-1.11-1.94c0,0,0.01,0.8-0.12,2.23c-0.06,0.22-0.13,0.44-0.2,0.66
			c-0.04-0.3-0.09-0.58-0.12-0.66c-0.43-1.07-0.85-1.64-1.32-2.69c-0.36-0.79-1.3-0.57-2.36-0.34c-1.55,0.34-2.15,1.4-2.1,1.82
			c0.06,0.55,0.51,1.18,0.72,1.68c0.46,1.13,0.83,2.13,1.2,3.29c0.58,1.8,0.88,3.64,0.81,5.53c-0.86,2.11-1.92,4.74-2.14,5.3
			c-0.22-0.12-0.6-0.38-1.22-1.02c0.71-0.76,1.56-2.88,1.9-3.6c0.21-1.58-0.11-3.22-0.34-4.18c-0.35-1.47-1.39-6.04-2.33-5.6
			c-0.32,0.33-3.26,4.12-4.6,5.63c0.01,0.05,0.03,0.11,0.05,0.17c-0.3-0.54-0.59-1.08-0.86-1.61c0.72-1.04,4.12-4.67,3.93-5.43
			c-0.12-0.44-2.36-1.97-2.74-4.11c0.55-0.54,1.08-1.04,1.47-1.42c-0.08,0.22-0.08,0.45-0.03,0.75c0.13,0.74,0.31,1.53,0.71,2.16
			c0.46,0.75,2.19,1.65,2.54,1.42c0.76-0.5,2.66-2.22,3-2.53c0.79-0.72,1.46-2.03,1.34-2.38c-0.06-0.19-0.9-0.7-1.53-1.61
			c-0.47-0.68-0.86-2.07-1.43-3.04c0.1-0.16,0.19-0.32,0.28-0.48l0.05-0.25l-0.02-0.12c-0.06-0.44-0.13-0.93,0-1.31l0.01-0.03
			l0.01-0.03c0,0,0,0,0-0.01c0.02,0.03,0.04,0.07,0.06,0.1c0.07,0.28,0.2,0.53,0.4,0.69c0.25,0.2,0.54,0.25,0.84,0.22
			c0.01,0.02,0.74-0.04,1.32-0.76c0.05-0.07,0.15-0.19,0.22-0.32c0.05,0.05,0.11,0.09,0.19,0.11c0.06,0.02,0.12-0.01,0.16-0.05
			c0.34-0.36-0.29-1.01-0.57-1.24c-0.35-0.29-0.77-0.49-1.21-0.57c-0.32-0.06-0.59-0.07-0.83-0.07c-0.09-0.15-0.19-0.31-0.2-0.37
			l-0.21-0.06c0,0-1.76-0.04-3.54-0.8c-0.88-0.38-1.64-0.78-2.42-1.44c-1.38-1.16-2.53-2.67-3.21-4.3c-0.24-0.57-0.5-1.33-0.25-2.22
			c0.04-0.15,1.43-0.59,1.88-0.63c1.7-0.12,3.33,0.6,4.74,1.67c0.44,0.33,1.71,1.5,1.94,1.69c0.61,0.53,2.6,1.56,2.6,1.56
			s0.43-1.31,0.37-2.7c-0.08-1.95-1.13-4.71-1.82-6.15c-0.24-0.51-0.67-0.98-0.69-1.02c-0.45-0.74,0.17-1.41,0.53-1.67
			c0.98-0.73,1.94-0.55,2.39,0c0.55,0.67,0.09,1.39-0.18,1.59l-0.49,0.41c0,0,1.07,1.89,1.92,3.39c0.75,1.33,2.04,2.91,2.42,2.92
			c0.92,0.03,3.08-0.17,3.94-0.45c0.86-0.28,1.31-1.67,1.76-3.09c0.14-0.42,0.45-2.32,0.34-2.79c-0.02-0.07-0.27-1.05-0.03-1.53
			c0.25-0.49,0.74-0.84,1.29-0.92c0.54-0.08,0.86-0.02,1.23,0.37c0.35,0.36,0.39,0.65,0.31,1.16c-0.08,0.49-0.35,0.79-0.65,0.93
			l-0.24,0.12c0,0-0.08,1.27,0.12,2.97c0.08,0.66,0.76,3.79,1.02,4.65c0.3,0.98,0.86,2.39,0.86,2.39s0.82-1.1,1.23-1.68
			c0.45-0.63,1.49-1.88,2.21-2.41c1.62-1.2,3.53-1.27,5.31-1.76C314.55,79.55,314.66,80.25,314.28,81.97z M305.97,90.62
			c-0.04,0.13-0.15,0.31-0.29,0.34c-0.03-0.01-0.05-0.01-0.08-0.01c-0.06,0-0.12-0.03-0.16-0.07c0,0,0,0-0.01,0
			c0.01-0.03,0.01-0.07,0.02-0.1c0.07-0.27,0.21-0.5,0.4-0.7C306.01,90.21,306.04,90.39,305.97,90.62z M290.06,90.79
			c0.06-0.06,0.14-0.09,0.22-0.09c0,0,0,0,0.01,0c0.07-0.02,0.13-0.02,0.2,0c0.04,0.01,0.08,0.03,0.11,0.05
			c0.01,0.01,0.02,0.01,0.02,0.02c0,0,0,0,0,0c0.04,0.03,0.07,0.07,0.1,0.11c0.02,0.03,0.03,0.06,0.04,0.09c0,0,0,0,0,0
			c0.05,0.13,0.05,0.26-0.02,0.38c-0.05,0.09-0.14,0.17-0.23,0.19c-0.1,0.03-0.21,0-0.3-0.05c-0.12-0.07-0.18-0.2-0.2-0.33
			c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.01,0-0.01-0.01-0.02C289.95,91.03,289.96,90.89,290.06,90.79z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_136_"
          gradientUnits="userSpaceOnUse"
          x1="302.1628"
          y1="98.5985"
          x2="304.6991"
          y2="91.6302"
        >
          <stop offset="0.2527" style={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" style={{ stopColor: "#909090" }} />
          <stop offset="0.7204" style={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" style={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-154"
          d="M303.75,91.37c-0.77,0.92-1.18,1.99-1.34,3.58c-0.08,0.75-0.13,1.43,0,2.11l0.01,0.08
			c0.13,0.72,0.33,1.57,0.67,1.78c0.07,0.04,0.15,0.09,0.22,0.09c0.03,0,0.05-0.01,0.07-0.02c0.09-0.06,0.05-0.22,0-0.39
			c-0.01-0.03-0.01-0.06-0.02-0.08c-0.02-0.08-0.05-0.19-0.09-0.32c-0.07-0.25-0.17-0.56-0.23-0.89c-0.12-0.66-0.16-1.55-0.09-2.21
			c0.16-1.58,0.61-2.67,0.89-3.32c0.04-0.11,0.08-0.2,0.12-0.28c0.02-0.06,0-0.12-0.05-0.15C303.86,91.31,303.79,91.32,303.75,91.37
			z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_137_"
          gradientUnits="userSpaceOnUse"
          x1="294.1871"
          y1="98.9044"
          x2="296.2984"
          y2="93.1037"
        >
          <stop offset="0.2527" tyle={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" tyle={{ stopColor: "#909090" }} />
          <stop offset="0.7204" tyle={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" tyle={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-155"
          d="M296.21,96.41c-0.18-0.25-0.34-0.46-0.52-0.71c-0.18-0.25-0.39-0.53-0.69-0.93
			c-0.45-0.61-0.91-1.34-1.27-2.55c-0.03-0.08-0.13-0.11-0.23-0.1c-0.09,0.02-0.14,0.07-0.13,0.14c0.09,1.22,1.2,3.02,2.11,4.19
			l0.09,0.11c0.45,0.56,1.2,1.51,1.02,2.96c-0.02,0.17,0.12,0.31,0.27,0.34c0.02,0,0.04,0.01,0.06,0.01c0.09,0,0.16-0.05,0.19-0.13
			c0.06-0.18,0.09-0.64-0.02-1.38C296.99,97.6,296.72,97.13,296.21,96.41z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_138_"
          gradientUnits="userSpaceOnUse"
          x1="297.8447"
          y1="87.0423"
          x2="298.6523"
          y2="84.8233"
        >
          <stop offset="0.2527" style={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" style={{ stopColor: "#909090" }} />
          <stop offset="0.7204" style={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" style={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-156"
          d="M298.21,87.18c0.3,0.09,0.67,0,0.86-0.26c0.3-0.41,0.32-1.12,0.02-1.54c-0.2-0.28-0.51-0.51-0.81-0.67
			c-0.52-0.29-1.08,0.65-1.05,0.69C297.23,85.42,297.89,87.08,298.21,87.18z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_139_"
          gradientUnits="userSpaceOnUse"
          x1="295.3325"
          y1="87.1904"
          x2="296.1547"
          y2="84.9312"
        >
          <stop offset="0.2527" style={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" style={{ stopColor: "#909090" }} />
          <stop offset="0.7204" style={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" style={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-157"
          d="M296.06,87.32c0.26,0.01,0.47-0.03,0.66-0.22c0.18-0.17,0.27-0.41,0.24-0.66c-0.02-0.16-0.24-1.13-0.4-1.32
			c-0.17-0.2-0.73-0.16-0.76-0.15c-0.12-0.07-0.28-0.11-0.43-0.1c-0.73,0.03-1.15,0.92-0.9,1.54
			C294.72,87.07,295.42,87.29,296.06,87.32z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_140_"
          gradientUnits="userSpaceOnUse"
          x1="299.2495"
          y1="84.7778"
          x2="299.9946"
          y2="82.7306"
        >
          <stop offset="0.2527" style={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" style={{ stopColor: "#909090" }} />
          <stop offset="0.7204" style={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" style={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-158"
          d="M299.78,84.96c0.26,0.24,0.65-0.69,0.73-0.97c0.17-0.65-0.11-0.67-0.3-0.95c-0.01-0.06-0.1-0.18-0.14-0.23
			c-0.19-0.28-1.09-0.1-1.2,0.37C298.66,84.04,299.13,84.36,299.78,84.96z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_141_"
          gradientUnits="userSpaceOnUse"
          x1="295.7745"
          y1="84.2393"
          x2="296.5341"
          y2="82.1523"
        >
          <stop offset="0.2527" style={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" style={{ stopColor: "#909090" }} />
          <stop offset="0.7204" style={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" style={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-159"
          d="M296.79,84.1c0.15-0.09,0.22-0.3,0.2-0.46c-0.06-0.51-0.12-0.51-0.15-1.02c-0.05-1.09-1.6,0.02-1.64,0.28
			c-0.02,0.15-0.15,0.78,0.01,0.89C295.47,84.36,296.31,84.37,296.79,84.1z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_142_"
          gradientUnits="userSpaceOnUse"
          x1="301.4231"
          y1="86.4871"
          x2="302.25"
          y2="84.2151"
        >
          <stop offset="0.2527" style={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" style={{ stopColor: "#909090" }} />
          <stop offset="0.7204" style={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" style={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-160"
          d="M302.45,86.41c0.29-0.64,0.41-1.7-0.22-2.18c-0.08-0.06-0.16-0.08-0.24-0.08c-0.1-0.1-0.27-0.14-0.43-0.03
			c-0.93,0.62-0.71,2.12,0.3,2.52C302.08,86.73,302.34,86.64,302.45,86.41z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_143_"
          gradientUnits="userSpaceOnUse"
          x1="298.7731"
          y1="90.7015"
          x2="299.7575"
          y2="87.9969"
        >
          <stop offset="0.2527" style={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" style={{ stopColor: "#909090" }} />
          <stop offset="0.7204" style={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" style={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-161"
          d="M299.36,87.93c-0.29,0.03-0.51,0.25-0.73,0.42c-0.05,0.04-0.11,0.09-0.16,0.13
			c-0.04,0.02-0.09,0.03-0.13,0.05c-0.35,0.16-0.66,0.4-0.73,0.8c-0.1,0.49,0.2,0.9,0.61,1.14c0.5,0.29,1.11,0.18,1.6,0.48
			c0.26,0.16,0.65-0.08,0.67-0.37c0.05-0.69,0.06-1.38,0.05-2.07C300.52,88.21,299.59,87.91,299.36,87.93z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_144_"
          gradientUnits="userSpaceOnUse"
          x1="292.419"
          y1="85.5708"
          x2="293.1991"
          y2="83.4276"
        >
          <stop offset="0.2527" style={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" style={{ stopColor: "#909090" }} />
          <stop offset="0.7204" style={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" style={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-162"
          d="M293.72,83.99c-0.1-0.22-0.39-0.51-0.61-0.59c-0.03-0.01-1.2,0.84-1.21,0.91
			c-0.07,0.54,0.12,1.24,0.64,1.29c0.47,0.05,0.88-0.41,1.13-0.82C293.81,84.53,293.84,84.25,293.72,83.99z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_145_"
          gradientUnits="userSpaceOnUse"
          x1="303.8272"
          y1="87.562"
          x2="304.6894"
          y2="85.193"
        >
          <stop offset="0.2527" style={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" style={{ stopColor: "#909090" }} />
          <stop offset="0.7204" style={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" style={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-163"
          d="M303.84,84.98c-0.37,0.21-0.63,1.74-0.6,2.14c0.12,0.04,0.22,0.19,0.24,0.31c0.24,0.1,0.5,0.13,0.76,0.08
			c0.17-0.03,0.75-0.41,0.84-0.57c0.4-0.75,0.19-1,0.21-1.25C305.31,85.41,304.03,84.87,303.84,84.98z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_146_"
          gradientUnits="userSpaceOnUse"
          x1="294.4154"
          y1="89.9132"
          x2="295.1839"
          y2="87.8018"
        >
          <stop offset="0.2527" tyle={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" tyle={{ stopColor: "#909090" }} />
          <stop offset="0.7204" tyle={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" tyle={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-164"
          d="M294.26,89.8c0.49-0.03,1.02-0.13,1.35-0.54c0.32-0.41,0.34-1.01-0.1-1.34c-0.23-0.18-1.9,0.78-1.85,1.4
			C293.68,89.63,293.97,89.82,294.26,89.8z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_147_"
          gradientUnits="userSpaceOnUse"
          x1="302.4519"
          y1="83.4697"
          x2="302.963"
          y2="82.0655"
        >
          <stop offset="0.2527" tyle={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" tyle={{ stopColor: "#909090" }} />
          <stop offset="0.7204" tyle={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" tyle={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-165"
          d="M302.56,83.49c0.51,0.11,0.95,0.25,1.06-0.29c0.06-0.29-0.7-1.4-1.31-1.3c-0.3,0.05-0.72,0.61-0.58,0.86
			C301.97,83.19,302.05,83.38,302.56,83.49z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_148_"
          gradientUnits="userSpaceOnUse"
          x1="302.155"
          y1="90.1667"
          x2="303.0539"
          y2="87.697"
        >
          <stop offset="0.2527" tyle={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" tyle={{ stopColor: "#909090" }} />
          <stop offset="0.7204" tyle={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" tyle={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-166"
          d="M303.11,90.01c0.18-0.05,0.48-1.15,0.51-1.29c0.06-0.26-1.02-1.84-1.44-0.83c-0.2,0.47-0.46,0.91-0.69,1.37
			c-0.07,0.14-0.09,0.37,0.03,0.5C301.88,90.17,302.68,90.45,303.11,90.01z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_149_"
          gradientUnits="userSpaceOnUse"
          x1="292.28"
          y1="88.6456"
          x2="292.6214"
          y2="87.7077"
        >
          <stop offset="0.2527" tyle={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" tyle={{ stopColor: "#909090" }} />
          <stop offset="0.7204" tyle={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" tyle={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-167"
          d="M292.79,87.77c-0.09-0.03-0.18-0.08-0.27-0.09c-0.09-0.01-0.19,0.01-0.28,0.02
			c-0.14,0.02-0.31,0.12-0.36,0.27c-0.03,0.09-0.04,0.14-0.04,0.24c0,0.16,0.14,0.3,0.27,0.36c0.09,0.04,0.17,0.09,0.26,0.1
			c0.09,0.01,0.19,0,0.28-0.01C293.11,88.64,293.22,87.93,292.79,87.77z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_150_"
          gradientUnits="userSpaceOnUse"
          x1="298.6908"
          y1="103.3748"
          x2="299.821"
          y2="100.2697"
        >
          <stop offset="0.2527" tyle={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" tyle={{ stopColor: "#909090" }} />
          <stop offset="0.7204" tyle={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" tyle={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-168"
          d="M298.6,99.91c-1.22,0.06-1.33,0.83-1.34,1.03c-0.01,0.58,0.71,0.96,1.29,1.03c0.08,0.01,0.59,0.22,0.94,0.4
			c0.74,0.38,1.24,1.76,1.27,1.75c0.02,0,0.04-0.03,0.06-0.12c0.09-0.29,0.19-1.31-0.76-3.15
			C299.82,100.42,299.18,99.88,298.6,99.91z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_151_"
          gradientUnits="userSpaceOnUse"
          x1="285.2014"
          y1="87.7042"
          x2="286.745"
          y2="83.463"
        >
          <stop offset="0.2527" tyle={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" tyle={{ stopColor: "#909090" }} />
          <stop offset="0.7204" tyle={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" tyle={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-169"
          d="M290.41,87.48c-0.06-0.03-0.13-0.06-0.2-0.09c-0.29-0.18-0.65-0.29-0.94-0.43
			c-0.42-0.21-0.82-0.49-1.1-0.87c-0.28-0.38-0.46-0.82-0.71-1.22c-0.24-0.38-0.54-0.73-0.88-1.03c-0.51-0.45-1.09-0.74-1.71-0.96
			c-0.11-0.05-0.23-0.1-0.34-0.14c-0.25-0.09-0.53-0.15-0.83-0.21c-0.64-0.18-1.28-0.41-1.78-0.83c-0.04-0.03-0.1,0.02-0.07,0.06
			c0.42,0.61,1.02,0.93,1.68,1.17c0.22,0.1,0.43,0.22,0.61,0.34c0.8,0.55,1.2,1.35,1.63,2.2c0.43,0.88,0.83,1.78,1.48,2.52
			c0.33,0.37,0.69,0.76,1.17,0.85c0.92,0.19,0.52,0.17,1.17,0.13C290.31,88.95,290.94,87.85,290.41,87.48z"
        />
        <linearGradient
          id="CERTIFICATE_MOBILE_152_"
          gradientUnits="userSpaceOnUse"
          x1="290.9822"
          y1="93.8746"
          x2="291.9899"
          y2="91.1061"
        >
          <stop offset="0.2527" tyle={{ stopColor: "#8A8A8A" }} />
          <stop offset="0.3562" tyle={{ stopColor: "#909090" }} />
          <stop offset="0.7204" tyle={{ stopColor: "#A0A0A0" }} />
          <stop offset="1" tyle={{ stopColor: "#A6A6A6" }} />
        </linearGradient>
        <path
          className="certificate-mobile-170"
          d="M292.45,92.41c-1.35,0.99-2.6,0.44-2.6,0.44l0.05,0.63c0.34,0.01,1.83,0.46,2.97-0.94
			c0.46-0.57,0.22-1.03,0.22-1.03S292.87,92.11,292.45,92.41z"
        />
      </g>
    </g>
  </Styled>
);

export default CertificateSvgMobile;
