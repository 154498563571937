import React from "react";
import styled from "@emotion/styled/macro";

const StyledTextArea = styled.textarea`
  box-shadow: 5px 5px 7px -2px rgba(0, 0, 0, 0.3);
`;

const TextArea = ({
  className = "",
  label = "",
  name = "",
  placeholder = "",
  error = false,
  errorText = "",
  defaultValue = "",
  description = "",
  resize = false,
  useRef,
  onChange = () => {},
  ...newProps
}) => {
  const hasError = error || errorText ? true : false;
  let finalClass = `${className} w-full rounded-sm px-4 py-3 h-44 outline-none transition-colors duration-150 ease-in-out ${
    hasError
      ? `border border-red-600 focus:border-red-600 `
      : ` focus:border-blue-400`
  }`;
  if (!resize) finalClass += " resize-none";
  return (
    <>
      {label && (
        <label
          className={`text-sm ${hasError && "text-red-600"}`}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <StyledTextArea
        name={name}
        className={finalClass}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={(e) => onChange(e.currentTarget.value)}
        ref={useRef}
        {...newProps}
      ></StyledTextArea>
      {description && (
        <span className="mt-2 text-xs">{description}</span>
      )}
      {errorText && (
        <div className="mt-1 text-xs text-red-600">{errorText}</div>
      )}
    </>
  );
};

export default TextArea;
