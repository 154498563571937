import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getExercise, sendAwnsers } from "services/formService";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import Button from "components/Button";
import { StyledCircle, Ul, Li, CustomButton, PlayerButton } from "./style";
import FeelingsCircle from "components/FeelingsCircle";
import ProgressBar from "components/ProgressBar";
import VideoModal from "components/VideoModal";

const FeelingsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit } = useForm();
  const { state, user } = useSelector((state) => state);
  const { id, quiz_respondent_id } = user;

  const [word, setWord] = useState(null);
  const [words, setWords] = useState([]);
  const [selectedWords, setSelectedWords] = useState([]);
  const [show, setShow] = useState(false);

  const [progress, setProgress] = useState(0);

  const addWord = () => {
    if (words.length < 10 && !words.includes(word)) {
      setWords([...words, word]);
      setSelectedWords([...selectedWords, word.id]);
    }
  };

  const removeWord = (id) => {
    setWords(words.filter((word) => word.id !== id));
    setSelectedWords(selectedWords.filter((word) => word !== id));
  };

  const Inputs = () => {
    let rows = [];
    for (let i = 0; i < 10; i++) {
      rows.push(
        <Li
          className="relative flex flex-col md:flex-row items-center w-40 md:w-52 mb-4 text-primary"
          key={i}
        >
          <span className="w-full md:w-6 text-sm text-left md:text-right">
            {i + 1}.
          </span>
          <span className="flex items-center h-8 w-full border-2 border-primary mx-2">
            <span
              className={`${
                words[i]?.text.length > 16
                  ? "mx-2 text-tiny md:text-xs"
                  : "mx-2 text-xs md:text-sm"
              } font-semibold`}
            >
              {words[i]?.text}
            </span>
          </span>
          {words[i] && (
            <span onClick={() => removeWord(words[i]?.id)} className="remove">
              x
            </span>
          )}
        </Li>
      );
    }
    return rows;
  };

  const onSubmit = async () => {
    dispatch({ type: "LOADING", payload: true });
    try {
      const body = {
        respondent_id: id,
        quiz_respondent_id: quiz_respondent_id,
        answers: [
          {
            question_id: state.isWork ? 49 : 43,
            options_id: selectedWords,
          },
        ],
      };

      await sendAwnsers(body);

      history.push("/feelings-words");

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: "LOADING", payload: false });
    }
  };

  useEffect(() => {
    const initPage = async () => {
      dispatch({ type: "LOADING", payload: true });
      try {
        const { data } = await getExercise(4, state.isWork);

        setTimeout(() => {
          new FeelingsCircle({
            el: "#feelings",
            type: "infinite",
            source: data?.[0]?.options,
            count: data?.[0]?.options.length,
            rotateTop: state.isWork ? 14 : 2,
            onChange: (selected, scroll) => {
              setWord(selected);
              setProgress(
                Math.round(((scroll + 1) / data?.[0]?.options.length) * 100)
              );
            },
          });
        }, 100);

        dispatch({ type: "LOADING", payload: false });
      } catch (error) {
        console.log(error);
        dispatch({ type: "LOADING", payload: false });
      }
    };

    initPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header variant="primary-style-2" />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary pb-14 px-5 pt-24 lg:px-24 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="flex">
          <ArrowRight className="text-primary" />
          <p className="pl-2 pr-4">
            Com o fato descrito no exercício anterior em mente, percorra o
            CÍRCULO DOS SENTIMENTOS (círculo azul claro) percebendo quais os
            sentimentos que esse evento provoca em você.
            <br />
            Gire a roleta e preste atenção na lista de sentimentos que vai
            aparecendo. Reconheça até 10 sentimentos preponderantes, clicando
            sobre eles no momento em que estiverem selecionados no visor azul
            marinho.
          </p>
        </div>
        <div className="flex mt-5">
          <ArrowRight className="text-primary" />
          <p className="px-2">
            Se você ficou com dúvidas sobre o exercício, assista o vídeo
            novamente{" "}
            <PlayerButton
              className="inline-block w-9 cursor-pointer"
              onClick={() => setShow(true)}
            />{" "}
            ou pergunte a(o) seu/sua facilitador(a).
          </p>
        </div>
        <hr className="my-8 border-gray-style-3" />
        <p className="text-2xl text-primary-style-2 font-semibold mb-8 md:mx-14">
          CÍRCULO DOS SENTIMENTOS
        </p>
        <div className="flex flex-col md:flex-row md:items-stretch">
          <div className="flex flex-row relative">
            <ProgressBar
              className="w-14"
              color={"#7591c8"}
              progress={progress}
            />
            <StyledCircle id="feelings" />
            <CustomButton className="bg-primary" onClick={() => addWord()}>
              <ArrowRight margintop={"0"} className="text-white" />
            </CustomButton>
          </div>
          <div className="flex flex-grow justify-end md:mr-6 mt-8 md:mt-0">
            <Ul className="md:flex flex-col justify-center items-end w-full">
              <Inputs />
            </Ul>
          </div>
        </div>

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-end mt-10">
              <Button
                className="bg-primary-style-2 hover:bg-primary-style-2-light active:bg-primary-style-2-light text-xs font-semibold text-white"
                type="submit"
              >
                CONCLUIR EXERCÍCIO
              </Button>
            </div>
          </form>
        </div>
      </div>
      <VideoModal
        video="videos/video-4-circulo-dos-sentimentos.mp4"
        open={show}
        onClose={() => setShow(false)}
      />
    </div>
  );
};

export default FeelingsPage;
