import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { sendAwnsers } from "services/formService";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import TextArea from "components/TextArea";
import Button from "components/Button";

const Fact = () => {
  const { register, errors, handleSubmit } = useForm();
  const { id, quiz_respondent_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = async (responses) => {
    dispatch({ type: "LOADING", payload: true });
    try {
      const body = {
        respondent_id: id,
        quiz_respondent_id: quiz_respondent_id,
        question_id: 42,
        text: responses.fact,
      };

      await sendAwnsers(body);

      history.push("/videos/4");

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: "LOADING", payload: false });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header variant="primary" />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary pb-14 p-5 pt-24 lg:px-24 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="flex ">
          <ArrowRight className="text-primary" />
          <p className="px-2">
            Identifique um fato concreto relacionado à situação trabalhada em
            que um ou mais dos seus chacais estiveram presentes.
          </p>
        </div>
        <hr className="my-8 border-gray-style-3" />
        <div>
          <div className="flex">
            <ArrowRight className="text-primary" />
            <p className="px-2">
              Descreva-o abaixo: tente descrevê-lo da forma mais objetiva
              possível, evitando avaliações, inferências ou julgamentos de
              valor.
            </p>
          </div>
          <div className="bg-primary-style-2 my-10">
            <p className="text-white text-lg py-8 px-8 lg:px-20 font-bold">
              Um fato é tudo aquilo que pode ser capturado por uma câmera
              fotográfica ou um gravador.
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <TextArea name="fact" error={errors.fact} useRef={register} />
            </div>
            <div className="flex justify-end mt-3">
              <Button
                className="bg-primary hover:bg-primary-light active:bg-primary-light text-xs font-semibold text-white"
                type="submit"
              >
                AVANÇAR
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Fact;
