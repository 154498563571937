export const pages = [
  {
    exerciseId: "0",
    page: "/videos/1",
  },
  {
    exerciseId: "1",
    page: "/videos/3",
  },
  {
    exerciseId: "2",
    page: "/jackals-responses",
  },
  {
    exerciseId: "3",
    page: "/videos/4",
  },
  {
    exerciseId: "4",
    page: "/feelings-words",
  },
  {
    exerciseId: "5",
    page: "/needs-words",
  },
  {
    exerciseId: "6",
    page: "/videos/6",
  },
  {
    exerciseId: "7",
    page: "/questions/2",
  },
  {
    exerciseId: "8",
    page: "/your-maze",
  },
];
