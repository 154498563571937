import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPDF } from "services/formService";
import Header from "components/Header";

const DownloadPage = () => {
  const { id, quiz_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const downloadPDF = async (idPDf) => {
    dispatch({ type: "LOADING", payload: true });
    try {
      const { data } = await getPDF(idPDf, id, quiz_id);

      const file = new Blob([data], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      window.open(fileURL);

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "LOADING", payload: false });
      console.log(error);
    }
  };
  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary pb-14 p-5 pt-24 lg:px-24 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="flex">
          <p className="font-medium">
            Agora que chegamos ao final da experiência, você poderá ter acesso
            aos materiais abaixo.
            <br />
            Ao imprimi-los, você terá a oportunidade de continuar praticando o
            GIRAFÊS, consigo mesmo e com os outros.
            <br />
            Você também receberá acesso a esses materiais atráves do seu e-mail.
            <br />
            <br />
            Clique nos links abaixo para acessar as páginas em PDF:
          </p>
        </div>
        <div className="flex flex-col px-8 mt-8">
          <p
            className="text-primary-style-2 font-semibold cursor-pointer"
            onClick={() => downloadPDF(2)}
          >
            Labirinto Pessoal
          </p>
          <p
            className="text-primary-style-2 font-semibold cursor-pointer mt-3"
            onClick={() => downloadPDF(1)}
          >
            Certificado
          </p>
          <p
            className="text-primary-style-2 font-semibold cursor-pointer mt-3"
            onClick={() => downloadPDF(3)}
          >
            Gramática Girafês
          </p>
        </div>
        <div className="bg-primary-style-2 mt-14">
          <p className="text-white font-semibold text-center p-4 lg:py-10 lg:px-24">
            "A prática da Comunicação Autêntica começa por conhecermos
            primeiramente a nossa própria humanidade."
          </p>
        </div>
        <p className="text-primary text-center font-semibold text-2xl my-10">
          Obrigada por vivenciar o Labirinto da Empatia!
        </p>
      </div>
    </div>
  );
};

export default DownloadPage;
