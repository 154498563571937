import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { respondent, getGroup } from "services/formService";
import Header from "components/Header";
import Button from "components/Button";

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, watch } = useForm();
  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [sector, setSector] = useState([]);
  const [title, setTitle] = useState("");
  const { user, state } = useSelector((state) => state);
  const { quiz_id } = user;

  useEffect(() => {
    if (state.isWork && state.workshop) {
      dispatch({ type: "LOADING", payload: true });
      (async () => {
        try {
          const { data } = await getGroup(state.group_id);
          //setTitle(data.title);

          setUnits(
            data?.units?.map((unit) => {
              return {
                label: unit.name,
                value: unit.id,
              };
            })
          );
          setSectors(
            data?.sectors?.map((sector) => {
              return {
                label: sector.name,
                value: sector.id,
              };
            })
          );
        } catch (error) {
          console.log(error);
        } finally {
          dispatch({ type: "LOADING", payload: false });
        }
      })();
    }
  }, [dispatch, state.isWork, state.group_id, state.workshop]);

  const onSubmit = async (responses) => {
    dispatch({ type: "LOADING", payload: true });
    try {
      const body = {
        user_type: state.isWork ? 2 : 1,
        quiz_id: quiz_id,
        name: responses.name,
        email: responses.email,
      };

      if (state.isWork) {
        body.group_id = state.group_id;
        body.unit_name = responses.unit;
        body.organization_name =responses.organization;
        body.section_name = responses.section;
      }

      if (state.isWork && state.workshop) {
        body.group_id = state.group_id;
        body.unit_id = unit.value;
        body.sector_id = sector.value;
      }

      const { data } = await respondent(body);

      dispatch({ type: "SET_LINK", payload: data.link });

      dispatch({
        type: "SET_QUIZ_RESPONDENT_ID",
        payload: data?.quiz_respondent_id,
      });

      dispatch({ type: "SET_USER_ID", payload: data?.respondent_id });

      history.push(`/videos/1`);

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "LOADING", payload: false });
      console.log(error);
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary p-5 pt-24 lg:p-24 md:pt-32 lg:pt-36 xl:pt-28">
        <p className="text-primary font-semibold text-center md:px-14 mb-10">
          Ao concluir o cadastro você receberá um link no seu e-mail através do
          qual você poderá retomar o Labirinto da Empatia de onde parou, caso
          não consiga concluí-lo agora.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <p className="mb-1">Nome completo:</p>
          <input
            className={`border w-full sm:text-sm focus:outline-none p-2 ${
              errors.name
                ? "border-red-600"
                : "border-gray-300 focus:ring-gray-500 focus:border-gray-900"
            }`}
            type="text"
            name="name"
            ref={register({ required: true })}
          />
          <p className="mt-5 mb-1">Email:</p>
          <input
            className={`border w-full sm:text-sm focus:outline-none p-2 ${
              errors.email
                ? "border-red-600"
                : "border-gray-300 focus:ring-gray-500 focus:border-gray-900"
            }`}
            type="email"
            name="email"
            ref={register({ required: true })}
          />
          <p className="mt-5 mb-1">Confirme seu Email:</p>
          <input
            className={`border w-full sm:text-sm focus:outline-none p-2 ${
              errors["email-confirmation"]
                ? "border-red-600"
                : "border-gray-300 focus:ring-gray-500 focus:border-gray-900"
            }`}
            type="email"
            name="email-confirmation"
            ref={register({
              validate: (value) => value === watch("email"),
              required: true,
            })}
          />
          <div className="flex flex-col lg:flex-row items-center">
            <div className="flex flex-col lg:w-1/2 lg:pr-2 w-full">
              <p className="mt-5 mb-1">Cargo:</p>
              <input
                className="border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 focus:outline-none p-2"
                type="text"
                name="occupation"
                ref={register}
              />
            </div>
            <div className="flex flex-col lg:w-1/2 lg:pl-2 w-full">
              <p className="mt-5 mb-1">Área:</p>
              {state.isWork && state.workshop ? (
                <Select
                  value={sector}
                  onChange={(value) => setSector(value)}
                  options={sectors}
                  placeholder="Selecione..."
                  name="sector"
                  ref={register}
                />
              ) : (
                <input
                  className="border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 focus:outline-none p-2"
                  type="text"
                  name="section"
                  ref={register}
                />
              )}
            </div>
          </div>
          <p className="mt-5 mb-1">Organização:</p>
          {state.isWork && state.workshop ? (
            <Select
              value={unit}
              onChange={(value) => setUnit(value)}
              options={units}
              placeholder="Selecione..."
              name="unit"
              ref={register}
            />
          ) : (
            <input
              className="border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 focus:outline-none p-2 disabled:bg-white"
              type="text"
              name="organization"
              value={title}
              onChange={({ target }) => setTitle(target.value)}
              //disabled={state.isWork}
              ref={register}
            />
          )}
          {/*           <input
            className="border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 focus:outline-none p-2 disabled:bg-white"
            type="text"
            name="organization"
            value={title}
            onChange={({ target }) => setTitle(target.value)}
            //disabled={state.isWork}
            ref={register}
          /> */}
          <p className="mt-5 mb-1">Unidade:</p>
          {state.isWork && state.workshop ? (
            <input
              className="border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 focus:outline-none p-2 disabled:bg-white"
              type="text"
              name="unit"
              value={title}
              onChange={({ target }) => setTitle(target.value)}
              //disabled={state.isWork}
              ref={register}
            />
          ) : (
            /*             <Select
              value={unit}
              onChange={(value) => setUnit(value)}
              options={units}
              placeholder="Selecione..."
              name="unit"
              ref={register}
            /> */
            <input
              className="border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 focus:outline-none p-2"
              type="text"
              name="unit"
              ref={register}
            />
          )}
          <div className="flex justify-end mt-5">
            <Button
              className="bg-primary hover:bg-primary-light active:bg-primary-light text-xs font-semibold text-white"
              type="submit"
            >
              AVANÇAR
            </Button>
          </div>
        </form>

        <div className="bg-primary mt-14">
          <p className="text-white p-4 lg:p-6 font-medium text-center">
            Em conformidade com a Lei Geral de Proteção de Dados (LGPD) ou Lei
            nº 13.709, suas informações pessoais e dados de respostas não serão
            compartilhados, sendo acessados somente pelo seu/sua facilitador(a),
            para uso específico do Labirinto da Empatia.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
