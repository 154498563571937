import styled from "@emotion/styled/macro";

export const StyledCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22em;
  width: 100%;
  position: relative;

  ul {
    position: absolute;
    top: 5%;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    background: #7a95cb;
    width: 22em;
    height: 22em;
    border-radius: 50%;
    border-style: solid;
    border-width: 6px;
    border-color: #d2d2d2;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    background: #f5f5f5;
    width: 17em;
    height: 17em;
    border-radius: 50%;
    border-style: solid;
    border-width: 6px;
    border-color: #d2d2d2;
    z-index: 0;
  }

  @media (min-width: 768px) {
    height: 30em;

    position: absolute;
    right: 9%;
    top: 30%;

    &:before {
      width: 30em;
      height: 30em;
    }

    &:after {
      width: 22em;
      height: 22em;
    }
  }
`;

export const Span = styled.span`
  height: 16.5em;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.6em;
  position: absolute;
  width: 20px;
  top: 0;
  transform-origin: bottom;
  transform: rotate(${({ rotate }) => `${rotate}deg`});

  @media (min-width: 768px) {
    height: 18em;
    font-size: 0.75em;
  }
`;

export const LiCircle = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
`;
