import styled from "@emotion/styled/macro";
import { PDFDownloadLink } from "@react-pdf/renderer";

export const Responses = styled.div`
  box-shadow: 5px 10px 10px 2px rgb(0 0 0 /15%);
  background: linear-gradient(0deg, #bccae5 87.6%, #22388b 87.6%);
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0;

    .response {
      padding: 0.25rem 2rem;
    }

    &:not(.top) {
      background-color: #bccae5;
    }
  }
`;

export const PDFButton = styled(PDFDownloadLink)`
  padding: 0.5rem 2.2rem;
  outline: none !important;
  border: none;
  border-radius: 10rem;
  box-shadow: 1px 2px 3px 0.5px #95a5a6;

  &:active {
    box-shadow: 0px 0.5px 3px 0.5px #95a5a6;
    transform: translateY(2px);
  }
`;
