import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import Button from "components/Button";
import { Player } from "./style";

const VideoPage5 = () => {
  const history = useHistory();
  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    history.push("/circle-of-needs");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary pb-14 p-5 pt-24 lg:px-24 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="flex">
          <ArrowRight className="text-primary" />
          <p className="pl-2 md:pr-24 text-lg font-semibold">
            Antes de prosseguir, assista ao video abaixo para entender o
            funcionamento do próximo exercício:
          </p>
        </div>
        <div className="flex h-52 md:h-100 lg:px-10 mt-16">
          <Player
            width="100%"
            height="100%"
            controls
            url="video-5-circulo-das-necessidades.mp4"
          />
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-end mt-16 lg:px-10">
              <Button
                className="bg-primary hover:bg-primary-light active:bg-primary-light text-xs font-semibold text-white"
                type="submit"
              >
                AVANÇAR
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VideoPage5;
