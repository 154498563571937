import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import Button from "components/Button";
import { Collapse } from "react-collapse";
import { CustomCollapse, Player } from "./style";

const VideoPage1 = () => {
  const history = useHistory();
  const { handleSubmit } = useForm();

  const [show, setShow] = useState(false);

  const onSubmit = async () => {
    history.push("/videos/2");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary pb-14 px-5 pt-24 md:pt-32 lg:px-24 lg:pt-24">
        <div className="flex">
          <ArrowRight className="text-primary" />
          <p className="pl-2 md:pr-24 text-lg font-semibold">
            Bem-vindos à esta ferramenta de aprendizagem pela experiência!
            Assista ao vídeo abaixo:
          </p>
        </div>
        <div className="flex h-52 md:h-100 lg:px-10 mt-16">
          <Player
            width="100%"
            height="100%"
            controls
            url="video-1-boas-vindas.mp4"
          />
        </div>
        <div className="flex lg:px-10 mt-16">
          <CustomCollapse>
            <p
              className="top text-primary text-center font-semibold bg-gray-300 py-2 cursor-pointer"
              onClick={() => {
                setShow((show) => !show);
              }}
            >
              Clique aqui caso queira saber mais sobre os fundamentos do
              Labirinto da Empatia.
            </p>
            <Collapse isOpened={show}>
              <div className="px-10 text-sm pt-5 pb-8">
                <p className="font-medium">
                  O LABIRINTO DA EMPATIA® é uma ferramenta física elaborada pelo
                  norte-americano Marc Weiner para ensinar e desenvolver
                  habilidades de empatia e comunicação compassiva. Já foi
                  traduzido para outros idiomas e atualmente é utilizado em
                  diversos países.
                  <br />
                  <br />
                  Visando a implementação no Brasil de forma inovadora,
                  desenvolvemos a ferramenta para utilização em ambiente
                  digital, com recursos interativos e didáticos e acompanhamento
                  de um facilitador capacitado.
                  <br />
                  <br />O LABIRINTO DA EMPATIA está fundamentado em diversas
                  teorias e áreas de conhecimento. São elas:
                </p>
                <div className="px-6 mt-5">
                  <ul className="list-disc text-primary font-bold">
                    <li>Comunicação Não Violenta:</li>
                  </ul>
                  <p className="mt-1 font-medium">
                    Explora os dois Paradigmas de Comunicação que norteiam nosso
                    processo de linguagem, além dos quatro elementos que
                    norteiam essa teoria (Observação, Sentimentos, Necessidades
                    e Pedidos).
                  </p>

                  <ul className="list-disc text-primary font-bold mt-3">
                    <li>Psicologia Positiva:</li>
                  </ul>
                  <p className="mt-1 font-medium">
                    A vivência ao longo do processo possibilita a experimentação
                    de emoções positivas e habilidades para amplificá-las no dia
                    a dia.
                  </p>

                  <ul className="list-disc text-primary font-bold mt-3">
                    <li>Inteligência Emocional:</li>
                  </ul>
                  <p className="mt-1 font-medium">
                    Explora os três elementos da Tríade da Empatia (Empatia
                    Cognitiva, Empatia Afetiva e Interesse Empático).
                  </p>

                  <ul className="list-disc text-primary font-bold mt-3">
                    <li>Teoria U:</li>
                  </ul>
                  <p className="mt-1 font-medium">
                    A Escuta Empática e Escuta Generativa são desenvolvidas na
                    experiência, pois são nestes níveis de escuta que a
                    verdadeira empatia entra em cena. Ao explorar pensamentos,
                    sentimentos e necessidades é possível estabelecer conexão
                    emocional, consigo mesmo e com os outros.
                  </p>

                  <ul className="list-disc text-primary font-bold mt-3">
                    <li>Atenção Plena e Compaixão:</li>
                  </ul>
                  <p className="mt-1 font-medium">
                    Explora Estado de Presença, Gentileza e Senso de Humanidade
                    Compartilhada.
                  </p>
                </div>
              </div>
            </Collapse>
          </CustomCollapse>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-end mt-24 lg:px-10">
              <Button
                className="bg-primary hover:bg-primary-light active:bg-primary-light text-xs font-semibold text-white"
                type="submit"
              >
                AVANÇAR
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VideoPage1;
