import styled from "@emotion/styled/macro";
import { ReactComponent as PlayerBtn } from "static/images/player-button.svg";

export const StyledCircle = styled.div`
  width: 16.5rem;
  height: 33rem;
  border-left: 5px solid #d2d2d2;

  font-size: 0.8rem;

  .select-wrap {
    position: relative;
    height: 100%;
    text-align: center;
    overflow: hidden;
    color: #ddd;

    .select-options {
      position: absolute;
      top: 50%;
      left: 0;
      width: 180px;
      display: block;
      .select-option {
        display: flex !important;
        align-items: center;
        color: #fff;
        text-align: left;
        position: absolute;
        top: 0;
        left: -4.5em;
        width: 128px;
        height: 50px;

        -webkit-font-smoothing: subpixel-antialiased;
      }
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      background: #7a95cb;
      width: 195%;
      left: -95%;
      height: 100%;
      border-radius: 50%;
      border-style: solid;
      border-width: 5px;
      border-color: #d2d2d2;
      margin: 0em;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 31%;
      left: -35%;
      background: #f5f5f5;
      width: 12.5rem;
      height: 12.5rem;
      border-radius: 50%;
      border-style: solid;
      border-width: 5px;
      border-color: #d2d2d2;
      z-index: 0;
    }
  }

  .highlight {
    position: absolute;
    top: 50%;
    transform: translateX(8em) translate(0, -50%);
    width: 100%;
    text-align: left;
    background-color: #22388b;
    overflow: hidden;
    color: #22388b;

    font-weight: 600;
    border: 5px solid #22388b;
    border-radius: 15px;
    width: 62%;
    z-index: 1;

    height: 45px;
    line-height: 45px;
  }

  .highlight-list {
    position: absolute;
    width: 100%;
  }
  .highlight-item {
    transform: translateY(-4px);
    background-color: #fff;
    padding-left: 1em;
  }
  > div {
    flex: 1;
  }

  @media (min-width: 768px) {
    width: 19rem;
    height: 38rem;
    font-size: 0.9rem;
    .select-wrap {
      .select-options {
        .select-option {
          width: 145px;
          left: -3.5rem !important;
        }
      }

      &:after {
        left: -39%;
        width: 15rem;
        height: 15rem;
      }
    }
  }
`;

export const Ul = styled.ul`
  column-count: 2;
  @media (min-width: 768px) {
    column-count: 1 !important;
  }
`;

export const Li = styled.li`
  .remove {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: #e5e6e6;
    color: #adadad;
    right: -1.8rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    top: 47%;
  }

  @media (min-width: 768px) {
   .remove {
     top: 13%;
   }
  }
`;

export const CustomButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 80%;
  transform: translate(7%, -50%);
  top: 50%;
  display: flex;
  width: 75px;
  height: 45px;
  padding: 0 10px;
  border-radius: 8px;
  outline: none !important;

  @media (min-width: 768px) {
    left: 94%;
  }
`;

export const PlayerButton = styled(PlayerBtn)`
  .st0 {
    fill: #6686c4;
  }
`;

