import styled from "@emotion/styled/macro";

export const Container = styled.div`
  background-size: 100% 100%;

  .container-top {
    border-top-left-radius: 3.5rem;
    border-top-right-radius: 3.5rem;
    border-left: 0.7rem solid #afbfe0;
    border-top: 0.7rem solid #afbfe0;
    border-right: 0.7rem solid #afbfe0;
    box-shadow: -12px 12px 0px 4px #d1d1d1;
  }

  .container-bottom {
    border-bottom-left-radius: 3.5rem;
    border-bottom-right-radius: 3.5rem;
    border-left: 0.7rem solid #f8f8f8;
    border-bottom: 0.7rem solid #f8f8f8;
    border-right: 0.7rem solid #f8f8f8;
    box-shadow: -12px 9px 0px 4px #d1d1d1;
  }
`;

export const Radios = styled.section`
  grid-template-columns: repeat(3fr);

  label {
    input,
    span {
      vertical-align: middle;
    }
    [type="radio"],  [type="checkbox"]{
      appearance: none;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      user-select: none;
      flex-shrink: 0;
      height: 1rem;
      width: 1rem;
      border-width: 1px;
      border-color: #000000;
      outline: none;

      &:checked {
        border-color: transparent;
        background-color: #5170b3;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
      }
    }
  }
`;
