import React from "react";
import { useSelector } from "react-redux";
import Routes from "./utils/Routes";
import { hot } from "react-hot-loader/root";
import Loader from "components/Loader";

function App() {
  const state = useSelector((state) => state.state);

  return (
    <div className="App">
      <Routes isLinkValid={state.isLinkValid} />
      {state.loading && <Loader />}
    </div>
  );
}

export default hot(App);
