/* eslint-disable no-loop-func */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { finishQuiz } from "services/formService";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import Button from "components/Button";
import CertificateSvg from "components/CertificateSvg/CertificateSvg";
import CertificateSvgMobile from "components/CertificateSvg/CertificateSvgMobile";

const CertificatePage = () => {
  const { handleSubmit } = useForm();
  const { id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const [quizData, setQuizData] = useState(null);

  const onSubmit = async () => {
    history.push("/grammar");
  };

  const getData = async (body) => {
    dispatch({ type: "LOADING", payload: true });
    try {
      const { data } = await finishQuiz(body);

      setQuizData(data);

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "LOADING", payload: false });
      console.log(error);
    }
  };

  useEffect(() => {
    getData({ respondent_id: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header variant="quinary" />
      {quizData && (
        <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary px-5 lg:px-24  pb-14 pt-24 md:pt-32 lg:pt-36 xl:pt-28">
          <div className="flex md:px-10">
            <ArrowRight className="text-quinary" />
            <p className="pl-2 pr-4 mb-10">
              Esse é o seu CERTIFICADO DE HABILITAÇÃO NO IDIOMA GIRAFÊS. Você
              pode imprimí-lo e até emoldurá-lo para lembrar de sua jornada e
              sua capacidade de estabelecer uma comunicação empática e
              compassiva em todos os seus relacionamentos.
            </p>
          </div>
          <div>
            <CertificateSvg className="hidden md:block" data={quizData} />
            <CertificateSvgMobile className="md:hidden" data={quizData} />
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex justify-end mt-10">
                  <Button
                    className="bg-primary hover:bg-primary-light active:bg-primary-light text-xs font-semibold text-white"
                    type="submit"
                  >
                    AVANÇAR
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CertificatePage;
