import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { sendAwnsers } from "services/formService";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import TextArea from "components/TextArea";
import Button from "components/Button";
import { Radios } from "./style";

const Page1 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();
  const { state, user } = useSelector((state) => state);
  const { id, quiz_respondent_id } = user;

  const onSubmit = async (responses) => {
    dispatch({ type: "LOADING", payload: true });
    try {
      const body = {
        respondent_id: id,
        quiz_respondent_id: quiz_respondent_id,
        answers: [
          {
            question_id: state.isWork ? 2 : 1,
            text: responses.situation,
          },
          {
            question_id: state.isWork ? 57 : 23,
            options_id: [responses.option],
          },
        ],
      };

      await sendAwnsers(body);

      history.push(`/videos/3`);

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: "LOADING", payload: false });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary pb-14 p-5 pt-24 lg:px-24 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="bg-primary mb-14">
          <p className="text-white p-6 lg:p-10 font-bold">
            Para iniciarmos, sente-se numa posição confortável.
            <br />
            Respire profundamente 3 vezes, mantendo a sua atenção no estado
            presente.
          </p>
        </div>
        {state.isWork ? (
          <>
            <div className="flex">
              <ArrowRight className="text-primary" />
              <span className="text-primary font-semibold ml-3">a)</span>
              <p className="pl-2 pr-5">
                Reconheça uma situação que tenha gerado, ou esteja gerando,
                incômodo ou conflito no seu ambiente de trabalho que você queira
                tratar nesse momento.
              </p>
            </div>
            <div className="flex mt-8">
              <ArrowRight className="text-primary" />
              <span className="text-primary font-semibold ml-3">b)</span>
              <p className="pl-2 pr-5">
                Ou...Reconheça uma situação que o grupo definir trabalhar em
                conjunto.
              </p>
            </div>
          </>
        ) : (
          <div className="flex">
            <ArrowRight className="text-primary" />
            <p className="px-2">
              Reconheça uma situação que tenha gerado, ou esteja gerando
              incômodo ou conflito na sua vida com a qual você quer trabalhar
              nesse momento.
            </p>
          </div>
        )}

        <hr className="my-8 border-gray-style-3" />
        <div>
          <div className="flex">
            <ArrowRight className="text-primary" />
            <p className="px-2">
              Marque abaixo com qual dessas áreas a situação está relacionada:
            </p>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            {state.isWork ? (
              <Radios
                className="grid grid-flow-col grid-rows-6 gap-2  md:grid-rows-4 lg:gap-4 text-xs mt-5 px-10"
                role="group"
              >
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="426"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">
                    Relacionamentos interpessoais
                  </span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="427"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">Comunicação</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="428"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">Liderança</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="429"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">
                    Foco no cliente (externo e interno)
                  </span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="430"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">
                    Desenvolvimento e crescimento
                  </span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="431"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">Performance e produtividade</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="432"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">Engajamento e motivação</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="433"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">Processo de trabalho</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="457"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">Ambiente de Trabalho</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="434"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">Bem-estar e saúde mental</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="435"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">Alinhamento estratégico</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="436"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">OUTROS</span>
                </label>
              </Radios>
            ) : (
              <Radios
                className="grid grid-flow-col grid-rows-4 gap-2  md:grid-rows-3 lg:gap-4 text-xs mt-5 px-10"
                role="group"
              >
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="1"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span>RELACIONAMENTO</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="2"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span>TRANSIÇÃO DE CARREIRA</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="3"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span>DESAFIO PESSOAL</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="4"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span>DESAFIO PROFISSIONAL</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="5"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span>AUTODESENVOLVIMENTO</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="6"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span>BEM-ESTAR E SAÚDE MENTAL</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-1"
                    type="radio"
                    value="7"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase">OUTROS</span>
                </label>
              </Radios>
            )}

            {errors.option && (
              <div className="mt-8 px-10 text-xs text-red-600">
                Escolha uma das alternativas antes de prosseguir!
              </div>
            )}

            <hr className="my-8 border-gray-style-3" />

            <div>
              <div className="flex">
                <ArrowRight className="text-primary" />
                <p className="px-2">
                  Descreva no campo abaixo a situação escolhida:
                </p>
              </div>
              <TextArea
                className="mt-5"
                name="situation"
                error={errors.situation}
                errorText={errors.situation && "Campo obrigatório!"}
                useRef={register({ required: true })}
              />
            </div>
            <div className="flex justify-end mt-3">
              <Button
                className="bg-primary hover:bg-primary-light active:bg-primary-light text-xs font-semibold text-white"
                type="submit"
              >
                AVANÇAR
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Page1;
