import styled from "@emotion/styled/macro";
import { ReactComponent as Jackal } from "static/images/jackal.svg";


export const Circle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width ?? "2rem"};
  height: ${({ height }) => height ?? "2rem"};
  border-radius: 50%;
  background-color: #ffffff;
  border: solid 4px #4e4845;
  position: absolute;
  top: -1.25rem;
  left: 1.5rem;
`;

export const Panel = styled.div`
  .panel-top {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
  }

  .panel-bottom div:last-child {
    border-top: none;

    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;

    textarea {
      border-bottom-left-radius: 2rem;
      border-bottom-right-radius: 2rem;
    }
  }
`;

export const JackalIcon = styled(Jackal)`
  position: absolute;
  height: 10rem;
  top: -4.8rem;
  right: -1.5rem;
`;

export const CustomDiv = styled.div`
  min-height: 66rem;

  @media (min-width: 1024px) {
    min-height: 41.5rem !important;
  }
`;