import React from "react";
import styled from "@emotion/styled/macro";

const Styled = styled.svg`
  .rock-3-st0 {
    fill: #d5b79d;
  }
  .rock-3-st1 {
    opacity: 0.2;
  }
  .rock-3-st2 {
    fill: #ffffff;
  }
  .rock-3-st3 {
    fill: #b59e7f;
  }
`;

const Rock3 = ({ className, text, textClass }) => (
  <Styled
    className={className}
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 427.3 166.3"
    style={{ enableBackground: "new 0 0 427.3 166.3" }}
    xmlSpace="preserve"
  >
    <style type="text/css"></style>
    <g>
      <g>
        <g>
          <path
            className="rock-3-st0"
            d="M165.7,162.4C56.9,174.2-36.5,95.2,16.6,48.9C75.4,0.4,226.4-12.5,317.2,16c75.9,22.2,150.2,61.6,82.1,107.6
				c-49.4,32.7-162.8,37.2-236,41.2"
          />
        </g>
        <g className="rock-3-st1">
          <path
            className="rock-3-st2"
            d="M60.9,119.9c-26.8-18.6-42.3-42.7-15.3-64.3c26-22.3,89.2-27.8,135.7-26.8c29.3,0.9,123.5,3.5,73.4,28.5
				c-27,12.6-77,10.8-109.8,22.4c-19,6.2-70.2,43.9-83,41"
          />
        </g>
        <text
          className={textClass}
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {text}
        </text>
        <g>
          <path
            className="rock-3-st3"
            d="M113.9,159.6c25.2,11.5,89.4,2.2,120.8,0.1c39.4-4.1,84.2-5.8,120.9-17.7c60.8-19.1,91-60.8,40.4-91.4
				c7.1,22.9,16.1,39.5-17.9,59.3c-17.6,10.2-47.7,20.7-69.5,28c-51.5,16.6-138.5,23.4-198.5,22.1"
          />
        </g>
        <g className="rock-3-st1">
          <path
            className="rock-3-st2"
            d="M88.6,76.6c5.7,16.5-41.2,36.4-41.7,9.5c-0.5-6.3,0.5-15.3,12.3-19.2c13.5-4.8,35.7,1.4,30.4,11"
          />
        </g>
        <g className="rock-3-st1">
          <path
            className="rock-3-st2"
            d="M117.6,59.4c-10.6,8.8-35.2-5.5-14.8-10.4c19.5-5.4,25.7,6.2,14,11.8"
          />
        </g>
      </g>
    </g>
  </Styled>
);

export default Rock3;
