import React from "react";
import styled from "@emotion/styled/macro";

const Styled = styled.svg`
  .rock-5-st0 {
    fill: #986d61;
  }
  .rock-5-st1 {
    opacity: 0.1;
  }
  .rock-5-st2 {
    fill: #ffffff;
  }
  .rock-5-st3 {
    fill: #8b6459;
  }
  .rock-5-st4 {
    opacity: 0.2;
  }
`;

const Rock5 = ({ className, text, textClass }) => (
  <Styled
    className={className}
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 420.2 173.1"
    style={{ enableBackground: "enable-background:new 0 0 420 171.1" }}
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <path
            className="rock-5-st0"
            d="M48.4,148.4C-76.5,84.1,72.4-24.9,222.2,6.9c44,9.4,72.9,30.9,107.8,46.3c35,13.8,76,24.7,87,48.8
				c16.2,44.8-75,64.8-138.5,66.6C93.6,173.8,48.4,148.4,48.4,148.4z"
          />
        </g>
        <g className="rock-5-st1">
          <path
            className="rock-5-st2"
            d="M25.5,101.9c1-28.4,2-55.1,55.6-68.3c44.2-11.9,125.2-5.9,155.6,18.5c45.6,36.6-42.2,43.7-86.2,52.3
				c-31.4,4-120,34.5-121.9-0.7"
          />
        </g>
        <text
          className={textClass}
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {text}
        </text>
        <g>
          <path
            className="rock-5-st3"
            d="M70.3,155.2c65.5,2.2,121.8,7.4,187.6,1.2c43.9-5.2,94.2-11.9,113.8-36.3s-14.2-38.9-32.2-61.2
				c197.9,72.6-41.2,122.7-206,108.8C98,164.6,70.3,155.2,70.3,155.2z"
          />
        </g>
        <g className="rock-5-st4">
          <path
            className="rock-5-st2"
            d="M34.2,78.3c2.8-12.1,37.7-39,53.4-19.8c16.5,20.2-54.6,34-53.4,16.5"
          />
        </g>
        <g className="rock-5-st4">
          <path
            className="rock-5-st2"
            d="M91.4,46.2c-21.6-6.6,19.4-19.4,28.4-8.2c10.6,10.4-22.4,15.1-33.1,8.1"
          />
        </g>
      </g>
    </g>
  </Styled>
);

export default Rock5;
