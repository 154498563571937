import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Register from "pages/Register";
import Page1 from "pages/Questions/Page1";
import Jackals from "pages/Jackals";
import JackalsResponses from "pages/JackalsResponses";
import Fact from "pages/Fact";
import FeelingsPage from "pages/FeelingsPage";
import NeedsPage from "pages/NeedsPage";
import FeelingsExplanation from "pages/FeelingsExplanation";
import NeedsExplanation from "pages/NeedsExplanation";
import MainRedirector from "pages/MainRedirector";
import Convertometer from "pages/Convertometer";
import GenerateLink from "pages/GenerateLink";
import CertificatePage from 'pages/CertificatePage';
import GrammarGiraffes from "pages/GrammarGiraffes";
import DownloadPage from "pages/DownloadPage";
import VideoPage2 from "pages/VideoPages/VideoPage2";
import VideoPage3 from "pages/VideoPages/VideoPage3";
import VideoPage4 from "pages/VideoPages/VideoPage4";
import VideoPage5 from "pages/VideoPages/VideoPage5";
import VideoPage6 from "pages/VideoPages/VideoPage6";
import VideoPage7 from "pages/VideoPages/VideoPage7";
import VideoPage1 from "pages/VideoPages/VideoPage1";
import Page2 from "pages/Questions/Page2";
import YourMaze from "pages/YourMaze";
import StartPage from "pages/StartPage";

const PrivateRoute = ({ component: Component, authorized, ...props }) =>
authorized ? <Component {...props} /> : <Redirect to="/" noThrow />;

const Routes = ({ isLinkValid }) => {
  return (
    <Router basename="/react/">
      <Switch>
        
        <Route exact path="/" component={GenerateLink} />
        <Route path="/link/:link" component={MainRedirector} />
        <Route path="/despertar" component={StartPage} />
        <PrivateRoute authorized={isLinkValid} path="/start" component={StartPage} />
        <PrivateRoute authorized={isLinkValid} path="/data" component={Register} />
        <PrivateRoute authorized={isLinkValid} path="/videos/1" component={VideoPage1} />
        <PrivateRoute authorized={isLinkValid} path="/videos/2" component={VideoPage2} />
        <PrivateRoute authorized={isLinkValid} path="/questions/1" component={Page1} />
        <PrivateRoute authorized={isLinkValid} path="/videos/3" component={VideoPage3} />
        <PrivateRoute authorized={isLinkValid} path="/jackals" component={Jackals} />
        <PrivateRoute authorized={isLinkValid} path="/jackals-responses" component={JackalsResponses} />
        <PrivateRoute authorized={isLinkValid} path="/fact" component={Fact} />
        <PrivateRoute authorized={isLinkValid} path="/videos/4" component={VideoPage4} />
        <PrivateRoute authorized={isLinkValid} path="/circle-of-feelings" component={FeelingsPage} />
        <PrivateRoute authorized={isLinkValid} path="/feelings-words" component={FeelingsExplanation} />
        <PrivateRoute authorized={isLinkValid} path="/videos/5" component={VideoPage5} />
        <PrivateRoute authorized={isLinkValid} path="/circle-of-needs" component={NeedsPage} />
        <PrivateRoute authorized={isLinkValid} path="/needs-words" component={NeedsExplanation} />
        <PrivateRoute authorized={isLinkValid} path="/videos/6" component={VideoPage6} />
        <PrivateRoute authorized={isLinkValid} path="/videos/7" component={VideoPage7} />
        <PrivateRoute authorized={isLinkValid} path="/convertometer" component={Convertometer} />
        <Route authorized={isLinkValid} path="/questions/2" component={Page2} />
        <PrivateRoute authorized={isLinkValid} path="/your-maze" component={YourMaze} />
        <PrivateRoute authorized={isLinkValid} path="/certificate" component={CertificatePage} />
        <PrivateRoute authorized={isLinkValid} path="/grammar" component={GrammarGiraffes} />
        <PrivateRoute authorized={isLinkValid} path="/download-page" component={DownloadPage} />
        

      </Switch>
    </Router>
  );
};

export default Routes;
