/* eslint-disable no-loop-func */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getExercise, sendAwnsers } from "services/formService";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import Button from "components/Button";
import { ReactComponent as Title } from "static/images/convertometer-title.svg";
import { ReactComponent as ButtonSvg } from "static/images/convertometer-button.svg";
import { CustomDiv, CustomTextArea, Jackal, Giraffe } from "./style";

const Convertometer = () => {
  const { register, handleSubmit } = useForm();
  const { id, quiz_respondent_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const [questions, setQuestion] = useState([]);

  const onSubmit = async (data) => {
    dispatch({ type: "LOADING", payload: true });
    const answers = Object.entries(data).map(([key, value]) => {
      return {
        question_id: key,
        text: value,
      };
    });

    try {
      const body = {
        quiz_respondent_id: quiz_respondent_id,
        respondent_id: id,
        answers: answers,
      };

      await sendAwnsers(body);

      history.push("/questions/2");

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: "LOADING", payload: false });
    }
  };

  const getQuestions = async (exercise) => {
    dispatch({ type: "LOADING", payload: true });
    try {
      const { data } = await getExercise(exercise);

      setQuestion(data);

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "LOADING", payload: false });
      console.log(error);
    }
  };

  useEffect(() => {
    getQuestions(7);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header variant="quinary" />
      {questions.length > 1 && (
        <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary pb-14 p-5 pt-24 lg:px-24 md:pt-32 lg:pt-36 xl:pt-28">
          <div className="flex md:px-16">
            <ArrowRight className="text-primary" />
            <p className="pl-2 pr-6 mb-20">
              Agora vamos aplicar o nosso “conversômetro” CHACAL - GIRAFÊS na
              situação que definimos. A partir do fato descrito e dos
              sentimentos e necessidades relacionados a ele, preencha o texto
              abaixo:
            </p>
          </div>
          <div className="md:px-16 relative">
            <Jackal className="h-96 hidden lg:block absolute" />
            <CustomDiv className="bg-gray-300 rounded-3xl md:mx-10">
              <div className="flex flex-col justify-center items-center">
                <Title className="w-80 mb-5 mt-10" />
                <CustomTextArea className="border-primary rounded-2xl mx-5 py-5">
                  {questions.map((question) => {
                    return (
                      <div className="w-full" key={question.id}>
                        <label
                          className="block py-1 px-4 text-sm"
                          htmlFor={question.id}
                        >
                          {question.text}
                        </label>
                        <textarea
                          className="w-full bg-transparent px-6 h-16 outline-none resize-none"
                          id={question.id}
                          name={question.id}
                          ref={register}
                          maxLength="125"
                        ></textarea>
                      </div>
                    );
                  })}
                </CustomTextArea>
                <div>
                  <ButtonSvg className="w-56 my-5" />
                </div>
              </div>
            </CustomDiv>
            <Giraffe className="h-52 hidden lg:block absolute" />
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex justify-end mt-10 md:px-8">
                  <Button
                    className="bg-primary hover:bg-primary-light active:bg-primary-light text-xs font-semibold text-white"
                    type="submit"
                  >
                    AVANÇAR
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Convertometer;
