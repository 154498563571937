import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { sendAwnsers } from "services/formService";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import TextArea from "components/TextArea";
import Button from "components/Button";
import { Radios } from "./style";

const Page2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm();
  const [other, setOther] = useState(false);
  const { user } = useSelector((state) => state);
  const { id, quiz_respondent_id } = user;

  const onSubmit = async (responses) => {
    dispatch({ type: "LOADING", payload: true });

    try {
      const body = {
        respondent_id: id,
        quiz_respondent_id: quiz_respondent_id,
        answers: [
          {
            question_id: 61,
            text: responses.situation,
          },
          {
            question_id: 60,
            options_id: responses.option,
            text: responses.text,
          },
        ],
      };

      await sendAwnsers(body);

      history.push(`/your-maze`);

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: "LOADING", payload: false });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header variant="quinary" />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary pb-14 p-5 pt-24 lg:px-24 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="flex">
          <ArrowRight className="text-primary" />
          <p className="px-5 md:pl-8 md:pr-16">
            Como você está se sentindo agora, após ter identificado os
            sentimentos e necessidades por trás dos chacais relacionados a sua
            situação escolhida?
          </p>
        </div>
        <div className="flex mt-8">
          <ArrowRight className="text-primary" />
          <p className="px-5 md:pl-8 md:pr-16">
            Reconheça seus sentimentos na lista abaixo (é possível selecionar
            quantos você quiser).
          </p>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="px-5 md:px-16">
              <Radios
                className="grid grid-flow-row grid-cols-2 md:grid-cols-3 gap-2 lg:gap-4 text-xs mt-5 p-5 md:p-12 bg-quaternary w-full"
                role="group"
              >
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="437"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">SURPRESO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="444"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">ENTUSIASMADO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="451"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">RELAXADO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="438"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">CONECTADO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="445"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">FELIZ</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="465"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">AFLITO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="458"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">REFLEXIVO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="462"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">INTRIGADO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="452"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">CONFORTÁVEL</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="440"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">CONFIANTE</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="446"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">RENOVADO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="466"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">CONFUSO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="442"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">ORGULHOSO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="447"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">ESPERANÇOSO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="453"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">SATISFEITO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="459"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">AGITADO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="463"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">INCOMODADO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="454"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">SERENO(A)</span>
                </label>

                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="461"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">INDECISO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="448"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">GRATO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="436"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">CORAJOSO(A)</span>
                </label>

                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="439"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">ESTIMULADO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="449"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">CALMO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="456"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">FORTALECIDO(A)</span>
                </label>

                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="460"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">DESAPONTADO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="464"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">ANSIOSO(A)</span>
                </label>
                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="441"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">SEGURO(A)</span>
                </label>

                <label className="flex">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="443"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">EMPODERADO(A)</span>
                </label>
                <label className="flex col-span-2">
                  <input
                    className="mr-2 bg-white"
                    type="checkbox"
                    value="450"
                    name="option"
                    error={errors.option}
                    ref={register({ required: true })}
                  />
                  <span className="uppercase font-medium">ALIVIADO(A)</span>
                </label>
                <div className="flex col-span-3 items-center">
                  <label className="flex">
                    <input
                      onClick={() => setOther(!other)}
                      className="mr-2 bg-white"
                      type="checkbox"
                      value="467"
                      name="option"
                      error={errors.option}
                      ref={register({ required: true })}
                    />
                    <span className="uppercase font-medium">OUTRO</span>
                  </label>
                  <label
                    className={`flex items-center ml-5 w-full pr-14${
                      other ? "" : " opacity-30"
                    }`}
                    htmlFor="text"
                  >
                    <span className="text-sm mr-2">Descreva:</span>
                    <input
                      className="border w-full focus:outline-none p-1"
                      type="text"
                      name="text"
                      id="text"
                      ref={register({ required: other })}
                    />
                  </label>
                </div>
              </Radios>
            </div>

            {errors.option && (
              <div className="mt-8 px-16 text-xs text-red-600">
                Escolha uma das alternativas antes de prosseguir!
              </div>
            )}

            <hr className="my-8 border-gray-style-3" />

            <div>
              <div className="flex">
                <ArrowRight className="text-primary" />
                <p className="px-5 md:pl-8 md:pr-16">
                  Escreva de forma breve como poderia ser a comunicação
                  empregada na próxima vez que uma situação similar surgir,
                  cuidando para que suas necessidades fiquem claras.
                </p>
              </div>
              <div className="px-5 md:px-16">
                <TextArea
                  className="mt-5 border-quinary border-2 focus:border-quinary-light"
                  name="situation"
                  error={errors.situation}
                  errorText={errors.situation && "Campo obrigatório!"}
                  useRef={register({ required: true })}
                />
              </div>
            </div>
            <div className="flex justify-end mt-8 px-2 md:px-14">
              <Button
                className="bg-primary hover:bg-primary-light active:bg-primary-light text-xs font-semibold text-white"
                type="submit"
              >
                AVANÇAR
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Page2;
