import React from "react";
import styled from "@emotion/styled/macro";

const ProgressBar = ({ color, progress, className }) => {
  const ContainerStyled = styled.div`
    .progress-bar {
      height: 96.8%;
      width: 25px;
      background-color: transparent;
      border-radius: 50px;
      border: 4px solid #d2d2d2;
      transform: rotateZ(180deg);

      .progress {
        height: ${({ progress }) => `${progress}%`};
        width: 100%;
        background-color: ${({ bgcolor }) => bgcolor};
        transition: height 1s ease-in-out;
        border-radius: ${({ progress }) =>
          progress > 99 ? "inherit" : "8px 8px 0 0"};
      }
    }
  `;

  return (
    <ContainerStyled className={className} bgcolor={color} progress={progress}>
      <div className="progress-bar">
        <div className="progress"></div>
      </div>
      <span className="text-xl">{`${progress}%`}</span>
    </ContainerStyled>
  );
};

export default ProgressBar;
