import styled from "@emotion/styled/macro";

export const StyledCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22em;
  width: 100%;
  position: relative;

  ul {
    position: absolute;
    top: 5%;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    background: #f26261;
    width: 22em;
    height: 22em;
    border-radius: 50%;
    border-style: solid;
    border-width: 6px;
    border-color: #d2d2d2;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    background: #f5f5f5;
    width: 17em;
    height: 17em;
    border-radius: 50%;
    border-style: solid;
    border-width: 6px;
    border-color: #d2d2d2;
    z-index: 0;
  }

  @media (min-width: 768px) {
    height: 30em;

    position: absolute;

    &:before {
      width: 30em;
      height: 30em;
    }

    &:after {
      width: 22em;
      height: 22em;
    }
  }
`;

export const Span = styled.span`
  height: 16.5em;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.6em;
  position: absolute;
  width: 20px;
  top: 0;
  transform-origin: bottom;
  transform: rotate(${({ rotate }) => `${rotate}deg`});

  @media (min-width: 768px) {
    height: 18em;
    font-size: 0.75em;
  }
`;

export const LiCircle = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ active }) => (active ? 1 : "70%")};
`;

export const CustomRock = styled.p`
  display: flex;
  align-items: center;
  position: relative;

  .remove {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e5e6e6;
    color: #adadad;
    border-radius: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    span {
      margin-top: -2px;
    }
  }
`;
