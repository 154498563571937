/* eslint-disable no-loop-func */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getResult, sendAwnsers } from "services/formService";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import Button from "components/Button";
import { StyledCircle, Span, LiCircle, CustomRock } from "./style";
import Rock1 from "components/Svgs/Rock1";
import Rock2 from "components/Svgs/Rock2";
import Rock3 from "components/Svgs/Rock3";
import Rock4 from "components/Svgs/Rock4";
import Rock5 from "components/Svgs/Rock5";

const NeedsExplanation = () => {
  const { handleSubmit } = useForm();
  const { id, quiz_respondent_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const [words, setWords] = useState([]);
  const [selectedWords, setSelectedWords] = useState([]);

  const getQuestions = async (exercise, id) => {
    dispatch({ type: "LOADING", payload: true });
    try {
      const { data } = await getResult(exercise, id);

      setSelectedWords(data.answer);

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "LOADING", payload: false });
      console.log(error);
    }
  };

  useEffect(() => {
    getQuestions(5, id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addWord = (word) => {
    if (words.length < 5 && !words.includes(word)) {
      setWords([...words, word]);
    } else {
      removeWord(word.text);
    }
  };

  const removeWord = (text) => {
    setWords(words.filter((word) => word.text !== text));
  };

  const Circle = () => {
    let rows = [];
    let rotate = 0;

    const totalLength =
      selectedWords.map((word) => word.text).join(" - ").length + 3;

    for (let index = 0; index < selectedWords.length; index++) {
      const split = (selectedWords[index].text + " - ").split("");
      rows.push(
        <LiCircle
          className="cursor-pointer"
          key={index}
          active={words.includes(selectedWords[index])}
          onClick={() => addWord(selectedWords[index])}
        >
          {split.map((letter, i) => {
            return (
              <Span rotate={(rotate + i) * (360 / totalLength)} key={i}>
                {letter}
              </Span>
            );
          })}
        </LiCircle>
      );
      rotate = rotate + split.length;
    }

    return rows;
  };

  const onSubmit = async () => {
    dispatch({ type: "LOADING", payload: true });
    try {
      const awnsers = words.map((word) => word.option_id);

      const body = {
        respondent_id: id,
        quiz_respondent_id: quiz_respondent_id,
        answers: [
          {
            question_id: 56,
            options_id: awnsers,
          },
        ],
      };

      await sendAwnsers(body);

      history.push("/videos/6");

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: "LOADING", payload: false });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header variant="tertiary" />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary px-5 lg:px-24  pb-14 pt-24 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="flex">
          <ArrowRight className="text-tertiary" />
          <p className="pl-2 pr-4 mb-20">
            Do seu círculo de necessidades, destaque as 5 mais importantes,
            clicando sobre as palavras.
            <br />
            As escolhidas ficarão em destaque nas pedras angulares ao lado.
          </p>
        </div>
        <hr className="my-8 border-gray-style-3" />
        <p className="text-2xl text-center md:text-left text-tertiary font-semibold mb-8 md:mx-14">
          CÍRCULO DAS NECESSIDADES
        </p>
        <div className="flex justify-between flex-col lg:flex-row">
          <div className="relative flex flex-col lg:w-3/6 mb-10 md:h-96 md:mb-40">
            <StyledCircle>
              <ul>
                <Circle />
              </ul>
            </StyledCircle>
          </div>
          <div className="flex flex-col lg:w-3/6">
            <div
              className="grid grid-cols-2 lg:grid-cols-1 gap-1 auto-cols-fr"
              style={{ marginTop: "-2rem" }}
            >
              <CustomRock className="justify-center lg:justify-start">
                <Rock1
                  className="w-60"
                  textClass={`${
                    words[0]?.text.length > 20 ? "text-lg" : "text-2xl"
                  } font-semibold fill-current text-white`}
                  text={words[0]?.text}
                />

                <span
                  onClick={() => removeWord(words[0]?.text)}
                  className={`remove ${
                    words[0]
                      ? "opacity-100 cursor-pointer"
                      : "opacity-0  cursor-default"
                  }`}
                >
                  <span>x</span>
                </span>
              </CustomRock>
              <CustomRock className="justify-center lg:justify-end mt-5">
                <Rock2
                  className="w-60"
                  textClass={`${
                    words[1]?.text.length > 20 ? "text-lg" : "text-2xl"
                  } font-semibold fill-current text-white`}
                  text={words[1]?.text}
                />
                <span
                  onClick={() => removeWord(words[1]?.text)}
                  className={`remove ${
                    words[1]
                      ? "opacity-100 cursor-pointer"
                      : "opacity-0  cursor-default"
                  }`}
                >
                  <span>x</span>
                </span>
              </CustomRock>
              <CustomRock className="justify-center mt-5">
                <Rock3
                  className="w-60"
                  textClass={`${
                    words[2]?.text.length > 20 ? "text-lg" : "text-2xl"
                  } font-semibold fill-current text-white`}
                  text={words[2]?.text}
                />
                <span
                  onClick={() => removeWord(words[2]?.text)}
                  className={`remove ${
                    words[2]
                      ? "opacity-100 cursor-pointer"
                      : "opacity-0  cursor-default"
                  }`}
                >
                  <span>x</span>
                </span>
              </CustomRock>
              <CustomRock className="justify-center lg:justify-end mt-5">
                <Rock4
                  className="w-60"
                  textClass={`${
                    words[3]?.text.length > 20 ? "text-lg" : "text-2xl"
                  } font-semibold fill-current text-white`}
                  text={words[3]?.text}
                />
                <span
                  onClick={() => removeWord(words[3]?.text)}
                  className={`remove ${
                    words[3]
                      ? "opacity-100 cursor-pointer"
                      : "opacity-0  cursor-default"
                  }`}
                >
                  <span>x</span>
                </span>
              </CustomRock>
              <CustomRock className="justify-center lg:justify-start mt-5">
                <Rock5
                  className="w-60"
                  textClass={`${
                    words[4]?.text.length > 20 ? "text-lg" : "text-2xl"
                  } font-semibold fill-current text-white`}
                  text={words[4]?.text}
                />
                <span
                  onClick={() => removeWord(words[4]?.text)}
                  className={`remove ${
                    words[4]
                      ? "opacity-100 cursor-pointer"
                      : "opacity-0  cursor-default"
                  }`}
                >
                  <span>x</span>
                </span>
              </CustomRock>
            </div>
          </div>
        </div>

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-end mt-10">
              <Button
                className="bg-tertiary hover:bg-tertiary-light active:bg-tertiary-light text-xs font-semibold text-white"
                type="submit"
              >
                CONCLUIR EXERCÍCIO
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NeedsExplanation;
