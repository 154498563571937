import React from "react";
import ReactPlayer from "react-player";
import Modal from "react-responsive-modal";

const VideoModal = ({ video, open, onClose }) => (
  <Modal
    styles={{
      modal: {
        margin: 0,
        padding: 0,
        backgroundColor: "#6b6a6b",
      },
      closeButton: {
        top: "4px",
        right: "4px",
        outline: "none",
      },
      overlay: {
        background: "rgba(0, 0, 0, 0.7)"
      },
    }}
    center
    open={open}
    onClose={onClose}
  >
    <div className="lg:h-28-rem lg:w-50-rem">
      <ReactPlayer width="100%" height="100%" controls url={video} />
    </div>
  </Modal>
);

export default VideoModal;
