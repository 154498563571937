import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getResult } from "services/formService";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import Button from "components/Button";
import YourMazeSvg from "components/Svgs/YourMazeSvg";
import { StyledDiv } from "./style";

const YourMaze = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit } = useForm();
  const [jackals, setJackals] = useState([]);
  const [feelingsWords, setFeelingsWords] = useState([]);
  const [needsWords, setNeedsWords] = useState([]);
  const [convertometer, setConvertometer] = useState([]);
  const { user } = useSelector((state) => state);
  const { id } = user;

  const onSubmit = async (responses) => {
    history.push(`/certificate`);
  };

  const getQuestions = async (exercise, id, setters) => {
    try {
      const { data } = await getResult(exercise, id);

      setters(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async () => {
      dispatch({ type: "LOADING", payload: true });
      try {
        await getQuestions(2, id, setJackals);
        await getQuestions(4, id, setFeelingsWords);
        await getQuestions(6, id, setNeedsWords);
        await getQuestions(7, id, setConvertometer);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: "LOADING", payload: false });
      }
    })();
  }, [dispatch, id]);

  const toString = (array) => {
    const text = array?.answer?.map((item) => item.text);
    return text?.toString().replaceAll(",", " - ");
  };

  const getText = (id) => {
    if (!id || !jackals.length) return "";
    return jackals?.find((question) => question?.id === id ?? "")?.answer?.text;
  };

  const getConvertometer = (i) => {
    return convertometer[i]?.answer?.text;
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header variant="quinary" />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary pb-14 p-5 pt-24 lg:px-24 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="flex">
          <ArrowRight className="text-primary" />
          <p className="md:px-5 md:pl-8 md:pr-16">
            Esse é o SEU Labirinto da Empatia!
            <br />
            Percorrendo-o você aprendeu a identificar o uso da linguagem CHACAL,
            reconhecer seus sentimentos e necessidades e usar o conversômetro
            CHACAL/GIRAFA.
            <br />
            Agora você já domina o idioma GIRAFÊS, e poderá aplicá-lo para criar
            conexões mais autênticas.
          </p>
        </div>
        <StyledDiv className="md:px-16 mt-10">
          <div className="custom-div">
            <p className="top text-gray-700 text-center text-sm font-semibold bg-gray-style-2 py-3">
              LABIRINTO DA EMPATIA DE
            </p>
            <div className="p-3 md:p-8">
              <YourMazeSvg
                className=""
                feelingsWords={toString(feelingsWords)}
                needsWords={toString(needsWords)}
              />
            </div>
            <div className="jackals grid grid-cols-3 auto-cols-fr text-xs mt-2 md:mt-5 text-gray-700">
              <div className="jackals-top bg-primary text-white font-semibold border-r border-dashed border-white rounded-tl-xl">
                <span>SEUS CHACAIS</span>
              </div>
              <div className="jackals-top bg-primary text-white font-semibold border-r border-dashed border-white">
                <span>O que você diz sobre os outros....</span>
              </div>
              <div className="jackals-top bg-primary text-white font-semibold rounded-tr-xl">
                <span>O que você diz sobre você mesmo...</span>
              </div>
              <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold text-white">ROTULA e AVALIA</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("24")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("25")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold text-white">IMPÕE JULGAMENTOS</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("26")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("27")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold text-white">JULGAMENTO MORAL</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("28")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("29")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold text-white">FAZ DEMANDAS E ULTIMATOS</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("30")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("31")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold text-white">MERECIMENTO</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("32")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("33")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold text-white">PERCEBE ESCASSEZ</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("51")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("52")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
            <span className="font-semibold text-white">
                ACUSA E NEGA RESPONSABILIDADES
              </span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("35")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("36")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold text-white">NÃO TEM ESCOLHA</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("37")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("38")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold text-white">ESCUTA SELETIVA</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("53")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("54")}</span>
            </div>
            <div className="border-r border-black border-dashed">
              <span className="font-semibold text-white">
                PENSAMENTOS PASSADOS E FUTUROS
              </span>
            </div>
            <div className="border-r  border-black border-dashed">
              <span className="response">{getText("40")}</span>
            </div>
            <div className="border-black border-dashed">
              <span className="response">{getText("41")}</span>
            </div>
            </div>
            <div className="convertometer convertometer-top">
              <p className="top text-white text-center text-sm font-semibold bg-quinary py-3 border-b border-white box-shadow-1">
                SEU CONVERSÔMETRO - CHACAL/GIRAFÊS
              </p>
              <div className="flex flex-col items-center justify-center bg-quinary-light px-14 pt-8 pb-14 text-custom-gray-style-2 box-shadow-2">
                <span className="w-full font-semibold">
                  Quando eu vejo/ouço
                </span>
                <p className="w-full break-all">{getConvertometer(0)}</p>
                <span className="w-full font-semibold">sinto...</span>
                <p className="w-full break-all">{getConvertometer(1)}</p>
                <span className="w-full font-semibold">porque preciso...</span>
                <p className="w-full break-all">{getConvertometer(2)}</p>
                <span className="w-full font-semibold">
                  Será que eu/você poderia...
                </span>
                <p className="w-full break-all">{getConvertometer(3)}</p>
              </div>
            </div>
          </div>
        </StyledDiv>

        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex justify-end mt-8 px-2 md:px-14">
              <Button
                className="bg-primary hover:bg-primary-light active:bg-primary-light text-xs font-semibold text-white"
                type="submit"
              >
                AVANÇAR
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default YourMaze;
