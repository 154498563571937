import styled from "@emotion/styled/macro";
import { ReactComponent as JackalSvg } from "static/images/convertometer-jackal.svg";
import { ReactComponent as GiraffeSvg } from "static/images/convertometer-giraffe.svg";

export const CustomDiv = styled.div`
  box-shadow: 5px 6px 31px 1px rgba(102, 102, 102, 0.75);
  background: linear-gradient(135deg, #d6d6d6 0%, #bfbfbe 50%, #757474 100%);
`;

export const CustomTextArea = styled.div`
  background: linear-gradient(135deg, #fdfdfd 0%, #e5e5e5 33%, #d4d4d4 100%);
  border-width: 6px;
`;

export const Jackal = styled(JackalSvg)`
  left: -12.5%;
  top: 2.5%;
`;

export const Giraffe = styled(GiraffeSvg)`
  right: -18.8%;
  top: 32%;
`;
