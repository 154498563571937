import React from "react";
import { useSelector } from "react-redux";
import styled from "@emotion/styled/macro";
import { ReactComponent as Maze } from "static/images/maze-symbol.svg";
import { ReactComponent as Logo } from "static/images/logo.svg";
import { ReactComponent as LogoWork } from "static/images/logo-work.svg";
import { ReactComponent as LogoWorkMobile } from "static/images/logo-work-mobile.svg";

const MazeIcon = styled(Maze)`
  margin-left: -2.5rem;

  @media (min-width: 768px) {
    margin-left: -3.5rem;
  }
`;

const Styled = styled.div`
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);

  .min-w {
    min-width: 3rem !important;

    @media (min-width: 768px) {
      min-width: 5rem !important;
    }
  }

  .custom-h {
    height: 1.7rem;
  }
`;

const Header = ({ variant }) => {
  const { isWork } = useSelector((state) => state.state);
  return (
    <Styled className="fixed top-0 flex w-full h-12 md:h-16 z-50">
      <div className={`md:flex-1 min-w bg-${variant ?? "white"}`}></div>
      <div className="container max-w-5xl bg-white">
        <MazeIcon className="absolute w-20 md:w-28" />
        <div className="flex h-full items-center text-primary ml-9 md:ml-14">
          {isWork ? (
            <>
              <LogoWorkMobile className="md:hidden custom-h ml-1" />
              <LogoWork className="hidden md:flex md:h-10 lg:h-14" />
            </>
          ) : (
            <Logo className="h-8 md:h-10 lg:h-14" />
          )}
        </div>
      </div>
      <div className="flex-1 bg-white"></div>
    </Styled>
  );
};

export default Header;
