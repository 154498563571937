import { createActions, createReducer } from "reduxsauce";

//Action types e Creators
export const { Types, Creators } = createActions({
  setUserId: ["id"],
  setQuizId: ["id"],
  setQuizRespondentId: ["id"],
});

//Reducer Handlers
const INITIAL_STATE = {
  id: "",
  quiz_id: "",
  quiz_respondent_id: "",
};

const id = (state = INITIAL_STATE, action) => {
  return { ...state, id: action.payload };
};

const quiz_id = (state = INITIAL_STATE, action) => {
  return { ...state, quiz_id: action.payload };
};

const quiz_respondent_id = (state = INITIAL_STATE, action) => {
  return { ...state, quiz_respondent_id: action.payload };
};


//Criando Reducer
export default createReducer(INITIAL_STATE, {
  [Types.SET_USER_ID]: id,
  [Types.SET_QUIZ_ID]: quiz_id,
  [Types.SET_QUIZ_RESPONDENT_ID]: quiz_respondent_id,
});
