/* eslint-disable no-loop-func */
import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Header from "components/Header";
import ArrowRight from "components/ArrowRight";
import Button from "components/Button";
import { ReactComponent as Grammar } from "static/images/grammar.svg";
import { ReactComponent as GrammarMobile } from "static/images/grammar-mobile.svg";

const CertificatePage = () => {
  const { handleSubmit } = useForm();
  const history = useHistory();

  const onSubmit = async () => {
    history.push("/download-page");
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header variant="quinary" />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary pt-24 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="flex px-5 lg:px-24">
          <ArrowRight className="text-primary" />
          <p className="pl-2 pr-4 mb-10">
            Essa é a Gramática de Girafês. Ela é um guia prático para você
            aplicar o girafês em diferentes relacionamentos: consigo mesmo(a) e
            com os outros.
            <br />
            Que tal imprimí-la e tê-la à mão?
          </p>
        </div>
        <div className="bg-gray-style-2 pt-8 pb-14">
          <div className="flex justify-center md:justify-start px-5 lg:px-24 font-bold">
            <ArrowRight className="text-white" />
            <p className="pl-2 mb-10 text-white">GRAMÁTICA DE GIRAFÊS</p>
          </div>
          <Grammar className="hidden md:block mx-4" />
          <GrammarMobile className="md:hidden" />
          <div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex justify-end mt-10 px-5 lg:px-24">
                  <Button
                    className="bg-primary hover:bg-primary-light active:bg-primary-light text-xs font-semibold text-white"
                    type="submit"
                  >
                    AVANÇAR
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificatePage;
