import baseService from "./baseService";

export const generate = (body) => baseService().post(`/quiz`, body);

export const verifyLink = (link) => baseService().get(`/quiz/${link}`);

export const getLink = (link) => baseService().get(`/quizrespondent/${link}`);

export const restart = (respondent_id) =>
  baseService().patch(`/quizrespondent/${respondent_id}`);

export const getGroup = (group) => baseService().get(`/group/${group}`);

export const getExercise = (exercise, isWork) =>
  baseService().get(`/exercise/${exercise}${isWork ? "?type=2" : ""}`);

export const getResult = (exercise, respondent_id) =>
  baseService().get(`/result/${exercise}/${respondent_id}`);

export const respondent = (body) => baseService().post("/respondent", body);

export const sendAwnsers = (body) => baseService().post("/answer", body);

export const finishQuiz = (body) => baseService().post("/finishquiz", body);

export const getPDF = (idPDF, respondent_id, quiz_id) =>
  baseService().get(
    `/pdf/${idPDF}?respondent_id=${respondent_id}&quiz_id=${quiz_id}`,
    { responseType: "blob" }
  );
