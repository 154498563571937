import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getLink, restart } from "services/formService";
import { pages } from "static/payloads/pages";
import Header from "components/Header";
import Button from "components/Button";

const MainRedirector = () => {
  const [lastExercise, setLastExercise] = useState(null);
  const [finishQuiz, setFinishQuiz] = useState(false);
  const [respondentId, setRespondentId] = useState(null);
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const continueMaze = async (exercise) => {
    if (exercise) {
      const page = pages.find((page) => page.exerciseId === exercise);

      history.push(page?.page);
    } else {
      history.push("/videos/1");
    }
  };

  const restartMaze = async (respondent) => {
    try {
      dispatch({ type: "LOADING", payload: true });
      await restart(respondent);

      history.push("/videos/1");
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: "LOADING", payload: false });
    }
  };

  useEffect(() => {
    const initPage = async (link) => {
      try {
        dispatch({ type: "LOADING", payload: true });
        const { data } = await getLink(link);

        if (data) {
          if (data?.quiz?.group_id) {
            dispatch({ type: "IS_WORK", payload: true });
            dispatch({ type: "SET_GROUP_ID", payload: data.group_id });
          }

          if (data?.respondent?.finished) {
            setFinishQuiz(true);
          }

          setLastExercise(data.last_exercise_id);

          dispatch({ type: "SET_USER_ID", payload: data?.respondent_id });

          setRespondentId(data?.respondent_id);

          dispatch({ type: "SET_LINK", payload: data?.link });

          dispatch({ type: "IS_LINK_VALID", payload: true });

          dispatch({ type: "SET_QUIZ_ID", payload: data?.quiz_id });

          dispatch({ type: "SET_QUIZ_RESPONDENT_ID", payload: data?.id });
        } else {
          history.push("/");
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: "LOADING", payload: false });
      }
    };

    initPage(params.link);
  }, [params.link, dispatch, history]);

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header />
      <div className="container flex flex-col  items-center min-h-screen max-w-5xl bg-secondary px-5 lg:px-24  pb-14 pt-24 md:pt-32 lg:pt-36 xl:pt-28">
        <p className="text-primary font-bold text-lg mt-8 mb-5">
          Bem vindo de volta!
        </p>
        <p className="mb-20 text-center">
          Você já {finishQuiz ? "completou" : "iniciou"} o seu Labirinto da
          empatia, para prosseguir <br />
          escolha uma das opções abaixo:
        </p>
        <Button
          className="bg-primary hover:bg-primary-light active:bg-primary-light text-sm font-semibold text-white mb-8"
          onClick={() => continueMaze(lastExercise)}
        >
          {finishQuiz ? "VER LABIRINTO" : "CONTINUAR DE ONDE PAREI"}
        </Button>
        <Button
          className="bg-primary hover:bg-primary-light active:bg-primary-light text-sm font-semibold text-white"
          onClick={() => restartMaze(respondentId)}
        >
          COMEÇAR DO INICIO
        </Button>
      </div>
    </div>
  );
};

export default MainRedirector;
