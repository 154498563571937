import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { sendAwnsers } from "services/formService";
import { ReactComponent as JackalsMaze } from "static/images/jackals-maze.svg";
import { ReactComponent as PlayerButton } from "static/images/player-button.svg";
import Header from "components/Header";
import Button from "components/Button";
import ArrowRight from "components/ArrowRight";
import { Circle, Panel, JackalIcon, CustomDiv } from "./style";
import { jackals } from "static/payloads/jackals";
import VideoModal from "components/VideoModal";

const Jackals = () => {
  const { handleSubmit } = useForm();
  const { id, quiz_respondent_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  const [jackal, setJackal] = useState(0);
  const [values, setValues] = useState(jackals);

  const [show, setShow] = useState(false);

  const onSubmit = async () => {
    if (jackal < 9) {
      setJackal(jackal + 1);
    } else {
      dispatch({ type: "LOADING", payload: true });
      const answers = values
        .map((jackal) => {
          return jackal.questions.map((question) => {
            return {
              question_id: question.id,
              text: question.value,
            };
          });
        })
        .flat();

      try {
        const body = {
          respondent_id: id,
          quiz_respondent_id: quiz_respondent_id,
          answers: answers,
        };

        await sendAwnsers(body);

        history.push(`/jackals-responses`);

        dispatch({ type: "LOADING", payload: false });
      } catch (error) {
        console.log(error);
        dispatch({ type: "LOADING", payload: false });
      }
    }
  };

  const setFormValue = (jackal, i, value) => {
    const newArr = [...values];
    newArr[jackal].questions[i].value = value;

    setValues(newArr);
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-quaternary">
      <Header variant="primary" />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary px-5 pt-24 py-24 lg:px-10 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="lg:px-14">
          <div className="flex">
            <ArrowRight className="text-primary" />
            <p className="px-2">
              Seguindo as instruções do vídeo, entre no primeiro círculo do
              LABIRINTO DA EMPATIA. Percorra-o, em sentido anti-horário,
              observando e reconhecendo os seus CHACAIS. Preencha o box dos
              CHACAIS com os quais se identifica e clique em avançar.
            </p>
          </div>
          <div className="flex mt-5">
            <ArrowRight className="text-primary" />
            <p className="px-2">
              Se você ficou com dúvidas sobre o exercício, assista o vídeo
              novamente{" "}
              <PlayerButton
                className="inline-block w-9 cursor-pointer"
                onClick={() => setShow(true)}
              />{" "}
              ou pergunte a(o) seu/sua facilitador(a).
            </p>
          </div>
        </div>

        <CustomDiv className="flex flex-col lg:flex-row mt-16">
          <div className="flex flex-col lg:w-1/2 px-6 lg:px-0 lg:pr-2">
            <JackalsMaze />
          </div>
          <div className="flex flex-col relative lg:w-1/2 pr-2 mt-8">
            <Panel>
              <div className="panel-top flex flex-col justify-center items-center bg-primary text-white">
                <Circle
                  className="text-2xl text-black font-medium"
                  width="2.5rem"
                  height="2.5rem"
                >
                  {jackal + 1}
                </Circle>
                <JackalIcon />
                <div className="flex flex-col justify-center items-center text-center py-8 px-10">
                  <p className="text-2xl font-medium uppercase">
                    {values[jackal]?.title}
                  </p>
                  <p className="mt-5">{values[jackal]?.example}</p>
                </div>
              </div>
              <div className="panel-bottom">
                {values[jackal]?.questions?.map((question, i) => {
                  return (
                    <div
                      className="bg-white text-center border border-gray-800"
                      key={i}
                    >
                      <label
                        className="block py-1 px-6 text-sm italic"
                        htmlFor={question.id}
                      >
                        {question.text}
                      </label>
                      <textarea
                        className="w-full px-4 h-32 outline-none"
                        id={question.id}
                        value={question.value}
                        maxLength="175"
                        onChange={({ target }) =>
                          setFormValue(jackal, i, target.value)
                        }
                      ></textarea>
                    </div>
                  );
                })}
              </div>
            </Panel>
          </div>
        </CustomDiv>
        <div className="flex justify-end mt-10 lg:mt-0">
          <div
            className={`flex lg:w-1/2 w-full ${
              jackal >= 1 ? "justify-between" : "justify-end"
            }`}
          >
            {jackal >= 1 && (
              <Button
                className="bg-tertiary text-xs font-semibold text-white"
                onClick={() => setJackal(jackal - 1)}
              >
                RETORNAR
              </Button>
            )}
            {jackal < 9 && (
              <Button
                className="bg-tertiary hover:bg-tertiary-light active:bg-tertiary-light text-xs font-semibold text-white"
                onClick={handleSubmit(onSubmit)}
              >
                AVANÇAR
              </Button>
            )}

            {jackal > 8 && (
              <Button
                className="bg-quinary hover:bg-quinary-light active:bg-quinary-light text-tiny md:text-xs font-semibold text-white"
                onClick={handleSubmit(onSubmit)}
              >
                CONCLUIR O EXERCÍCIO
              </Button>
            )}
          </div>
        </div>
      </div>
      <VideoModal
        video="videos/video-3-chacais.mp4"
        open={show}
        onClose={() => setShow(false)}
      />
    </div>
  );
};

export default Jackals;
