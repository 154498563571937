/* eslint-disable no-loop-func */
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyLink } from "services/formService";
import Header from "components/Header";
import Button from "components/Button";

const StartPage = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === "/despertar") {
      dispatch({ type: "LOADING", payload: true });
      (async () => {
        try {
          dispatch({ type: "IS_LINK_VALID", payload: true });
          dispatch({ type: "IS_WORK", payload: true });
          dispatch({ type: "SET_WORKSHOP", payload: true });
          
          const { data } = await verifyLink("despertar");

          dispatch({ type: "SET_QUIZ_ID", payload: data.id });
          dispatch({ type: "SET_GROUP_ID", payload: data.group_id });
        } catch (error) {
          console.log(error);
        } finally {
          dispatch({ type: "LOADING", payload: false });
        }
      })();
    }
  }, [location.pathname, dispatch]);

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header />
      <div className="container flex flex-col  items-center min-h-screen max-w-5xl bg-secondary px-5 lg:px-24  pb-14 pt-24 md:pt-32 lg:pt-36 xl:pt-28">
        <div className="flex flex-col">
          <h1 className="text-xl font-semibold mt-5 mb-7">
            Seja bem-vindo(a) à esta ferramenta de aprendizagem pela
            experiência.
          </h1>
          <p className="text-lg leading-loose font-normal mb-4">
            O Labirinto da Empatia® tem como objetivo apoiar você a identificar
            seus pensamentos, avaliações e julgamentos e que despertam em você
            variados sentimentos e necessidades singulares e subjacentes,
            evocados por uma determinada situação e temática específica, que nos
            colocam na maioria das vezes em uma posição defensiva e reativa nos
            relacionamentos. Ao reconhecê-los, fazendo seu próprio caminho na
            jornada do labirinto, você aprenderá os elementos e etapas
            essenciais para uma atitude mais compassiva e responsiva por meio de
            uma comunicação mais autêntica e consciente, consigo e com os
            outros.
          </p>
          <p className="text-lg leading-loose font-normal mb-4">
            Percorrer o labirinto permitirá a você praticar a autoempatia
            acessando as suas necessidades e valores mais importantes ao
            desacelerar e escutar ativamente a si mesmo(a) em estado presente de
            consciência. Este é o primeiro passo e um aprendizado elementar para
            a prática da conexão empática com os outros. Portanto, ao
            praticá-lo, você estará mais preparado(a) para participar da vida
            emocional dos outros, respondendo aos seus sentimentos e
            necessidades com mais atenção e presença e contribuindo para o
            bem-estar.
          </p>
          <p className="text-lg leading-loose font-normal mb-4">
            Acolher e cuidar de si mesmo(a) assumindo uma postura
            autorresponsável é fundamental para oferecer empatia nas relações de
            trabalho e sustentar um ambiente mais confiável, seguro e saudável.
          </p>
          <p className="text-lg leading-loose font-normal">
            Que tal começar agora?
          </p>
        </div>

        <Button
          className="bg-primary hover:bg-primary-light active:bg-primary-light text-xl font-semibold text-white mt-14"
          onClick={() => history.push("/data")}
        >
          COMEÇAR
        </Button>
      </div>
    </div>
  );
};

export default StartPage;
