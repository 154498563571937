import styled from "@emotion/styled/macro";
import ReactPlayer from "react-player";

export const CustomCollapse = styled.div`
  box-shadow: 0px 1px 10px 1px rgba(102, 102, 102, 0.3);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  .top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .ReactCollapse--collapse {
    background-color: #ffff;
    transition: height 500ms;
  }
`;

export const Player = styled(ReactPlayer)`
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 40%);
`;
