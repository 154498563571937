import React from "react";
import styled from "@emotion/styled/macro";

const Styled = styled.svg`
  .rock-2-st0 {
    fill: #baa598;
  }
  .rock-2-st1 {
    opacity: 0.2;
  }
  .rock-2-st2 {
    fill: #ffffff;
  }
  .rock-2-st3 {
    opacity: 0.8;
  }
  .rock-2-st4 {
    fill: #998a80;
  }
`;

const Rock2 = ({ className, text, textClass }) => (
  <Styled
    className={className}
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 417.2 173.1"
    style={{ enableBackground: "new 0 0 417.2 173.1" }}
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          className="rock-2-st0"
          d="M16.2,147C-38.3,74.9,62.7-1.4,194.3,1.1c42.9,1.6,118.1,7,158.4,25.3c56.9,24.9,65.2,68.5,62.9,98.5
			c-3.5,53.6-94.6,52.3-176.1,38c-54.4-8.7-68.2-1.6-114.9,4.1c-26,3.8-99.6,3.9-108.3-21.2"
        />
      </g>
      <g className="rock-2-st1">
        <path
          className="rock-2-st2"
          d="M48.6,74.1C58.4,54.2,102,38.9,132.9,31C174,21.3,233,23,280.1,27c35.6,4,74.4,8.4,80.6,31.7
			c8.3,30.5-61.9,27-102.5,23.1c-47.1-4.1-79.8-5.9-119.4,4.9c-20.7,6.1-35.1,13.3-63.7,7c-23.8-5.5-38.7-15.6-23.7-26.9"
        />
      </g>
      <text
        className={textClass}
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {text}
      </text>
      <g className="rock-2-st3">
        <path
          className="rock-2-st4"
          d="M18.8,147.4c-0.5,23.9,95,23.7,118.6,20.6c38.7-5,63-12.7,108.2-7.2c37.1,5.1,66.4,17,117.7,5.5
			c39.4-8.9,51.8-25.2,52-47.4c-0.7-6.1,0.1-11.1-4-16.1c-2.3,14.2-27.8,40.1-51.8,45.7c-30.8,7.1-87.5,1.5-121.5-2.2
			c-37.5-2.6-75.2-3.4-110.4,0.4c-36.9,4.4-67.6,10.6-110-2.1"
        />
      </g>
      <g className="rock-2-st1">
        <path
          className="rock-2-st2"
          d="M324.5,57.7c-28.6,8.9-66.8-15.2-26.6-19c7.4-0.1,28.3,0.7,33.9,4.1c12.5,6.5,1.1,16.3-12.5,16.6"
        />
      </g>
      <g className="rock-2-st1">
        <path
          className="rock-2-st2"
          d="M257.8,49.6c1.5,6-14.8,7.3-15,1.5S252.6,43.7,257.8,49.6"
        />
      </g>
    </g>
  </Styled>
);

export default Rock2;
