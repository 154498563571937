import styled from "@emotion/styled/macro";

export const StyledDiv = styled.div`
  .custom-div {
    box-shadow: inset 2px 0px 5px -1px rgba(102, 102, 102, 0.5),
      -1px 0px 9px 0px rgba(102, 102, 102, 0.7);
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .top {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .box-shadow-1 {
    box-shadow: -2px 0px 3px 0px rgba(0,0,0,0.5), inset 0px 1px 6px -1px rgba(0,0,0,0.5);
  }

  .box-shadow-2 {
    box-shadow:  -2px 2px 4px 0px rgba(0,0,0,0.5)
  }

  .jackals {
    div {
      display: flex;
      align-items: center;
      &:not(.jackals-top) {
        background-color: #bccae5;
      }
      span {
        width: 100%;
        padding: 0.55rem 1.5rem;
      }
    }
  }

  .convertometer {
    &.convertometer-top {
      background-color: #bccae5;
    }
  }
`;
