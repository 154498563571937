import React from "react";
import styled from "@emotion/styled/macro";

const Styled = styled.svg`
  .rock-4-st0 {
    fill: #bc7d5c;
  }
  .rock-4-st1 {
    opacity: 0.2;
  }
  .rock-4-st2 {
    fill: #ffffff;
  }
  .rock-4-st3 {
    fill: #b37156;
  }
`;

const Rock4 = ({ className, text, textClass }) => (
  <Styled
    className={className}
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 427.1 144.9"
    style={{ enableBackground: "new 0 0 417.2 173.1" }}
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <path
            className="rock-4-st0"
            d="M252.6,2.7c102.9,8.3,190,46.9,170.4,89.7c-19.4,50.7-146.5,59.8-250,46.1C99,129.2,8.5,102,2.3,68.1
				C-4.4,19.7,151.3-6.2,252.6,2.7"
          />
        </g>
        <g className="rock-4-st1">
          <path
            className="rock-4-st2"
            d="M249.7,10.7c55-2.6,118.8,20.2,143,37.7c24.2,16.7,22,43.8,2.2,60.5c-15.4,14.9-99,43-101.2,11.4
				c0-22.8,26.4-37.7-8.8-59.6c-24.2-14-74.8-12.3-96.8-25.4c-33-18.4,22-23.7,57.2-23.7"
          />
        </g>
        <text
          className={textClass}
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {text}
        </text>
        <g>
          <path
            className="rock-4-st3"
            d="M76.8,17.4C-9,35.8-23.3,81,40.5,107.3c57.2,23.7,135.3,36.4,221.1,34.6c-19.8-8.8-70.4-14-96.8-21
				C134,113,96.6,105.1,74.6,93.7c-39.6-19.3-37.4-55.2,0-75.4"
          />
        </g>
        <g className="rock-4-st1">
          <path
            className="rock-4-st2"
            d="M342.2,49.1c-10.1-2.6-18.3-12.2-9.1-17.7c9-4.2,24.3,1.8,31.3,5.9C395.3,54.5,361.2,55.5,342.2,49.1"
          />
        </g>
        <g className="rock-4-st1">
          <path
            className="rock-4-st2"
            d="M379.6,76.6c-18.6-5.9-7-16.4,9.3-11.2c21,6.6,2.3,21.1-8.2,11.2"
          />
        </g>
      </g>
    </g>
  </Styled>
);
export default Rock4;
