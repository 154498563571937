import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getResult } from "services/formService";
import Header from "components/Header";
import Button from "components/Button";
import { Responses } from "./style";

const Jackals = () => {
  const { handleSubmit } = useForm();
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const [results, setResuls] = useState([]);

  const onSubmit = () => {
    history.push(`/fact`);
  };

  const getQuestions = async (exercise, id) => {
    dispatch({ type: "LOADING", payload: true });
    try {
      const { data } = await getResult(exercise, id);

      setResuls(data);

      dispatch({ type: "LOADING", payload: false });
    } catch (error) {
      dispatch({ type: "LOADING", payload: false });
      console.log(error);
    }
  };

  const getText = (id) => {
    if (!id || !results.length) return "";
    return results?.find((question) => question?.id === id ?? "")?.answer?.text;
  };

  useEffect(() => {
    getQuestions(2, user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-quaternary">
      <Header variant="primary" />
      <div className="container flex flex-col min-h-screen max-w-5xl bg-secondary pb-14 p-5 lg:px-24">
        <div className="lg:px-14 pt-24 md:pt-32 lg:pt-36 xl:pt-28">
          <div className="flex px-10 mb-10">
            <p className="font-semibold text-gray-700">
              Esses são os seus CHACAIS - reconhecê-los é uma etapa essencial
              para convertê-los em girafas. Estamos prontos para avançar para os
              próximos passos.
            </p>
          </div>
          <Responses className="grid grid-cols-3 auto-cols-fr text-xs mt-5 text-gray-700">
            <div className="top bg-primary text-white text-base font-semibold border-r border-dashed border-white">
              <span className="px-8">CHACAL</span>
            </div>
            <div className="top bg-primary text-white text-base font-semibold border-r border-dashed border-white">
              <span className="px-8">O QUE VOCÊ DIZ SOBRE OS OUTROS...</span>
            </div>
            <div className="top bg-primary text-white text-base font-semibold">
              <span className="px-8">O QUE VOCÊ DIZ SOBRE VOCÊ MESMO...</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold">ROTULA e AVALIA</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("24")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("25")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold">IMPÕE JULGAMENTOS</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("26")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("27")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold">JULGAMENTO MORAL</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("28")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("29")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold">FAZ DEMANDAS E ULTIMATOS</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("30")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("31")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold">MERECIMENTO</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("32")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("33")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold">PERCEBE ESCASSEZ</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("51")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("52")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold">
                ACUSA E NEGA RESPONSABILIDADES
              </span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("35")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("36")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold">NÃO TEM ESCOLHA</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("37")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("38")}</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="font-semibold">ESCUTA SELETIVA</span>
            </div>
            <div className="border-r border-b border-black border-dashed">
              <span className="response">{getText("53")}</span>
            </div>
            <div className="border-b border-black border-dashed">
              <span className="response">{getText("54")}</span>
            </div>
            <div className="border-r border-black border-dashed">
              <span className="font-semibold">
                PENSAMENTOS PASSADOS E FUTUROS
              </span>
            </div>
            <div className="border-r  border-black border-dashed">
              <span className="response">{getText("40")}</span>
            </div>
            <div className="border-black border-dashed">
              <span className="response">{getText("41")}</span>
            </div>
          </Responses>
        </div>

        <div className="flex justify-end px-24 mt-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Button
              className="bg-primary hover:bg-primary-light active:bg-primary-light text-xs font-semibold text-white"
              type="submit"
            >
              AVANÇAR
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Jackals;
