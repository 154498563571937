import styled from "@emotion/styled/macro";

const Button = styled.button`
  padding: 0.5rem 2.2rem;
  outline: none !important;
  border: none;
  border-radius: 10rem;
  box-shadow: 1px 2px 3px 0.5px #95a5a6;

  &:active {
    box-shadow: 0px 0.5px 3px 0.5px #95a5a6;
    transform: translateY(2px);
  }
`;

export default Button;
