export const jackals = [
  {
    title: "Rotula e Avalia",
    example:
      "Ex: Eu sou indecisa. | Ele é muito egoísta, pois sempre pensa nele primeiro.",
    questions: [
      {
        id: 24,
        text:
          "O que você diz sobre os outros - (Você é/ está... | Eles são/ estão …)",
        sub_id: 1,
        value: "",
      },
      {
        id: 25,
        text: "O que você diz sobre si mesmo(a) - (Eu sou/ estou…)",
        sub_id: 1,
        value: "",
      },
    ],
  },
  {
    title: "Impõe Julgamentos",
    example:
      "Ex: Meu chefe deveria me oferecer uma nova oportunidade profissional. | Eu deveria ter respondido ao comentário pejorativo que ela me fez.",
    questions: [
      {
        id: 26,
        text:
          "O que você diz sobre os outros - (Você deve/ deveria... | Eles devem/ deveriam…)",
        sub_id: 2,
        value: "",
      },
      {
        id: 27,
        text: "O que você diz sobre si mesmo(a) - (Eu devo/ deveria…)",
        sub_id: 2,
        value: "",
      },
    ],
  },
  {
    title: "Julgamento Moral",
    example:
      "Ex: O comportamento dele naquele encontro foi impróprio. | Ela é uma pessoa má, não se importa comigo.",
    questions: [
      {
        id: 28,
        text:
          "O que você diz sobre os outros - (Você é/ está certo – errado | bom – ruim | justo – injusto…)",
        sub_id: 3,
        value: "",
      },
      {
        id: 29,
        text:
          "O que você diz sobre si mesmo(a) - (Eu sou/ estou certo – errado | bom – ruim | justo – injusto…)",
        sub_id: 3,
        value: "",
      },
    ],
  },
  {
    title: "Faz Demandas e Ultimatos",
    example:
      "Ex: Se ele não me ajudar hoje, então nunca mais ajudo ele. | Ela não deveria fazer isso.",
    questions: [
      {
        id: 30,
        text:
          "O que você diz sobre os outros - (Se você fizer/ não fizer, então... | Se eles fizerem/ não fizerem, então…)",
        sub_id: 4,
        value: "",
      },
      {
        id: 31,
        text: "O que você diz sobre si mesmo(a) - (Eu mereço/ não mereço…)",
        sub_id: 4,
        value: "",
      },
    ],
  },
  {
    title: "Merecimento",
    example: "Ex: Ela merece um castigo pelo que fez. | Eu não mereço isso.",
    questions: [
      {
        id: 32,
        text:
          "O que você diz sobre os outros - (Você merece/ não merece... | Eles merecem/ não merecem…)",
        sub_id: 5,
        value: "",
      },
      {
        id: 33,
        text: "O que você diz sobre si mesmo(a) - (Eu mereço/ não mereço…)",
        sub_id: 5,
        value: "",
      },
    ],
  },
  {
    title: "Percebe Escassez",
    example: "Ex: Se eu não for escolhida agora, não terei outra oportunidade.",
    questions: [
      {
        id: 51,
        text: "O que você diz sobre os outros - (Sou eu ou você…)",
        sub_id: 6,
        value: "",
      },
      {
        id: 52,
        text: "O que você diz sobre si mesmo(a) - (Sou eu ou você…)",
        sub_id: 6,
        value: "",
      },
    ],
  },
  {
    title: "Acusa e Nega Responsabilidade",
    example:
      "Ex: Ela me faz sentir culpada. | Não tinha como fazer diferente, meu chefe me obrigou.",
    questions: [
      {
        id: 35,
        text: "O que você diz sobre os outros - (É tudo sua culpa...)",
        sub_id: 7,
        value: "",
      },
      {
        id: 36,
        text: "O que você diz sobre si mesmo(a) - (É tudo minha culpa)",
        sub_id: 7,
        value: "",
      },
    ],
  },
  {
    title: "Não tem Escolha",
    example:
      "Ex: Não precisamos mais falar sobre isso. | Tem que ser tudo do seu jeito!",
    questions: [
      {
        id: 37,
        text:
          "O que você diz sobre os outros - (Eles não podem.../ tem que.../ devem...)",
        sub_id: 8,
        value: "",
      },
      {
        id: 38,
        text:
          "O que você diz sobre si mesmo(a) - (Eu não posso.../ tenho que.../ devo...)",
        sub_id: 8,
        value: "",
      },
    ],
  },
  {
    title: "Escuta Seletiva",
    example:
      "Ex: Já sei o que você vai me dizer agora. | Você está sempre fazendo outra coisa quando eu falo com você!",
    questions: [
      {
        id: 53,
        text:
          "O que você diz sobre os outros - (Quando eu estou/você está dando lição de moral, corrigindo ou discutindo. Pensando naquilo que irá dizer em seguida.)",
        sub_id: 9,
        value: "",
      },
      {
        id: 54,
        text:
          "O que você diz sobre si mesmo(a) - (Quando eu estou/você está dando lição de moral, corrigindo ou discutindo. Pensando naquilo que irá dizer em seguida.)",
        sub_id: 9,
        value: "",
      },
    ],
  },
  {
    title: "Pensamentos Passados e Futuros",
    example:
      "Ex: Se ao menos eu tivesse um claro entendimento do que é esperado de mim, eu teria uma contribuição diferente. | E se eu pudesse prever o que ela vai dizer",
    questions: [
      {
        id: 40,
        text:
          "O que você diz sobre os outros - (Se ao menos eles fizessem…/ tivessem.../ pudessem...)",
        sub_id: 10,
        value: "",
      },
      {
        id: 41,
        text:
          "O que você diz sobre si mesmo(a) - (Se ao menos eu fizesse…/ tivesse.../ pudesse...)",
        sub_id: 10,
        value: "",
      },
    ],
  },
];
