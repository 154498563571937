import React from "react";
import styled from "@emotion/styled/macro";

const Styled = styled.svg`
  .rock-1-st0 {
    fill: #74726d;
  }
  .rock-1-st1 {
    opacity: 0.2;
  }
  .rock-1-st2 {
    fill: #ffffff;
  }
  .rock-1-st3 {
    fill: #686561;
  }
`;

const Rock1 = ({ className, text, textClass }) => (
  <Styled
    className={className}
    version="1.1"
    id="Camada_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 395.1 149.4"
    style={{ enableBackground: "new 0 0 395.1 149.4" }}
    xmlSpace="preserve"
  >
    <g>
      <g>
        <g>
          <path
            className="rock-1-st0 "
            d="M11,84.5c18.7,29.9,184.2,62.2,243.1,64.3C349.7,152,423,99.3,383.9,53.8c-43.3-48.9-103-57-201.1-51.7
				C110.1,5.9-49.7,42,19.9,95.5"
          />
        </g>
        <g className="rock-1-st1">
          <path
            className="rock-1-st2"
            d="M205.6,8.2c52.9-9.5,121.1,5.7,150.2,27.3c24.6,18.9,36.9,49.5-15.3,64.4c-67.6,19.3-83.1-20.9-119.7-39.3
				c-17.1-7.7-102.7-47.2-22.9-50.3"
          />
        </g>
        <text
          className={textClass}
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {text}
        </text>

        <g>
          <path
            className="rock-1-st3"
            d="M58.9,28C23.4,32.8-3.4,56.6,1.1,74.9c4.8,20.5,36.1,29.6,63.4,38.1c54.5,17,142,40.7,209.2,33.8
				c-50.1-14.3-111.1-20.4-163.5-36.7C70.6,98.8,6.6,56.3,64.8,27.2"
          />
        </g>
        <g className="rock-1-st1">
          <path
            className="rock-1-st2"
            d="M323.7,54.2c-10-23.2,15.7-35,36.4-7.8c18,23.9-15.6,33.1-33.7,11.1"
          />
        </g>
        <g className="rock-1-st1">
          <path
            className="rock-1-st2"
            d="M303.3,38.4c-21.8,5.5-40.6-11.1-15.9-17c20.3-5.3,36.1,10.9,16.1,18.7"
          />
        </g>
      </g>
    </g>
  </Styled>
);

export default Rock1;
