import { createActions, createReducer } from "reduxsauce";

//Action types e Creators
export const { Types, Creators } = createActions({
  loading: ["status"],
  isLinkValid: ["status"],
  setLink: ["link"],
  isWork: ["status"],
  setGroupId: ["id"],
  setWorkshop: ["boolean"],
});

//Reducer Handlers
const INITIAL_STATE = {
  loading: false,
  isLinkValid: false,
  link: null,
  isWork: false,
  group_id: null,
  workshop: false,
};

const loading = (state = INITIAL_STATE, action) => {
  return { ...state, loading: action.payload };
};

const isLinkValid = (state = INITIAL_STATE, action) => {
  return { ...state, isLinkValid: action.payload };
};

const setLink = (state = INITIAL_STATE, action) => {
  return { ...state, link: action.payload };
};

const isWork = (state = INITIAL_STATE, action) => {
  return { ...state, isWork: action.payload };
};

const setGroupId = (state = INITIAL_STATE, action) => {
  return { ...state, group_id: action.payload };
};

const setWorkshop = (state = INITIAL_STATE, action) => {
  return { ...state, workshop: action.payload };
};

//Criando Reducer
export default createReducer(INITIAL_STATE, {
  [Types.LOADING]: loading,
  [Types.IS_LINK_VALID]: isLinkValid,
  [Types.SET_LINK]: setLink,
  [Types.IS_WORK]: isWork,
  [Types.SET_GROUP_ID]: setGroupId,
  [Types.SET_WORKSHOP]: setWorkshop,
});
