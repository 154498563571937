/* eslint-disable no-loop-func */
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "components/Header";
import Button from "components/Button";
import { generate } from "services/formService";

const GenerateLink = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const generateQuiz = async (work) => {
    dispatch({ type: "LOADING", payload: true });
    try {
      if (work) {
        const { data } = await generate({
          user_owner_id: 1,
          group_id: 1,
        });

        dispatch({ type: "IS_WORK", payload: true });

        dispatch({ type: "SET_GROUP_ID", payload: data.group_id });

        dispatch({ type: "SET_LINK", payload: data.link });

        dispatch({ type: "IS_LINK_VALID", payload: true });

        dispatch({ type: "SET_QUIZ_ID", payload: data.id });

        history.push(`start`);
      } else {
        const { data } = await generate({
          user_owner_id: 1,
        });

        dispatch({ type: "SET_LINK", payload: data.link });

        dispatch({ type: "IS_LINK_VALID", payload: true });

        dispatch({ type: "SET_QUIZ_ID", payload: data.id });

        history.push(`start`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch({ type: "LOADING", payload: false });
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-quaternary">
      <Header variant="primary" />
      <div className="container flex flex-col justify-center items-center min-h-screen max-w-5xl bg-secondary px-5 lg:px-24  pb-14 pt-24 md:pt-32 lg:pt-36 xl:pt-28">
        <Button
          className="bg-primary hover:bg-primary-light active:bg-primary-light text-xl font-semibold text-white"
          onClick={() => generateQuiz()}
        >
          Individual
        </Button>
        <Button
          className="bg-tertiary hover:bg-tertiary-light active:bg-tertiary-light text-xl font-semibold text-white mt-10"
          onClick={() => generateQuiz(true)}
        >
          Ambientes de trabalho
        </Button>
      </div>
    </div>
  );
};

export default GenerateLink;
